import { Component } from '@angular/core';

@Component({
  template: `
    <div
      fxLayout="column"
      fxLayoutAlign="center center"
      fxLayoutGap="8px"
      [style.width.%]="100"
      [style.height]="'calc(100vh - 54px)'"
      [style.font-size.px]="18"
    >
      <h1 color="primary">ACCES PRESTATAIRE ISITECH</h1>
      <span color="medium" [style.font-weight]="500"
        >Votre accès à ISITECH a bien été activé mais votre périmètre n'a pas
        encore été défini !</span
      >
      <span color="medium"
        >Merci de vous rapprocher de votre référent RIVP afin qu'il définisse
        vos droits d'accès et vous permette de consulter votre patrimoine et vos
        équipements.</span
      >
      <span color="secondary">L'équipe Support ISITECH</span>
    </div>
  `,
})
export class LandingComponent {}
