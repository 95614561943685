/** @format */

import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { IntentService } from '../../../../_services/intent.service';
import { ColumnOption } from '../../../datagrid/column-def.class';
import { IOptions } from '../../../datagrid/datagrid.component';
import { IRecord } from '../../../datagrid/datasource';
import { IDatatableOptions, IDatatableRecords } from '../../../datagrid/datatable.class';

@Component({
  selector: 'app-operation-log',
  templateUrl: './operation-log.component.html',
  styleUrls: ['./operation-log.component.scss'],
})
export class OperationLogComponent implements OnInit {
  @Input()
  light = false;

  @Input()
  assetReference: string;

  @Input()
  operationReference: string;

  private columns: ColumnOption[] = [
    { property: 'value.reference', label: 'Référence', searchable: true, sortable: true },
    { property: 'value.description', label: 'Description', searchable: true, sortable: true },
    {
      property: 'value.creationDate',
      label: 'Date de création',
      type: 'date',
      format: 'L LT',
      searchable: true,
      sortable: true,
    },
    { property: 'value.serviceCode', label: 'Code du service', searchable: true, sortable: true },
    {
      property: 'value.workType',
      label: 'Prestation',
      searchable: true,
      sortable: true,
      displayWith: (record: any) => this.translate.instant('intent-' + record.value.workType),
    },
    {
      property: 'value.status',
      label: 'État',
      displayWith: (record: any) => this.translate.instant(record.value.status, { gender: 'female' }),
      searchable: true,
      sortable: true,
    },
    {
      property: 'value.event',
      label: 'Évènement',
      displayWith: (record: any) => this.translate.instant(`intent-${record.value.status}-${record.value.event}`),
      searchable: true,
      sortable: true,
    },
    {
      property: 'value.eventDate',
      label: "Date de l'évènement",
      type: 'date',
      format: 'L LT',
      searchable: true,
      sortable: true,
    },
    {
      property: 'value.logDate',
      label: 'Date du journal',
      type: 'date',
      format: 'L LT',
      searchable: true,
      sortable: true,
    },
    {
      property: 'value.equipmentWorkingOrder',
      label: "État de l'équipement après opération",
      searchable: true,
      sortable: true,
    },
  ];
  private lightColumns: ColumnOption[] = [
    { property: 'value.eventDate', label: "Date de l'opération", type: 'date', format: 'L LT', sortable: true },
    {
      property: 'value.event',
      label: 'Évènement',
      displayWith: (record: any) => this.translate.instant(`intent-${record.value.status}-${record.value.event}`),
    },
    {
      property: 'value.status',
      label: 'État',
      displayWith: (record: any) => this.translate.instant(record.value.status, { gender: 'female' }),
    },
    { property: 'value.serviceCode', label: 'Code du service' },
    {
      property: 'value.workType',
      label: 'Prestation',
      displayWith: (record: any) => this.translate.instant('intent-' + record.value.workType),
    },
    { property: 'value.description', label: 'Description' },
  ];

  public options: IOptions = {
    pagination: { default: 2, options: [10, 20, 50, 100] },
    service: (options: IDatatableOptions): Observable<IDatatableRecords<IRecord>> =>
      this.service.datatable({
        equipmentReference: this.assetReference,
        operationReference: this.operationReference,
        kind: 'operation-log',
        query: options,
      }),
    columns: [],
    enableRowNumber: false,
    enableStickyColumn: false,
    enableReorderColumn: false,
    enableSelect: false,
    enableHideShowColumns: false,
    enableExport: false,
    enableFullscreen: false,
    disableScrollbarModule: true,
  };

  constructor(
    private modalController: ModalController,
    private service: IntentService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.options.columns = this.light ? this.lightColumns : this.columns;
    this.options.showPagination = this.light ? 'never' : 'always';
    this.options.sortedColumns = this.light ? [{ column: 0, dir: 'desc' }] : [{ column: 7, dir: 'desc' }];
    this.options.enableExport = !this.light;
  }

  dismiss() {
    this.modalController.dismiss();
  }
}
