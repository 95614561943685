<app-page-tracking *ngIf="tracking" [origin]="tracking.origin" [page]="tracking.page" [id]="tracking.id">
</app-page-tracking>
<ion-header>
  <ion-toolbar color="primary">
    <ion-title>
      <ion-label translate>{{name}}</ion-label>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div *ngIf="contractor" @fadeIn class="contractor" fxLayout="column" fxLayoutGap="16px">
    <ion-card class="contact-block">
      <ion-card-header>
        <ion-card-title translate>information</ion-card-title>
      </ion-card-header>
      <ion-card-content>
        <div fxLayout="column" fxLayoutGap="8px">
          <ion-label *ngIf="contractor.address || contractor.zipCode || contractor.city">
            {{contractor.address}} {{contractor.zipCode}} {{contractor.city}}
          </ion-label>
          <div *ngIf="contractor.phone || contractor.fax" fxLayoutAlign="start center" fxLayoutGap="16px">
            <ion-label *ngIf="contractor.phone" [innerHTML]="'hotline-number' | translate:{number: contractor.phone}">
            </ion-label>
            <ion-label *ngIf="contractor.fax" [innerHTML]="'fax-number' | translate:{number: contractor.fax}">
            </ion-label>
          </div>
        </div>
      </ion-card-content>
    </ion-card>
    <div fxLayout="row wrap" fxLayoutAlign="space-evenly start">
      <ion-card class="user-block" *ngFor="let user of users">
        <ion-card-header>
          <ion-card-title>{{user.role}}</ion-card-title>
        </ion-card-header>
        <ion-card-content fxLayoutAlign="start start" fxLayout="column" [style.width.%]="100">
          <div fxLayoutAlign="start center" fxLayout="row wrap">
            <mat-form-field [style.min-width.px]="300">
              <mat-label>Email</mat-label>
              <input type="email" autocomplete="off" matInput [value]="user.email" disabled>
            </mat-form-field>
            <mat-form-field [style.min-width.px]="300">
              <mat-label>Téléphone</mat-label>
              <input type="tel" autocomplete="off" matInput [value]="user.phoneNumber" disabled>
            </mat-form-field>
          </div>
          <div fxLayoutAlign="start center" fxLayout="row wrap">
            <mat-form-field [style.min-width.px]="300">
              <mat-label>Prénom</mat-label>
              <input type="text" autocomplete="off" matInput [value]="user.firstname" disabled>
            </mat-form-field>
            <mat-form-field [style.min-width.px]="300">
              <mat-label>Nom</mat-label>
              <input type="text" autocomplete="off" matInput [value]="user.lastname" disabled>
            </mat-form-field>
          </div>
        </ion-card-content>
      </ion-card>
    </div>
    <div id="documents" fxLayout="row wrap" fxLayoutAlign="space-evenly start">
      <app-document *ngFor="let item of documents" [item]="item"></app-document>
    </div>
  </div>
  <sesio-loader *ngIf="!contractor" @fadeOut></sesio-loader>
</ion-content>