import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  Equipment,
  EquipmentDocument,
  EquipmentDocumentKind,
  EquipmentDocumentService,
  fade,
  fadeIn,
  fadeOut,
  Family,
} from 'sesio-lib';
import { ModalController } from '@ionic/angular';
import { SubSink } from 'subsink';
import { EquipmentService } from '../../../../_services/equipment.service';
import {
  clone,
  head,
  map,
  merge,
  omit,
  values,
} from 'lodash-es';
import { environment } from '../../../../../environments/environment';
import { FileInput } from 'ngx-material-file-input';
import { IOptions } from '../../../datagrid/datagrid.component';
import { TranslateService } from '@ngx-translate/core';
import {
  IDatatableOptions,
  IDatatableRecords,
} from '../../../datagrid/datatable.class';
import { Observable } from 'rxjs';
import { IRecord } from '../../../datagrid/datasource';
import { DatagridComponent } from '../../../datagrid/datagrid.component';
import { ModelMapper } from 'model-mapper';

@Component({
  templateUrl: './admin-equipment-document-edit.modal.html',
  styleUrls: ['./admin-equipment-document-edit.modal.scss'],
  animations: [fadeOut, fade, fadeIn],
})
export class AdminEquipmentDocumentEditModal implements OnInit, OnDestroy {
  public repo = environment.repoUrl;

  @Input()
  public id: string;

  @Input()
  public families: Family[] = values(Family);

  public kinds = values(EquipmentDocumentKind);

  public loading = true;
  public ready = false;
  public updating = false;
  public equipmentDocument: EquipmentDocument;
  public equipments: Equipment[];
  public formGroup: UntypedFormGroup;
  public fileCtrl = new UntypedFormControl();
  public editEquipments = false;
  public options: IOptions = {
    pagination: { default: 2, options: [10, 20, 50, 100] },
    service: (
      options: IDatatableOptions
    ): Observable<IDatatableRecords<IRecord>> =>
      this.equipmentService.datatable(options, { families: this.families }),
    columns: [
      {
        property: 'kind',
        label: 'kind',
        displayWith: (record: any) => record.kind?.name,
        searchable: true,
        sortable: true,
      },
      {
        property: 'reference',
        label: 'reference',
        searchable: true,
        sortable: true,
      },
      {
        label: 'group',
        property: 'organizationalUnit.pathNames',
        width: 350,
        content: 'group',
        sortable: true,
        searchable: true,
      },
      {
        property: 'contractor.name',
        label: 'contractor',
        searchable: true,
        sortable: true,
      },
    ],
    enableRowNumber: false,
    enableStickyColumn: false,
    enableReorderColumn: false,
    enableSelect: true,
    enableHideShowColumns: false,
    enableExport: false,
    enableFullscreen: false,
    disableScrollbarModule: false,
    actions: [
      {
        icon: { name: 'check_circle' },
        tooltip: 'Valider la sélection',
        exec: (datagrid) => this.updateEquipments(datagrid),
      },
      {
        icon: { name: 'cancel' },
        tooltip: 'Annuler',
        color: 'var(--ion-color-warning)',
        exec: () => {
          this.options.selected = map(this.equipments, (eq) =>
            merge(clone(eq), { _id: eq.id })
          );
          this.editEquipments = false;
        },
      },
    ],
    selected: [],
  };

  public file: File;
  private subsink = new SubSink();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private equipmentDocumentService: EquipmentDocumentService,
    private equipmentService: EquipmentService,
    private modalController: ModalController,
    private changeDetectorRef: ChangeDetectorRef,
    private translate: TranslateService
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.id) {
      this.equipmentDocument = await this.equipmentDocumentService
        .get(this.id)
        .toPromise();
      const response = await fetch(
        `${environment.repoUrl}/${this.equipmentDocument.path}`
      );
      const data = await response.blob();
      this.fileCtrl.setValue(
        new FileInput([
          (this.file = new File([data], this.equipmentDocument?.filename, {
            type: head(this.equipmentDocument?.mimetypes),
            lastModified: this.equipmentDocument?.date?.unix(),
          })),
        ])
      );
      this.equipments = await this.equipmentDocumentService
        .getEquipments(this.id)
        .toPromise();
      this.options.selected = map(this.equipments, (eq) =>
        merge(clone(eq), { _id: eq.id })
      );
    }
    await this.buildFormGroup();
    this.loading = false;
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  public setReady(ready: boolean): void {
    this.ready = ready;
    this.changeDetectorRef.detectChanges();
  }

  public async dismiss(reload = false): Promise<void> {
    await this.modalController.dismiss(reload);
  }

  public async edit() {
    if (!this.formGroup.valid) {
      return;
    }
    this.updating = true;
    const value: any = this.formGroup.getRawValue();
    if (this.id) {
      await this.equipmentDocumentService
        .updateEquipmentDocument(
          this.id,
          merge(value, {
            filename: this.equipmentDocument?.filename,
            path: this.equipmentDocument?.path,
            size: this.equipmentDocument?.size,
            date: this.equipmentDocument?.date,
            mimetypes: this.equipmentDocument?.mimetypes,
            userId: this.equipmentDocument?.userId,
          }),
          map(this.equipments, 'id'),
          this.fileCtrl.untouched ? undefined : this.file
        )
        .toPromise();
    } else {
      await this.equipmentDocumentService
        .addEquipmentsDocument(map(this.equipments, 'id'), {
          file: this.file,
          family: value.family,
          kind: value.kind,
          description: value.description,
        })
        .toPromise();
    }
    this.updating = false;
    this.dismiss(true);
  }

  private async buildFormGroup(): Promise<void> {
    this.formGroup = this.formBuilder.group({
      family: [
        {
          value: this.equipmentDocument?.family,
          disabled: !!this.equipments?.length,
        },
        Validators.required,
      ],
      kind: [this.equipmentDocument?.kind, Validators.required],
      title: [this.equipmentDocument?.title],
      description: [this.equipmentDocument?.description],
      file: [this.file, Validators.required],
    });
    this.fileCtrl.valueChanges.subscribe((value) => {
      this.formGroup.get('file').setValue((this.file = head(value._files)));
    });
  }

  private async updateEquipments(datagrid: DatagridComponent): Promise<void> {
    this.editEquipments = false;
    this.equipments = map(datagrid.selected, (selected) =>
      new ModelMapper(Equipment).map(selected)
    );
  }
}
