/** @format */

import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {
  EmbededOrganizationalUnit,
  Equipment,
  EquipmentDocumentKind,
  EquipmentDocumentService,
  EquipmentFile,
  EquipmentKind,
  EquipmentKindCategory,
  EquipmentStatusColor,
  EquipmentStatusIcon,
  fade,
  fadeIn,
  fadeOut,
  Family,
  Inspection,
  InspectionStateOptions,
  InterventionStatusColor,
  OrganizationalUnit,
} from 'sesio-lib';
import {ModalController} from '@ionic/angular';
import {SubSink} from 'subsink';
import {EquipmentService} from '../../../../_services/equipment.service';
import {filter, findIndex, get, map, omit, values} from 'lodash-es';
import {environment} from '../../../../../environments/environment';
import {EquipmentEditComponent} from '../../equipment-edit/equipment-edit.component';
import moment from 'moment';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmDialogComponent} from '../../../confirm-dialog/confirm-dialog.component';
import {EquipmentKindService} from '../../../../_services/equipment-kind.service';

@Component({
  templateUrl: './equipment-datagrid-edit.modal.html',
  styleUrls: ['./equipment-datagrid-edit.modal.scss'],
  animations: [fadeOut, fade, fadeIn],
})
export class EquipmentDatagridEditModal implements OnInit, OnDestroy {
  public repo = environment.repoUrl;
  public InspectionStateOptions = InspectionStateOptions;
  public InterventionStatusColor = InterventionStatusColor;
  public EquipmentStatusColor = EquipmentStatusColor;
  public EquipmentStatusIcon = EquipmentStatusIcon;

  @Input()
  public administration: boolean;

  @Input()
  public id: string;

  @Input()
  public families: Family[] = values(Family);

  @Input()
  public categories: EquipmentKindCategory[] = values(EquipmentKindCategory);

  @Input()
  public organizationalUnit: EmbededOrganizationalUnit;

  @ViewChild(EquipmentEditComponent, {
    static: false,
    read: EquipmentEditComponent,
  })
  private equipmentEditComponent: EquipmentEditComponent;

  public loading = true;
  public ready = false;
  public updating = false;
  public tabIndex = 0;
  public equipment: Equipment;
  public kind: EquipmentKind;
  public formGroup: UntypedFormGroup;
  public documentsToAdd: {kind; file: File}[] = [];
  private documentsToRemove: string[] = [];

  inspections: Inspection[];

  private subsink = new SubSink();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private equipmentService: EquipmentService,
    private equipmentKindService: EquipmentKindService,
    private modalController: ModalController,
    private changeDetectorRef: ChangeDetectorRef,
    private dialog: MatDialog
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.id) {
      this.equipment = await this.equipmentService.get(this.id, false).toPromise();
      if (this.equipment?.kind) this.kind = await this.equipmentKindService.get(this.equipment.kind.id).toPromise();
      this.inspections = await this.equipmentService.getInspections(this.id).toPromise();
    }
    this.formGroup = this.formBuilder.group({});
    this.loading = false;
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  public setReady(ready: boolean): void {
    this.ready = ready;
    this.changeDetectorRef.detectChanges();
  }

  public async dismiss(): Promise<void> {
    await this.modalController.dismiss(this.equipmentEditComponent?.saved);
  }

  public display(value: OrganizationalUnit): string {
    return value ? value.name : null;
  }

  public async edit(): Promise<void> {
    this.updating = true;
    try {
      await this.equipmentEditComponent.save();
      this.id = this.equipmentEditComponent.equipment.id;
    } finally {
      this.updating = false;
    }
  }

  public addNew() {
    this.equipmentEditComponent.reset();
    this.id = null;
  }

  public addDocuments(kind: EquipmentDocumentKind, files: FileList): void {
    Array.from(files).forEach(file => this.documentsToAdd.push({kind, file}));
  }
  public removeDocument(index): void {
    this.documentsToAdd.splice(index, 1);
  }

  public deleteDocument($event: MouseEvent, id: string): boolean {
    $event.preventDefault();
    this.documentsToRemove.push(id);
    const docs: EquipmentFile[] = this.formGroup.get('files').value;
    docs.splice(
      findIndex(docs, d => d.id === id),
      1
    );
    this.formGroup.get('files').setValue(docs);
    return false;
  }

  async setArchived(archived: boolean) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Confirmation d'archivage",
        message: `Étes-vous sûr de vouloir ${archived ? 'archiver' : 'déarchiver'} l'équipement ${
          this.equipment.reference
        } ?`,
      },
    });
    if (!(await dialogRef.afterClosed().toPromise())) return;
    const archivedData = await this.equipmentService.setArchived(this.equipment.id, archived).toPromise();
    this.equipment.archived = archivedData.archived;
    this.equipment.archivedChangedAt = archivedData.archivedChangedAt;
    this.equipment.archivedChangedBy = archivedData.archivedChangedBy;
  }
}
