/** @format */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QualityColorPipe } from './quality-colors.pipe';
import { ControlRateColorPipe } from './control-rate-colors.pipe';
import { AvailabilityRateColorPipe } from './availability-rate-colors.pipe';

@NgModule({
  declarations: [QualityColorPipe, ControlRateColorPipe, AvailabilityRateColorPipe],
  imports: [CommonModule],
  exports: [QualityColorPipe, ControlRateColorPipe, AvailabilityRateColorPipe],
})
export class ColorsModule {}
