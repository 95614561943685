/** @format */

import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import * as Highcharts from 'highcharts/highstock';
import {cloneDeep, isEqual} from 'lodash-es';
import moment, {Moment} from 'moment';
import {Subscription, lastValueFrom} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {ChartComponent, fadeOut, highchartsOptions} from 'sesio-lib';
import {BoilerRoomService} from '../../../../_services/boiler-room.service';

interface IRange {
  start: Moment;
  end: Moment;
}

@Component({
  selector: 'app-sensor-chart',
  templateUrl: './sensor-chart.component.html',
  styleUrls: ['./sensor-chart.component.scss'],
  animations: [fadeOut],
})
export class WindChartComponent implements OnInit, OnDestroy {
  @Input()
  public realEstateStructureId: string;

  @Input('range')
  public set setRange(range: UntypedFormGroup) {
    if (this.rangeSub) {
      this.rangeSub.unsubscribe();
      this.rangeSub = null;
    }
    this.range = range;
    if (this.range) {
      this.rangeValue = this.range.value;
      this.rangeSub = this.range.valueChanges.pipe(debounceTime(100)).subscribe({
        next: value => {
          if (value && value.start && value.end && !isEqual(this.rangeValue, value) && this.chart) {
            this.rangeValue = value;
            this.loadData();
          }
        },
      });
    } else {
      this.rangeValue = null;
    }
  }

  options = {
    chart: {
      zoomType: 'x',
    },
    title: '',
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            'viewFullscreen',
            'printChart',
            'separator',
            'downloadPNG',
            'downloadJPEG',
            'downloadPDF',
            'downloadSVG',
            'separator',
            'downloadCSV',
            'downloadXLS',
            'openInCloud',
          ],
        },
      },
    },
    credits: {enabled: false},
    data: {dateFormat: 'dd/mm/YYYY'},
    rangeSelector: {enabled: false, inputEnabled: false},
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              itemWidth: 200,
            },
          },
        },
      ],
    },
    legend: {
      enabled: true,
      verticalAlign: 'top',
    },
    tooltip: {
      shared: true,
      split: false,
      useHTML: true,
      headerFormat: '<small>{point.key}</small><br/>',
    },
    xAxis: {
      type: 'category',
      tickmarkPlacement: 'on',
      startOnTick: true,
      endOnTick: true,
      events: {
        afterSetExtremes: event => {
          if (event.type !== 'setExtremes') return;
          if (event.min !== this.rangeValue?.start.valueOf() || event.max !== this.rangeValue?.end.valueOf()) {
            this.rangeValue = {start: moment(event.min), end: moment(event.max)};
            this.range.setValue(this.rangeValue);
            this.loadData();
          }
        },
      },
    },
    yAxis: [
      {
        title: {
          text: this.translate.instant('wind-with-unit'),
          offset: 0,
          align: 'high',
          rotation: 0,
          style: {
            fontSize: '12px',
          },
          textAlign: 'left',
          x: -30,
        },
        startOnTick: false,
        endOnTick: false,
        opposite: false,
        labels: {align: 'left', x: -20, format: '{value:.0f}'},
        lineWidth: 1,
        resize: {enabled: true},
      },
    ],
    series: [
      {
        name: this.translate.instant('indoor-temperature'),
        type: 'spline',
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
        tooltip: {
          pointFormat:
            '<span style="color:{point.color}">\u25CF</span> ' + '{series.name}: <b>{point.y:.1f}°C</b><br/>',
        },
      },
      {
        name: this.translate.instant('indoor-humidity'),
        type: 'spline',
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
        tooltip: {
          pointFormat: '<span style="color:{point.color}">\u25CF</span> ' + '{series.name}: <b>{point.y:.0f}%</b><br/>',
        },
      },
      {
        name: this.translate.instant('air-quality-indoor'),
        type: 'spline',
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
        tooltip: {
          pointFormat:
            '<span style="color:{point.color}">\u25CF</span> ' + '{series.name}: <b>{point.y:.0f}ppm</b><br/>',
        },
      },
    ],
  };

  private range: UntypedFormGroup;
  private rangeValue: IRange;
  private rangeSub: Subscription;

  constructor(private translate: TranslateService, private boilerRoomService: BoilerRoomService) {}

  ngOnInit(): void {
    Highcharts.setOptions(highchartsOptions[this.translate.getDefaultLang()]);
  }

  ngOnDestroy(): void {
    if (this.rangeSub) this.rangeSub.unsubscribe();
  }

  private chart: ChartComponent;
  public async load(chart: ChartComponent) {
    if (chart) this.chart = chart;
    else if (!this.chart) return;
    await this.loadData();
  }

  private async loadData(): Promise<void> {
    this.chart.setLoading(true);
    const data = await lastValueFrom(this.boilerRoomService.getSensorChartData(this.realEstateStructureId));
    this.chart.setSerieData(data.indoorTemperature, 0);
    this.chart.setSerieData(data.indoorHumidity, 1);
    this.chart.setSerieData(data.indoorAirQuality, 2);
    this.chart.setExtremes(this.rangeValue.start, this.rangeValue.end);
    this.chart.setLoading(false);
  }
}
