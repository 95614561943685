/** @format */

import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ControlContainer, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {
  HypervisionConfiguration,
  HypervisionConnectivityKind,
  HypervisionProviderCodec,
  HypervisionProviderName,
  fade,
  fadeOut,
} from 'sesio-lib';
import {SubSink} from 'subsink';

@Component({
  selector: 'app-hypervision-edit',
  templateUrl: './hypervision-edit.component.html',
  styleUrls: ['./hypervision-edit.component.scss'],
  animations: [fade, fadeOut],
})
export class HypervisionEditComponent implements OnInit, OnDestroy {
  @Input()
  public equipmentId: string;

  @Input()
  public configuration: HypervisionConfiguration;

  @Input()
  hideTitle = false

  public formGroup: UntypedFormGroup;
  public providerNames: HypervisionProviderName[] = Object.keys(HypervisionProviderName).map(
    key => HypervisionProviderName[key]
  );
  public providerCodecs: HypervisionProviderCodec[] = Object.keys(HypervisionProviderCodec).map(
    key => HypervisionProviderCodec[key]
  );
  public connectivityKinds: HypervisionConnectivityKind[] = Object.keys(HypervisionConnectivityKind).map(
    key => HypervisionConnectivityKind[key]
  );

  private subsink = new SubSink();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    private controlContainer: ControlContainer
  ) {}

  async ngOnInit(): Promise<void> {
    this.formGroup = this.controlContainer.control as UntypedFormGroup;
    this.formGroup.setControl(
      'provider',
      this.formBuilder.group({
        name: [this.configuration?.provider?.name, Validators.required],
        codec: [this.configuration?.provider?.codec, Validators.required],
        productName: [this.configuration?.provider?.productName],
        description: [this.configuration?.provider?.description],
      })
    );
    this.formGroup.setControl(
      'connectivity',
      this.formBuilder.group({
        kind: [this.configuration?.connectivity?.kind, Validators.required],
        identifiers: this.formBuilder.group({
          devEUI: [this.configuration?.connectivity?.identifiers?.devEUI],
          appEUI: [this.configuration?.connectivity?.identifiers?.appEUI],
          appKEY: [this.configuration?.connectivity?.identifiers?.appKEY],
          serialNumber: [this.configuration?.connectivity?.identifiers?.serialNumber],
          objeniousDeviceId: [this.configuration?.connectivity?.identifiers?.objeniousDeviceId],
        }),
      })
    );
    this.changeDetectorRef.markForCheck();
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }
}
