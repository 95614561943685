import { Component, Input, OnInit } from '@angular/core';
import { OrganizationalUnitService } from '../../../_services/organizational-unit.service';

@Component({
  selector: 'app-organizational-unit-contact-info',
  templateUrl: './organizational-unit-contact-info.component.html',
  styleUrls: ['./organizational-unit-contact-info.component.scss'],
})
export class OrganizationalUnitContactInfoComponent implements OnInit {
  @Input('id')
  set setId(id: string) {
    if (!id) {
      this.id = id;
      this.info = null;
    } else if (this.id !== id) {
      this.id = id;
      this.loading = true;
      this.organizationalUnitService
        .getContactInfo(this.id)
        .toPromise()
        .then((info) => (this.info = info))
        .then(() => (this.loading = false));
    }
  }
  private id: string;
  info: any;
  loading = true;

  constructor(private organizationalUnitService: OrganizationalUnitService) {}

  ngOnInit() {}
}
