/** @format */

import {propertyMap} from 'model-mapper';
import moment, {Moment} from 'moment';

export enum ReportState {
  NONE = 'none',
  ERROR = 'error',
  SENT = 'sent',
}

export const ReportStateName = {
  [ReportState.NONE]: 'Inconnu',
  [ReportState.ERROR]: 'Erreur',
  [ReportState.SENT]: 'Transmis',
};

export class EquipmentFailure {
  @propertyMap({type: 'Moment'})
  public startDate: moment.Moment;

  @propertyMap({type: 'Moment'})
  public endDate: moment.Moment;

  @propertyMap()
  public duration: number;

  @propertyMap()
  public state: number;

  @propertyMap()
  reportState?: string;

  @propertyMap()
  startReported?: boolean;

  @propertyMap({type: 'Moment'})
  startReportedAt?: Moment;

  @propertyMap()
  endReported?: boolean;

  @propertyMap()
  externalId?: string;

  @propertyMap()
  reportedAcknowledgement?: boolean;

  @propertyMap({type: 'Moment'})
  reportedAcknowledgementDate?: Moment;

  @propertyMap({type: 'Moment'})
  createdAt?: Moment;
}
