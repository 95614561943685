/** @format */

import { propertyMap } from 'model-mapper';
import { EmbededContractor, EmbededOrganizationalUnit, Home } from 'sesio-lib';

export enum UserKind {
  USER = 'user',
  CONTRACTOR = 'contractor',
  ADMINISTRATOR = 'administrator',
  API = 'api',
}

export class UserFavorites {
  @propertyMap()
  public equipments: string[];
}

export class UserFollow {
  @propertyMap()
  public equipments: string[];
}

export class User {
  @propertyMap({ source: '_id' })
  public id: string;

  @propertyMap()
  public username: string;

  @propertyMap()
  public email: string;

  @propertyMap()
  public firstname: string;

  @propertyMap()
  public lastname: string;

  @propertyMap()
  public phoneNumber: string;

  @propertyMap()
  public role: string;

  @propertyMap()
  public avatar: string;

  @propertyMap()
  public kind: UserKind;

  @propertyMap({ type: EmbededContractor })
  public contractor: EmbededContractor;

  @propertyMap()
  public contractorPassword: string;

  @propertyMap({ default: false })
  public administrator: boolean;

  @propertyMap({ default: [] })
  public spaces: string[];

  @propertyMap()
  public adminAccess: any;

  @propertyMap({ type: [EmbededOrganizationalUnit] })
  public perimeter: EmbededOrganizationalUnit[];

  @propertyMap({ default: null })
  public home: Home;

  @propertyMap({ type: UserFavorites, default: { equipments: [] } })
  public favorites: UserFavorites;

  @propertyMap({ type: UserFollow, default: { equipments: [] } })
  public follow: UserFollow;

  @propertyMap({ default: {} })
  public datatable: any;

  @propertyMap({ default: {} })
  public tutorials: { [key: string]: boolean };

  @propertyMap()
  public isOverwritten: boolean;

  @propertyMap()
  public position: {
    reference: string;
    label: string;
  };

  public get fullname(): string {
    return `${this.firstname} ${this.lastname}`;
  }

  public addFavoriteEquipment(id: string): void {
    if (!this.favorites) {
      this.favorites = { equipments: [] };
    }
    if (!this.favorites.equipments) {
      this.favorites.equipments = [];
    }
    if (this.favorites.equipments.indexOf(id) === -1) {
      this.favorites.equipments.push(id);
    }
  }

  public removeFavoriteEquipment(id: string): void {
    if (!this.favorites) {
      this.favorites = { equipments: [] };
    }
    if (!this.favorites.equipments) {
      this.favorites.equipments = [];
    }
    const index = this.favorites.equipments.indexOf(id);
    if (index !== -1) {
      this.favorites.equipments.splice(index, 1);
    }
  }

  public addFollowEquipment(id: string): void {
    if (!this.follow) {
      this.follow = { equipments: [] };
    }
    if (!this.follow.equipments) {
      this.follow.equipments = [];
    }
    if (this.follow.equipments.indexOf(id) === -1) {
      this.follow.equipments.push(id);
    }
  }

  public removeFollowEquipment(id: string): void {
    if (!this.follow) {
      this.follow = { equipments: [] };
    }
    if (!this.follow.equipments) {
      this.follow.equipments = [];
    }
    const index = this.follow.equipments.indexOf(id);
    if (index !== -1) {
      this.follow.equipments.splice(index, 1);
    }
  }
}
