export const lightPalette = [
  '#f44336',
  '#e91e63',
  '#9c27b0',
  '#673ab7',
  '#3f51b5',
  '#2196f3',
  '#03a9f4',
  '#00bcd4',
  '#009688',
  '#4caf50',
  '#8bc34a',
  '#cddc39',
  '#ffeb3b',
  '#ffc107',
  '#ff9800',
  '#ff5722',
  '#795548',
  '#607d8b',
];

export const palette = [
  '#71C7E2',
  '#F19195',
  '#EEC151',
  '#BFE2D6',
  '#C4C8DB',

  '#815799',
  '#FADE7D',
  '#DF5970',
  '#23AFD6',
  '#F59138',

  '#F4AD84',
  '#8AD1BA',
  '#D4DFE3',
  '#95D4A3',
  '#EF553C',

  '#508AB2',
  '#D5B60D',
  '#F6CFB8',
  '#F1815C',
  '#CFA0BE',

  '#717FB6',
  '#96D2CF',
  '#589651',
  '#ECDCC5',
  '#E1CE60',

  '#B4DCE1',
  '#F16A40',
  '#AD5B8F',
  '#3D4A81',
  '#63B8B3',

  '#A4BF43',
  '#D2CFC8',
  '#FFB0B9',
  '#B8DE9A',
  '#71B578',

  '#999FC1',
  '#AC90BB',
  '#F9A2BF',
  '#3E9399',
  '#B19C89',
];

export const chromatic = [
  '#D4E6F1',
  '#A9CCE3',
  '#AED6F1',
  '#85C1E9',
  '#5DADE2',
  '#3498DB',
  '#2E86C1',
  '#2874A6',
  '#21618C',
  '#1B4F72',
  '#154360',
];
