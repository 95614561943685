import { Component, OnInit, Input } from '@angular/core';
import { isNil } from 'lodash-es';

@Component({
  selector: 'app-stats-block',
  templateUrl: './stats-block.component.html',
  styleUrls: ['./stats-block.component.scss'],
})
export class StatsBlockComponent implements OnInit {
  @Input() public title: string;
  @Input() public subtitle: string;
  @Input() public color = '#295974';
  @Input() public unit: string;
  @Input() public evolution: number;
  @Input() public evolutionUnit = '%';
  @Input() public reverse = false;
  @Input() public info: { color: string; label: string }[];
  @Input('hideDecimal')
  set setHideDecimal(hideDecimal: any) {
    this.hideDecimal = hideDecimal !== false;
  }
  public hideDecimal = false;
  @Input('value')
  public set setValue(value: number) {
    this.value = value;
    this.integer = this.getInteger(value);
    this.decimal = this.getDecimal(value);
  }
  public value: number;
  public integer: string;
  public decimal: string;

  private getInteger(value: number): string {
    if (isNil(value) || isNaN(value)) {
      return null;
    }
    return Math.floor(value).toString();
  }

  private getDecimal(value: number): string {
    if (isNil(value) || isNaN(value)) {
      return null;
    }
    return (value - Math.floor(value)).toFixed(2).substring(2);
  }

  constructor() {}

  ngOnInit(): void {}
}
