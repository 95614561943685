import { propertyMap } from 'model-mapper';
import { InspectionStatus } from './inspection-status';

export class EmbededInspection {
  @propertyMap({ source: '_id' })
  public id: string;

  @propertyMap()
  public reference: string;

  @propertyMap()
  public status: InspectionStatus;

  @propertyMap()
  public externalReference: string;
}
