<app-page-tracking *ngIf="tracking" [origin]="tracking.origin" [page]="tracking.page" [id]="tracking.id">
</app-page-tracking>

<ion-header>
  <ion-toolbar color="primary">
    <ion-title *ngIf="title">
      <ion-label>{{title}}</ion-label>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div *ngIf="data$ | async as data; else loading" @fadeIn class="chart-container" fxLayout="column">
    <ng-container [ngSwitch]="config?.type">
      <sesio-column-chart *ngSwitchCase="'column'" [config]="config" [data]="data" [style.height.%]="100">
      </sesio-column-chart>
      <sesio-area-chart *ngSwitchCase="'area'" [config]="config" [data]="data" [style.height.%]="100">
      </sesio-area-chart>
    </ng-container>
  </div>
  <ng-template #loading>
    <sesio-loader @fadeOut></sesio-loader>
  </ng-template>
</ion-content>
