/** @format */

import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({ name: 'momentDuration' })
export class MomentDurationPipe implements PipeTransform {
  transform(data: any): string {
    return data ? moment.duration(data).humanize() : null;
  }
}
