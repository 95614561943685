/** @format */

import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {includes} from 'lodash-es';
import {UserService} from '../_services/user.service';
import {Space} from 'sesio-lib';

@Injectable({
  providedIn: 'root',
})
export class DashboardGuard implements CanActivate {
  constructor(private router: Router, private userService: UserService) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const user = await this.userService.$user.value;
    if (!user) {
      return true;
    }
    switch (user.home) {
      case 'isitech':
      case Space.JANITOR:
        return true;
      // if (includes(user.spaces, Space.JANITOR)) {
      //   this.router.navigate([`/janitor-space`]);
      //   return false;
      // }
      case Space.FIRE_SAFETY:
        if (includes(user.spaces, Space.FIRE_SAFETY)) {
          this.router.navigate([`/fire-safety-space`]);
          return false;
        }
      case Space.ELEVATOR:
        if (includes(user.spaces, Space.ELEVATOR)) {
          this.router.navigate([`/elevator-space`]);
          return false;
        }
      case Space.PARKING_DOOR:
        if (includes(user.spaces, Space.PARKING_DOOR)) {
          this.router.navigate([`/parking-door-space`]);
          return false;
        }
      case Space.ENERGY_FLUID:
        if (includes(user.spaces, Space.ENERGY_FLUID)) {
          this.router.navigate([`/energy-fluid-space`]);
          return false;
        }
      case Space.ACCESS_CONTROL:
        if (includes(user.spaces, Space.ACCESS_CONTROL)) {
          this.router.navigate([`/access-control-space`]);
          return false;
        }
      default:
        this.router.navigate([`/landing`]);
        return false;
    }
  }
}
