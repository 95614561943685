/** @format */

import { Pipe, PipeTransform } from '@angular/core';
import { filter } from 'lodash';
import { get, isEqual, keys } from 'lodash-es';

@Pipe({ name: 'filter' })
export class FilterPipe implements PipeTransform {
  transform(data: any[], filterData: any, options?: { reverse: boolean }): any[] {
    const reverse = get(options, 'reverse', false);
    return filter(data, d => {
      if (typeof filterData === 'object') {
        for (let key of keys(filterData)) {
          if (!this.filterData(d[key], filterData[key], reverse)) return false;
        }
      } else {
        if (!this.filterData(d, filterData, reverse)) return false;
      }
      return true;
    });
  }

  filterData(d1: any, d2: any, reverse: boolean): boolean {
    if (reverse) {
      if (isEqual(d1, d2)) return false;
    } else {
      if (!isEqual(d1, d2)) return false;
    }
    return true;
  }
}
