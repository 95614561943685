import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapComponent } from './map.component/map.component';
import { LocationDialog } from './location.dialog/location.dialog';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { LoaderModule } from 'sesio-lib';
import { InViewportModule } from 'ng-in-viewport';
import { MapDashboardComponent } from './map-dashboard/map-dashboard.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';



@NgModule({
    declarations: [MapComponent, LocationDialog, MapDashboardComponent],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        FlexLayoutModule,
        MatInputModule,
        MatIconModule,
        MatFormFieldModule,
        MatButtonModule,
        MatDialogModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatAutocompleteModule,
        LoaderModule,
        InViewportModule,
        MatProgressBarModule
    ],
    exports: [MapComponent, LocationDialog, MapDashboardComponent]
})
export class MapModule { }
