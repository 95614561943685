import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { EnergyTagComponent } from './energy-tag/energy-tag.component';
import { ClimatTagComponent } from './climat-tag/climat-tag.component';
import { AngularResizeEventModule } from 'angular-resize-event';
import { NgPipesModule } from 'ngx-pipes';
import { EcsTagComponent } from './ecs-tag/ecs-tag.component';
import { HeatingTagComponent } from './heating-tag/heating-tag.component';
import { GreenEnergyTagComponent } from './green-energy-tag/green-energy-tag.component';
import { TranslateModule } from '@ngx-translate/core';
import { SafePipeModule } from 'safe-pipe';
import { IonicModule } from '@ionic/angular';
import { LoaderModule } from '../loader/loader.module';

@NgModule({
  declarations: [
    EnergyTagComponent,
    ClimatTagComponent,
    EcsTagComponent,
    HeatingTagComponent,
    GreenEnergyTagComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    FlexLayoutModule,
    AngularResizeEventModule,
    LoaderModule,
    NgPipesModule,
    TranslateModule.forChild(),
    SafePipeModule
  ],
  exports: [
    EnergyTagComponent,
    ClimatTagComponent,
    EcsTagComponent,
    HeatingTagComponent,
    GreenEnergyTagComponent
  ]
})
export class TagModule { }
