/** @format */

import {IHeaderSelection} from '../_services/session.service';

export interface IHeaderSearch {
  organizationalUnit?: {
    id: string;
    name: string;
    pathNames: string[];
  };
  perimeter?: boolean;
}

export function buildHeaderSearchFilter(data: IHeaderSearch): any {
  const filter: any = {};
  if (data) {
    if (data.perimeter) {
      filter.perimeter = true;
    } else if (data.organizationalUnit) {
      filter.organizationalUnitId = data.organizationalUnit.id;
    }
  }
  return filter;
}

export function convertHeaderSelection(selection: IHeaderSelection): IHeaderSearch {
  const headerSearch: IHeaderSearch = {};
  headerSearch.perimeter = false;
  headerSearch.organizationalUnit = null;
  if (selection?.perimeter) {
    headerSearch.perimeter = true;
  } else if (selection) {
    headerSearch.organizationalUnit = selection.group || selection.agency || selection.division || selection.cluster;
  }
  return headerSearch;
}
