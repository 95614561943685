/** @format */

import {propertyMap} from 'model-mapper';
import {DatagridComponent} from './datagrid.component';
import {IRecord} from './datasource';
import {Icon, IIcon} from './icon.class';

interface IAction {
  color?: string;
  tooltip?: string;
  isDisabled?: (records: IRecord[]) => boolean;
  isHidden?: (records: IRecord[]) => boolean;
  exec: (datagrid: DatagridComponent, selected: IRecord[]) => void;
}

export interface IIconAction extends IAction {
  icon: IIcon;
  label?: string;
  align?: 'left' | 'right';
}

export type ActionOption = IIconAction;

export class Action {
  @propertyMap()
  public color: string;

  @propertyMap()
  public tooltip: string;

  @propertyMap()
  public isDisabled: (records: IRecord[]) => boolean;

  @propertyMap()
  public isHidden: (records: IRecord[]) => boolean;

  @propertyMap()
  public exec: (datagrid: DatagridComponent, selected: IRecord[]) => void;

  @propertyMap({type: Icon})
  public icon: Icon;

  @propertyMap()
  public label?: string;

  @propertyMap()
  public align?: string;

  public hidden = false;
  public disabled = false;
}
