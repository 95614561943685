<mat-card id="container">
  <mat-card-header *ngIf="!hideTitle">
    <mat-card-title translate>hypervision</mat-card-title>
  </mat-card-header>
  <mat-card-content [formGroup]="formGroup" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="16px">
    <div class="divider">
      <mat-divider></mat-divider>
      <ion-label color="primary" translate>provider</ion-label>
    </div>
    <div formGroupName="provider" class="block" fxLayout="row wrap" fxLayoutAlign="start center">
      <mat-form-field [style.width.px]="200">
        <mat-label translate>name</mat-label>
        <mat-select formControlName="name">
          <mat-option *ngFor="let name of providerNames" [value]="name">
            <mat-label translate>{{name}}</mat-label>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field [style.width.px]="200">
        <mat-label translate>codec</mat-label>
        <mat-select formControlName="codec">
          <mat-option *ngFor="let name of providerCodecs" [value]="name">
            <mat-label translate>{{name}}</mat-label>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field [style.min-width.px]="400" [style.width]="'calc(100% - 448px)'">
        <mat-label translate>product-name</mat-label>
        <input autocomplete="off" matInput formControlName="productName">
      </mat-form-field>
      <mat-form-field [style.min-width.px]="400" [style.width.%]="100">
        <mat-label translate>description</mat-label>
        <textarea matInput formControlName="description"></textarea>
      </mat-form-field>
    </div>
    <div class="divider">
      <mat-divider></mat-divider>
      <ion-label color="primary" translate>connectivity</ion-label>
    </div>
    <div formGroupName="connectivity" class="block" fxLayout="row wrap" fxLayoutAlign="start center">
      <mat-form-field [style.width.px]="200">
        <mat-label translate>kind</mat-label>
        <mat-select formControlName="kind">
          <mat-option *ngFor="let name of connectivityKinds" [value]="name">
            <mat-label translate>{{name}}</mat-label>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <ng-container *ngIf="formGroup.get('connectivity').get('kind').value === 'lora'" formGroupName="identifiers">
        <mat-form-field @fade [style.width.px]="200">
          <mat-label translate>dev-eui</mat-label>
          <input autocomplete="off" matInput formControlName="devEUI">
        </mat-form-field>
        <mat-form-field @fade [style.width.px]="200">
          <mat-label translate>app-eui</mat-label>
          <input autocomplete="off" matInput formControlName="appEUI">
        </mat-form-field>
        <mat-form-field @fade [style.width.px]="200">
          <mat-label translate>app-key</mat-label>
          <input autocomplete="off" matInput formControlName="appKEY">
        </mat-form-field>
        <mat-form-field @fade [style.width.px]="200">
          <mat-label translate>serial-number</mat-label>
          <input autocomplete="off" matInput formControlName="serialNumber">
        </mat-form-field>
        <mat-form-field @fade [style.width.px]="200" [attr.readonly]="true">
          <mat-label translate>objenious-device-id</mat-label>
          <input autocomplete="off" matInput formControlName="objeniousDeviceId">
        </mat-form-field>
      </ng-container>
    </div>
  </mat-card-content>
</mat-card>