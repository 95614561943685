/** @format */

import { Space } from './space';

export type Home =
  | null
  | 'isitech'
  | Space.JANITOR
  | Space.FIRE_SAFETY
  | Space.ELEVATOR
  | Space.PARKING_DOOR
  | Space.ENERGY_FLUID
  | Space.ACCESS_CONTROL;

export const HomeEnum = [
  null,
  'isitech',
  Space.JANITOR,
  Space.FIRE_SAFETY,
  Space.ELEVATOR,
  Space.PARKING_DOOR,
  Space.ENERGY_FLUID,
  Space.ACCESS_CONTROL,
];
