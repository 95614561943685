/** @format */

export enum Family {
  ELEVATOR = 'elevator',
  PARKING_DOOR = 'parking-door',
  OPTICAL_FIBER = 'optical-fiber',
  RELAY_ANTENNA = 'relay-antenna',
  IOT = 'iot',
  PHOTOVOLTAIC_PANEL = 'photovoltaic-panel',
  ACCESS_CONTROL = 'access-control',
  ENERGY_FLUID = 'energy-fluid',
  FIRE_SAFETY = 'fire-safety',
  CHARGING_STATION = 'charging-station',
  OTHER = 'other',
}

export const FamilyName = {
  [Family.ELEVATOR]: 'Ascenseur',
  [Family.PARKING_DOOR]: 'Porte de parking',
  [Family.OPTICAL_FIBER]: 'Fibre optique',
  [Family.RELAY_ANTENNA]: 'Antenne relais',
  [Family.IOT]: 'Object connecté',
  [Family.PHOTOVOLTAIC_PANEL]: 'Panneau photovoltaïque',
  [Family.ACCESS_CONTROL]: 'Contrôle d\'accès',
  [Family.ENERGY_FLUID]: 'Énergie & fluide',
  [Family.FIRE_SAFETY]: 'Sécurité incendie',
  [Family.CHARGING_STATION]: 'Borne de rechargement',
  [Family.OTHER]: 'Autre',
};

export const FamilyNamePlural = {
  [Family.ELEVATOR]: 'Ascenseurs',
  [Family.PARKING_DOOR]: 'Portes de parking',
  [Family.OPTICAL_FIBER]: 'Fibres optique',
  [Family.RELAY_ANTENNA]: 'Antennes relais',
  [Family.IOT]: 'Object connectés',
  [Family.PHOTOVOLTAIC_PANEL]: 'Panneaux photovoltaïques',
  [Family.ACCESS_CONTROL]: 'Contrôle d\'accès',
  [Family.ENERGY_FLUID]: 'Énergie & fluides',
  [Family.FIRE_SAFETY]: 'Sécurité incendie',
  [Family.CHARGING_STATION]: 'Bornes de rechargement',
  [Family.OTHER]: 'Autre',
};
