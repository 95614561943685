/** @format */

import { merge, Options } from 'highcharts';
import moment from 'moment';
import 'moment-timezone';
import { chromatic } from './colors';

export interface IHighchartsOptions extends Options {
  dateTimeLabelFormats: {
    hour: { list: string[] };
    day: { list: string[] };
    week: { list: string[] };
    month: { list: string[] };
  };
}

export const highchartsOptions: { [locale: string]: IHighchartsOptions } = {
  fr: merge(
    {
      colors: chromatic,
      dateTimeLabelFormats: {
        hour: { list: ['%H:%M', '%H'] },
        day: { list: ['%A, %e. %B', '%a, %e. %b', '%E'] },
        week: { list: ['Semaine %W', 'S%W'] },
        month: { list: ['%B', '%b', '%o'] },
      },
    },
    {
      time: {
        timezone: 'Europe/Paris',
        moment,
      },
      lang: {
        months: [
          'Janvier',
          'Février',
          'Mars',
          'Avril',
          'Mai',
          'Juin',
          'Juillet',
          'Août',
          'Septembre',
          'Octobre',
          'Novembre',
          'Décembre',
        ],
        shortMonths: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Jui', 'Jui', 'Aoû', 'Sep', 'Oct', 'Nov', 'Déc'],
        weekdays: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
        shortWeekdays: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
        decimalPoint: ',',
        loading: 'Chargement...',
        noData: 'Aucune donnée',
        rangeSelectorZoom: 'Zoom',
        rangeSelectorFrom: 'De',
        rangeSelectorTo: 'À',
        resetZoom: 'Réinitialiser le zoom',
        resetZoomTitle: 'Réinitialiser le zoom niveau 1:1',
        // drillUpText: '<span class="material-icons">arrow_back</span> {series.name}',
        downloadCSV: 'Télécharger au format CSV',
        downloadJPEG: "Télécharger en tant qu'image JPEG",
        downloadPDF: 'Télécharger en tant que document PDF',
        downloadPNG: "Télécharger en tant qu'image PNG",
        downloadSVG: "Télécharger en tant qu'image SVG",
        downloadXLS: 'Télécharger au format XLS',
        printChart: 'Imprimer le graphique',
        viewData: 'Afficher sous forme de tableau',
        viewFullscreen: 'Afficher en plein écran',
        hideData: 'Masquer la donnée',
      },
    }
  ),
};
