/** @format */

import {propertyMap} from 'model-mapper';
import {Family} from './family';
import {values} from 'lodash-es';

export enum MetricKind {
  STRING = 'string',
  NUMBER = 'number',
  BOOLEAN = 'boolean',
  ENUM = 'enum',
  DATE = 'date',
  MULTI = 'multi',
}

export enum Metric {
  MOTION = 'motion',
  MOTION_COUNT = 'motion-count',
  DAILY_MOTION_COUNT = 'daily-motion-count',
  MONTHLY_MOTION_COUNT = 'monthly-motion-count',

  BOILER_USAGE_KIND = 'boiler-usage-kind',
  ENERGY_KIND = 'energy-kind',
  TEMPERATURE_INDOOR = 'temperature-indoor',
  TEMPERATURE_OUTDOOR = 'temperature-outdoor',
  PRIMARY = 'primary',
  TEMPERATURE_WATER_DEPARTURE = 'temperature-water-departure',
  TEMPERATURE_WATER_ARRIVAL = 'temperature-water-arrival',
  TEMPERATURE_HEATING_DEPARTURE = 'temperature-heating-departure',
  TEMPERATURE_HEATING_ARRIVAL = 'temperature-heating-arrival',
  HUMIDITY_INDOOR = 'humidity-indoor',
  HUMIDITY_OUTDOOR = 'humidity-outdoor',
  AIR_QUALITY_INDOOR = 'air-quality-indoor',
  AIR_QUALITY_OUTDOOR = 'air-quality-outdoor',
  GREEN_ENERGY = 'green-energy',
  TAG_ENERGY = 'energy-tag',
  TAG_CLIMAT = 'climat-tag',
  POWER = 'power',
  SOLAR_POWER = 'solar-power',
  LOWER_CALORIFIC_VALUE = 'lower-calorific-value',
  HIGHER_CALORIFIC_VALUE = 'higher-calorific-value',
  PRESSURE = 'pressure',

  SETPOINT = 'setpoint',
  SETPOINT_TEMPERATURE_HEATING = 'setpoint-temperature_heating',
  SETPOINT_TEMPERATURE_WATER = 'setpoint-temperature_water',
  SETPOINT_PRESSURE = 'setpoint-pressure',
}

export const FamilyMetrics = {
  [Family.ELEVATOR]: [Metric.MOTION_COUNT, Metric.DAILY_MOTION_COUNT, Metric.MONTHLY_MOTION_COUNT],
  [Family.ENERGY_FLUID]: [
    Metric.BOILER_USAGE_KIND,
    Metric.ENERGY_KIND,
    Metric.TEMPERATURE_INDOOR,
    Metric.TEMPERATURE_OUTDOOR,
    Metric.PRIMARY,
    Metric.TEMPERATURE_WATER_DEPARTURE,
    Metric.TEMPERATURE_WATER_ARRIVAL,
    Metric.TEMPERATURE_HEATING_DEPARTURE,
    Metric.TEMPERATURE_HEATING_ARRIVAL,
    Metric.HUMIDITY_INDOOR,
    Metric.HUMIDITY_OUTDOOR,
    Metric.AIR_QUALITY_INDOOR,
    Metric.AIR_QUALITY_OUTDOOR,
    Metric.GREEN_ENERGY,
    Metric.TAG_ENERGY,
    Metric.TAG_CLIMAT,
    Metric.POWER,
    Metric.SOLAR_POWER,
    Metric.LOWER_CALORIFIC_VALUE,
    Metric.HIGHER_CALORIFIC_VALUE,
    Metric.PRESSURE,
    Metric.SETPOINT,
    Metric.SETPOINT_TEMPERATURE_HEATING,
    Metric.SETPOINT_TEMPERATURE_WATER,
    Metric.SETPOINT_PRESSURE,
  ],
  [Family.IOT]: values(Metric),
};

export enum EnergyKind {
  GAS = 'gas', // Gaz
  FUEL = 'fuel', // Fioul
  ELECTRICITY = 'electricity', // Électricité
  THERMODYNAMICS = 'thermodynamics', // Pompe à chaleur
  CPCU = 'cpcu', // CPCU Vapeur
  MIXED = 'mixed', // Mixte
  SOLAR_PANEL = 'solar-panel', // Panneau solaire
  DIGITAL = 'digital', // Numérique
  CPU_WATER = 'cpu-water', // CPU Eau surchauffée
}

export enum BoilerUsageKind {
  HEATING_WATER_USAGE = 'heating-water-usage', // Chauffage et Eau Chaude Sanitaire
  HEATING_USAGE = 'heating-usage', // Chauffage uniquement
  WATER_USAGE = 'water-usage', // Eau Chaude Sanitaire uniquement
}

export interface IMetricsSetpoint {
  name: string;
  code: string;
  value: number;
  unit: string;
}

export interface IMetric {
  code: Metric;
  kind: MetricKind;
  enum?: string[];
  group?: 'setpoints';
  unit?: string;
  isEditable: boolean;
}

export const MetricCodes = Object.values(Metric);

export const Metrics: IMetric[] = [
  {
    code: Metric.BOILER_USAGE_KIND,
    kind: MetricKind.ENUM,
    enum: Object.values(BoilerUsageKind),
    isEditable: true,
  },
  {
    code: Metric.ENERGY_KIND,
    kind: MetricKind.ENUM,
    enum: Object.values(EnergyKind),
    isEditable: true,
  },
  {
    code: Metric.MOTION,
    kind: MetricKind.NUMBER,
    isEditable: true,
  },
  {
    code: Metric.MOTION_COUNT,
    kind: MetricKind.NUMBER,
    isEditable: true,
  },
  {
    code: Metric.DAILY_MOTION_COUNT,
    kind: MetricKind.NUMBER,
    isEditable: true,
  },
  {
    code: Metric.MONTHLY_MOTION_COUNT,
    kind: MetricKind.NUMBER,
    isEditable: true,
  },
  {
    code: Metric.TEMPERATURE_INDOOR,
    kind: MetricKind.NUMBER,
    unit: 'in-celcius',
    isEditable: true,
  },
  {
    code: Metric.TEMPERATURE_OUTDOOR,
    kind: MetricKind.NUMBER,
    unit: 'in-celcius',
    isEditable: true,
  },
  {
    code: Metric.PRIMARY,
    kind: MetricKind.NUMBER,
    unit: 'in-celcius',
    isEditable: true,
  },
  {
    code: Metric.TEMPERATURE_WATER_DEPARTURE,
    kind: MetricKind.NUMBER,
    unit: 'in-celcius',
    isEditable: true,
  },
  {
    code: Metric.TEMPERATURE_WATER_ARRIVAL,
    kind: MetricKind.NUMBER,
    unit: 'in-celcius',
    isEditable: true,
  },
  {
    code: Metric.TEMPERATURE_HEATING_DEPARTURE,
    kind: MetricKind.NUMBER,
    unit: 'in-celcius',
    isEditable: true,
  },
  {
    code: Metric.TEMPERATURE_HEATING_ARRIVAL,
    kind: MetricKind.NUMBER,
    unit: 'in-celcius',
    isEditable: true,
  },
  {
    code: Metric.HUMIDITY_INDOOR,
    kind: MetricKind.NUMBER,
    unit: 'in-percent',
    isEditable: true,
  },
  {
    code: Metric.HUMIDITY_OUTDOOR,
    kind: MetricKind.NUMBER,
    unit: 'in-percent',
    isEditable: true,
  },
  {
    code: Metric.AIR_QUALITY_INDOOR,
    kind: MetricKind.NUMBER,
    unit: 'in-ppm',
    isEditable: true,
  },
  {
    code: Metric.AIR_QUALITY_OUTDOOR,
    kind: MetricKind.NUMBER,
    unit: 'in-ppm',
    isEditable: true,
  },
  {
    code: Metric.GREEN_ENERGY,
    kind: MetricKind.NUMBER,
    unit: 'in-percent',
    isEditable: true,
  },
  {
    code: Metric.TAG_ENERGY,
    kind: MetricKind.NUMBER,
    isEditable: true,
  },
  {
    code: Metric.TAG_CLIMAT,
    kind: MetricKind.NUMBER,
    isEditable: true,
  },
  {
    code: Metric.POWER,
    kind: MetricKind.NUMBER,
    unit: 'in-kw',
    isEditable: true,
  },
  {
    code: Metric.SOLAR_POWER,
    kind: MetricKind.NUMBER,
    unit: 'in-kwh',
    isEditable: true,
  },
  {
    code: Metric.LOWER_CALORIFIC_VALUE,
    kind: MetricKind.NUMBER,
    unit: 'in-kw',
    isEditable: true,
  },
  {
    code: Metric.HIGHER_CALORIFIC_VALUE,
    kind: MetricKind.NUMBER,
    unit: 'in-kw',
    isEditable: true,
  },
  {
    code: Metric.PRESSURE,
    kind: MetricKind.NUMBER,
    unit: 'in-bar',
    isEditable: true,
  },

  {
    code: Metric.SETPOINT,
    kind: MetricKind.MULTI,
    group: 'setpoints',
    isEditable: false,
  },
  {
    code: Metric.SETPOINT_TEMPERATURE_HEATING,
    kind: MetricKind.NUMBER,
    group: 'setpoints',
    unit: 'in-celcius',
    isEditable: true,
  },
  {
    code: Metric.SETPOINT_TEMPERATURE_WATER,
    kind: MetricKind.NUMBER,
    group: 'setpoints',
    unit: 'in-celcius',
    isEditable: true,
  },
  {
    code: Metric.SETPOINT_PRESSURE,
    kind: MetricKind.NUMBER,
    group: 'setpoints',
    unit: 'in-bar',
    isEditable: true,
  },
];

export class MetricValue {
  @propertyMap()
  [Metric.BOILER_USAGE_KIND]: EnergyKind;

  @propertyMap()
  [Metric.ENERGY_KIND]: EnergyKind;

  @propertyMap()
  [Metric.MOTION]: number;

  @propertyMap()
  [Metric.MOTION_COUNT]: number;

  @propertyMap()
  [Metric.DAILY_MOTION_COUNT]: number;

  @propertyMap()
  [Metric.MONTHLY_MOTION_COUNT]: number;

  @propertyMap()
  [Metric.TEMPERATURE_INDOOR]: number;

  @propertyMap()
  [Metric.TEMPERATURE_OUTDOOR]: number;

  @propertyMap()
  [Metric.PRIMARY]: number;

  @propertyMap()
  [Metric.TEMPERATURE_WATER_DEPARTURE]: number;

  @propertyMap()
  [Metric.TEMPERATURE_WATER_ARRIVAL]: number;

  @propertyMap()
  [Metric.TEMPERATURE_HEATING_DEPARTURE]: number;

  @propertyMap()
  [Metric.TEMPERATURE_HEATING_ARRIVAL]: number;

  @propertyMap()
  [Metric.HUMIDITY_INDOOR]: number;

  @propertyMap()
  [Metric.HUMIDITY_OUTDOOR]: number;

  @propertyMap()
  [Metric.AIR_QUALITY_INDOOR]: number;

  @propertyMap()
  [Metric.AIR_QUALITY_OUTDOOR]: number;

  @propertyMap()
  [Metric.GREEN_ENERGY]: number;

  @propertyMap()
  [Metric.TAG_ENERGY]: number;

  @propertyMap()
  [Metric.TAG_CLIMAT]: number;

  @propertyMap()
  [Metric.POWER]: number;

  @propertyMap()
  [Metric.SOLAR_POWER]: number;

  @propertyMap()
  [Metric.LOWER_CALORIFIC_VALUE]: number;

  @propertyMap()
  [Metric.HIGHER_CALORIFIC_VALUE]: number;

  @propertyMap()
  [Metric.PRESSURE]: number;

  @propertyMap()
  [Metric.SETPOINT_TEMPERATURE_HEATING]: number;

  @propertyMap()
  [Metric.SETPOINT_TEMPERATURE_WATER]: number;

  @propertyMap()
  [Metric.SETPOINT_PRESSURE]: number;

  @propertyMap()
  [Metric.SETPOINT]: IMetricsSetpoint[];
}

export class AggregatedMetricValue {
  @propertyMap()
  [Metric.ENERGY_KIND]: EnergyKind;

  @propertyMap()
  [Metric.MOTION]?: number;

  @propertyMap()
  [Metric.MOTION_COUNT]?: number;

  @propertyMap()
  [Metric.TEMPERATURE_INDOOR]?: number;

  @propertyMap()
  [Metric.TEMPERATURE_OUTDOOR]?: number;

  @propertyMap()
  [Metric.PRIMARY]?: number;

  @propertyMap()
  [Metric.TEMPERATURE_WATER_DEPARTURE]?: number;

  @propertyMap()
  [Metric.TEMPERATURE_WATER_ARRIVAL]?: number;

  @propertyMap()
  [Metric.TEMPERATURE_HEATING_DEPARTURE]?: number;

  @propertyMap()
  [Metric.TEMPERATURE_HEATING_ARRIVAL]?: number;

  @propertyMap()
  [Metric.HUMIDITY_INDOOR]?: number;

  @propertyMap()
  [Metric.HUMIDITY_OUTDOOR]?: number;

  @propertyMap()
  [Metric.AIR_QUALITY_INDOOR]?: number;

  @propertyMap()
  [Metric.AIR_QUALITY_OUTDOOR]?: number;

  @propertyMap()
  [Metric.GREEN_ENERGY]?: number;

  @propertyMap()
  [Metric.TAG_ENERGY]?: number;

  @propertyMap()
  [Metric.TAG_CLIMAT]?: number;

  @propertyMap()
  [Metric.POWER]?: number;

  @propertyMap()
  [Metric.SOLAR_POWER]?: number;

  @propertyMap()
  [Metric.LOWER_CALORIFIC_VALUE]?: number;

  @propertyMap()
  [Metric.HIGHER_CALORIFIC_VALUE]?: number;

  @propertyMap()
  [Metric.PRESSURE]?: number;

  @propertyMap()
  [Metric.SETPOINT_TEMPERATURE_HEATING]?: number;

  @propertyMap()
  [Metric.SETPOINT_TEMPERATURE_WATER]?: number;

  @propertyMap()
  [Metric.SETPOINT_PRESSURE]?: number;

  @propertyMap()
  [Metric.SETPOINT]?: IMetricsSetpoint[];
}
