import { Component, Input } from '@angular/core';
import { isNil } from 'lodash-es';

@Component({
  selector: 'app-ecs-tag',
  templateUrl: './ecs-tag.component.html',
  styleUrls: ['./ecs-tag.component.scss'],
})
export class EcsTagComponent {

  @Input()
  public display: 'full' | 'letter' = 'full';

  @Input('setpoint')
  public set setSetpoint(setpoint: number) {
    if (isNil(setpoint) || typeof setpoint !== 'number') {
      this.setpoint = null;
    } else {
      this.setpoint = Math.round(setpoint);
    }
  }
  public setpoint: number = null;

  @Input('value')
  public set setValue(value: number) {
    if (isNil(value) || typeof value !== 'number') {
      this.value = 0;
      this.color = 'var(--ion-color-accent)';
    } else {
      this.value = Math.round(value);
      this.color = this.value < 50 ? 'var(--ion-color-secondary)' :
        this.value > 60 ? 'var(--ion-color-danger)' :
          'var(--ion-color-accent)';
    }
  }
  public value = 0;
  public color = 'var(--ion-color-accent)';

  constructor() { }

}
