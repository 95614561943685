import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColumnChartComponent } from './column-chart/column-chart.component';
import { TranslateModule } from '@ngx-translate/core';
import { InViewportModule } from 'ng-in-viewport';
import { AreaChartComponent } from './area-chart/area-chart.component';
import { SafePipeModule } from 'safe-pipe';
import { FlexLayoutModule } from '@angular/flex-layout';
import { IonicModule } from '@ionic/angular';
import { ChartModal } from './chart.modal/chart.modal';
import { LoaderModule } from '../loader/loader.module';
import { LineChartComponent } from './line-chart/line-chart.component';
import { PieChartComponent } from './pie-chart/pie-chart.component';
import { SpiderwebChartComponent } from './spiderweb-chart/spiderweb-chart.component';
import { ChartComponent } from './chart/chart.component';
import { TrackingModule } from '../tracking/tracking.module';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ChartRangeSelectorComponent } from './chart-range-selector/chart-range-selector.component';
import { NgScrollbarModule } from 'ngx-scrollbar';

@NgModule({
  declarations: [
    ChartModal,
    ColumnChartComponent,
    AreaChartComponent,
    LineChartComponent,
    PieChartComponent,
    SpiderwebChartComponent,
    ChartComponent,
    ChartRangeSelectorComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    InViewportModule,
    SafePipeModule,
    FlexLayoutModule,
    IonicModule,
    LoaderModule,
    TrackingModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    MatDatepickerModule,
    NgScrollbarModule
  ],
  exports: [
    ChartModal,
    ColumnChartComponent,
    AreaChartComponent,
    LineChartComponent,
    PieChartComponent,
    SpiderwebChartComponent,
    ChartComponent,
    ChartRangeSelectorComponent
  ],
})
export class ChartModule {}
