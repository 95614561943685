/** @format */

import {Pipe, PipeTransform} from '@angular/core';
import {last} from 'lodash-es';

@Pipe({name: 'last'})
export class LastPipe implements PipeTransform {
  transform<T>(data: T[]): T {
    return last(data);
  }
}
