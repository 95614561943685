<div id="container">
  <ng-container *ngTemplateOutlet="multiple ? multiSelectTmpl : singleSelectTmpl"></ng-container>
</div>

<ng-template #singleSelectTmpl>
  <mat-form-field *ngIf="control" id="equipement" [class.has-prefix]="control.value?.kind">
    <mat-label>{{label ? label : 'Équipement'}}</mat-label>
    <input id="input" autocomplete="off" type="text" aria-label="Group" matInput [formControl]="control" [value]="value"
      [matAutocomplete]="auto" [required]="isRequired">
    <mat-progress-bar *ngIf="loading" @fade mode="buffer" color="primary"></mat-progress-bar>
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayOption">
      <mat-optgroup *ngFor="let group of filteredOptions | async | groupBy:'kind.name' | pairs" [label]="group[0]">
        <mat-option *ngFor="let option of group[1]" [value]="option">
          <mat-label>{{option.reference}}</mat-label>
        </mat-option>
      </mat-optgroup>
    </mat-autocomplete>
    <mat-label *ngIf="control.value?.kind" matPrefix>
      {{control.value.kind.name}} -&nbsp;
    </mat-label>
    <button *ngIf="!isDisabled && control.value" mat-icon-button matSuffix (click)="control.setValue(null)">
      <mat-icon color="danger">close</mat-icon>
    </button>
  </mat-form-field>
</ng-template>

<ng-template #multiSelectTmpl>
  <mat-form-field *ngIf="control" id="equipement" [class.has-prefix]="control.value?.kind">
    <mat-label>{{label ? label : 'Équipements'}}</mat-label>
    <mat-chip-list #chipList [formControl]="control" [value]="value">
      <mat-chip *ngFor="let v of control.value; index as i" (removed)="remove(i)">
        <span light>{{v.kind.name}}</span> - {{v.reference}}
        <button matChipRemove *ngIf="!isDisabled">
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip>
      <input #valueInput [formControl]="valueCtrl" [matAutocomplete]="auto" [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
      <mat-progress-bar *ngIf="loading" @fade mode="buffer" color="primary"></mat-progress-bar>
    </mat-chip-list>
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayOption"
      (optionSelected)="selected($event)">
      <mat-optgroup *ngFor="let group of filteredOptions | async | groupBy:'kind.name' | pairs" [label]="group[0]">
        <mat-option *ngFor="let option of group[1]" [value]="option">
          <mat-label>{{option.reference}}</mat-label>
        </mat-option>
      </mat-optgroup>
    </mat-autocomplete>
  </mat-form-field>
</ng-template>
