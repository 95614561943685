import { propertyMap } from 'model-mapper';

export class EquipmentFireSafetyStatuses {

  @propertyMap({ source: 'not-verified' })
  public notVerified: number;

  @propertyMap()
  public issue: number;

  @propertyMap()
  public warn: number;

  @propertyMap()
  public valid: number;

  @propertyMap()
  public late: number;

  public get total(): number {
    return (this.notVerified || 0) +
      (this.issue || 0) +
      (this.warn || 0) +
      (this.valid || 0) +
      (this.late || 0);
  }

}

export class EquipmentElevatorStatuses {

  @propertyMap()
  public disconnected: number;

  @propertyMap()
  public online: number;

  @propertyMap()
  public noflow: number;

  @propertyMap()
  public critical: number;

  @propertyMap()
  public maintenance: number;

  @propertyMap()
  public stopped: number;

  @propertyMap()
  public offline: number;

  public get total(): number {
    return (this.disconnected || 0) +
      (this.online || 0) +
      (this.noflow || 0) +
      (this.critical || 0) +
      (this.maintenance || 0) +
      (this.stopped || 0) +
      (this.offline || 0);
  }

}

export class EquipmentParkingDoorStatuses {

  @propertyMap()
  public disconnected: number;

  @propertyMap()
  public online: number;

  @propertyMap()
  public noflow: number;

  @propertyMap()
  public critical: number;

  @propertyMap()
  public offline: number;

  public get total(): number {
    return (this.disconnected || 0) +
      (this.online || 0) +
      (this.noflow || 0) +
      (this.critical || 0) +
      (this.offline || 0);
  }

}
