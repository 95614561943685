import { propertyMap } from 'model-mapper';
import { Moment } from 'moment';
import { EmbededOrganizationalUnit } from './embeded-organizational-unit';

export class TechnicalControlEquipmentKind {

  @propertyMap()
  public code: string;

}

export class TechnicalControlEquipment {

  @propertyMap({ source: '_id' })
  public id: string;

  @propertyMap({ type: TechnicalControlEquipmentKind })
  public kind: TechnicalControlEquipmentKind;

}

export class TechnicalControl {

  @propertyMap({ source: '_id' })
  public id: string;

  @propertyMap({ type: 'Moment' })
  public date: Moment;

  @propertyMap({ type: TechnicalControlEquipment })
  public equipment: TechnicalControlEquipment;

  @propertyMap({ type: EmbededOrganizationalUnit })
  public organizationalUnit: EmbededOrganizationalUnit;

  @propertyMap()
  public metadata: any;

}
