/** @format */

import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import moment, {Moment} from 'moment';
import 'moment-timezone';
import {Observable} from 'rxjs';
import {AggregationValue} from '../classes/aggregation';
import {Metric} from '../classes/metrics';
import {LibConfig, LibConfigService} from '../config.service';
import {IChartData} from '../modules/chart/chart.interface';
import {DrilldownService} from './drilldown.service';

export type Granularity = 'year' | 'month' | 'day' | 'hour' | 'minute';

@Injectable({
  providedIn: 'root',
})
export class SensorDataService {
  constructor(
    @Inject(LibConfigService) protected config: LibConfig,
    protected http: HttpClient,
    private drilldownService: DrilldownService
  ) {}

  public count(
    op: 'sum' | 'avg',
    granularity: Granularity,
    match: {kind?: string; eqId?: string; ouId?: string},
    start?: moment.Moment,
    end?: moment.Moment
  ): Observable<AggregationValue[]> {
    const params: any = {op};
    if (match) {
      if (match.kind) {
        params.kind = match.kind;
      }
      if (match.eqId) {
        params.eqId = match.eqId;
      }
      if (match.ouId) {
        params.ouId = match.ouId;
      }
    }
    switch (granularity) {
      case 'year':
        return this.http.get<any[]>(`${this.config.environment.apiUrl}/sensor-data/by-year`, {params});
      case 'month':
        params.year = start.year();
        return this.http.get<any[]>(`${this.config.environment.apiUrl}/sensor-data/by-month`, {params});
      case 'day':
        params.year = start.year();
        params.month = start.month() + 1;
        return this.http.get<any[]>(`${this.config.environment.apiUrl}/sensor-data/by-day`, {params});
      case 'hour':
        params.year = start.year();
        params.month = start.month() + 1;
        params.day = start.date();
        return this.http.get<any[]>(`${this.config.environment.apiUrl}/sensor-data/by-hour`, {params});
      case 'minute':
        params.year = start.year();
        params.month = start.month() + 1;
        params.day = start.date();
        params.hour = start.hour();
        return this.http.get<any[]>(`${this.config.environment.apiUrl}/sensor-data/by-minute`, {params});
    }
  }

  public getMotionDrilldownData(
    op: 'sum' | 'avg',
    match: {kind: Metric; eqId?: string; ouId?: string},
    granularity?: Granularity,
    date?: Moment
  ): Promise<IChartData[]> {
    return this.drilldownService.getDrilldown(this.count.bind(this), op, match, granularity, date || moment());
  }

  public data(kinds: Metric[], ouId?: string): Observable<{kind: string; values: [number, number][]}[]> {
    const params: any = {kinds};
    return this.http.get<any>(`${this.config.environment.apiUrl}/sensor-data/data`, {params});
  }

  public getKindDataMetrics(
    kind: Metric,
    equipmentId: string,
    unitGrouping: 'year' | 'month' | 'day',
    start?: Date,
    end?: Date
  ): Observable<{count: number; sum: number; avg: number; min: number; max: number}> {
    return this.http.post<any>(`${this.config.environment.apiUrl}/sensor-data/get-kind-data-metrics`, {
      kind,
      equipmentId,
      unitGrouping,
      start,
      end,
    });
  }
}
