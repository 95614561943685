/** @format */

import {Injectable} from '@angular/core';
import {isNil} from 'lodash-es';
import {Moment} from 'moment';
import {Observable} from 'rxjs';
import {AggregationValue, EquipmentKind, SensorDataService as SesioSensorDataService} from 'sesio-lib';
import {IHeaderSearch, buildHeaderSearchFilter} from '../_classes/header-search';

// TODO add filter on qualification
export interface IFilter extends IHeaderSearch {
  organizationalUnitId?: string;
  realEstateStructureId?: string;
  equipmentId?: string;
  families?: string[];
  kinds?: string[];
  contractorIds?: string[];
  start?: Moment;
  end?: Moment;
  equipmentKind?: EquipmentKind;
  signatureSettingName?: string;
  isTertiary?: boolean;
  unitGrouping?: 'day' | 'hour' | 'minute';
  groupingMethod?: 'sum' | 'avg' | 'count';
  groupingKey?: 'kind' | 'sensorId';
  supervised?: boolean;
  sensorIds?: string[];
}

@Injectable({
  providedIn: 'root',
})
export class SensorDataService extends SesioSensorDataService {
  public getChartRangeData(search: IFilter): Observable<{[kind: string]: AggregationValue[]}> {
    return this.http.post<any>(
      `${this.config.environment.apiUrl}/sensor-data/get-chart-range-data`,
      this.buildSearchFilter(search)
    );
  }

  private buildSearchFilter(search: IFilter): any {
    const filter = buildHeaderSearchFilter(search);
    if (search?.organizationalUnitId) filter.organizationalUnitId = search.organizationalUnitId;
    if (search?.realEstateStructureId) filter.realEstateStructureId = search.realEstateStructureId;
    if (search?.equipmentId) filter.equipmentId = search.equipmentId;
    if (search?.families) filter.families = search.families;
    if (search?.kinds) filter.kinds = search.kinds;
    if (search?.contractorIds) filter.contractorIds = search.contractorIds;
    if (search.start) filter.start = search.start.toISOString();
    if (search.end) filter.end = search.end.toISOString();
    if (search.equipmentKind) filter.equipmentKindId = search.equipmentKind.id;
    if (search?.signatureSettingName) filter.signatureSettingName = search.signatureSettingName;
    if (search?.isTertiary === true) filter.isTertiary = search.isTertiary;
    if (search?.unitGrouping) filter.unitGrouping = search.unitGrouping;
    if (search?.groupingMethod) filter.groupingMethod = search.groupingMethod;
    if (search?.groupingKey) filter.groupingKey = search.groupingKey;
    if (!isNil(search?.supervised)) filter.supervised = search.supervised;
    if (search?.sensorIds) filter.sensorIds = search.sensorIds;
    return filter;
  }
}
