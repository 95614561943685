/** @format */

import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldControl } from '@angular/material/form-field';

export interface IConfirmDialogData {
  title: string;
  message: string;
  titleParams?: any;
  messageParams?: any;
  noLabel?: string;
  yesLabel?: string;
  yesLabelColor?: string;
  reasonLabel?: string;
  askForReason?: boolean;
  reasonMandatory?: boolean;
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
  public title: string;
  public message: string;
  public titleParams: any;
  public messageParams: any;
  public noLabel: string;
  public yesLabel: string;
  public yesLabelColor?: string;
  public reasonLabel?: string;
  public askForReason: boolean;
  public reasonMandatory: boolean;

  reasonControl: FormControl<string>;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IConfirmDialogData,
    private formBuilder: FormBuilder
  ) {
    this.title = data.title || 'confirm-dialog-title';
    this.message = data.message;
    this.titleParams = data.titleParams;
    this.messageParams = data.messageParams;
    this.noLabel = data.noLabel || 'no';
    this.yesLabel = data.yesLabel || 'yes';
    this.yesLabelColor = data.yesLabelColor || 'primary';
    this.reasonLabel = data.reasonLabel || 'reason';
    this.askForReason = data.askForReason || false;
    this.reasonMandatory = data.reasonMandatory || false;
    this.reasonControl = this.formBuilder.control(null, this.reasonMandatory ? [Validators.required] : []);
  }

  ngOnInit(): void {}

  onConfirm(): void {
    if (this.askForReason) this.dialogRef.close(this.reasonControl.value || true);
    else this.dialogRef.close(true);
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }
}
