/** @format */

import {Component, Input, OnInit} from '@angular/core';
import Highcharts from 'highcharts';
import {cloneDeep, isEqual, map, merge, reduce, round, sum} from 'lodash-es';
import moment, {Moment} from 'moment';
import 'moment-timezone';
import {lastValueFrom} from 'rxjs';
import {AggregationValue, ChartComponent} from 'sesio-lib';
import {EquipmentFailureService} from '../../../_services/equipment-failure.service';
import {EquipmentService} from '../../../_services/equipment.service';

@Component({
  selector: 'app-equipment-availability-failure-chart',
  templateUrl: './equipment-availability-failure-chart.component.html',
  styleUrls: ['./equipment-availability-failure-chart.component.scss'],
})
export class EquipmentAvailabilityFailureChartComponent implements OnInit {
  @Input()
  id: string;
  
  @Input()
  supervised: boolean = true;

  @Input('filter')
  public set setFilter(filter: any) {
    if (!isEqual(this.filter, filter)) {
      this.filter = cloneDeep(filter);
      if (this.chart) this.loadData();
    }
  }
  private filter: any;

  private chart: ChartComponent;

  options = {
    chart: {zoomType: 'x'},
    credits: {enabled: false},
    exporting: {enabled: false},
    title: {text: ''},
    rangeSelector: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      split: false,
      useHTML: true,
      formatter: (() => {
        const self = this;
        return function () {
          return `
          <small>${moment(this.x).format('L LT')}</small><br/>
          ${reduce(
            this.points,
            (res, point) => {
              return `
            ${res}
            <span style="color:${point.color}">\u25CF</span>
            ${point.series.name}: <b>${round(point.y, point.series.index % 2 === 0 ? 2 : 0)}${
                point.series.index % 2 === 0 ? ' %' : ''
              }
            </b><br/>
          `;
            },
            ''
          )}
        `;
        };
      })(),
    },
    legend: {
      enabled: true,
    },
    xAxis: {ordinal: false},
    yAxis: [
      {
        showLastLabel: true,
        opposite: false,
        lineColor: 'var(--ion-color-primary)',
        labels: {style: {color: 'var(--ion-color-primary)'}},
        lineWidth: 1,
        resize: {enabled: true},
        height: '75%',
        max: 100,
        min: 0,
        tooltipValueFormat: '{value:.2f}',
      },
      {
        showLastLabel: true,
        opposite: true,
        lineColor: 'var(--ion-color-danger)',
        labels: {align: 'left', style: {color: 'var(--ion-color-danger)'}},
        lineWidth: 1,
        resize: {enabled: true},
        top: '75%',
        height: '25%',
        offset: 0,
        min: 0,
        tooltipValueFormat: '{value:.0f}',
      },
    ],
  };
  private extremes: {start?: Moment; end?: Moment};

  private data: [number, AggregationValue[]];

  constructor(private equipmentFailureService: EquipmentFailureService, private equipmentService: EquipmentService) {}

  ngOnInit() {}

  public setExtremes(start?: Moment, end?: Moment) {
    this.extremes = {start, end};
    if (this.chart) {
      this.chart.setExtremes(this.extremes.start, this.extremes.end);
    }
  }

  buildSeries(chartComponent: ChartComponent) {
    this.chart = chartComponent;
    this.chart.setSeries([
      {
        name: 'Disponibilité',
        type: 'area',
        threshold: null,
        step: true,
        tooltip: {
          valueDecimals: 2,
        },
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, Highcharts.getOptions().colors[0]],
            [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')],
          ],
        },
      } as any,
      {
        name: 'Pannes',
        type: 'area',
        lineWidth: 0,
        step: true,
        color: 'var(--ion-color-danger)',
        yAxis: 1,
      } as any,
    ]);
    if (this.extremes) this.chart.setExtremes(this.extremes.start, this.extremes.end);
    this.loadData();
  }

  private setData() {
    this.chart.setSerieData(
      map(this.data[1], d => [d.name, 100 - (d.value / this.data[0]) * 100]),
      0
    );
    this.chart.setSerieData(
      map(this.data[1], d => [d.name, d.value]),
      1
    );
    this.chart.setLoading(false);
  }

  private loadDataTm;
  private loadDataEcho;
  private async loadData(): Promise<void> {
    if (!this.chart) return;
    if (this.loadDataTm) clearTimeout(this.loadDataTm);
    await new Promise<void>(resolve => (this.loadDataTm = setTimeout(() => resolve(), 300)));
    this.loadDataEcho = Date.now();
    const echo = this.loadDataEcho;
    this.chart.setLoading(true);
    const search = merge(
      {equipmentId: this.id, start: this.extremes.start, end: this.extremes.end},
      this.filter
    );
    if (this.supervised) search.supervised = true;
    this.data = await Promise.all([
      this.id
        ? Promise.resolve(1)
        : lastValueFrom(this.equipmentService.count(search)).then(data => sum(map(data, 'count'))),
      lastValueFrom(this.equipmentFailureService.getChartRangeData(search)),
    ]);
    if (echo !== this.loadDataEcho) return;
    this.setData();
  }
}
