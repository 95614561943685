// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export interface IEnvironment {
  name: string;
  apiUrl: string;
  wsUrl: string;
  repoUrl: string;
  sentryUrl?: string;
}

export const environment: IEnvironment = {
  name: 'recette',
  apiUrl: 'https://recette-api.isitech.paris',
  wsUrl: 'https://recette-websocket.isitech.paris',
  repoUrl: 'https://s3-eu-west-3.amazonaws.com/recette-assets.isitech.paris'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
