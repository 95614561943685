/** @format */

import {propertyMap} from 'model-mapper';
import {EmbededContractor} from './embeded-contractor';
import {EmbededInspection} from './embeded-inspection';
import {EmbededOrganizationalUnit} from './embeded-organizational-unit';
import {File} from './file';
import {InspectionState} from './inspection-status';
import {Location} from './location';

export enum RealEstateStructureKind {
  LODGE = 'lodge',
  BUILDING = 'building',
  BOILER_ROOM = 'boiler-room',
  PARKING = 'parking',
  HOUSING = 'housing',
  STAIRCASE = 'staircase',
}

export const RealEstateStructureKindReferencePrefix = {
  [RealEstateStructureKind.LODGE]: 'C',
  [RealEstateStructureKind.BUILDING]: 'B',
  [RealEstateStructureKind.BOILER_ROOM]: 'T',
  [RealEstateStructureKind.PARKING]: 'S',
  [RealEstateStructureKind.HOUSING]: 'H',
  [RealEstateStructureKind.STAIRCASE]: 'E',
};

export class SecurityRegisterDocument {
  @propertyMap({source: '_id'})
  public id: string;

  @propertyMap({type: EmbededContractor})
  public contractor: EmbededContractor;

  @propertyMap()
  public depositary: string;

  @propertyMap({type: 'Moment'})
  public date: Date;

  @propertyMap({type: [EmbededInspection]})
  public inspections: EmbededInspection[];

  @propertyMap({type: File})
  public file: File;
}

export class RealEstateStructureAlertInfo {
  @propertyMap()
  thresholds: {
    [key: string]: {anomaly: number; failure: number}; // key => RealEstateStructureAlertKind
  };

  @propertyMap()
  total: number; // total of alert (open and closed)

  @propertyMap({type: Date})
  lastDate: Date; // last open date if has opened alert or last close date if no open alert

  @propertyMap()
  anomalies: number; // total of open anomalies

  @propertyMap()
  failures: number; // total of open failures
}

export class RealEstateStructure {
  @propertyMap({source: '_id'})
  public id: string;

  @propertyMap()
  public s3Path: string;

  @propertyMap()
  public kind: RealEstateStructureKind;

  @propertyMap()
  public name: string;

  @propertyMap()
  public reference: string;

  @propertyMap()
  public description: string;

  @propertyMap({type: Location})
  public location: Location;

  @propertyMap({type: File})
  public schema2D: File;

  @propertyMap({type: File})
  public photogrammetry: File;

  @propertyMap({type: File})
  public interactiveSchema: File;

  @propertyMap({type: EmbededOrganizationalUnit})
  public organizationalUnit: EmbededOrganizationalUnit;

  @propertyMap()
  public metadata: any;

  @propertyMap()
  public parent: string;

  @propertyMap()
  public path: string;

  @propertyMap()
  public pathNames: string[];

  @propertyMap({type: [SecurityRegisterDocument]})
  public securityRegisterDocuments: SecurityRegisterDocument[];

  @propertyMap()
  public inspectionCount: number;

  @propertyMap()
  public interventionCount: number;

  @propertyMap()
  public quotation: {
    total: {count: number; amount: number};
    confirmed: {count: number; amount: number};
    validated: {count: number; amount: number};
    refused: {count: number; amount: number};
  };

  @propertyMap()
  public equipment: {
    inspectionState: InspectionState;
    count: number;
    anomalyCount: number;
    dangerCount: number;
    controlRate: number;
    qualityIndex: number;
  };

  @propertyMap({type: RealEstateStructureAlertInfo})
  public alert: RealEstateStructureAlertInfo;

  @propertyMap({type: [File]})
  public documents: File[];

  @propertyMap()
  public isOverwritten: boolean;
}

export class EmbededRealEstateStructure {
  @propertyMap({source: '_id'})
  public id: string;

  @propertyMap()
  public kind: RealEstateStructureKind;

  @propertyMap()
  public path: string;

  @propertyMap()
  public pathNames: string[];

  @propertyMap()
  public reference: string;

  @propertyMap()
  public organizationalUnitId: string;
}
