/** @format */

import {Pipe, PipeTransform} from '@angular/core';
import {each, get, merge, omit} from 'lodash-es';

@Pipe({name: 'unwind'})
export class UnwindPipe implements PipeTransform {
  transform(data: any[], field: string): any[] {
    const res: any[] = [];
    each(data, d => {
      each(get(d, field), r => {
        res.push(merge({[field]: r}, omit(d, field)));
      });
    });
    return res;
  }
}
