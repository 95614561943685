export enum InspectionStatus {
  INFEASIBLE = '10-infeasible',
  OUT_OF_TIME = '20-out-of-time',
  LATE = '30-late',
  NEW = '40-new',
  PLANNED = '50-planned',
  WIP = '60-wip',
  TO_VALIDATE = '65-to-validate', // En attente de l'ajout de document ou vérification
  DONE = '70-done',
  CANCELED = '80-canceled',
  ARCHIVED = '90-archived',
}

export enum InspectionState {
  DANGER = 'danger',
  ANOMALY = 'anomaly',
  OK = 'ok',
  LATE = 'late',
  KO = 'ko',
}

export enum InspectionEquipmentStatus {
  OK = 'ok',
  KO = 'ko',
  MISSING = 'missing',
}
