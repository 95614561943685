import { propertyMap } from 'model-mapper';
import { Moment } from 'moment';
import { EmbededEquipment } from './equipment';
import { InterventionStatus, InterventionKind } from './intervention';
import { EmbededUnitPriceSchedule } from './embeded-unit-price-schedule';

export class EmbededIntervention {
  @propertyMap({ source: '_id' })
  public id: string;

  @propertyMap()
  public status: InterventionStatus;

  @propertyMap()
  public reference: string;

  @propertyMap()
  public externalReference: string;

  @propertyMap()
  public kind: InterventionKind;

  @propertyMap()
  public inspectionId: string;

  @propertyMap({ type: EmbededEquipment })
  public equipment: EmbededEquipment;

  @propertyMap({ type: EmbededUnitPriceSchedule })
  public unitPriceSchedule: EmbededUnitPriceSchedule;

  @propertyMap()
  public quantity: number;

  @propertyMap({ type: 'Moment' })
  public createdAt: Moment;

  @propertyMap({ type: 'Moment' })
  public updatedAt: Moment;
}
