import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {
  catchError,
  finalize,
  map,
  filter,
  debounceTime,
} from 'rxjs/operators';
import { IDatatableOptions, IDatatableRecords } from './datatable.class';

export interface IRecord {
  _id: any;
}

export type Service = (
  options: IDatatableOptions
) => Observable<IDatatableRecords<any>>;

export class DatatableDataSource<T extends IRecord> implements DataSource<T> {
  public dataSubject = new BehaviorSubject<T[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(true);
  private options: IDatatableOptions;
  private loadingTm: any;

  public loading$ = this.loadingSubject.asObservable();
  public recordsTotal = 0;
  public recordsFiltered = 0;
  public get data(): T[] {
    return this.dataSubject.value;
  }

  constructor(private service: Service) {}

  connect(): Observable<T[]> {
    return this.dataSubject.asObservable();
  }

  disconnect(): void {
    this.dataSubject.complete();
    this.loadingSubject.complete();
  }

  public loadData(options: IDatatableOptions): void {
    this.options = options;
    if (this.loadingTm) clearTimeout(this.loadingTm);
    this.loadingTm = setTimeout(() => {
      this.loadingTm = null;
      this.loadingSubject.next(true);
      const subscription = this.service(options)
        .pipe(
          catchError(() => of({})),
          filter(
            (datasource: IDatatableRecords<T>) =>
              datasource.draw === this.options.draw
          ),
          map((datasource: IDatatableRecords<T>) => {
            this.recordsTotal = datasource.recordsTotal;
            this.recordsFiltered = datasource.recordsFiltered;
            this.dataSubject.next(datasource.data);
          }),
          finalize(() => {
            this.loadingSubject.next(false);
            subscription.unsubscribe();
          })
        )
        .subscribe();
    }, 100);
  }

  public fetchData(options: IDatatableOptions): Promise<IDatatableRecords<T>> {
    return this.service(options)
      .pipe(catchError(() => of({} as IDatatableRecords<T>)))
      .toPromise();
  }

  public refresh(): void {
    return this.loadData(this.options);
  }
}
