/** @format */

import { filter, find, get, includes, isNil, join, map } from 'lodash-es';
import moment from 'moment';
import { propertyMap } from 'model-mapper';
import { IRecord } from './datasource';
import { ValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';
import striptags from 'striptags';
import { IIcon } from './icon.class';
import { DatagridComponent } from './datagrid.component';
import { formatNumber } from '@angular/common';
import { O } from '@angular/cdk/keycodes';

export interface IColumnOptionLabelValue {
  value: any;
  name: string;
  color?: string;
  bgColor?: string;
  icon?: IIcon;
}
export interface IColumnOptionIconValue {
  value: any;
  icon: IIcon;
  color?: string;
  name?: string;
}

export type ColumnOptionValue = IColumnOptionLabelValue | IColumnOptionIconValue;

export type SortDir = 'asc' | 'desc';

interface IColumnOption {
  property: string;
  label: string;
  tooltip?: string;
  translateValue?: boolean;
  textAlign?: 'start' | 'center' | 'end';
  color?: ((record: IRecord) => string) | string;
  bgColor?: ((record: IRecord) => string) | string;
  prefixIcon?: ((record: IRecord) => IIcon | string) | string;
  suffix?: ((record: IRecord) => string) | string;
  searchable?: boolean;
  sortable?: boolean;
  show?: boolean;
  width?: number;
  paddingLeft?: string;
  sticky?: boolean;
  type?: string;
  displayProperty?: string;
  searchProperty?: string;
  sortProperty?: string;
  displayWith?: (record: IRecord) => string;
  content?: string;
  exportable?: boolean;
  contentExport?: (column: ColumnDef, record: IRecord) => string;
  splitExport?: (record: IRecord) => { name: string; value: any }[];
  click?: (record: IRecord, datagrid: DatagridComponent) => Promise<any>;
  linkedProperties?: string[];
  metadata?: any;
  cellTooltip?: (record) => string

  // TODO
  searchValidators?: ValidatorFn[];
}

export interface IHiddenColumnOption {
  property: string;
  hidden: true;
  sortable?: boolean;
}

export interface ITextColumnOption extends IColumnOption {
  type?: 'text';
}

export interface INumberColumnOption extends IColumnOption {
  type: 'number';
  precision?: number;
}

export interface IDateColumnOption extends IColumnOption {
  type: 'date';
  fromNow?: boolean;
  format?: string;
}

export interface ISelectColumnOption extends IColumnOption {
  type: 'select';
  options: ColumnOptionValue[];
  multiple?: boolean;
  hideNane?: boolean;
  default?: ColumnOptionValue;
}

export interface IAutocompleteColumnOption extends IColumnOption {
  type: 'autocomplete';
  options: ColumnOptionValue[];
}

export interface IAutocompleteAsyncColumnOption extends IColumnOption {
  type: 'autocomplete';
  optionsAsync: (value: string) => Observable<ColumnOptionValue[]>;
}

export interface IToggleButtonColumnOption extends IColumnOption {
  type: 'button-toggle';
  options: ColumnOptionValue[];
}

export interface ICheckboxColumnOption extends IColumnOption {
  type: 'checkbox';
}

export type ColumnOption =
  | IHiddenColumnOption
  | ITextColumnOption
  | INumberColumnOption
  | IDateColumnOption
  | ISelectColumnOption
  | IAutocompleteColumnOption
  | IAutocompleteAsyncColumnOption
  | IToggleButtonColumnOption
  | ICheckboxColumnOption;

export class Option {
  @propertyMap()
  public value: any;

  @propertyMap()
  public name: string;

  @propertyMap()
  public color: string;

  @propertyMap()
  public bgColor: string;

  @propertyMap()
  public icon: IIcon;
}

export class Sort {
  static build({ dir, column }: { dir?: SortDir; column: number }): Sort {
    const sort = new Sort();
    sort.dir = dir || 'asc';
    sort.position = column;
    return sort;
  }

  @propertyMap()
  public dir: SortDir;

  @propertyMap()
  public position: number;
}

export class ColumnDef {
  @propertyMap({ default: 'text' })
  public type: string;

  @propertyMap()
  public property: string;

  @propertyMap({ default: null })
  public displayProperty: string;

  @propertyMap({ default: null })
  public searchProperty: string;

  @propertyMap({ default: null })
  public sortProperty: string;

  @propertyMap({ default: [] })
  public linkedProperties: string[];

  @propertyMap()
  public label: string;

  @propertyMap()
  public tooltip: string;

  @propertyMap({ default: false })
  public translateValue: boolean;

  @propertyMap({ default: 'start' })
  public textAlign: 'start' | 'center' | 'end';

  @propertyMap({ default: false })
  public searchable: boolean;

  @propertyMap({ default: false })
  public sortable: boolean;

  @propertyMap({ default: true })
  public show: boolean;

  @propertyMap({ default: false })
  public hidden: boolean;

  @propertyMap({ default: null })
  public width: number;

  @propertyMap({ default: null })
  public paddingLeft: string;

  @propertyMap({ default: false })
  public sticky: boolean;

  @propertyMap({ default: null })
  public prefixIcon: ((record: IRecord) => IIcon | string) | string;

  @propertyMap()
  public suffix: ((record: IRecord) => string) | string;

  @propertyMap({ default: null })
  public color: ((record: IRecord) => string) | string;

  @propertyMap({ default: null })
  public bgColor: ((record: IRecord) => string) | string;

  @propertyMap({ default: null })
  public click: (record: IRecord, datagrid: DatagridComponent) => Promise<void>;

  @propertyMap({ default: null })
  public displayWith: (record: IRecord) => string;

  @propertyMap({ default: null })
  public cellTooltip: (record: IRecord) => string;

  @propertyMap({ default: null })
  public content: string;

  @propertyMap({ default: true })
  public exportable: boolean;

  @propertyMap({ default: null })
  public contentExport: (column: ColumnDef, record: IRecord) => string;

  @propertyMap({ default: null })
  public splitExport: (record: IRecord) => { name: string; value: any }[];

  @propertyMap({ default: null, type: [Option] })
  public options: Option[];

  @propertyMap({ default: null })
  public default: Option;

  @propertyMap({ default: false })
  public multiple: boolean;

  @propertyMap({ default: false })
  public hideNane: boolean;

  @propertyMap({ default: null })
  public optionsAsync: (search: string) => Observable<Option[]>;

  @propertyMap({ default: false })
  public fromNow: boolean;

  @propertyMap()
  public format: string;

  @propertyMap()
  public precision: number;

  @propertyMap({ default: {} })
  public metadata: any;

  public index: number;

  public left = 0;

  public displaySticky = false;

  public translateX = 0;

  public sort: Sort;

  public getCellData(record: any): {
    value: string;
    color: string;
    bgColor: string;
    icon: IIcon;
    content: string;
    suffix?: string;
    tooltip?: string
  } {
    const data: { value: string; color: string; bgColor: string; icon: IIcon; content: string; suffix?: string; tooltip?: string } = {
      value: null,
      color: null,
      bgColor: null,
      icon: null,
      content: this.content,
    };
    const recordValue = get(record, this.displayProperty ? this.displayProperty : this.property);
    let option: Option;

    if (this.cellTooltip) data.tooltip = this.cellTooltip(record);

    if (this.displayWith) {
      data.value = this.displayWith(record);
    } else {
      if (['select'].includes(this.type)) {
        if (Array.isArray(recordValue)) {
          data.value = join(
            map(
              filter(this.options, o => includes(recordValue, o.value)),
              'name'
            ),
            ', '
          );
        } else {
          option = find(this.options, o => o.value === recordValue);
          if (!option && !isNil(recordValue) && this.default) option = this.default;
          if (!this.hideNane) data.value = option ? option.name : recordValue;
        }
      } else if (['button-toggle'].includes(this.type)) {
        option = (this.options || []).find(o => o.value === recordValue);
        if (!this.hideNane) data.value = option ? option.name : recordValue;
      } else if (['checkbox'].includes(this.type)) {
        option = (this.options || []).find(o => o.value === recordValue);
      } else if (['number'].includes(this.type)) {
        data.value = isNil(recordValue)
          ? recordValue
          : formatNumber(recordValue, 'fr', `.0-${this.precision || 0}`);
      } else if (recordValue && this.type === 'date') {
        data.value = this.fromNow ? moment(recordValue).fromNow(true) : moment(recordValue).format(this.format || 'L');
      } else {
        data.value = recordValue;
      }
    }

    if (option && option.color) {
      data.color = option.color;
    } else if (typeof this.color === 'string') {
      data.color = this.color;
    } else if (typeof this.color === 'function') {
      data.color = this.color(record);
    }
    if (option && option.bgColor) {
      data.bgColor = option.bgColor;
    } else if (typeof this.bgColor === 'string') {
      data.bgColor = this.bgColor;
    } else if (typeof this.bgColor === 'function') {
      data.bgColor = this.bgColor(record);
    }

    if (option && option.icon) {
      data.icon = option.icon;
    } else if (!this.displayWith && 'checkbox' === this.type) {
      data.icon = {
        name:
          recordValue === true
            ? 'check_box'
            : recordValue === false
            ? 'check_box_outline_blank'
            : 'indeterminate_check_box',
      };
    } else if (typeof this.prefixIcon === 'string') {
      data.icon = { name: this.prefixIcon };
    } else if (typeof this.prefixIcon === 'function') {
      const icon = this.prefixIcon(record);
      data.icon = typeof icon === 'string' ? { name: icon } : icon;
    }

    if (typeof this.suffix === 'string') {
      data.suffix = this.suffix;
    } else if (typeof this.suffix === 'function') {
      data.suffix = this.suffix(record);
    }

    return data;
  }

  public getExportCellData(record: any): any {
    const recordValue = get(record, this.displayProperty ? this.displayProperty : this.property);
    let value: any;
    if (this.contentExport) {
      value = this.contentExport(this, record);
    } else if (this.displayWith) {
      value = this.displayWith(record);
    } else {
      if (['select', 'button-toggle'].includes(this.type)) {
        const option = (this.options || []).find(o => o.value === recordValue);
        value = option ? option.name : recordValue;
      } else if (recordValue && this.type === 'date') {
        value = this.fromNow ? moment(recordValue).fromNow(true) : moment(recordValue).format(this.format || 'L');
      } else {
        value = recordValue;
      }
    }
    const rawValue =
      value && value.changingThisBreaksApplicationSecurity ? value.changingThisBreaksApplicationSecurity : value;

    return typeof rawValue === 'string' ? striptags(striptags(rawValue, ['br', 'div', 'li']), [], '\n') : rawValue;
  }
}
