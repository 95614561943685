import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'qualityColor'
})
export class QualityColorPipe implements PipeTransform {

  transform(value: number): string {
    if (value === 5) { return '#33a357'; }
    if (value >= 4) { return '#c2d545'; }
    if (value >= 3) { return '#fff02b'; }
    if (value >= 2) { return '#edb731'; }
    if (value >= 1) { return '#d66f2c'; }
    return '#cc232a';
  }

}
