import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { filter } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { HelpService, Tutorial, TutorialPlatform } from 'sesio-lib';
import { User } from '../../_classes/user';
import { UserService } from '../../_services/user.service';
import { TutorialDialogComponent } from './tutorial-dialog/tutorial-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class TutorialService {

  public $replay: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private user: User;
  private dialogRef: MatDialogRef<TutorialDialogComponent>;
  private tutorials: Tutorial[];
  private loadingPromise;

  constructor(
    private dialog: MatDialog,
    private helpService: HelpService,
    private userService: UserService
  ) {
    this.user = this.userService.$user.value;
    this.userService.$user.subscribe(user => this.user = user);
  }

  public async trigger(page: string, force = false): Promise<void> {
    if (this.loadingPromise) { await this.loadingPromise; }
    if (!this.tutorials) {
      this.tutorials = await (this.loadingPromise = this.helpService.tutorials(TutorialPlatform.WEBAPP).toPromise());
    }
    if (this.dialogRef) { this.dialogRef.close(); }
    if (!force && this.user?.tutorials[`${TutorialPlatform.WEBAPP}.${page}`] === true) {
      return;
    }
    const tutorials = filter(this.tutorials, { page });
    if (tutorials?.length > 0) {
      this.dialogRef = this.dialog.open(TutorialDialogComponent, {
        data: {
          tutorials, platform: TutorialPlatform.WEBAPP, page
        }, maxWidth: '90vw'
      });
      return this.dialogRef.afterClosed().toPromise();
    }
  }

}