/** @format */

import { propertyMap } from 'model-mapper';
import { Moment } from 'moment';
import { EmbededRealEstateStructure } from './real-estate-structure';
import { EmbededOrganizationalUnit } from './embeded-organizational-unit';
import { File } from './file';
import { EmbededContractor } from './embeded-contractor';
import { EmbededEquipment } from './equipment';
import { EmbededEquipmentKind, EquipmentKindIssue } from './equipment-kind';
import { EmbededUnitPriceSchedule } from './embeded-unit-price-schedule';
import { EmbededUser } from './user';
import { Location } from './location';

export enum InterventionKind {
  FIX = 'fix',
  SOS = 'sos',
  REPAIR = 'repair',
}

export const InterventionKindOptions = [
  { value: InterventionKind.FIX, name: 'Maintenance', color: 'var(--ion-color-secondary)' },
  { value: InterventionKind.SOS, name: 'SOS', color: 'var(--ion-color-danger)' },
  { value: InterventionKind.REPAIR, name: 'Intervention', color: 'var(--ion-color-warning)' },
];

export enum InterventionStatus {
  NEW = 'new', // from quotation
  ESTIMATED = 'estimated', // from quotation
  VALIDATED = 'validated', // from quotation
  ACKNOWLEDGED = 'acknowledged', // from order
  DONE = 'done',
  CLOSED = 'closed',
  INFEASIBLE = 'infeasible',
  CANCELED = 'canceled',
  ARCHIVED = 'archived',
}

export const InterventionStatusColor = {
  [InterventionStatus.NEW]: 'var(--ion-color-medium)',
  [InterventionStatus.ESTIMATED]: '#707070',
  [InterventionStatus.VALIDATED]: '#8bb0c4',
  [InterventionStatus.ACKNOWLEDGED]: 'var(--ion-color-secondary)',
  [InterventionStatus.DONE]: 'var(--ion-color-success)',
  [InterventionStatus.CLOSED]: '#000',
  [InterventionStatus.INFEASIBLE]: '#9564E2',
  [InterventionStatus.CANCELED]: '#ccc',
};

export const InterventionStatusTooltip = {
  [InterventionStatus.NEW]: "État de l'intervention : l'intervention doit être devisé.",
  [InterventionStatus.ESTIMATED]:
    "État de l'intervention : l'intervention fait l'objet d'un devis en attente de validation.",
  [InterventionStatus.VALIDATED]:
    "État de l'intervention : la commande du devis a été validée, l'intervention doit être réalisé.",
  [InterventionStatus.DONE]: "État de l'intervention : l'intervention a été réalisée.",
  [InterventionStatus.CLOSED]: "État de l'intervention : l'intervention a été cloturée.",
  [InterventionStatus.INFEASIBLE]: "État de l'intervention : l'intervention a été marquée comme infaisable.",
  [InterventionStatus.CANCELED]: "État de l'intervention : l'intervention a été marquée comme annulée.",
};

export class Intervention {
  @propertyMap({ source: '_id' })
  public id: string;

  @propertyMap()
  public reference: string;

  @propertyMap()
  public externalReference: string;

  @propertyMap()
  public kind: InterventionKind;

  @propertyMap({ type: EmbededOrganizationalUnit })
  public organizationalUnit: EmbededOrganizationalUnit;

  @propertyMap({ type: EmbededRealEstateStructure })
  public realEstateStructure: EmbededRealEstateStructure;

  @propertyMap()
  public inspectionId: string;

  @propertyMap()
  public directOrderId: string;

  @propertyMap()
  public quotationId: string;

  @propertyMap()
  public technicianName: string;

  @propertyMap({ type: EmbededEquipment })
  public equipment: EmbededEquipment;

  @propertyMap({ type: EmbededContractor })
  public contractor: EmbededContractor;

  @propertyMap({ type: EmbededUnitPriceSchedule })
  public unitPriceSchedule: EmbededUnitPriceSchedule;

  @propertyMap({ type: Location })
  public location: Location;

  @propertyMap({ type: EmbededUser })
  public user: EmbededUser;

  @propertyMap()
  public quantity: number;

  @propertyMap()
  public comment: number;

  @propertyMap()
  public status: InterventionStatus;

  @propertyMap({ type: 'Moment' })
  public startDate: Moment;

  @propertyMap({ type: 'Moment' })
  public endDate: Moment;

  @propertyMap()
  public duration: number;

  @propertyMap({ type: [EquipmentKindIssue] })
  public issues: EquipmentKindIssue[];

  @propertyMap({ type: [File] })
  public files: File[];

  @propertyMap({ type: 'Moment' })
  public createdAt: Moment;

  @propertyMap({ type: 'Moment' })
  public updatedAt: Moment;
}
