import { Injectable } from '@angular/core';
import { ModelMapper } from 'model-mapper';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Contractor, ContractorService as SesioContractorService, Family } from 'sesio-lib';
import { environment } from '../../environments/environment';
import { buildHeaderSearchFilter, IHeaderSearch } from '../_classes/header-search';
import { IDatatableOptions, IDatatableRecords } from '../_components/datagrid/datatable.class';

export interface IContractorFilter extends IHeaderSearch {
  families?: string[];
}

@Injectable({
  providedIn: 'root'
})
export class ContractorService extends SesioContractorService {

  public list(search: IContractorFilter): Observable<Contractor[]> {
    return this.http.post<any[]>(`${environment.apiUrl}/contractors/get-contractors`, this.buildSearchFilter(search))
      .pipe(map(data => data.map(d => new ModelMapper(Contractor).map(d))));
  }

  public datatable(options: { families?: Family[], query: IDatatableOptions }): Observable<IDatatableRecords<any>> {
    return this.http.post<any>(`${this.config.environment.apiUrl}/contractors/datatable`, options);
  }

  private buildSearchFilter(search: IContractorFilter): any {
    const filter = buildHeaderSearchFilter(search);
    if (search?.families) {
      filter.families = search.families;
    }
    return filter;
  }

}
