/** @format */

import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ControlContainer, UntypedFormBuilder, UntypedFormGroup, FormGroupDirective} from '@angular/forms';
import {each, filter, isNil, reduce} from 'lodash-es';
import {round} from 'lodash-es';
import {fadeOut, fade, MetricValue, IMetric, MetricKind, Metric, Metrics} from 'sesio-lib';
import {SubSink} from 'subsink';

@Component({
  selector: 'app-metrics-edit',
  templateUrl: './metrics-edit.component.html',
  styleUrls: ['./metrics-edit.component.scss'],
  animations: [fade, fadeOut],
})
export class MetricsEditComponent implements OnInit, OnDestroy {
  @Input('metricsCodes')
  public set setMetricsCodes(metricsCodes: Metric[]) {
    this.metricsCodes = metricsCodes;
    if (!this.loading) {
      this.load();
    }
  }
  public metricsCodes: Metric[];

  @Input()
  public metrics: MetricValue;

  public formGroup: UntypedFormGroup;
  public metricList: IMetric[];

  private loading = true;
  private subsink = new SubSink();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    private controlContainer: ControlContainer
  ) {}

  ngOnInit(): void {
    this.formGroup = this.controlContainer.control as UntypedFormGroup;
    this.load();
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  private load() {
    Object.keys(this.formGroup.controls).forEach(key => this.formGroup.removeControl(key));
    this.metricList = filter(Metrics, m => m.isEditable && (this.metricsCodes || []).includes(m.code));
    each(this.metricList, metric => {
      switch (metric.kind) {
        case MetricKind.NUMBER:
          this.formGroup.setControl(
            metric.code,
            this.formBuilder.control(
              this.metrics && !isNil(this.metrics[metric.code]) ? round(this.metrics[metric.code] as number, 2) : null
            )
          );
          break;
        default:
          this.formGroup.setControl(
            metric.code,
            this.formBuilder.control(this.metrics ? this.metrics[metric.code] : null)
          );
      }
    });
    this.changeDetectorRef.markForCheck();
  }
}
