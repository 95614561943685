import { propertyMap } from 'model-mapper';

export class LocationAddress {

  @propertyMap()
  public formatted: string;

  @propertyMap()
  public number: string;

  @propertyMap()
  public street: string;

  @propertyMap()
  public city: string;

  @propertyMap()
  public postalCode: string;

  @propertyMap()
  public country: string;

  @propertyMap()
  public countryShort: string;

  @propertyMap()
  public type: string;

}

export class Location {

  @propertyMap({ type: LocationAddress })
  public address: LocationAddress;

  @propertyMap()
  public additional?: string;

  @propertyMap()
  public coordinates: [number, number];

}
