import { propertyMap } from 'model-mapper';
import { Metric } from './metrics';
import { Family } from './family';
import { EquipmentDocumentKind } from './equipment-document';

export enum EquipmentKindFireSafetyCategory {
  // Eclairage de sécurité
  SECURITY_LIGHT = 'security-light',
  // Plans de sécurité
  SECURITY_PLANS = 'security-plans',
  // Désenfumage
  SMOKE_EXTRACTION = 'smoke-extraction',
  // Système de sécurité incendie (SSI)
  FIRE_SAFETY_SYSTEM = 'fire-safety-system',
  // Compartimentage parking
  CAR_PARK_COMPARTMENTALIZATION = 'car-park-compartmentalization',
  // Portes coupe feu
  FIRE_DOOR = 'fire-door',
  // Extinction automatique
  AUTOMATIC_SHUTDOWN = 'automatic-shutdown',
  // Extincteurs
  EXTINGUISHER = 'extinguisher',
  // Bac à sable / eau
  SANDBOX_OR_WATER_TANK = 'sandbox-or-water-tank',
  // Colonnes sèche / humide
  COLUMNS = 'columns',
  // Bouche / Poteau d'incendie
  FIRE_HYDRANT = 'fire-hydrant',
  // Alarme incendie
  FIRE_ALARM = 'fire-alarm',
}

export enum EquipmentKindCategory {
  // electro technique
  ELEVATOR = 'elevator',
  PARKING_DOOR = 'parking-door',
  OPTICAL_FIBER = 'optical-fiber',
  RELAY_ANTENNA = 'relay-antenna',
  REMOTE_READING = 'remote-reading',
  METER = 'meter',
  PHOTOVOLTAIC_PANEL = 'photovoltaic-panel',
  CHARGING_STATION = 'charging-station',

  // fire safety
  SECURITY_LIGHT = 'security-light',
  SECURITY_PLANS = 'security-plans',
  SMOKE_EXTRACTION = 'smoke-extraction',
  FIRE_SAFETY_SYSTEM = 'fire-safety-system',
  CAR_PARK_COMPARTMENTALIZATION = 'car-park-compartmentalization',
  FIRE_DOOR = 'fire-door',
  AUTOMATIC_SHUTDOWN = 'automatic-shutdown',
  EXTINGUISHER = 'extinguisher',
  SANDBOX_OR_WATER_TANK = 'sandbox-or-water-tank',
  COLUMNS = 'columns',
  FIRE_HYDRANT = 'fire-hydrant',
  FIRE_ALARM = 'fire-alarm',

  // energy & fluid
  BOILER = 'boiler',
  VALVE = 'valve',
  EXCHANGER = 'exchanger',

  SESIO_BOX = 'sesio-box',
  SESIO_SENSOR = 'sesio-sensor',
  TEMPERATURE_SENSOR = 'temperature-sensor',
  HYGROMETRY_SENSOR = 'hygrometry-sensor',

  OTHER = 'other',

  TRANSMITTER = 'transmitter',
  TREND = 'trend',
}

export enum EquipmentKindMetadataKind {
  STRING = 'string',
  NUMBER = 'number',
  BOOLEAN = 'boolean',
  DATE = 'date',
  STRING_ENUM = 'string-enum',
  NUMBER_ENUM = 'number-enum',
  STRING_ARRAY = 'string-array',
  NUMBER_ARRAY = 'number-array',
  CONTRACTOR = 'contractor',
}

export class EquipmentKindMetadataOption {
  @propertyMap()
  public label: string;

  @propertyMap()
  public value: string | number;
}

export class EquipmentKindMetadata {
  @propertyMap()
  public name: string;

  @propertyMap()
  public label: string;

  @propertyMap()
  public block: string;

  @propertyMap()
  public kind: EquipmentKindMetadataKind;

  @propertyMap({ default: false })
  public isRequired: boolean;

  @propertyMap({ default: true })
  public isEditable: boolean;

  @propertyMap({ default: false })
  public isInDatagrid: boolean;

  @propertyMap({ type: [EquipmentKindMetadataOption] })
  public options: EquipmentKindMetadataOption[];

  @propertyMap()
  public min: number;

  @propertyMap()
  public max: number;

  @propertyMap()
  public step: number;

  @propertyMap()
  public filter: any;
}

export class EquipmentKindControl {
  @propertyMap({ source: '_id' })
  public id: string;

  @propertyMap()
  public name: string;

  @propertyMap()
  public options: string[];
}

export class EquipmentKindIssue {
  @propertyMap({ source: '_id' })
  public id: string;

  @propertyMap()
  public reference: string;

  @propertyMap()
  public name: string;
}

export class EquipmentKindInspectionConfig {
  @propertyMap({ default: 'none' })
  public kind: 'none' | 'interval';

  @propertyMap()
  public interval: string;

  @propertyMap()
  public dates: string[]; // 'MM/DD'[]

  @propertyMap()
  public lateOffset: number;

  @propertyMap()
  public outOfTimeOffset: number;
}

export class EquipmentKind {
  @propertyMap({ source: '_id' })
  public id: string;

  @propertyMap()
  public name: string;

  @propertyMap()
  public referenceCode: string;

  @propertyMap({ default: 'organizational-unit' })
  public referenceBase: 'organizational-unit' | 'real-estate-structure';

  @propertyMap()
  public referencePad: number;

  @propertyMap()
  public family: Family;

  @propertyMap()
  public category: EquipmentKindCategory;

  @propertyMap({ default: false })
  public supervision: boolean;

  @propertyMap({ default: false })
  public hypervision: boolean;

  @propertyMap({ default: [] })
  public metrics: Metric[];

  @propertyMap({
    type: EquipmentKindInspectionConfig,
    default: { kind: 'none' },
  })
  public inspectionConfig: EquipmentKindInspectionConfig;

  @propertyMap()
  public allowedDocumentKinds: EquipmentDocumentKind[];

  @propertyMap({ type: [EquipmentKindMetadata] })
  public metadata: EquipmentKindMetadata[];

  @propertyMap({ type: [EquipmentKindControl] })
  public controls: EquipmentKindControl[];

  @propertyMap({ type: [EquipmentKindIssue] })
  public issues: EquipmentKindIssue[];
}

export class EmbededEquipmentKind {
  @propertyMap({ source: '_id' })
  public id: string;

  @propertyMap()
  public name: string;

  @propertyMap()
  public referenceCode: string;

  @propertyMap({ default: 'organizational-unit' })
  public referenceBase: 'organizational-unit' | 'real-estate-structure';

  @propertyMap()
  public family: Family;

  @propertyMap()
  public category: EquipmentKindCategory;

  @propertyMap({ type: [EquipmentKindMetadata] })
  public metadata: EquipmentKindMetadata[];

  @propertyMap({ default: false })
  public supervision: boolean;

  @propertyMap({ default: false })
  public hypervision: boolean;

  @propertyMap({ default: [] })
  public metrics: Metric[];
}
