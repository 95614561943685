import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'controlRateColor'
})
export class ControlRateColorPipe implements PipeTransform {

  transform(value: number): string {
    if (value === 100) { return '#33a357'; }
    if (value >= 99) { return '#79b752'; }
    if (value >= 98) { return '#c2d545'; }
    if (value >= 97) { return '#fff02b'; }
    if (value >= 96) { return '#edb731'; }
    if (value >= 95) { return '#d66f2c'; }
    return '#cc232a';
  }

}
