<div class="sub-block" fxLayout="column" fxLayoutAlign="start start">
  <div class="header" fxLayout="row" fxLayoutAlign="space-between start">
    <ion-label class="sub-block-title" color="primary" fxLayout="column" fxLayoutAlign="start">
      <ion-text class="title" [innerHTML]="title | safe:'html'"></ion-text>
      <ion-text class="subtitle" *ngIf="subtitle" [innerHTML]="subtitle | safe:'html'"></ion-text>
    </ion-label>
    <div *ngIf="evolution !== undefined" class="indicator" fxLayout="column" fxLayoutAlign="space-evenly center"
      [attr.color]="!reverse ? (evolution < 0 ? 'danger' : 'success') : (evolution <= 0 ? 'success' : 'danger')">
      <mat-icon *ngIf="!reverse && evolution > 0" color="white">trending_up</mat-icon>
      <mat-icon *ngIf="reverse && evolution > 0" color="white">trending_up</mat-icon>
      <mat-icon *ngIf="!reverse && evolution < 0" color="white">trending_down</mat-icon>
      <mat-icon *ngIf="reverse && evolution < 0" color="white">trending_down</mat-icon>
      <mat-icon *ngIf="!evolution" color="white">trending_flat</mat-icon>
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="1px">
        <span *ngIf="evolution">{{evolution > 0 ? '+' : '-'}}</span>
        <span>{{evolution | round: 1 | absolute}}</span>
        <span *ngIf="evolution !== null">{{evolutionUnit}}</span>
      </div>
    </div>
  </div>
  <div *ngIf="integer !== null || decimal !== null" class="data" fxLayout="row" fxLayoutAlign="start end">
    <span #integerRef class="integer" [style.color]="color" [countUp]="integer"
      [options]="{ duration: 1, separator: ' ' }" [reanimateOnClick]="false"></span>
    <span *ngIf="!hideDecimal" class="decimal" [style.color]="color">,{{decimal}}</span>
    <span *ngIf="unit" class="unit" color="primary" [style.left.px]="integerRef.clientWidth + 10">{{unit}}</span>
  </div>
  <div *ngIf="integer === null && decimal === null" class="data" [style.color]="color">
    <span class="decimal" [style.color]="color">-</span>
  </div>
</div>
