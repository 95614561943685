import { Component, OnInit, Input } from '@angular/core';
import {
  EquipmentDocument, EquipmentDocumentService,
  fadeIn, fadeOut, IPageTrackingInfo
} from 'sesio-lib';
import { ModalController } from '@ionic/angular';
import { includes } from 'lodash-es';

@Component({
  templateUrl: './document.modal.html',
  styleUrls: ['./document.modal.scss'],
  animations: [fadeIn, fadeOut]
})
export class DocumentModal implements OnInit {

  @Input()
  public id: string;

  @Input()
  public reference: string;

  @Input()
  public kind: string;

  @Input()
  public tracking: IPageTrackingInfo;

  public documents: EquipmentDocument[];

  constructor(
    private modalController: ModalController,
    private equipmentDocumentService: EquipmentDocumentService
  ) { }

  async ngOnInit(): Promise<void> {
    this.documents = await this.equipmentDocumentService.getEquipmentDocuments({
      eqId: this.id, kind: this.kind
    }).toPromise();
  }

  public async dismiss(): Promise<void> {
    await this.modalController.dismiss();
  }

  public type(doc: EquipmentDocument): string {
    if (doc.mimetypes) {
      if (doc.mimetypes.find(mt => mt.startsWith('image/'))) { return 'image'; }
      if (includes(doc.mimetypes, 'application/pdf')) { return 'pdf'; }
      if (doc.mimetypes.find(mt => includes([
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel'
      ], mt))) { return 'office'; }
    }
    return null;
  }
}
