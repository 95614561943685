/** @format */

import { HttpErrorResponse } from '@angular/common/http';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { sentryVersion } from './sentry-version';

declare const QWATracker: any;

if (environment.sentryUrl) {
  Sentry.init({
    release: sentryVersion,
    autoSessionTracking: true,
    environment: environment.name,
    dsn: environment.sentryUrl,
    integrations: [
      new BrowserTracing({
        tracingOrigins: ['localhost', environment.apiUrl],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    beforeSend: (event, hint) => {
      if (hint.originalException && hint.originalException instanceof Error) {
        if ((hint.originalException as any).rejection instanceof HttpErrorResponse) {
          const httpError: HttpErrorResponse = (hint.originalException as any).rejection;
          if ([401, 403, 404].includes(httpError.status)) return null;
        }
      }
      // Sentry.showReportDialog({ eventId: event.event_id });
      return event;
    },
    tracesSampleRate: 1.0,
  });
}
if (environment.qwatTrackerId) QWATracker.activate(environment.qwatTrackerId);
if (environment.production) enableProdMode();

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
