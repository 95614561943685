import { values } from 'lodash-es';
import { propertyMap } from 'model-mapper';
import { EmbededContractor } from './embeded-contractor';
import { Family } from './family';

export enum UnitPriceScheduleAccessControlCategory {
  INTERVENTION_REQUEST = 'intervention-request',
  MEANS_OF_ACCESS_CREATION = 'means-of-access-creation',
  MEANS_OF_ACCESS_MODIFICATION = 'means-of-access-modification',
  MEANS_OF_ACCESS_DEACTIVATION = 'means-of-access-deactivation',
}

export enum UnitPriceScheduleFireSafetyCategory {
  FLAT_RATE = 'FORFAIT',
  PLAN = 'PLAN',
  EXTINGUISHER = 'EXTINCTEUR',
  DRY_COLUMNS = 'COLONNES SECHES',
  EMERGENCY_LIGHTING_UNIT = 'BLOC ECLAIRAGE DE SECURITE',
  SMOKE_EXHAUST = 'DESENFUMAGE',
  FIRE_DOORS = 'PORTES COUPE-FEU',
  FACILITIES_COVERED_PARKING = 'EQUIPEMENTS - PARC DE STATIONNEMENT COUVERT',
  TECHNICAL_DUCTS = 'GAINES TECHNIQUES',
  VARIOUS = 'DIVERS',
  SECURING_ROOF_ACCESS = 'SECURISATION ACCES TOITURE',
  PARTITIONING_AND_CAULKING = 'CLOISONNEMENT ET CALFEUTREMENTS',
}

export const UnitPriceScheduleCategories = {
  [Family.ACCESS_CONTROL]: values(UnitPriceScheduleAccessControlCategory),
  [Family.FIRE_SAFETY]: values(UnitPriceScheduleFireSafetyCategory),
};

export enum UnitPriceScheduleFieldKind {
  STRING = 'string',
  NUMBER = 'number',
  ENUM = 'enum',
}

export class UnitPriceScheduleFieldEnumElmt {
  @propertyMap()
  public name: string;

  @propertyMap()
  public value: string;
}

export class UnitPriceScheduleField {
  @propertyMap()
  public code: string;

  @propertyMap()
  public label: string;

  @propertyMap()
  public kind: UnitPriceScheduleFieldKind;

  @propertyMap()
  public isRequired: boolean;

  @propertyMap()
  public min: number;

  @propertyMap()
  public max: number;

  @propertyMap()
  public step: number;

  @propertyMap()
  public isRequiredPraxedo: boolean;

  @propertyMap({ type: [UnitPriceScheduleFieldEnumElmt] })
  public enum: UnitPriceScheduleFieldEnumElmt[];
}

export class UnitPriceSchedule {
  @propertyMap({ source: '_id' })
  public id: string;

  @propertyMap()
  public reference: string;

  @propertyMap()
  public family: Family;

  @propertyMap()
  public category: UnitPriceScheduleAccessControlCategory;

  @propertyMap()
  public name: string;

  @propertyMap()
  public priority: number;

  @propertyMap()
  public externalReference: string;

  @propertyMap()
  public externalName: string;

  @propertyMap()
  public fare: number;

  @propertyMap()
  public fareType: string;

  @propertyMap()
  public vat: number;

  @propertyMap()
  public completionTime: string;

  @propertyMap()
  public marketNumber: string;

  @propertyMap()
  public providerNumber: string;

  @propertyMap()
  public delayTime: string;

  @propertyMap()
  public cancellationTime: string;

  @propertyMap({ type: EmbededContractor })
  public contractor: EmbededContractor;

  @propertyMap()
  public hasPurchaseOrder: boolean;

  @propertyMap()
  public isEsiRequired: boolean;

  @propertyMap()
  public esiNatures: string[];

  @propertyMap()
  public esiInformation: string;

  @propertyMap({ type: [UnitPriceScheduleField] })
  public fields: UnitPriceScheduleField[];
}
