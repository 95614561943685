<mat-card id="container">
  <mat-card-header>
    <mat-card-title>Caractéristiques techniques</mat-card-title>
  </mat-card-header>
  <mat-card-content [formGroup]="formGroup" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="16px">
    <ng-container *ngFor="let pair of ((metricList | groupBy: 'group') | pairs)">
      <div class="divider" *ngIf="pair[0] && pair[0] !== 'undefined'">
        <mat-divider></mat-divider>
        <ion-label color="primary" translate>{{pair[0]}}</ion-label>
      </div>
      <ng-container *ngFor="let metric of pair[1]">
        <mat-form-field [style.width.px]="200">
          <mat-label [innerHTML]="(metric.code | translate) | safe:'html'"></mat-label>
          <ng-container [ngSwitch]="metric.kind">
            <mat-select *ngSwitchCase="'enum'" [formControlName]="metric.code">
              <mat-option *ngFor="let option of metric.enum" [value]="option">
                <mat-label translate>{{option}}</mat-label>
              </mat-option>
            </mat-select>
            <input *ngSwitchCase="'number'" type="number" autocomplete="off" matInput [formControlName]="metric.code">
            <input *ngSwitchDefault autocomplete="off" matInput [formControlName]="metric.code">
          </ng-container>
          <span *ngIf="metric.unit" matSuffix translate>{{metric.unit}}</span>
        </mat-form-field>
      </ng-container>
    </ng-container>
  </mat-card-content>
</mat-card>