/** @format */

import {animate, style, transition, trigger} from '@angular/animations';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {MatPaginator} from '@angular/material/paginator';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {
  clone,
  concat,
  each,
  find,
  get,
  isEmpty,
  isEqual,
  isNil,
  map as lmap,
  merge,
  pick,
  remove,
  trim,
} from 'lodash-es';
import {ModelMapper} from 'model-mapper';
import {NGXLogger} from 'ngx-logger';
import {NgScrollbar} from 'ngx-scrollbar';
import {lastValueFrom, of, Subscription} from 'rxjs';
import {debounceTime, filter, map, mergeMap, tap} from 'rxjs/operators';
import {SubSink} from 'subsink';
import * as XLSX from 'xlsx';
import {UserService} from '../../_services/user.service';
import {Action, ActionOption} from './action.class';
import {ColumnDef, ColumnOption, Option, Sort} from './column-def.class';
import {DatagridCellDirective} from './datagrid-cell.directive';
import {DatatableDataSource, IRecord, Service} from './datasource';
import {IColumn} from './datatable.class';
import {IIcon} from './icon.class';

export interface IUserConfig {
  label: string;
  index: number;
  show: boolean;
  sticky: boolean;
}

export interface IPagination {
  default: number;
  options: (number | 'all')[];
}

export interface ICell {
  index: number;
  rawValue: any;
  value: SafeHtml;
  color: SafeHtml;
  bgColor: SafeHtml;
  content: string;
  icon?: IIcon;
  inview?: boolean;
  suffix?: string;
  tooltip?: string;
}

interface IRow {
  index: number;
  selected: boolean;
  _id: any;
  cells: ICell[];
  tooltip?: string;
  color?: string;
  backgroundColor?: string;
}

export interface IOptions {
  configKey?: string;
  service: Service;
  columns: ColumnOption[];
  enableRowNumber?: boolean;
  enableSelect?: boolean;
  enableStickyColumn?: boolean;
  enableReorderColumn?: boolean;
  enableHideShowColumns?: boolean;
  enableExport?: boolean;
  enableFullscreen?: boolean;
  actionsPosition?: 'top' | 'bottom' | 'floating';
  pagination?: IPagination;
  selected?: IRecord[];
  showPagination?: 'never' | 'auto' | 'always';
  rowClick?: (datagrid: DatagridComponent, record: IRecord, $event: MouseEvent) => void;
  rowColor?: (record: IRecord) => string;
  rowBackgroundColor?: (record: IRecord) => string;
  rowTooltip?: (record: IRecord) => string;
  actions?: ActionOption[];
  disableScrollbarModule?: boolean;
  columnMinWidth?: number;
  rowHeight?: number;
  heightAuto?: boolean;
  dataMaxHeight?: number;
  sortedColumns?: {column: number; dir: 'asc' | 'desc'}[];
  search?: {[property: string]: any};
  userConfig?: IUserConfig[];
  loadOnDisplay?: boolean;
}

@Component({
  selector: 'app-datagrid',
  templateUrl: './datagrid.component.html',
  styleUrls: ['./datagrid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({opacity: 0}), // initial
        animate('0.2s', style({opacity: 1})), // final
      ]),
      transition(':leave', [
        style({opacity: 1}), // initial
        animate('0.2s', style({opacity: 0})), // final
      ]),
    ]),
    trigger('fadein', [
      transition(':enter', [
        style({opacity: 0}), // initial
        animate('0.2s', style({opacity: 1})), // final
      ]),
    ]),
    trigger('fadeout', [
      transition(':leave', [
        style({opacity: 1}), // initial
        animate('0.2s', style({opacity: 0})), // final
      ]),
    ]),
  ],
})
export class DatagridComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input('options')
  public set setOptions(options: IOptions) {
    this.configKey = options.configKey;
    this.enableRowNumber = options.enableRowNumber !== undefined ? options.enableRowNumber : this.enableRowNumber;
    this.enableSelect = options.enableSelect !== undefined ? options.enableSelect : this.enableSelect;
    this.enableStickyColumn =
      options.enableStickyColumn !== undefined ? options.enableStickyColumn : this.enableStickyColumn;
    this.enableReorderColumn =
      options.enableReorderColumn !== undefined ? options.enableReorderColumn : this.enableReorderColumn;
    this.enableHideShowColumns =
      options.enableHideShowColumns !== undefined ? options.enableHideShowColumns : this.enableHideShowColumns;
    this.enableExport = options.enableExport !== undefined ? options.enableExport : this.enableExport;
    this.enableFullscreen = options.enableFullscreen !== undefined ? options.enableFullscreen : this.enableFullscreen;
    if (options.actionsPosition) {
      this.actionsPosition = options.actionsPosition;
    }
    if (options.pagination) {
      this.pagination = options.pagination;
    }
    if (options.selected) {
      this.selected = options.selected;
    }
    if (options.showPagination) {
      this.showPagination = options.showPagination;
    }
    if (options.service) {
      this.service = options.service;
    }
    if (options.search) {
      this.initialSearch = options.search;
    }
    if (options.sortedColumns) {
      this.sortedColumns = options.sortedColumns;
      // this.initSort();
    }
    if (options.columns) {
      this.buildColumn(options.columns);
    }
    if (options.rowClick) {
      this.rowClickOption = options.rowClick;
    }
    if (options.rowColor) {
      this.rowColorOption = options.rowColor;
    }
    if (options.rowBackgroundColor) {
      this.rowBackgroundColorOption = options.rowBackgroundColor;
    }
    if (options.rowTooltip) {
      this.rowTooltipOption = options.rowTooltip;
    }
    if (options.actions) {
      this.actions = options.actions.map(a => new ModelMapper(Action).map(a));
    }
    this.disableScrollbarModule =
      options.disableScrollbarModule !== undefined ? options.disableScrollbarModule : this.disableScrollbarModule;
    if (options.rowHeight) {
      this.rowHeight = options.rowHeight;
    }
    this.heightAuto = options.heightAuto === true;
    if (options.dataMaxHeight) {
      this.dataMaxHeight = options.dataMaxHeight;
    }
    if (options.loadOnDisplay === false) {
      this.loadOnDisplay = false;
    }
  }
  public configKey: string;
  public enableRowNumber = false;
  public enableSelect = false;
  public enableStickyColumn = false;
  public enableReorderColumn = false;
  public enableHideShowColumns = false;
  public enableExport = false;
  public enableFullscreen = false;
  public actionsPosition: 'top' | 'bottom' | 'floating' = 'top';
  public disableScrollbarModule = false;
  public columnMinWidth: number;
  public rowHeight: number;
  public heightAuto = false;
  public dataMaxHeight: number;
  public enteredSticky = false;
  private userConfig: IUserConfig[];

  @Output()
  public selectionChanged: EventEmitter<string[]> = new EventEmitter();

  @Output()
  public exported: EventEmitter<void> = new EventEmitter();

  @Output()
  public updatedColumnsConfig: EventEmitter<any> = new EventEmitter();

  @Output('loading')
  public loadingEmitter: EventEmitter<boolean> = new EventEmitter();

  @Output('ready')
  public readyEmitter: EventEmitter<boolean> = new EventEmitter();

  public rowClickOption: (datagrid: DatagridComponent, record: IRecord, $event: MouseEvent) => void;
  public rowColorOption: (record: IRecord) => string;
  public rowBackgroundColorOption: (record: IRecord) => string;
  public rowTooltipOption: (record: IRecord) => string;

  public actions: Action[] = [];

  public pagination: IPagination = {default: 1, options: [10, 20, 50, 100]};
  public showPagination: 'never' | 'auto' | 'always' = 'always';

  public columns: ColumnDef[];
  public headers: ColumnDef[];

  @ContentChildren(DatagridCellDirective)
  private templateRefs: QueryList<DatagridCellDirective>;
  public templatesByName: any = {};

  public dataSource: DatatableDataSource<IRecord>;
  public loading = true;
  public data: IRecord[];
  public rows: IRow[];
  public hasSearchHeader = false;

  public columnsWidth: number;
  public lastStickyColumnIndex: number;
  public columnStickyWidth: number;
  public columDefaultWidth: number;
  public displayedColumns = {start: 0, end: 0};
  public scrollWidth = 0;
  public scrollLeft = 0;
  public scrollTop = 0;
  public height: number;

  public selectAllControl: UntypedFormControl;
  public selected: IRecord[] = [];

  public sortedColumns: {column: number; dir: 'asc' | 'desc'}[] = [];

  public updatedHidden: {
    label: string;
    index: number;
    show: boolean;
    sticky: boolean;
  }[] = [];

  public exporting = false;
  public isFullscreen = false;

  public search: UntypedFormGroup;
  private initialSearch?: {[property: string]: any} = {};

  private loadOnDisplay = true;

  private loaded = false;
  @Input()
  private service: Service;
  @ViewChild('container', {static: true})
  private container: ElementRef<HTMLElement>;
  @ViewChild('scrollbar', {static: true, read: NgScrollbar})
  private scrollbarRef: NgScrollbar;
  @ViewChild('virtualScroll', {static: true, read: CdkVirtualScrollViewport})
  private virtualScrollRef: CdkVirtualScrollViewport;
  @ViewChild(MatPaginator, {static: true})
  private paginator: MatPaginator;

  private searchSub: Subscription;
  private subsink = new SubSink();

  public enterStickyPredicate = () => this.enableStickyColumn;

  public selectCompareWidth = (o1, o2) => o1?.value === o2?.value;

  @HostListener('document:fullscreenchange')
  @HostListener('document:webkitfullscreenchange')
  @HostListener('document:mozfullscreenchange')
  @HostListener('document:MSFullscreenChange')
  fullScreenChange(): void {
    this.isFullscreen = !!document.fullscreenElement;
  }

  constructor(
    private elementRef: ElementRef,
    private logger: NGXLogger,
    private sanitizer: DomSanitizer,
    private changeDetectorRef: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    private translate: TranslateService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    const rowHeight = parseInt(
      window.getComputedStyle(this.elementRef.nativeElement).getPropertyValue('--datagrid-row-height'),
      10
    );
    if (!this.rowHeight) {
      if (!isNaN(rowHeight)) {
        this.rowHeight = rowHeight;
      } else {
        this.rowHeight = 32;
      }
    }
    const columnMinWidth = parseInt(
      window.getComputedStyle(this.elementRef.nativeElement).getPropertyValue('--datagrid-column-min-width'),
      10
    );
    if (!this.columnMinWidth) {
      if (!isNaN(columnMinWidth)) {
        this.columnMinWidth = columnMinWidth;
      } else {
        this.columnMinWidth = 200;
      }
    }
    this.paginator.pageSizeOptions = this.pagination.options.map(o =>
      o === 'all' ? this.dataSource.recordsFiltered : o
    );
    this.dataSource = new DatatableDataSource<IRecord>(this.service);
    this.initSelection();
  }

  ngAfterViewInit(): void {
    this.templateRefs.forEach(ref => (this.templatesByName[ref.name] = ref));
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
    if (this.searchSub) {
      this.searchSub.unsubscribe();
    }
  }

  private async loadUserConfig(): Promise<void> {
    if (this.configKey) {
      this.userConfig = await this.userService.getDatatableConfig(this.configKey);
      each(this.userConfig, uc => {
        const columnIndex = this.columns.findIndex(c => c.label === uc.label);
        if (columnIndex !== -1) {
          const column = this.columns[columnIndex];
          merge(column, pick(uc, ['show', 'sticky']));
          moveItemInArray(this.columns, columnIndex, uc.index);
        }
      });
    } else {
      this.userConfig = [];
    }
  }

  private async updateUserConfig(): Promise<any> {
    this.userConfig = this.columns.filter(c => !c.hidden).map(c => pick(c, ['label', 'index', 'show', 'sticky']));
    this.updatedColumnsConfig.emit(this.userConfig);
    if (this.configKey) {
      return this.userService.updateDatatableConfig(this.configKey, this.userConfig);
    }
  }

  public async ready(visible: boolean): Promise<void> {
    if (this.loaded || !visible) return;
    this.loaded = true;
    this.subsink.add(
      this.paginator.page.subscribe(() => this.loadPage()),
      this.dataSource
        .connect()
        .pipe(tap(data => this.loadRecords(data)))
        .subscribe(),
      this.dataSource.loading$
        .pipe(
          tap(loading => this.loadingEmitter.emit((this.loading = loading))),
          tap(() => this.changeDetectorRef.detectChanges())
        )
        .subscribe()
    );
    await this.loadUserConfig();
    this.refreshDisplay();
    if (this.loadOnDisplay !== false) this.loadPage();
    this.changeDetectorRef.detectChanges();
    this.readyEmitter.emit(true);
  }

  public cellClick($event: MouseEvent, row: IRow, cell: ICell): boolean {
    if (typeof this.rowClickOption !== 'function') {
      const columnDef = this.columns[cell.index];
      if (columnDef && columnDef.click) {
        columnDef.click(this.data[row.index], this);
        $event.preventDefault();
        $event.stopPropagation();
        return false;
      }
    }
    return true;
  }

  public execAction(action: Action): void {
    if (action.exec) {
      action.exec(this, clone(this.selected));
    }
  }

  public rowClick($event: MouseEvent, row: IRow): boolean {
    if (typeof this.rowClickOption === 'function') {
      this.rowClickOption(this, this.data[row.index], $event);
      $event.preventDefault();
      $event.stopPropagation();
      return false;
    }
    return true;
  }

  public openHideShowMenu(): void {
    this.updatedHidden = this.columns.filter(c => !c.hidden).map(c => pick(c, ['label', 'index', 'show', 'sticky']));
  }

  public drop(event: CdkDragDrop<any[]>) {
    this.updatedHidden[event.previousIndex].index = null;
    moveItemInArray(this.updatedHidden, event.previousIndex, event.currentIndex);
    moveItemInArray(this.columns, event.previousIndex, event.currentIndex);
  }

  public closeHideShowMenu(): void {
    let updated = false;
    this.updatedHidden.forEach(u => {
      const column = this.columns.find(c => c.label === u.label);
      if ((u.show && !column.show) || u.index === null) {
        updated = true;
      }
      column.show = u.show;
      column.sticky = u.sticky;
    });
    this.updatedHidden = null;
    this.refreshDisplay();
    if (updated) {
      this.loadPage();
    }
    this.updateUserConfig();
  }

  public fullscreen(): void {
    if (this.isFullscreen) {
      document.exitFullscreen();
    } else {
      this.container.nativeElement.requestFullscreen();
    }
  }

  private async buildExportRow(record: IRecord): Promise<any> {
    const row: any = {};
    for (const column of this.columns) {
      if (column.exportable && !column.hidden && column.show) {
        if (column.splitExport) {
          each(column.splitExport(record), exp => (row[exp.name] = exp.value));
        } else {
          row[await lastValueFrom(this.translate.get(column.label))] = column.getExportCellData(record);
        }
      }
    }
    return row;
  }

  private async buildExportData(): Promise<any[]> {
    const data: any[] = [];
    const columns = this.getPageColumns();
    const order = this.getPageOrder(columns);

    let total = 0;
    let start = 0;
    let size = 0;
    do {
      const res = await this.dataSource.fetchData({draw: Date.now().toString(), columns, order, start, length: 100});
      total = res.recordsFiltered;
      size = res?.data?.length || 0;
      if (res?.data) {
        for (const record of res.data) {
          data.push(await this.buildExportRow(record));
        }
      }
      ++start;
    } while (size === 100);

    return data;
  }

  public async export(): Promise<void> {
    if (this.exporting) return;
    this.exporting = true;
    try {
      const data = await this.buildExportData();
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(workbook, worksheet, 'export');
      await XLSX.writeFile(workbook, `datagrid-${Date.now()}.xlsx`);
    } finally {
      this.exported.emit();
      this.exporting = false;
      this.changeDetectorRef.detectChanges();
    }
  }

  public loadPage(): void {
    if (!this.dataSource) return;
    const columns = this.getPageColumns();
    const order = this.getPageOrder(columns);
    let options = {
      draw: Date.now().toString(),
      columns,
      order,
    };
    if (this.showPagination !== 'never') {
      options = merge(options, {
        start: this.paginator.pageIndex,
        length: this.paginator.pageSize,
      });
    }
    this.dataSource.loadData(options);
  }

  public refreshDisplay(): void {
    this.columns.forEach((c, i) => (c.index = i));
    this.userConfig = this.columns.filter(c => !c.hidden).map(c => pick(c, ['label', 'index', 'show', 'sticky']));
    this.rows = this.data?.map((r, i) => this.buildRow(r, i));
    this.buildDisplayedColumns();
    this.changeDetectorRef.detectChanges();
  }

  public getPageColumns(): IColumn[] {
    const columns: IColumn[] = [];
    const missingColumns: IColumn[] = [];
    (this.columns || []).forEach((columnDef, i) => {
      if (columnDef.type === 'action' || (!columnDef.hidden && !columnDef.show)) return;
      const column: IColumn = {
        data: columnDef.property,
        name: columnDef.label,
        orderable: true,
        searchable: true,
      };
      if (columnDef.type === 'date') column.type = 'Date';
      this.addColumnSearch(columnDef, column);
      columns.push(column);
      this.addLoadPageMissingColumns(missingColumns, columnDef);
    });
    return columns.concat(missingColumns);
  }

  private addLoadPageMissingColumns(columns: IColumn[], columnDef: ColumnDef): void {
    if (columnDef.searchProperty) {
      const column: IColumn = {
        data: columnDef.searchProperty,
        name: columnDef.label,
        searchable: true,
        orderable: true,
      };
      this.addColumnSearch(columnDef, column);
      if (column.search) {
        columns.push(column);
      }
    }
    if (columnDef.sortProperty) {
      const column: IColumn = {
        data: columnDef.sortProperty,
        name: columnDef.label,
        searchable: true,
        orderable: true,
      };
      if (this.sortedColumns.find(sc => sc.column === columnDef.index)) {
        columns.push(column);
      }
    }
    if (columnDef.displayProperty) {
      const column: IColumn = {
        data: columnDef.displayProperty,
        name: columnDef.label,
        searchable: true,
        orderable: true,
      };
      this.addColumnSearch(columnDef, column);
      columns.push(column);
    }
    if (columnDef.linkedProperties && columnDef.linkedProperties.length) {
      columnDef.linkedProperties.forEach(property =>
        columns.push({
          data: property,
          name: columnDef.label,
          searchable: true,
          orderable: true,
        })
      );
    }
  }

  private addColumnSearch(columnDef: ColumnDef, column: IColumn): void {
    if (column.searchable) {
      const control = this.search.controls[column.data];
      if (control && !isNil(control.value)) {
        switch (columnDef.type) {
          case 'select':
          case 'autocomplete':
          case 'button-toggle':
            column.search = {
              value: columnDef.multiple ? lmap(control.value, 'value') : control.value.value,
            };
            break;
          case 'date':
            column.search = {
              value: {
                op: control.value?.op === '=' ? '<=>' : control.value?.op,
                from:
                  control.value?.op === '='
                    ? control.value?.from?.startOf('day').toDate()
                    : control.value?.from?.toDate(),
                to:
                  control.value?.op === '=' ? control.value?.from?.endOf('day').toDate() : control.value?.to?.toDate(),
              },
            };
            break;
          case 'number':
            if (!isEmpty(trim(control.value))) {
              column.search = {value: Number(control.value)};
            }
            break;
          default:
            if (!isEmpty(trim(control.value))) {
              column.search = {value: control.value};
            }
        }
      }
    }
  }

  private getPageOrder(columns: IColumn[]): {column: number; dir: string}[] {
    const order: {column: number; dir: string}[] = [];
    this.sortedColumns.forEach(sc => {
      const property =
        this.columns[sc.column].sortProperty ||
        this.columns[sc.column].displayProperty ||
        this.columns[sc.column].property;
      const ci = columns.findIndex(c => c.data === property && (c.orderable || this.columns[sc.column].hidden));
      if (ci !== -1) {
        order.push({column: ci, dir: sc.dir});
      }
    });
    return order;
  }

  private loadRecords(records: IRecord[]): void {
    this.data = records;
    this.rows = this.data.map((r, i) => this.buildRow(r, i));
    this.updateSelectState();
    this.changeDetectorRef.detectChanges();
  }

  private buildRow(record: IRecord, index: number): IRow {
    const row: IRow = {
      index,
      _id: record._id,
      cells: [],
      selected: this.selected.findIndex(s => s._id === record._id) !== -1,
      tooltip: this.rowTooltipOption ? this.rowTooltipOption(record) : null,
      color: this.rowColorOption ? this.rowColorOption(record) : null,
      backgroundColor: this.rowBackgroundColorOption ? this.rowBackgroundColorOption(record) : null,
    };
    this.columns.forEach((columnDef, ci) => {
      if (!columnDef.hidden) {
        row.cells.push(this.getCellData(ci, columnDef, record));
      } else {
        row.cells.push(null);
      }
    });
    return row;
  }

  private getCellData(index: number, columnDef: ColumnDef, record: IRecord): ICell {
    const data = columnDef.getCellData(record);
    return {
      index,
      content: columnDef.content,
      rawValue: get(record, columnDef.displayProperty ? columnDef.displayProperty : columnDef.property),
      value:
        !isNil(data.value) && !isEmpty(data.value)
          ? this.sanitizer.bypassSecurityTrustHtml(
              columnDef.translateValue ? this.translate.instant(data.value) : data.value
            )
          : data.value,
      color: data.color ? this.sanitizer.bypassSecurityTrustHtml(data.color) : null,
      bgColor: data.bgColor ? this.sanitizer.bypassSecurityTrustHtml(data.bgColor) : null,
      icon: data.icon,
      suffix: data.suffix,
      tooltip: data.tooltip,
    };
  }

  private buildColumn(columns: ColumnOption[]): void {
    if (this.searchSub) this.searchSub.unsubscribe();
    this.hasSearchHeader = false;
    this.search = this.formBuilder.group({});
    const statics = [];
    if (this.enableSelect) {
      statics.push({
        type: 'action',
        label: '_select',
        show: true,
        sticky: true,
        width: 40,
      });
    }
    if (this.enableRowNumber) {
      statics.push({
        type: 'action',
        label: '_rownumber',
        show: true,
        sticky: true,
        width: 40,
      });
    }
    this.columns = concat(statics, columns).map(c => new ModelMapper(ColumnDef).map(c));
    this.columns.forEach((c, i) => {
      c.index = i;
      if (this.enableStickyColumn === false) {
        c.sticky = false;
      }
      if (c.sortable) {
        const sort = find(this.sortedColumns, {column: i});
        if (sort) c.sort = Sort.build(sort);
      }
      if (c.searchable) {
        this.hasSearchHeader = true;
        const property = c.searchProperty || c.displayProperty || c.property;
        const control =
          c.type === 'date'
            ? this.formBuilder.group({
                op: ['='],
                from: [],
                to: [],
              })
            : new UntypedFormControl(this.initialSearch[property]);
        this.search.addControl(property, control);
        if (c.type === 'autocomplete') {
          (control as any).optionsAsync = ((control as any).asynOptionsControl =
            new UntypedFormControl()).valueChanges.pipe(
            tap(() => ((control as any).optionsAsyncLoading = true)),
            debounceTime(300),
            mergeMap(value =>
              typeof value === 'string'
                ? c.optionsAsync(value).pipe(map(options => options.map(o => new ModelMapper(Option).map(o))))
                : of([])
            ),
            tap(() => ((control as any).optionsAsyncLoading = false))
          );
        }
      }
    });
    let lastSearch = null;
    this.searchSub = this.search.valueChanges
      .pipe(
        debounceTime(500),
        filter(value => !isEqual(lastSearch, value)),
        tap(value => (lastSearch = value)),
        tap(() => (this.paginator.pageIndex = 0))
      )
      .subscribe(() => this.loadPage());
    this.changeDetectorRef.detectChanges();
  }

  private buildDisplayedColumns(): void {
    this.headers = [];
    this.columns.forEach(column => {
      if (column.hidden || !column.show) return;
      this.headers.push(column);
    });
  }

  public sortColumn(columnDef: ColumnDef): void {
    if (columnDef.sortable === false) return;
    const i = this.sortedColumns.findIndex(c => c.column === columnDef.index);
    if (i === -1) {
      this.sortedColumns.push({column: columnDef.index, dir: 'asc'});
      columnDef.sort = new ModelMapper(Sort).map({
        dir: 'asc',
        position: this.sortedColumns.length,
      });
    } else {
      const sort = this.sortedColumns[i];
      if (sort.dir === 'asc') {
        sort.dir = 'desc';
        columnDef.sort.dir = 'desc';
      } else {
        this.sortedColumns.splice(i, 1);
        columnDef.sort = null;
        this.sortedColumns.slice(i).forEach(sc => (this.columns[sc.column].sort.position -= 1));
      }
    }
    this.paginator.pageIndex = 0;
    this.loadPage();
  }

  public select(row: IRow): void {
    row.selected = !row.selected;
    remove(this.selected, s => s._id === row._id);
    if (row.selected) {
      this.selected.push(this.data[row.index]);
    }
    this.updateSelectState();
    this.selectionChanged.emit(lmap(this.selected, '_id'));
  }

  private initSelection(): void {
    this.selectAllControl = new UntypedFormControl(false);
    this.updateSelectState();
    this.selectAllControl.valueChanges.subscribe(value => {
      this.rows.forEach(row => {
        row.selected = value;
        remove(this.selected, s => s._id === row._id);
        if (row.selected) {
          this.selected.push(this.data[row.index]);
        }
      });
      this.updateSelectState();
      this.selectionChanged.emit(lmap(this.selected, '_id'));
    });
  }

  private updateSelectState(): void {
    const unselectedData = !!(this.rows || []).find(row => this.selected.findIndex(s => s._id === row._id) === -1);
    const value = !this.selected.length ? false : unselectedData ? null : true;
    this.selectAllControl.setValue(value, {emitEvent: false});
    this.actions.forEach(a => {
      a.hidden = a.isHidden ? a.isHidden(this.selected) : a.hidden;
      a.disabled = a.isDisabled ? a.isDisabled(this.selected) : a.disabled;
    });
  }
}
