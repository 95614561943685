<app-page-tracking page="ISITECH > Accueil > Statistiques"></app-page-tracking>
<mat-card>
  <mat-card-header>
    <mat-card-title translate>patrimony</mat-card-title>
  </mat-card-header>
  <mat-card-content [style.min-height.px]="300">
    <mat-tab-group #patrimonyTabs mat-align-tabs="end" tab-icon>
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon>leaderboard</mat-icon>
        </ng-template>
        <div id="orga-units-treemap"></div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon>account_tree</mat-icon>
        </ng-template>
        <mat-tree id="orga-units-tree" [dataSource]="dataSource" [treeControl]="treeControl">
          <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
            <button mat-icon-button disabled></button>
            <ion-label color="dark" [style.font-weight]="400" fxLayoutGap="8px">
              <ion-text *ngIf="node.kind !== 'group'">[{{node.code}}]</ion-text>
              <ion-text>{{node.name}}</ion-text>
            </ion-label>
          </mat-tree-node>
          <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
            <button mat-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
              <mat-icon class="mat-icon-rtl-mirror">
                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
              </mat-icon>
              <ion-label color="dark" [style.font-weight]="400" fxLayoutGap="8px">
                <ion-text *ngIf="node.kind !== 'group'">[{{node.code}}]</ion-text>
                <ion-text>{{node.name}}</ion-text>
              </ion-label>
            </button>
          </mat-tree-node>
        </mat-tree>
      </mat-tab>
    </mat-tab-group>
    <sesio-loader *ngIf="loading" @fadeOut></sesio-loader>
  </mat-card-content>
</mat-card>
