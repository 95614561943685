import { propertyMap } from 'model-mapper';

export class Option {

  @propertyMap()
  public name: string;

  @propertyMap()
  public value: string;

}
