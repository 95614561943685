<ng-scrollbar visibility="hover">
  <div id="container" fxLayout="column">

    <div id="header" fxLayout="row wrap" fxLayoutAlign="space-between start">

      <ng-content select="[additionalFilter]"></ng-content>

      <div id="range-selector" fxLayout="row wrap" fxLayoutAlign="start start" >
        <mat-button-toggle-group #group="matButtonToggleGroup" [value]="selectedZoom"
          (change)="changeZoom($event.value)">
          <mat-button-toggle *ngFor="let zoom of zooms" [checked]="zoom === selectedZoom" [value]="zoom"
            [aria-label]="zoom | translate">
            <mat-label translate>{{zoom}}</mat-label>
          </mat-button-toggle>
        </mat-button-toggle-group>
        <mat-form-field>
          <mat-label translate>period</mat-label>
          <mat-date-range-input [rangePicker]="picker" [formGroup]="range" [max]="max">
            <input matStartDate formControlName="start" [placeholder]="'start-date' | translate">
            <input matEndDate formControlName="end" [placeholder]="'end-date' | translate">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
    </div>

    <ng-content select="[content]"></ng-content>

  </div>
</ng-scrollbar>