import { Component, OnInit, Input } from '@angular/core';
import { IChartLabels, IChartData } from '../chart.interface';
import { fadeIn, fadeOut } from '../../../constants/animations';
import { ModalController } from '@ionic/angular';
import { IPageTrackingInfo } from '../../../services/user-tracking.service';

export interface IChartConfig {
  type: 'column' | 'area';
  labels: IChartLabels;
  suffix?: string;
  color?: string;
  min?: number;
  max?: number;
  format?: string;
  hasTrend?: boolean;
  trendSuffix?: string;
  trendColor?: string;
  plotColor?: string;
}

@Component({
  templateUrl: './chart.modal.html',
  styleUrls: ['./chart.modal.scss'],
  animations: [fadeIn, fadeOut]
})
export class ChartModal implements OnInit {

  @Input()
  public title: string;

  @Input()
  public config: IChartConfig;

  @Input()
  public data$: Promise<IChartData[]>;

  @Input()
  public tracking: IPageTrackingInfo;

  constructor(private modalController: ModalController) { }

  ngOnInit(): void { }

  public async dismiss(): Promise<void> {
    await this.modalController.dismiss();
  }


}
