import { property } from 'lodash-es';
import { propertyMap } from 'model-mapper';
import { Moment } from 'moment';
import { EmbededContractor } from './embeded-contractor';
import { EmbededUnitPriceSchedule } from './embeded-unit-price-schedule';
import { StatusHistory } from './status-history';

export class InterventionTreeElement {
  @propertyMap()
  public reference: string;

  @propertyMap({ type: EmbededContractor })
  public contractor: EmbededContractor;

  @propertyMap({ type: EmbededUnitPriceSchedule })
  public unitPriceSchedule: EmbededUnitPriceSchedule;

  @propertyMap()
  public quantity: number;

  @propertyMap({ type: [StatusHistory] })
  public statusHistory: StatusHistory[];
}

export class EquipmentTreeElement {
  @propertyMap()
  public reference: string;

  @propertyMap()
  public kind: string;

  @propertyMap()
  public category: string;

  @propertyMap()
  public count: number;

  @propertyMap()
  public total: number;

  @propertyMap({ type: 'Moment' })
  public start: Moment;

  @propertyMap({ type: 'Moment' })
  public end: Moment;

  @propertyMap({ type: [InterventionTreeElement], default: [] })
  public interventions: InterventionTreeElement[];

  public open;
}

export class RealEstateStructuresTreeElement {
  @propertyMap()
  public reference: string;

  @propertyMap()
  public kind: string;

  @propertyMap()
  public count: number;

  @propertyMap()
  public total: number;

  @propertyMap({ type: 'Moment' })
  public start: Moment;

  @propertyMap({ type: 'Moment' })
  public end: Moment;

  @propertyMap({ type: [EquipmentTreeElement], default: [] })
  public equipments: EquipmentTreeElement[];

  public open;
}

export class GroupTreeElement {
  @propertyMap()
  public group: string[];

  @propertyMap()
  public count: number;

  @propertyMap()
  public total: number;

  @propertyMap({ type: 'Moment' })
  public start: Moment;

  @propertyMap({ type: 'Moment' })
  public end: Moment;

  @propertyMap({ type: [RealEstateStructuresTreeElement], default: [] })
  public realEstateStructures: RealEstateStructuresTreeElement[];

  public open;
}
