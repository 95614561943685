import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { includes, replace } from 'lodash-es';
import moment from 'moment';
import { Moment } from 'moment';
import { EquipmentDocument } from 'sesio-lib';
import { environment } from '../../../../../../environments/environment';

enum DisplayType {
  IMG = 'image',
  PDF = 'pdf',
  OFFICE = 'office',
}

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss'],
})
export class DocumentComponent implements OnInit {
  public locale = this.translate.currentLang;
  public display: DisplayType;
  public kind: 'document' | 'file';

  @Input('item')
  public set setItem(item: EquipmentDocument) {
    this.item = item;
    if (item) {
      this.kind = 'document';
    }
    this.title = item?.title;
    this.description = item?.description;
    this.date = item?.date;
    this.path = item?.path ? `${environment.repoUrl}/${item?.path}` : null;
    this.display = this.getType(item?.mimetypes);
    this.print();
  }
  private item: EquipmentDocument;

  @Input('file')
  public set setFile(file: File) {
    this.file = file;
    if (file) {
      this.kind = 'file';
    }
    this.title = file.name;
    this.description = null;
    this.date = moment(file.lastModified);
    if (file) {
      var reader = new FileReader();
      reader.onload = (e) => {
        this.display = this.getType([file.type]);
        this.path = e.target.result as string;
        this.print();
      };
      reader.readAsDataURL(file);
    } else {
      this.path = null;
      this.display = null;
    }
  }
  private file: File;

  public title: string;
  public description: string;
  public date: Moment;
  public path: string;

  constructor(private translate: TranslateService) {}

  ngOnInit() {}

  public getType(mimetypes: string[]): DisplayType {
    if (mimetypes?.length > 0) {
      if (mimetypes.find((mt) => mt.startsWith('image/'))) {
        return DisplayType.IMG;
      }
      if (includes(mimetypes, 'application/pdf')) {
        return DisplayType.PDF;
      }
      if (
        mimetypes.find((mt) =>
          includes(
            [
              'application/vnd.openxmlformats-officedocument.presentationml.presentation',
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              'application/vnd.ms-excel',
            ],
            mt
          )
        )
      ) {
        return DisplayType.OFFICE;
      }
    }
    return null;
  }

  private print() {
    console.log({
      title: this.title,
      description: this.description,
      date: this.date?.format(),
      path: this.path,
      display: this.display,
    });
  }
}
