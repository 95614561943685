/** @format */

import {propertyMap} from 'model-mapper';
import {Moment} from 'moment';

export interface IRequestUploadUrlData {
  filename: string;
  contentType: string;
  prefix?: string;
}

export class File {
  @propertyMap()
  public kind: string;

  @propertyMap()
  public title: string;

  @propertyMap()
  public description: string;

  @propertyMap()
  public filename: string;

  @propertyMap()
  public path: string;

  @propertyMap()
  public size: number;

  @propertyMap()
  public mimetypes: string[];

  @propertyMap({type: 'Moment'})
  public date: Moment;

  @propertyMap()
  public userId: string;
}
