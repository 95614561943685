import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContractorModal } from './contractor.modal/contractor.modal';
import { FlexLayoutModule } from '@angular/flex-layout';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { SafePipeModule } from 'safe-pipe';
import { ChartModule, LoaderModule, PipesModule, TrackingModule } from 'sesio-lib';
import { InViewportModule } from 'ng-in-viewport';
import { ContractorSelectComponent } from './contractor-select/contractor-select.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgPipesModule } from 'ngx-pipes';
import { MatSelectModule } from '@angular/material/select';
import { DocumentModule } from '../document/document.module';

@NgModule({
  declarations: [ContractorModal, ContractorSelectComponent],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule.forChild(),
    FlexLayoutModule,
    SafePipeModule,
    LoaderModule,
    ChartModule,
    InViewportModule,
    FormsModule,
    MatButtonModule,
    MatChipsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressBarModule,
    NgPipesModule,
    ReactiveFormsModule,
    MatSelectModule,
    TrackingModule,
    DocumentModule,
    PipesModule
  ],
  exports: [ContractorModal, ContractorSelectComponent],
})
export class ContractorModule {}
