import { propertyMap } from 'model-mapper';
import { File } from './file';

export enum TutorialPlatform {
  WEBAPP = 'webapp',
  MOBILE = 'mobile',
}

export enum TutorialPage {
  DASHBOARD = 'dashboard',
  JANITOR_SPACE = 'janitor-space',
  ELEVATOR_SPACE = 'elevator-space',
  PARKING_DOOR_SPACE = 'parking-door-space',
  OPTICAL_FIBER_SPACE = 'optical-fiber-space',
  RELAY_ANTENNA_SPACE = 'relay-antenna-space',
  PHOTOVOLTAIC_PANEL_SPACE = 'photovoltaic-panel-space',
  ACCESS_CONTROL_SPACE = 'access-control-space',
  ENERGY_FLUID_SPACE = 'energy-fluid-space',
  BOILER_ROOM = 'boiler-room',
  FIRE_SAFETY_SPACE = 'fire-safety-space',
}

export class Tutorial {

  @propertyMap({ source: '_id' })
  public id: string;

  @propertyMap()
  public platform: TutorialPlatform;

  @propertyMap()
  public page: TutorialPage;

  @propertyMap()
  public group: string;

  @propertyMap()
  public title: string;

  @propertyMap()
  public caption: string;

  @propertyMap()
  public description: string;

  @propertyMap({ type: File })
  public image: File;

  @propertyMap({ type: File })
  public video: File;

  @propertyMap({ type: File })
  public videoSubtitle: File;

  @propertyMap({ type: File })
  public documentation: File;

  @propertyMap()
  public tags: string[];

  @propertyMap()
  public index: number;

}

export class Faq {

  @propertyMap({ source: '_id' })
  public id: string;

  @propertyMap()
  public folder: string;

  @propertyMap()
  public group: string;

  @propertyMap()
  public title: string;

  @propertyMap()
  public caption: string;

  @propertyMap()
  public description: string;

  @propertyMap({ type: File })
  public image: File;

  @propertyMap({ type: File })
  public video: File;

  @propertyMap({ type: File })
  public videoSubtitle: File;

  @propertyMap({ type: File })
  public documentation: File;

  @propertyMap()
  public tags: string[];

  @propertyMap()
  public tutorials: string[];

  @propertyMap()
  public index: number;
}

