<mat-form-field *ngIf="ready" [appearance]="appearance" [class.has-prefix-top]="!!selectControl.value?._id">
  <mat-label translate>{{label}}</mat-label>
  <input matInput type="text" [formControl]="selectControl" [matAutocomplete]="auto" (focus)="onfocus()"
    autocomplete="off">
  <div *ngIf="selectControl.value?._id" matPrefix>
    <div class="prefix-top">
      <span [attr.color]="selectControl.value.family">
        {{selectControl.value.family | translate}}</span>
    </div>
  </div>
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayOption">
    <mat-option *ngIf="searching" class="full-width">
      <mat-progress-bar @fade mode="buffer"></mat-progress-bar>
    </mat-option>
    <ng-container *ngIf="options$ | async as options">
      <mat-option *ngFor="let option of options" [value]="option">
        <div fxLayout="column" fxLayoutAlign="center start">
          <div class="option-prefix-top">
            <span [attr.color]="option.family">{{option.family | translate}}</span>
          </div>
          <span>{{option.name}}</span>
        </div>
      </mat-option>
      <mat-option *ngIf="!searching && searchMatched > options.length" class="full-width">
        <mat-progress-bar @fade mode="buffer" intersectionObserver (intersection)="loadMore($event)"></mat-progress-bar>
      </mat-option>
    </ng-container>
  </mat-autocomplete>
  <button *ngIf="selectControl.value" mat-icon-button size="small" matSuffix class="form-field-clear-btn"
    (click)="selectControl.setValue(null)">
    <mat-icon>close</mat-icon>
  </button>
  <mat-error *ngIf="control.errors && selectControl.touched" [translateParams]="control.errors | values | head"
    translate="form-field-error-{{control.errors | keys | head}}"></mat-error>
</mat-form-field>