/** @format */

import { Component, Input, OnInit } from '@angular/core';
import { EmbededRealEstateStructure } from 'sesio-lib';
import { ICell } from '../../datagrid/datagrid.component';

@Component({
  selector: 'app-real-estate-structure-datatgrid-cell',
  templateUrl: './real-estate-structure-datatgrid-cell.component.html',
  styleUrls: ['./real-estate-structure-datatgrid-cell.component.scss'],
})
export class RealEstateStructureDatatgridCellComponent implements OnInit {
  @Input('cell')
  public set setCell(cell: ICell) {
    if (cell) {
      if (Array.isArray(cell.rawValue)) this.pathNames = cell.rawValue;
      else this.realEstateStructure = cell?.rawValue;
    }
  }

  @Input()
  public realEstateStructure: EmbededRealEstateStructure;

  @Input()
  public pathNames: string[];

  constructor() {}

  ngOnInit(): void {}
}
