/** @format */

import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {ModelMapper} from 'model-mapper';
import moment from 'moment';
import 'moment-timezone';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AggregationValue} from '../classes/aggregation';
import {Contractor} from '../classes/contractor';
import {Family} from '../classes/family';
import {User} from '../classes/user';
import {LibConfig, LibConfigService} from '../config.service';

@Injectable({
  providedIn: 'root',
})
export class ContractorService {
  constructor(@Inject(LibConfigService) protected config: LibConfig, protected http: HttpClient) {}

  public list({
    families,
    categories,
    fields,
  }: {
    families?: Family[];
    categories?: string[];
    fields?: string[];
  } = {}): Observable<Contractor[]> {
    const params: any = {};
    if (families?.length) params.families = families;
    if (categories?.length) params.categories = categories;
    if (fields?.length) params.fields = fields;
    return this.http
      .get<any[]>(`${this.config.environment.apiUrl}/contractors`, {params})
      .pipe(map(data => data.map(d => new ModelMapper(Contractor).map(d))));
  }

  public search(search: string, family?: Family): Observable<Contractor[]> {
    const params: any = {};
    if (search) {
      params.search = search;
    }
    if (family) {
      params.families = [family];
    }
    return this.http
      .get<any[]>(`${this.config.environment.apiUrl}/contractors`, {params})
      .pipe(map(data => data.map(d => new ModelMapper(Contractor).map(d))));
  }

  public count({families}: {families?: Family[]}): Observable<number> {
    const params: any = {};
    if (families?.length) params.families = families;
    return this.http.get<number>(`${this.config.environment.apiUrl}/contractors/count`, {params});
  }

  public countByFamily(): Observable<{family: Family; count: number}[]> {
    return this.http.get<any[]>(`${this.config.environment.apiUrl}/contractors/count-by-family`);
  }

  public get(id: string): Observable<Contractor> {
    return this.http
      .get<any>(`${this.config.environment.apiUrl}/contractors/${id}`)
      .pipe(map(data => new ModelMapper(Contractor).map(data)));
  }

  public getUsers(id: string): Observable<User[]> {
    return this.http
      .get<any>(`${this.config.environment.apiUrl}/contractors/${id}/users`)
      .pipe(map(data => data.map(d => new ModelMapper(User).map(d))));
  }

  public delete(id: string): Observable<boolean> {
    return this.http.delete<boolean>(`${this.config.environment.apiUrl}/contractors/${id}`);
  }

  public create(update: any): Observable<Contractor> {
    return this.http
      .post<any>(`${this.config.environment.apiUrl}/contractors`, update)
      .pipe(map(data => new ModelMapper(Contractor).map(data)));
  }

  public update(id: string, update: any): Observable<boolean> {
    return this.http.patch<boolean>(`${this.config.environment.apiUrl}/contractors/${id}`, update);
  }

  public getFailureByMonth(eqId: string, year: string | number): Observable<AggregationValue[]> {
    const params: any = {eqId, year, timezone: moment.tz.guess()};
    return this.http.get<any[]>(`${this.config.environment.apiUrl}/contractor-events/failure/count-by-month`, {
      params,
    });
  }

  public getAvailabilityByMonth(eqId: string, year: string | number): Observable<AggregationValue[]> {
    const params: any = {eqId, year, timezone: moment.tz.guess()};
    return this.http.get<any[]>(`${this.config.environment.apiUrl}/contractor-events/availability/avg-by-month`, {
      params,
    });
  }
}
