import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastController } from '@ionic/angular';
import { v4 as uuidV4 } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private notifications: { uuid: string, toast: HTMLIonToastElement, presented: boolean }[] = [];
  private max = 5;

  constructor(
    private translate: TranslateService,
    private toastController: ToastController
  ) { }

  public async show(kind: 'info' | 'warning' | 'error', msg: string | { key: string, data: any }, duration?: number): Promise<void> {
    if (duration && duration < 300) { duration = 300; }

    const message = await (typeof msg === 'string' ? this.translate.get(msg) : this.translate.get(msg.key, msg.data)).toPromise();
    const toast = await this.toastController.create({
      message, duration, buttons: duration ? undefined : [{ icon: 'close', role: 'cancel' }],
      position: 'top', cssClass: `notification notification-${kind}`
    });
    const uuid = uuidV4();
    toast.onDidDismiss().then(() => {
      const index = this.notifications.findIndex(t => t.uuid === uuid);
      this.notifications.splice(index, 1);
      this.display();
    });
    this.notifications.push({ uuid, toast, presented: false });
    this.display();
  }

  private display(): void {
    for (let i = 0; i < Math.min(this.notifications.length, this.max); ++i) {
      const item = this.notifications[i];
      if (!item.presented) {
        item.presented = true;
        item.toast.present();
      }
    }
  }

}
