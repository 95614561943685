/** @format */

import {Component, Input, OnInit} from '@angular/core';
import {EquipmentStatus, EquipmentStatusColor, EquipmentStatusIcon} from 'sesio-lib';

@Component({
  selector: 'app-equipment-status',
  templateUrl: './equipment-status.component.html',
  styleUrls: ['./equipment-status.component.scss'],
})
export class EquipmentStatusComponent implements OnInit {
  options = {
    [EquipmentStatus.ONLINE]: {
      name: 'En service',
      color: EquipmentStatusColor[EquipmentStatus.ONLINE],
      icon: EquipmentStatusIcon[EquipmentStatus.ONLINE],
    },
    [EquipmentStatus.OFFLINE]: {
      name: 'En panne',
      color: EquipmentStatusColor[EquipmentStatus.OFFLINE],
      icon: EquipmentStatusIcon[EquipmentStatus.OFFLINE],
    },
    [EquipmentStatus.WARNING]: {
      name: 'En anomalie',
      color: EquipmentStatusColor[EquipmentStatus.WARNING],
      icon: EquipmentStatusIcon[EquipmentStatus.WARNING],
    },
    [EquipmentStatus.NOFLOW]: {
      name: 'En absence de flux',
      color: EquipmentStatusColor[EquipmentStatus.NOFLOW],
      icon: EquipmentStatusIcon[EquipmentStatus.NOFLOW],
    },
    [EquipmentStatus.ND]: {
      name: 'Non supervisés',
      color: EquipmentStatusColor[EquipmentStatus.ND],
      icon: EquipmentStatusIcon[EquipmentStatus.ND],
    },
  };

  @Input()
  public status: EquipmentStatus;

  constructor() {}

  ngOnInit(): void {}
}
