import { propertyMap } from 'model-mapper';

export type EquipmentEventCodeKind =
  'accessPass' | 'cellTower' | 'elevator' | 'opticalFiber' | 'parkingDoor' | 'photovoltaicPanel' | 'other';

export class EquipmentEventCode {

  @propertyMap({ source: '_id' })
  public id: string;

  @propertyMap()
  public parentId: string;

  @propertyMap()
  public name: string;

  @propertyMap()
  public elevatorPlace: number;

  @propertyMap()
  public elevatorNumber: number;

  @propertyMap()
  public code: string;

  @propertyMap()
  public codeTemplate: string;

  @propertyMap()
  public state: number;

  @propertyMap()
  public kind: EquipmentEventCodeKind;

}
