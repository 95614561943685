<div id="container" [class]="display" fxLayout="column" [@.disabled]="isAnimationDisabled">
  <svg *ngIf="display === 'full'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 168.862 190">
    <g id="climat-tag" data-name="climat tag" transform="translate(-502 -331)">
      <g *ngIf="position !== null" [@line]="position" id="value-line-block" data-name="Climat Value Line"
        [attr.transform]="'translate(205.407 ' + position + ')'">
        <line x1="40" y1="0" x2="115" y2="0" stroke-dasharray="1"
          style="stroke:var(--ion-color-primary);stroke-width:0.5" transform="translate(303 373.5)" />
      </g>
      <g id="climat-A" data-name="Climat A" transform="translate(201.407 -5)">
        <path id="Tracé_661" data-name="Tracé 661" d="M-1681.151,9334.293l10.2,10.487-10.2,10.487h-41.256v-20.975Z"
          transform="translate(2023 -8998.293)" fill="#ddcaf5" />
        <text id="_5" data-name="&lt; 5" transform="translate(303 349)" fill="#fff" font-size="8"
          font-family="Lato-Regular, Lato">
          <tspan x="0" y="0">&#8804; 5</tspan>
        </text>
        <text id="A" transform="translate(342 350)" fill="#fff" font-size="12" font-family="Lato-Semibold, Lato"
          font-weight="600">
          <tspan x="-8.238" y="0">A</tspan>
        </text>
      </g>
      <g id="climat-B" data-name="Climat B" transform="translate(201.407 -5)">
        <path id="Tracé_662" data-name="Tracé 662" d="M-1671.151,9334.293l10.2,10.487-10.2,10.487h-51.256v-20.975Z"
          transform="translate(2023 -8971.293)" fill="#cbb1ef" />
        <text id="_6_à_10" data-name="6 à 10" transform="translate(303 376)" fill="#fff" font-size="8"
          font-family="Lato-Regular, Lato">
          <tspan x="0" y="0">6 à 10</tspan>
        </text>
        <text id="B" transform="translate(352 377)" fill="#fff" font-size="12" font-family="Lato-Semibold, Lato"
          font-weight="600">
          <tspan x="-7.836" y="0">B</tspan>
        </text>
      </g>
      <g id="climat-C" data-name="Climat C" transform="translate(201.407 -5)">
        <path id="Tracé_663" data-name="Tracé 663" d="M-1661.151,9334.293l10.2,10.487-10.2,10.487h-61.256v-20.975Z"
          transform="translate(2023 -8944.293)" fill="#bc98ea" />
        <text id="_11_à_20" data-name="11 à 20" transform="translate(303 403)" fill="#fff" font-size="8"
          font-family="Lato-Regular, Lato">
          <tspan x="0" y="0">11 à 20</tspan>
        </text>
        <text id="C" transform="translate(362 404)" fill="#fff" font-size="12" font-family="Lato-Semibold, Lato"
          font-weight="600">
          <tspan x="-7.974" y="0">C</tspan>
        </text>
      </g>
      <g id="climat-D" data-name="Climat D" transform="translate(201.407 -5)">
        <path id="Tracé_664" data-name="Tracé 664" d="M-1658.151,9334.293l10.2,10.487-10.2,10.487h-71.256v-20.975Z"
          transform="translate(2030 -8917.293)" fill="#aa7de5" />
        <text id="_21_à_35" data-name="21 à 35" transform="translate(303 430)" fill="#fff" font-size="8"
          font-family="Lato-Regular, Lato">
          <tspan x="0" y="0">21 à 35</tspan>
        </text>
        <text id="D" transform="translate(369 431)" fill="#fff" font-size="12" font-family="Lato-Semibold, Lato"
          font-weight="600">
          <tspan x="-9.126" y="0">D</tspan>
        </text>
      </g>
      <g id="climat-E" data-name="Climat E" transform="translate(201.407 -5)">
        <path id="Tracé_665" data-name="Tracé 665" d="M-1647.151,9334.293l10.2,10.487-10.2,10.487h-82.256v-20.975Z"
          transform="translate(2030 -8891.293)" fill="#9964df" />
        <text id="_36_à_55" data-name="36 à 55" transform="translate(303 456)" fill="#fff" font-size="8"
          font-family="Lato-Regular, Lato">
          <tspan x="0" y="0">36 à 55</tspan>
        </text>
        <text id="E" transform="translate(379 457)" fill="#fff" font-size="12" font-family="Lato-Semibold, Lato"
          font-weight="600">
          <tspan x="-6.912" y="0">E</tspan>
        </text>
      </g>
      <g id="climat-F" data-name="Climat F" transform="translate(201.407 -5)">
        <path id="Tracé_666" data-name="Tracé 666" d="M-1637.151,9334.293l10.2,10.487-10.2,10.487h-92.256v-20.975Z"
          transform="translate(2030 -8864.293)" fill="#874ada" />
        <text id="_56_à_80" data-name="56 à 80" transform="translate(303 483)" fill="#fff" font-size="8"
          font-family="Lato-Regular, Lato">
          <tspan x="0" y="0">56 à 80</tspan>
        </text>
        <text id="F" transform="translate(389 484)" fill="#fff" font-size="12" font-family="Lato-Semibold, Lato"
          font-weight="600">
          <tspan x="-6.786" y="0">F</tspan>
        </text>
      </g>
      <g id="climat-G" data-name="Climat G" transform="translate(201.407 -5)">
        <path id="Tracé_667" data-name="Tracé 667" d="M-1627.151,9334.293l10.2,10.487-10.2,10.487h-102.256v-20.975Z"
          transform="translate(2030 -8837.293)" fill="#7028d2" />
        <text id="_80" data-name="&gt; 80" transform="translate(303 510)" fill="#fff" font-size="8"
          font-family="Lato-Regular, Lato">
          <tspan x="0" y="0">&gt; 80</tspan>
        </text>
        <text id="G" transform="translate(399 511)" fill="#fff" font-size="12" font-family="Lato-Semibold, Lato"
          font-weight="600">
          <tspan x="-8.736" y="0">G</tspan>
        </text>
      </g>
      <g *ngIf="position !== null" @fade id="value-block" data-name="Climat Value"
        [attr.transform]="'translate(205.407 ' + position + ')'">
        <path id="Tracé_668" data-name="Tracé 668" d="M-1712.208,9334.293l-10.2,10.487,10.2,10.487h41.256v-20.975Z"
          transform="translate(2136.407 -8971.293)" fill="#295974" />
        <text *ngIf="value !== null" id="_14" data-name="14"
          [attr.transform]="'translate(' + (473 - (value.toString().length * 7)) + ' 378)'" fill="#fff" font-size="12"
          font-family="Lato-Semibold, Lato" font-weight="600">
          <tspan x="-13.92" y="0">{{value}}</tspan>
        </text>
        <text id="KG_eqCO2_m_.an" data-name="KG eqCO2/m².an" transform="translate(424 391)" fill="#295974" font-size="7"
          font-family="Lato-Regular, Lato">
          <tspan x="0" y="0">KG </tspan>
          <tspan y="0" font-size="4">eqCO2</tspan>
          <tspan y="0" font-size="5">/m²</tspan>
          <tspan y="0">.an</tspan>
        </text>
      </g>
    </g>
  </svg>
  <div *ngIf="display === 'letter'" fxLayoutAlign="space-between center" [style.width.%]="100" fxLayoutGap="8px">
    <div id="letter" [style.background-color]="color">{{letter}}</div>
    <span id="value">{{value}}</span>
  </div>
</div>
