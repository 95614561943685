import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HTTP_INTERCEPTORS,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthService } from '../_services/auth.service';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';

@Injectable()
export class AuthInterceptorClass implements HttpInterceptor {
  constructor(private router: Router, private authService: AuthService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!req.url.startsWith(environment.apiUrl)) return next.handle(req);
    if (
      !this.router.url.startsWith('/login') &&
      !this.authService.isValidToken(this.authService.$authToken.value)
    ) {
      this.logout();
      return throwError(() => new Error('auth token invalid'));
    }
    const clonedRequest = req.clone({
      headers: req.headers.set(
        'Authorization',
        `Bearer ${this.authService.$authToken.value}`
      ),
    });
    return next.handle(clonedRequest).pipe(
      catchError((err) => {
        if (
          err.status === 401 &&
          !this.router.url.startsWith('/login') &&
          !this.router.url.startsWith('/user-tracking')
        ) {
          this.logout();
        }
        throw err;
      })
    );
  }

  private logout() {
    this.authService.logout().then(() => {
      const queryParams: any = {};
      if (this.router.url) queryParams.returnUrl = this.router.url;
      this.router.navigate(['/login'], { queryParams });
    });
  }
}

export const AuthInterceptor = {
  provide: HTTP_INTERCEPTORS,
  useClass: AuthInterceptorClass,
  multi: true,
};
