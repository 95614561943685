/** @format */

import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ModelMapper } from 'model-mapper';
import { LibConfig, LibConfigService } from '../config.service';
import { HttpClient } from '@angular/common/http';
import { Family } from '../classes/family';
import { EquipmentManager } from '../classes/equipment-manager';

@Injectable({
  providedIn: 'root',
})
export class EquipmentManagerService {
  constructor(@Inject(LibConfigService) protected config: LibConfig, protected http: HttpClient) {}

  public list({
    families,
  }: {
    families?: Family[];
  } = {}): Observable<EquipmentManager[]> {
    const params: any = {};
    if (families?.length) params.families = families;
    return this.http
      .get<any[]>(`${this.config.environment.apiUrl}/equipment-managers`, { params })
      .pipe(map(data => data.map(d => new ModelMapper(EquipmentManager).map(d))));
  }
}
