/** @format */

import {Pipe, PipeTransform} from '@angular/core';
import {replace} from 'lodash-es';

type ReplaceFunction = (match: string, ...args: any[]) => string;

@Pipe({name: 'replace'})
export class ReplacePipe implements PipeTransform {
  transform(data: string, pattern: RegExp | string, replacement: ReplaceFunction | string): string {
    return replace(data, pattern, replacement);
  }
}
