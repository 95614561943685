/** @format */

import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {merge} from 'lodash-es';
import {ModelMapper} from 'model-mapper';
import moment, {Moment} from 'moment';
import 'moment-timezone';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AggregationValue} from '../classes/aggregation';
import {EquipmentFailure as EquipmentFailureData} from '../classes/equipment-failure';
import {LibConfig, LibConfigService} from '../config.service';
import {IChartData} from '../modules/chart/chart.interface';
import {DrilldownService} from './drilldown.service';
import {Granularity} from './sensor-data.service';

@Injectable({
  providedIn: 'root',
})
export class EquipmentFailureService {
  constructor(
    @Inject(LibConfigService) protected config: LibConfig,
    protected http: HttpClient,
    private drilldownService: DrilldownService
  ) {}

  public list(equipmentId: string, year?: string | number, limit?: number): Observable<EquipmentFailureData[]> {
    const params: any = {equipmentId, timezone: moment.tz.guess()};
    if (year) params.year = year;
    if (limit) params.limit = limit;
    return this.http
      .get<any[]>(`${this.config.environment.apiUrl}/equipment-failures`, {params})
      .pipe(map(data => data.map(d => new ModelMapper(EquipmentFailureData).map(d))));
  }

  public count(
    op: 'sum' | 'avg',
    granularity: Granularity,
    search: {organizationalUnitId?: string; equipmentId?: string},
    start?: moment.Moment,
    end?: moment.Moment
  ): Observable<AggregationValue[]> {
    const filter: any = merge(search, {op, start});
    switch (granularity) {
      case 'year':
        return this.http.post<any[]>(`${this.config.environment.apiUrl}/equipment-failures/get-by-year`, filter);
      case 'month':
        filter.year = start.year();
        return this.http.post<any[]>(`${this.config.environment.apiUrl}/equipment-failures/get-by-month`, filter);
      case 'day':
        filter.year = start.year();
        filter.month = start.month() + 1;
        return this.http.post<any[]>(`${this.config.environment.apiUrl}/equipment-failures/get-by-day`, filter);
      case 'hour':
        filter.year = start.year();
        filter.month = start.month() + 1;
        filter.day = start.date();
        return this.http.post<any[]>(`${this.config.environment.apiUrl}/equipment-failures/get-by-hour`, filter);
      case 'minute':
        filter.year = start.year();
        filter.month = start.month() + 1;
        filter.day = start.date();
        filter.hour = start.hour();
        return this.http.post<any[]>(`${this.config.environment.apiUrl}/equipment-failures/get-by-minute`, filter);
    }
  }

  public getDrilldownData(
    search: {organizationalUnitId?: string; equipmentId?: string},
    granularity?: Granularity,
    start?: Moment
  ): Promise<IChartData[]> {
    return this.drilldownService.getDrilldown(
      this.count.bind(this),
      'sum',
      search,
      granularity,
      start || moment(),
      'minute'
    );
  }

  public getRangeDate(
    search: {organizationalUnitId?: string; equipmentId?: string},
    start?: moment.Moment,
    end?: moment.Moment
  ): Observable<AggregationValue[]> {
    const filter: any = merge(search, {start, end});
    return this.http.post<any[]>(`${this.config.environment.apiUrl}/equipment-failures/get-range-date`, filter);
  }

  public getCountByContractor(filter: any): Observable<AggregationValue[]> {
    return this.http.post<any[]>(`${this.config.environment.apiUrl}/equipment-failures/count-by-contractor`, filter);
  }

  public byHourOfDayOfWeek(
    filter: any
  ): Observable<{_id: string; day: number; count: number; hours: {_id: string; hour: number; count: number}[]}[]> {
    return this.http.post<any[]>(
      `${this.config.environment.apiUrl}/equipment-failures/count-by-hour-of-day-of-week`,
      filter
    );
  }
}
