/** @format */

import {Directive, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ConfirmDialogComponent} from './confirm-dialog.component';

@Directive({
  selector: '[confirm]',
})
export class ConfirmDialogDirective {
  @Input('confirmTitle') title?: string;
  @Input('confirmMessage') message!: string;
  @Input('confirmValidate') validate?: string;
  @Input('confirmCancel') cancel?: string;
  @Input('yesLabelColor') yesLabelColor?: string;
  @Output() confirm = new EventEmitter<void>();
  @Output() canceld = new EventEmitter<void>();

  private dialogRef: MatDialogRef<ConfirmDialogComponent, any> | null;

  constructor(private dialog: MatDialog) {}

  @HostListener('click', ['$event']) async onClick(e: MouseEvent): Promise<void> {
    e.preventDefault();
    e.stopPropagation();

    if (this.dialogRef) return;
    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: this.title,
        message: this.message,
        ok: this.validate,
        cancel: this.cancel,
        yesLabelColor: this.yesLabelColor,
      },
      minWidth: '400px',
      panelClass: 'dialog-confirm',
    });
    this.dialogRef.afterClosed().subscribe(data => {
      this.dialogRef = null;
      if (data) this.confirm.emit();
      else this.canceld.emit();
    });
  }
}
