/** @format */

import {DatePipe as AngularDatePipe} from '@angular/common';
import {Pipe} from '@angular/core';
import {Moment, isMoment} from 'moment';

@Pipe({name: 'date'})
export class DatePipe extends AngularDatePipe {
  override transform(
    value: Moment | Date | string | number,
    format?: string,
    timezone?: string,
    locale?: string
  ): string | null;
  override transform(value: null | undefined, format?: string, timezone?: string, locale?: string): null;
  override transform(
    value: Moment | Date | string | number | null | undefined,
    format?: string,
    timezone?: string,
    locale?: string
  ): string | null {
    if (isMoment(value)) value = value.valueOf();
    return super.transform(value, format, timezone, locale);
  }
}
