/** @format */

import {Component, Input, OnInit} from '@angular/core';
import {get, isEqual} from 'lodash-es';
import {SubSink} from 'subsink';
import {convertHeaderSelection, IHeaderSearch} from '../../../../../../sesio-webapp/src/app/_classes/header-search';
import {OrganizationalUnitService} from '../../../../../../sesio-webapp/src/app/_services/organizational-unit.service';
import {SessionService} from '../../../../../../sesio-webapp/src/app/_services/session.service';
import {EnergyTagService, IEnergyTag} from './energy-tag.service';

@Component({
  selector: 'app-energy-tags',
  templateUrl: './energy-tags.component.html',
  styleUrls: ['./energy-tags.component.scss'],
})
export class EnergyTagsComponent implements OnInit {
  public tags: IEnergyTag[] = this.energyTagService.getTags();

  @Input()
  public shrink = false;

  @Input()
  public fromHeader = false;

  @Input()
  public consumption: number;

  private filter: IHeaderSearch;
  private subsink = new SubSink();

  constructor(
    private energyTagService: EnergyTagService,
    private sessionService: SessionService,
    private organizationalUnitService: OrganizationalUnitService
  ) {}

  ngOnInit(): void {
    this.subsink.add(
      this.sessionService.$headerSelection.subscribe(selection => {
        if (!this.fromHeader) {
          return;
        }
        const filter: any = convertHeaderSelection(selection);
        if (isEqual(this.filter, filter)) return;
        this.load(filter);
      })
    );
  }

  public arrowtagWidth(i: number): number {
    return this.shrink ? 50 + 10 * i : 100 + 20 * i;
  }

  public consumptiontagWidth(i: number): number {
    return this.shrink ? 180 - (60 + 10 * i) : 340 - (120 + 20 * i);
  }

  public consumptiontagLeft(i: number): number {
    return this.shrink ? 59 + 10 * i : 134 + 20 * i;
  }

  private async load(filter: any): Promise<void> {
    if (isEqual(this.filter, filter)) {
      return;
    }
    this.filter = filter;
    this.consumption = get(await this.organizationalUnitService.getConsumption(this.filter).toPromise(), 'energy');
  }
}
