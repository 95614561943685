import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SessionService } from '../../_services/session.service';

@Component({
  templateUrl: './statistics.modal.html',
  styleUrls: ['./statistics.modal.scss'],
})
export class StatisticsModal implements OnInit {

  public $statistics = this.sessionService.$statistics;
  public statistics: { page: string; data?: any };

  constructor(
    private modalController: ModalController,
    private sessionService: SessionService,
  ) { }

  ngOnInit(): void {
    this.statistics = this.$statistics.value;
  }

  public dismiss(): void {
    this.modalController.dismiss();
  }

}
