import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { TechnicalControlModal } from './technical-control.modal';
import { LoaderModule } from '../loader/loader.module';


@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        TranslateModule.forChild(),
        FlexLayoutModule,
        LoaderModule
    ],
    declarations: [TechnicalControlModal],
    exports: [TechnicalControlModal]
})
export class TechnicalControlModule { }
