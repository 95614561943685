/** @format */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SafePipeModule } from 'safe-pipe';
import { IonicModule } from '@ionic/angular';
import { LoaderModule } from 'sesio-lib';
import { ConfirmDialogComponent } from './confirm-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { ConfirmDialogService } from './confirm-dialog.service';
import { ConfirmDialogDirective } from './confirm-dialog.directive';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [ConfirmDialogComponent, ConfirmDialogDirective],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule.forChild(),
    FlexLayoutModule,
    SafePipeModule,
    LoaderModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
  ],
  exports: [ConfirmDialogComponent, ConfirmDialogDirective],
  providers: [ConfirmDialogService],
})
export class ConfirmDialogModule {}
