/** @format */

import {animate, state, style, transition, trigger} from '@angular/animations';
import {AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatTabGroup} from '@angular/material/tabs';
import humanizeDuration from 'humanize-duration';
import {clone, each, filter, find, get, isEqual, map, merge, orderBy, round, sum} from 'lodash-es';
import moment from 'moment';
import {lastValueFrom} from 'rxjs';
import {ChartComponent, Contractor, Family} from 'sesio-lib';
import striptags from 'striptags';
import * as XLSX from 'xlsx';
import {convertHeaderSelection, IHeaderSearch} from '../../../_classes/header-search';
import {AccessControlService} from '../../../_services/access-control.service';
import {ContractorService} from '../../../_services/contractor.service';
import {IHeaderSelection, SessionService} from '../../../_services/session.service';

const CONTRACTOR_COLORS = [
  {color: 'var(--ion-color-primary)', contrast: 'var(--ion-color-primary-contrast)'},
  {color: 'var(--ion-color-secondary)', contrast: 'var(--ion-color-secondary-contrast)'},
];

const MEAN_OF_ACCESS_COLORS = [
  {color: 'var(--ion-color-primary)', contrast: 'var(--ion-color-primary-contrast)'},
  {color: 'var(--ion-color-success)', contrast: 'var(--ion-color-success-contrast)'},
  {color: 'var(--ion-color-secondary)', contrast: 'var(--ion-color-secondary-contrast)'},
];

interface IContractor extends Contractor {
  selected?: boolean;
}

interface IExpandedRowElement {
  delay: number;
  delayStr: string;
  date: Date;
  esi: string;
  kind: string;
  quantity: number;
  amount: number;
  applicant?: string;
  group?: string;
}

@Component({
  selector: 'app-access-control-space',
  templateUrl: './access-control-space.component.html',
  styleUrls: ['./access-control-space.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class AccessControlSpaceComponent implements OnInit, AfterViewInit {
  filter: IHeaderSearch;
  contractors: IContractor[];
  years: number[] = [moment().subtract(1, 'year').year(), moment().year()];
  selectedYear: number = moment().year();

  pieChartOptions = {
    chart: {
      type: 'pie',
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      backgroundColor: 'transparent',
    },
    title: {
      text: '',
      align: 'left',
      // y: 20,
      style: {
        fontSize: '24px',
      },
    },
    exporting: {
      enabled: false,
    },
    credits: {enabled: false},
    tooltip: {
      pointFormat: '<b>{point.percentage:.1f}%</b> ({point.y})',
    },
    accessibility: {
      point: {
        valueSuffix: '%',
      },
    },
    legend: {
      labelFormat: '<span style="color:{color}">{name} {y}</span>',
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'middle',
      itemMarginTop: 10,
      itemMarginBottom: 10,
      itemDistance: 30,
      itemStyle: {
        fontSize: '12px',
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '{point.percentage:.1f} %',
          distance: -50,
        },
        showInLegend: true,
        size: '220px',
      },
    },
    series: [],
  };

  stackedChartOptions = {
    chart: {
      type: 'column',
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      backgroundColor: 'transparent',
    },
    title: {
      text: 'SUIVI DES COMMANDES',
      align: 'left',
      style: {
        fontSize: '24px',
      },
    },
    exporting: {
      enabled: false,
    },
    credits: {enabled: false},
    xAxis: {categories: moment.monthsShort(), labels: {rotation: -45}},
    yAxis: {
      title: {
        text: '',
      },
      stackLabels: {
        enabled: true,
      },
      labels: {
        enabled: false,
      },
    },
    legend: {
      labelFormat: '<span style="color:{color}">{name} {y}</span>',
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',
      itemStyle: {
        fontSize: '12px',
      },
      alignColumns: false,
      itemDistance: 20,
      margin: 0,
      padding: 0,
      symbolPadding: 0,
      itemMarginTop: 8,
    },
    tooltip: {
      headerFormat: '<b>{point.x}</b><br/>',
      pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}',
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: true,
        },
      },
    },
  };

  @ViewChild('contractorChart', {read: ChartComponent, static: true})
  private contractorChartRef: ChartComponent;
  @ViewChild('meanOfAccessChart', {read: ChartComponent, static: true})
  private meanOfAccessChartRef: ChartComponent;
  @ViewChild('meanOfAccessFollowupChart', {read: ChartComponent, static: true})
  private meanOfAccessFollowupChartRef: ChartComponent;

  byGroupDisplayedColumns: string[] = [
    'group',
    'count',
    'quantity',
    'avg',
    'amount',
    'amountByBuilding',
    'amountBySurface',
    'rate',
    'badge',
    'biTech',
    'triTech',
  ];
  byGroupDisplayedColumnNames: {[key: string]: string} = {
    group: 'GROUPE',
    count: 'COMMANDES',
    quantity: 'QUANTITÉ',
    avg: 'MOYENNE',
    amount: 'MONTANT',
    amountByBuilding: 'MONTANT / HABITATION',
    amountBySurface: 'MONTANT / M<sup>2</sup>',
    rate: 'FRÉQUENCE MOYENNE',
    badge: 'BADGES',
    biTech: 'BI-TECHNOLOGIE',
    triTech: 'TRI-TECHNOLOGIE',
  };
  byGroupDataSource = new MatTableDataSource([]);
  @ViewChild('byGroupSort', {static: false}) byGroupSort: MatSort;
  @ViewChild('byGroupPaginator', {static: false}) byGroupPaginator: MatPaginator;

  byApplicantDisplayedColumns: string[] = [
    'applicant',
    'groups',
    'count',
    'quantity',
    'avg',
    'amount',
    'rate',
    'badge',
    'biTech',
    'triTech',
  ];
  byApplicantDisplayedColumnNames: {[key: string]: string} = {
    applicant: 'GARDIEN',
    groups: 'GROUPES',
    count: 'COMMANDES',
    quantity: 'QUANTITÉ',
    avg: 'MOYENNE',
    amount: 'MONTANT',
    rate: 'FRÉQUENCE MOYENNE',
    badge: 'BADGES',
    biTech: 'BI-TECHNOLOGIE',
    triTech: 'TRI-TECHNOLOGIE',
  };
  byApplicantDataSource = new MatTableDataSource([]);
  @ViewChild('byApplicantSort', {static: false}) byApplicantSort: MatSort;
  @ViewChild('byApplicantPaginator', {static: false}) byApplicantPaginator: MatPaginator;

  countTop: number;
  countFlop: number;
  quantityTop: number;
  quantityFlop: number;
  countSum: number;
  quantitySum: number;
  countAvg: number;
  quantityAvg: number;

  expandedRow: any;
  expandedDisplayedColumns: string[];
  expandedRowDatasource: MatTableDataSource<IExpandedRowElement>;

  constructor(
    private contractorService: ContractorService,
    private accessControlService: AccessControlService,
    private sessionService: SessionService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  async ngAfterViewInit(): Promise<void> {
    this.loadFilter(this.sessionService.$headerSelection.value);
    this.byGroupDataSource.sort = this.byGroupSort;
    this.byGroupDataSource.paginator = this.byGroupPaginator;
    this.byApplicantDataSource.sort = this.byApplicantSort;
    this.byApplicantDataSource.paginator = this.byApplicantPaginator;
    this.contractors = orderBy(
      map(
        await this.contractorService.list({families: [Family.ACCESS_CONTROL]}).toPromise(),
        (c: IContractor) => ((c.selected = true), c)
      ),
      ['name']
    );
    this.loadData();
  }

  toggleContractor(contractor: IContractor): void {
    contractor.selected = !contractor.selected;
    this.loadData();
  }

  selectYear(year: number): void {
    if (this.selectedYear === year) return;
    this.selectedYear = year;
    this.loadData();
  }

  async toggleExpandedRow(row: any): Promise<void> {
    this.expandedRow = this.expandedRow === row ? null : row;
    this.expandedRowDatasource = null;
    if (this.expandedRow === null) return;

    let data: any[];
    if (this.datagridSelectedIndex === 0) {
      this.expandedDisplayedColumns = ['delay', 'date', 'esi', 'kind', 'quantity', 'amount', 'applicant'];
      data = await lastValueFrom(
        this.accessControlService.getGroupList(
          merge(this.getDataFilter(), {organizationalUnit: {id: this.expandedRow.groupId}})
        )
      );
    } else {
      this.expandedDisplayedColumns = ['delay', 'group', 'date', 'esi', 'kind', 'quantity', 'amount'];
      data = await lastValueFrom(
        this.accessControlService.getJanitorList(merge(this.getDataFilter(), {janitorId: this.expandedRow.applicantId}))
      );
    }

    this.expandedRowDatasource = new MatTableDataSource(
      map(data, (d: any) => {
        d.delayStr = d.delay ? humanizeDuration(d.delay, {language: 'fr', round: true, largest: 2}) : '-';
        return d;
      })
    );
  }

  private loadData() {
    const dataFilter = this.getDataFilter();
    this.contractorChartRef.setLoading(true);
    this.accessControlService
      .getContractorStatistics(dataFilter)
      .toPromise()
      .then(data => {
        this.contractorChartRef.setTitle(
          `PRESTATAIRES : <span style="color: var(--ion-color-primary);">${sum(map(data, 'count'))}</span>`
        );
        this.contractorChartRef.setSeries([
          {
            name: 'contractors',
            colorByPoint: true,
            data: map(orderBy(data, ['contractor.name']), (d, index) => ({
              index,
              name: d.contractor.name,
              y: d.count,
              color: CONTRACTOR_COLORS[index]?.color,
            })),
          },
        ] as any);
        this.contractorChartRef.setLoading(false);
      });
    this.meanOfAccessChartRef.setLoading(true);
    this.accessControlService
      .getMeanOfAccessStatistics(dataFilter)
      .toPromise()
      .then(data => {
        this.meanOfAccessChartRef.setTitle(
          `TYPES COMMANDES : <span style="color: var(--ion-color-primary);">${sum(map(data, 'count'))}</span>`
        );
        this.meanOfAccessChartRef.setSeries([
          {
            name: 'mean of access',
            colorByPoint: true,
            data: map(orderBy(data, ['name']), (d, index) => ({
              index,
              name: d.name,
              y: d.count,
              color: MEAN_OF_ACCESS_COLORS[index]?.color,
            })),
          },
        ] as any);
        this.meanOfAccessChartRef.setLoading(false);
      });
    this.meanOfAccessFollowupChartRef.setLoading(true);
    this.accessControlService
      .getMeanOfAccessFollowup(dataFilter)
      .toPromise()
      .then(data => {
        this.meanOfAccessFollowupChartRef.setSeries(
          map(orderBy(data, ['name']), (d, index) => ({
            name: d.name,
            color: MEAN_OF_ACCESS_COLORS[index]?.color,
            data: map(Array(12), (key, index) => get(find(d.values, {month: index + 1}), 'count', null)),
          })) as any
        );
        this.meanOfAccessFollowupChartRef.setLoading(false);
      });
    this.loadDatagridData(dataFilter);
    this.changeDetectorRef.detectChanges();
  }

  datagridTabsInitialized = false;
  @ViewChild('datagridTabs', {read: MatTabGroup})
  datagridTabs: MatTabGroup;
  datagridSelectedIndex = 0;

  initDatagridTabs($event: any) {
    if ($event.visible && !this.datagridTabsInitialized) {
      this.datagridTabsInitialized = true;
      this.datagridTabs?.realignInkBar();
    }
  }

  async datagridTabChanged($event: number) {
    console.log('datagridTabChanged', $event);
    this.datagridSelectedIndex = $event;
    await this.loadDatagridData(this.getDataFilter());
    this.changeDetectorRef.detectChanges();
    // console.log(this.sort);
    // if (this.datagridSelectedIndex === 0) {
    //   this.byGroupDataSource.sort = this.sort;
    //   this.byGroupDataSource.paginator = this.paginator;
    // } else {
    //   this.byApplicantDataSource.sort = this.sort;
    //   this.byApplicantDataSource.paginator = this.paginator;
    // }
  }

  exporting = false;
  public async export(by: 'groupe' | 'gardien'): Promise<void> {
    if (this.exporting) return;
    this.exporting = true;
    try {
      let data: any[] = [];
      switch (by) {
        case 'groupe':
          data.push(map(this.byGroupDisplayedColumns, column => striptags(this.byGroupDisplayedColumnNames[column])));
          each(this.byGroupDataSource.data, d => {
            const row = [];
            each(this.byGroupDisplayedColumns, column => row.push(d[column]));
            data.push(row);
          });
          break;
        case 'gardien':
          data.push(
            map(this.byApplicantDisplayedColumns, column => striptags(this.byApplicantDisplayedColumnNames[column]))
          );
          each(this.byApplicantDataSource.data, d => {
            const row = [];
            each(this.byApplicantDisplayedColumns, column => row.push(d[column]));
            data.push(row);
          });
          break;
      }
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.aoa_to_sheet(data);
      XLSX.utils.book_append_sheet(workbook, worksheet, 'export');
      await XLSX.writeFile(workbook, `export antenne relai ${by} ${moment().format('DD-MM-YYYY HH:mm:ss')}.xlsx`);
    } finally {
      this.exporting = false;
      this.changeDetectorRef.detectChanges();
    }
  }

  private async loadDatagridData(dataFilter: IHeaderSearch & {year: number; contractorIds: string[]}) {
    const data = await lastValueFrom<any>(
      this.datagridSelectedIndex === 0
        ? this.accessControlService.getGroupStatistics(dataFilter)
        : this.accessControlService.getJanitorStatistics(dataFilter)
    );
    this.countSum = 0;
    this.quantitySum = 0;
    const dataSourceData = map(data, (d: any) => {
      this.countSum += d.count;
      this.quantitySum += d.quantity;
      d.avg = d.amount ? round(d.quantity / d.count) : '-';
      d.rateStr = d.rate ? humanizeDuration(d.rate, {language: 'fr', round: true, largest: 2}) : '-';
      if (this.datagridSelectedIndex === 0) {
        d.amountByBuilding = d.housingCount ? d.amount / d.housingCount : 0;
        d.amountBySurface = d.livingSurface ? d.amount / d.livingSurface : 0;
      }
      d.badge = get(
        find(d.kinds, k => k.name === 'Commande badge'),
        'count',
        0
      );
      d.biTech = get(
        find(d.kinds, k => k.name === 'Commande télécommande bi-technologie'),
        'count',
        0
      );
      d.triTech = get(
        find(d.kinds, k => k.name === 'Commande télécommande tri-technologie'),
        'count',
        0
      );
      return d;
    });
    this.countAvg = this.countSum / dataSourceData.length;
    this.quantityAvg = this.quantitySum / this.countSum;
    this.countTop = this.countAvg * 1.25;
    this.countFlop = this.countAvg * 0.25;
    this.quantityTop = this.quantityAvg * 1.25;
    this.quantityFlop = this.quantityAvg * 0.25;

    if (this.datagridSelectedIndex === 0) this.byGroupDataSource.data = dataSourceData;
    else this.byApplicantDataSource.data = dataSourceData;
  }

  private getDataFilter(): IHeaderSearch & {year: number; contractorIds: string[]} {
    return merge(clone(this.filter), {
      year: this.selectedYear,
      contractorIds: map(filter(this.contractors, {selected: true}), 'id'),
    });
  }

  private loadFilter(selection: IHeaderSelection): void {
    const filter: any = convertHeaderSelection(selection);
    if (isEqual(this.filter, filter)) return;
    this.filter = filter;
  }
}
