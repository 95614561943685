import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NgxNotificationStatusMsg, NgxNotificationDirection, NgxNotificationMsgService } from 'ngx-notification-msg';
import { ConfirmDialogComponent } from './confirm-dialog.component';


export interface IConfirmOptions {
  title?: string;
  message?: string;
  successHeader?: string;
  successMessage?: string;
  errorHeader?: string;
  errorMessage?: string;
}

@Injectable()
export class ConfirmDialogService {

  constructor(
    private ngxNotificationMsgService: NgxNotificationMsgService,
    private translate: TranslateService,
    private dialog: MatDialog
  ) {

  }

  public async confirm(options: IConfirmOptions, action: () => Promise<any>): Promise<boolean> {
    try {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: { title: options.title || 'confirm-delete', message: options.message || 'confirm-delete-message' }
      });
      const confirmed = await dialogRef.afterClosed().toPromise();
      if (!confirmed) { return false; }
      await action();
      this.ngxNotificationMsgService.open({
        status: NgxNotificationStatusMsg.SUCCESS, direction: NgxNotificationDirection.TOP_RIGHT, delay: 3000,
        header: await this.translate.get(options.successHeader || 'delete-success-header').toPromise(),
        messages: [await this.translate.get(options.successMessage || 'delete-success-message').toPromise()]
      });
      return true;
    } catch (err) {
      console.error(err);
      this.ngxNotificationMsgService.open({
        status: NgxNotificationStatusMsg.FAILURE, direction: NgxNotificationDirection.TOP_RIGHT, delay: 3000,
        header: await this.translate.get(options.errorHeader || 'error-header', { status: err.status }).toPromise(),
        messages: [await this.translate.get(options.errorMessage  || 'error-message', { status: err.status }).toPromise()]
      });
    }
    return false;
  }

}