/** @format */

import {propertyMap} from 'model-mapper';
import {Moment} from 'moment';
import {EmbededOrganizationalUnit} from './embeded-organizational-unit';
import {Family} from './family';
import {Home} from './home';

export class Hotline {
  @propertyMap()
  public phone: string;

  @propertyMap()
  public email: string;

  @propertyMap()
  public comment: string;
}

export class Contractor {
  @propertyMap({source: '_id'})
  public id: string;

  @propertyMap()
  public families: Family[];

  @propertyMap()
  public name: string;

  @propertyMap()
  public address: string;

  @propertyMap()
  public zipCode: string;

  @propertyMap()
  public city: string;

  @propertyMap()
  public email: string;

  @propertyMap()
  public phone: string;

  @propertyMap()
  public fax: string;

  @propertyMap()
  public leader: string;

  @propertyMap({default: []})
  public spaces: string[];

  @propertyMap({type: [EmbededOrganizationalUnit]})
  public perimeter: EmbededOrganizationalUnit[];

  @propertyMap({default: null})
  public home: Home;

  @propertyMap({type: Hotline})
  public hotline: Hotline;

  @propertyMap()
  public contractNumber: string;

  @propertyMap()
  public marketNumber: string;

  @propertyMap({type: 'Moment'})
  public marketStartDate: Moment;

  @propertyMap({type: 'Moment'})
  public marketEndDate: Moment;

  @propertyMap()
  public nexxioOriginKey: string;

  @propertyMap()
  public nexxioOriginId: string;

  @propertyMap()
  public nexxioMarketId: string;
}
