/** @format */

import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {get, map, round} from 'lodash-es';
import moment, {DurationInputArg2, Moment} from 'moment';
import {lastValueFrom} from 'rxjs';
import {debounceTime, filter, tap} from 'rxjs/operators';
import {BoilerRoomMetricCodes, fade} from 'sesio-lib';
import {SubSink} from 'subsink';
import {BoilerRoomService, IWeatherInfo} from '../../../_services/boiler-room.service';
import {SensorService} from '../../../_services/sensor.service';
import {IOptions} from '../../datagrid/datagrid.component';

@Component({
  selector: 'app-statistics-energy-fluid-space-group',
  templateUrl: './energy-fluid-space-group.component.html',
  styleUrls: ['./energy-fluid-space-group.component.scss'],
  animations: [fade],
})
export class StatisticsEnergyFluidSpaceGroupComponent implements OnInit, OnDestroy {
  @Input()
  public data: {id: string};

  public options: IOptions = {
    service: options => this.sensorService.datatable(options, {realEstateStructureId: this.data.id}),
    columns: [
      {
        label: "Type d'indicateur",
        searchable: true,
        sortable: true,
        property: 'kind',
        type: 'select',
        options: map(BoilerRoomMetricCodes, code => ({value: code, name: this.translate.instant(code)})),
      },
      {label: 'name', searchable: true, sortable: true, property: 'name', type: 'text'},
      {
        label: 'value',
        searchable: true,
        sortable: true,
        property: 'lastValue',
        type: 'text',
        displayWith: record => {
          const value = get(record, 'lastValue');
          switch (typeof value) {
            case 'number':
              return round(value, 2);
            default:
              return value;
          }
        },
      },
      {label: 'unit', searchable: true, sortable: true, property: 'lastValueUnit', type: 'text'},
      {label: 'code', searchable: true, sortable: true, property: 'code', type: 'text'},
      {label: 'date', searchable: true, sortable: true, property: 'lastValueDate', type: 'date', format: 'LLL'},
    ],
    showPagination: 'auto',
    enableRowNumber: false,
    enableStickyColumn: false,
    enableReorderColumn: false,
    enableHideShowColumns: false,
    enableExport: false,
    enableFullscreen: false,
    disableScrollbarModule: false,
    heightAuto: true,
  };

  public range = new UntypedFormGroup({
    start: new UntypedFormControl(moment().startOf('day')),
    end: new UntypedFormControl(moment().endOf('day')),
  });

  public zooms = ['1-day', '1-week', '1-month', '3-months', '6-months', '1-year'];
  public selectedZoom: string;
  public weatherInfo: IWeatherInfo;
  public tab = 0;

  public colors = {
    temperature: [
      {max: 19, value: 'var(--ion-color-secondary)'},
      {min: 19, max: 23, value: 'var(--ion-color-success)'},
      {min: 23, max: 25, value: 'var(--ion-color-accent)'},
      {min: 25, value: 'var(--ion-color-danger)'},
    ],
    humidity: [
      {max: 25, value: 'var(--ion-color-danger)'},
      {min: 25, max: 30, value: 'var(--ion-color-accent)'},
      {min: 30, max: 40, value: 'var(--ion-color-warning)'},
      {min: 40, max: 61, value: 'var(--ion-color-success)'},
      {min: 61, max: 70, value: 'var(--ion-color-warning)'},
      {min: 70, max: 75, value: 'var(--ion-color-accent)'},
      {min: 75, value: 'var(--ion-color-danger)'},
    ],
    airQuality: [
      {max: 800, value: 'var(--ion-color-success)'},
      {min: 800, max: 1201, value: 'var(--ion-color-warning)'},
      {min: 1201, max: 1401, value: 'var(--ion-color-accent)'},
      {min: 1401, value: 'var(--ion-color-danger)'},
    ],
    quality: [
      {min: 1, max: 2, value: '#4FF0E6'},
      {min: 2, max: 3, value: '#51CCAA'},
      {min: 3, max: 4, value: '#F0E641'},
      {min: 4, max: 5, value: '#FF5150'},
      {min: 5, max: 6, value: '#960132'},
      {min: 6, max: 7, value: '#7D2181'},
    ],
  };

  public qualities = ['good', 'medium', 'degraded', 'bad', 'very-bad', 'extremely-bad'];

  public forcast = false;

  private subsink = new SubSink();

  constructor(
    private sensorService: SensorService,
    private boilerRoomService: BoilerRoomService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    let previousValue = this.range.value;
    this.subsink.add(
      this.range.valueChanges
        .pipe(
          filter(value => {
            if (
              previousValue?.start?.valueOf() === value?.start?.valueOf() &&
              previousValue?.end?.valueOf() === value?.end?.valueOf()
            ) {
              return false;
            }
            previousValue = value;
            return true;
          }),
          tap(() => (this.selectedZoom = null)),
          debounceTime(300),
          tap(() => this.getWeatherInfo().then(info => (this.weatherInfo = info)))
        )
        .subscribe()
    );
    this.getWeatherInfo().then(info => (this.weatherInfo = info));
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  public changeZoom(zoom): void {
    let data: {amount: number; unit: DurationInputArg2};
    switch (zoom) {
      case '1-day':
        data = {amount: 1, unit: 'day'};
        break;
      case '1-week':
        data = {amount: 1, unit: 'week'};
        break;
      case '1-month':
        data = {amount: 1, unit: 'month'};
        break;
      case '3-months':
        data = {amount: 3, unit: 'month'};
        break;
      case '6-months':
        data = {amount: 6, unit: 'month'};
        break;
      case '1-year':
        data = {amount: 1, unit: 'year'};
        break;
      default:
        return;
    }
    const rangeValue = {
      start: moment().subtract(data.amount, data.unit).add(1, 'day').startOf('day'),
      end: moment().endOf('day'),
    };
    this.range.setValue(rangeValue);
    this.selectedZoom = zoom;
  }

  private getWeatherInfo(): Promise<IWeatherInfo> {
    if (
      !this.range.value.start ||
      !this.range.value.end ||
      (this.range.value.end as Moment).isBefore(this.range.value.start as Moment)
    ) {
      return Promise.resolve(this.weatherInfo);
    }
    return lastValueFrom(
      this.boilerRoomService.getWeatherInfo(this.data?.id, this.range.value.start, this.range.value.end)
    );
  }
}
