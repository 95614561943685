import { propertyMap } from 'model-mapper';
import { OrderStatus } from './order';

export class EmbededOrder {
  @propertyMap({ source: '_id' })
  public id: string;

  @propertyMap()
  public reference: string;

  @propertyMap()
  public externalReference: string;

  @propertyMap()
  public nexxioData: {
    nexxioDocumentId?: string;
    nexxioNumber?: string;
    nexxioOrderNumber?: string;
  };

  @propertyMap()
  public status: OrderStatus;
}
