/** @format */

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {
  AccessControlStatus,
  AggregationValue,
  EmbededContractor,
  AccessControlService as SesioLibAccessControlService,
} from 'sesio-lib';
import {buildHeaderSearchFilter, IHeaderSearch} from '../../../../sesio-webapp/src/app/_classes/header-search';
import {IDatatableOptions, IDatatableRecords} from '../_components/datagrid/datatable.class';

export interface IAccessControlFilter extends IHeaderSearch {
  statuses?: AccessControlStatus[];
}

interface IStatisticsFilter extends IHeaderSearch {
  contractorIds?: string[];
  year: number;
  janitorId?: string;
}

@Injectable({
  providedIn: 'root',
})
export class AccessControlService extends SesioLibAccessControlService {
  public datatable(
    query: IDatatableOptions,
    search: IAccessControlFilter,
    year?: number,
    archived?: boolean
  ): Observable<IDatatableRecords<any>> {
    return this.http.post<any>(`${this.config.environment.apiUrl}/access-controls/datatable`, {
      query,
      filter: this.buildSearchFilter(search),
      year,
      archived,
    });
  }

  public countByStatus(filter: IHeaderSearch): Observable<AggregationValue[]> {
    return this.http.post<any[]>(
      `${this.config.environment.apiUrl}/access-controls/count-by-status`,
      buildHeaderSearchFilter(filter)
    );
  }

  public getEvolution(
    filter: IHeaderSearch
  ): Observable<{kind: {name: string; translate: boolean}; data: {[month: string]: number}}[]> {
    return this.http.post<any[]>(
      `${this.config.environment.apiUrl}/access-controls/get-evolution`,
      buildHeaderSearchFilter(filter)
    );
  }

  public getStatistics(
    filter: IHeaderSearch,
    year: number
  ): Observable<{
    byStatus: {status: string; count: number}[];
    byKind: {kind: {name: string; translate: boolean}; count: number}[];
    byKindArchived: {kind: {name: string; translate: boolean}; count: number}[];
    map: {
      organizationalUnit: {name: string; coordinates: [number, number]};
      data: {kind: {name: string; translate: boolean}; count: number}[];
    }[];
    mapArchived: {
      organizationalUnit: {name: string; coordinates: [number, number]};
      data: {kind: {name: string; translate: boolean}; count: number}[];
    }[];
    evolution: {kind: {name: string; translate: boolean}; data: {[month: string]: number}}[];
  }> {
    return this.http.post<any>(
      `${this.config.environment.apiUrl}/access-controls/get-statistics`,
      buildHeaderSearchFilter(filter),
      {params: {year}}
    );
  }

  public getContractorStatistics(filter: IStatisticsFilter): Observable<
    {
      contractor: EmbededContractor;
      count: number;
    }[]
  > {
    return this.http.post<any>(
      `${this.config.environment.apiUrl}/access-controls/get-contractor-statistics`,
      this.buildStatisticFilter(filter)
    );
  }

  public getMeanOfAccessStatistics(filter: IStatisticsFilter): Observable<
    {
      name: string;
      count: number;
    }[]
  > {
    return this.http.post<any>(
      `${this.config.environment.apiUrl}/access-controls/get-mean-of-access-statistics`,
      this.buildStatisticFilter(filter)
    );
  }

  public getMeanOfAccessFollowup(filter: IStatisticsFilter): Observable<
    {
      name: string;
      values: {month: number; count: number}[];
    }[]
  > {
    return this.http.post<any>(
      `${this.config.environment.apiUrl}/access-controls/get-mean-of-access-followup`,
      this.buildStatisticFilter(filter)
    );
  }

  public getGroupStatistics(filter: IStatisticsFilter): Observable<
    {
      count: number;
      amount: number;
      rate: number;
      quantity: number;
      kinds: {
        name: string;
        count: number;
      }[];
      group: string;
      housingCount: string;
      livingSurface: number;
    }[]
  > {
    return this.http.post<any>(
      `${this.config.environment.apiUrl}/access-controls/get-group-statistics`,
      this.buildStatisticFilter(filter)
    );
  }

  public getJanitorStatistics(filter: IStatisticsFilter): Observable<
    {
      count: number;
      amount: number;
      rate: number;
      quantity: number;
      kinds: {
        name: string;
        count: number;
      }[];
      applicant: string;
      applicantId: string;
      groups: string[];
    }[]
  > {
    return this.http.post<any>(
      `${this.config.environment.apiUrl}/access-controls/get-janitor-statistics`,
      this.buildStatisticFilter(filter)
    );
  }

  public getGroupList(
    filter: IStatisticsFilter
  ): Observable<
    {delay: number; date: Date; esi: string; kind: string; quantity: number; amount: number; applicant: string}[]
  > {
    return this.http.post<any>(
      `${this.config.environment.apiUrl}/access-controls/get-group-list`,
      this.buildStatisticFilter(filter)
    );
  }

  public getJanitorList(
    filter: IStatisticsFilter
  ): Observable<
    {delay: number; date: Date; esi: string; kind: string; quantity: number; amount: number; group: string}[]
  > {
    return this.http.post<any>(
      `${this.config.environment.apiUrl}/access-controls/get-janitor-list`,
      this.buildStatisticFilter(filter)
    );
  }

  private buildStatisticFilter(filter: IStatisticsFilter) {
    const data = buildHeaderSearchFilter(filter);
    data.year = filter.year;
    if (filter.contractorIds?.length) data.contractorIds = filter.contractorIds;
    if (filter.janitorId) data.janitorId = filter.janitorId;
    return data;
  }

  public buildSearchFilter(search?: IAccessControlFilter): any {
    if (!search) return {};
    const filter = buildHeaderSearchFilter(search);
    if (search?.statuses?.length) filter.statuses = search.statuses;
    return filter;
  }
}
