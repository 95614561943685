import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  EquipmentDocumentService as SesioEquipmentDocumentService,
  Family,
} from 'sesio-lib';
import { IHeaderSearch } from '../_classes/header-search';
import {
  IDatatableOptions,
  IDatatableRecords,
} from '../_components/datagrid/datatable.class';

export interface IEquipmentDocumentFilter extends IHeaderSearch {
  families?: string[];
}

@Injectable({
  providedIn: 'root',
})
export class EquipmentDocumentervice extends SesioEquipmentDocumentService {
  public datatable(options: {
    families?: Family[];
    query: IDatatableOptions;
  }): Observable<IDatatableRecords<any>> {
    return this.http.post<any>(
      `${this.config.environment.apiUrl}/equipment-documents/datatable`,
      options
    );
  }
}
