/** @format */

import { head, get } from 'lodash-es';
import moment from 'moment';
import { Moment } from 'moment';

export class AggregationValue {
  public name: any;

  public value: any;
}

export function consolidateDayData(
  extremes: { start?: Moment; end?: Moment },
  unitGrouping: 'day' | 'hour',
  data: AggregationValue[]
): { count: number; maxValue: number; values: [number, number, number][] } {
  if (!data?.length) return { count: 0, maxValue: 0, values: [] };
  const values = [];
  let count = 1;
  let maxValue = 1;
  for (let entry of data) {
    count += get(entry, 'value', 0);
    maxValue = Math.max(maxValue, get(entry, 'value', 0));
    values.push([
      moment(get(entry, 'name')).startOf('day').valueOf(),
      unitGrouping === 'hour' ? moment(get(entry, 'name')).hour() : 0,
      get(entry, 'value', 0),
    ]);
  }
  return { count, maxValue, values };
}
