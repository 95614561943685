<div mat-dialog-title [style.position]="'relative'">
  <span color="primary" color="primary" translate>location</span>
  <button mat-icon-button mat-dialog-close [style.position]="'absolute'" [style.top.px]="-20" [style.right.px]="-20">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div mat-dialog-content [style.height]="'calc(100% - 104px)'" fxLayout="column">
  <mat-form-field [style.width.%]="100">
    <mat-label color="primary" translate>address</mat-label>
    <mat-icon color="primary" matPrefix>place</mat-icon>
    <input matInput autocomplete="off" type="text" [formControl]="addressControl" [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayOption" (optionSelected)="selected($event.option)">
      <mat-option *ngIf="searching">
        <div [style.position]="'relative'" [style.height.px]="48">
          <sesio-loader [style.--diameter]="'32px'"></sesio-loader>
        </div>
      </mat-option>
      <mat-option *ngFor="let option of options | async" [value]="option">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
          <span>{{option.address.formatted}}</span>
        </div>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <mat-form-field [style.width.%]="100">
    <mat-label color="primary" translate>additional-address</mat-label>
    <input matInput autocomplete="off" type="text" [formControl]="additionalControl">
  </mat-form-field>
  <app-map #map [style.height.%]="100"></app-map>
</div>
<div mat-dialog-actions fxLayoutAlign="end end" [style.width.%]="100">
  <button mat-raised-button color="primary" (click)="update()" translate>validate</button>
</div>
