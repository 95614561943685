<ion-header>
  <ion-toolbar color="primary">
    <ion-title>
      <ion-label *ngIf="!id" translate>Création document d'équipement</ion-label>
      <ion-label *ngIf="id" translate>Mise à jour document d'équipement</ion-label>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>


<ion-content class="ion-padding">
  <form *ngIf="!loading; else loadingTmpl" [formGroup]="formGroup">
    <mat-tab-group mat-align-tabs="start">
      <mat-tab label="Information" [disabled]="editEquipments">
        <ng-template matTabContent>
          <div class="content" fxLayout="column">
            <div fxLayout="row wrap">
              <mat-form-field [style.width.px]="300">
                <mat-label translate>family</mat-label>
                <mat-select formControlName="family" required>
                  <mat-option *ngFor="let family of families" [value]="family">
                    <mat-label translate>{{family}}</mat-label>
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="formGroup.get('family').errors && formGroup.get('family').errors.required">
                  <mat-label translate>required</mat-label>
                </mat-error>
              </mat-form-field>
              <mat-form-field [style.width.px]="300">
                <mat-label translate>kind</mat-label>
                <mat-select formControlName="kind" required>
                  <mat-option *ngFor="let kind of kinds" [value]="kind">
                    <mat-label translate>{{kind}}</mat-label>
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="formGroup.get('kind').errors && formGroup.get('kind').errors.required">
                  <mat-label translate>required</mat-label>
                </mat-error>
              </mat-form-field>
              <mat-form-field [style.width.px]="500">
                <mat-label translate>title</mat-label>
                <input matInput formControlName="title" />
              </mat-form-field>
            </div>
            <mat-form-field class="expand">
              <mat-label translate>description</mat-label>
              <textarea matInput formControlName="description"></textarea>
            </mat-form-field>
            <mat-form-field class="expand">
              <ngx-mat-file-input [formControl]="fileCtrl" placeholder="File"></ngx-mat-file-input>
              <mat-icon matSuffix>file_present</mat-icon>
            </mat-form-field>
            <app-document [file]="file"></app-document>
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab [disabled]="!formGroup.get('family').value">
        <ng-template mat-tab-label>
          <mat-label [matBadge]="equipments?.length" [matBadgeOverlap]="false">
            Équipements
          </mat-label>
        </ng-template>
        <ng-template matTabContent>
          <button @fade *ngIf="!editEquipments" id="edit" mat-icon-button color="primary"
            (click)="editEquipments = true">
            <mat-icon>edit</mat-icon>
          </button>
          <mat-list @fade *ngIf="!editEquipments">
            <mat-list-item *ngFor="let equipment of equipments">
              <div mat-line>{{equipment.kind.name}} - {{equipment.reference}}</div>
              <div mat-line>
                {{equipment.organizationalUnit?.pathNames?.join(' > ')}}
              </div>
            </mat-list-item>
          </mat-list>
          <app-datagrid @fade *ngIf="editEquipments" #datagrid [options]="options">
            <sesio-loader datagrid-spinner></sesio-loader>
            <ng-template appDatagridCell="group" let-cell="cell">
              <app-organizational-unit-datagrid-cell [cell]="cell"></app-organizational-unit-datagrid-cell>
            </ng-template>
          </app-datagrid>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </form>
</ion-content>


<ion-footer>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button color="accent" fill="solid" cdkFocusInitial [disabled]="updating" (click)="dismiss()" translate>cancel
      </ion-button>
      <ion-button color="primary" fill="solid" [disabled]="loading || updating || editEquipments || !formGroup?.valid"
        (click)="edit()" translate>
        {{id ? 'update' : 'create'}}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>

<sesio-loader *ngIf="updating" @fadeOut [backdrop]="true"></sesio-loader>

<ng-template #loadingTmpl>
  <sesio-loader @fadeOut></sesio-loader>
</ng-template>
