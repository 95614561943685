import { propertyMap } from 'model-mapper';
import { Moment } from 'moment';
import { EmbededRealEstateStructure } from './real-estate-structure';
import { EmbededOrganizationalUnit } from './embeded-organizational-unit';
import { File } from './file';
import { EmbededContractor } from './embeded-contractor';
import { Location } from './location';
import { EmbededEquipment } from './equipment';
import { EmbededIntervention } from './embeded-intervention';
import {
  InspectionStatus,
  InspectionState,
  InspectionEquipmentStatus,
} from './inspection-status';
import { EquipmentKindFireSafetyCategory } from './equipment-kind';

export enum InspectionFileKind {
  VISITING_REPORT = 'visiting-report',
  INTERVENTION_REPORT = 'intervention-report',
  WORK_ORDER = 'work-order',
  SAFETY_STUDY = 'safety-study',
  INVENTORY_STATEMENT = 'inventory-statement',
  QUOTE = 'quote',
  PHOTO = 'photo',
  OTHER = 'other',
}

export const InspectionStatusColor = {
  [InspectionStatus.INFEASIBLE]: '#9564E2',
  [InspectionStatus.OUT_OF_TIME]: 'var(--ion-color-danger)',
  [InspectionStatus.LATE]: 'var(--ion-color-warning)',
  [InspectionStatus.NEW]: 'var(--ion-color-dark)',
  [InspectionStatus.PLANNED]: 'var(--ion-color-medium)',
  [InspectionStatus.WIP]: 'var(--ion-color-primary)',
  [InspectionStatus.TO_VALIDATE]: 'var(--ion-color-secondary)',
  [InspectionStatus.DONE]: 'var(--ion-color-success)',
  [InspectionStatus.CANCELED]: '#ccc',
};

export const InspectionStateOptions = {
  [InspectionState.DANGER]: {
    color: '#E7235B',
    icon: 'report_problem',
    tooltip: {
      inspection: 'VR présentant au moins un danger',
      equipment: 'Équipements présentant un danger',
      realEstateStructure: 'ESI présentant un danger',
    },
  },
  [InspectionState.ANOMALY]: {
    color: '#FF7F00',
    icon: 'error',
    tooltip: {
      inspection: 'VR présentant une anomalie',
      equipment: 'Équipements présentant une anomalie',
      realEstateStructure: 'ESI présentant une anomalie',
    },
  },
  [InspectionState.OK]: {
    color: '#0D9E8A',
    icon: 'check_circle',
    tooltip: {
      inspection: 'VR OK',
      equipment: 'Équipements OK',
      realEstateStructure: 'ESI OK',
    },
  },
  [InspectionState.LATE]: {
    color: '#AAAAAA',
    icon: 'alarm',
    tooltip: {
      inspection: 'VR en retard',
      equipment: 'Équipements en retard de vérification',
      realEstateStructure: 'ESI en retard de vérification',
    },
  },
  [InspectionState.KO]: {
    color: '#000000',
    icon: 'close',
    tooltip: {
      inspection: 'VR hors délai',
      equipment: 'Équipements hors délai de vérification',
      realEstateStructure: 'ESI hors délai de vérification',
    },
  },
};

export class InspectionEquipmentReport {
  @propertyMap({ source: '_id' })
  public id: string;

  @propertyMap()
  public equipmentId: string;

  @propertyMap()
  public status: InspectionEquipmentStatus;

  @propertyMap({ type: 'Moment' })
  public startDate: Moment;

  @propertyMap({ type: 'Moment' })
  public endDate?: Moment;

  @propertyMap({ type: [EmbededIntervention] })
  public interventions: EmbededIntervention[];

  @propertyMap()
  public comment: string;

  @propertyMap()
  public technicianName: string;

  @propertyMap({ type: [File] })
  public files: File[];
}

export class InspectionConfig {
  @propertyMap({ type: 'Moment' })
  public deadline: Moment;

  @propertyMap({ type: 'Moment' })
  public activeAt: Moment;

  @propertyMap()
  public lateOffset: number;

  @propertyMap()
  public outOfTimeOffset: number;
}

export class InspectionInfo {
  @propertyMap({ type: 'Moment' })
  public plannedDate: Moment;

  @propertyMap({ type: 'Moment' })
  public startDate: Moment;

  @propertyMap({ type: 'Moment' })
  public endDate: Moment;

  @propertyMap()
  public duration: number;

  @propertyMap()
  public anomalyCount: number;

  @propertyMap()
  public dangerCount: number;

  @propertyMap()
  public controlRate: number;

  @propertyMap()
  public qualityIndex: number;

  @propertyMap()
  public state: InspectionState;
}

export class InspectionPeriodicity {
  @propertyMap()
  public kind: 'none' | 'interval' | 'dates';

  @propertyMap()
  public interval: string;

  @propertyMap()
  public dates: string[];

  @propertyMap()
  public activeDate: string;
}

export class InspectionMetadata {
  @propertyMap()
  public externalReference: string;

  @propertyMap()
  public esi: string;

  @propertyMap()
  public managerName: string;

  @propertyMap()
  public staircaseNumber: string;

  @propertyMap()
  public psc: string;

  @propertyMap()
  public stairsFamily: string;

  @propertyMap()
  public stairsKind: string;

  @propertyMap()
  public comment: string;
}

export class Inspection {
  @propertyMap({ source: '_id' })
  public id: string;

  @propertyMap()
  public reference: string;

  @propertyMap()
  public label: string;

  @propertyMap()
  public status: InspectionStatus;

  @propertyMap({ type: EmbededOrganizationalUnit })
  public organizationalUnit: EmbededOrganizationalUnit;

  @propertyMap({ type: EmbededRealEstateStructure })
  public realEstateStructure: EmbededRealEstateStructure;

  @propertyMap({ default: [] })
  public equipmentCategories: EquipmentKindFireSafetyCategory[];

  @propertyMap({ type: EmbededContractor })
  public contractor: EmbededContractor;

  @propertyMap({ type: Location })
  public location: Location;

  @propertyMap({ type: [EmbededEquipment] })
  public equipments: EmbededEquipment[];

  @propertyMap({ type: [InspectionEquipmentReport] })
  public reports: InspectionEquipmentReport[];

  @propertyMap({ type: [File] })
  public files: File[];

  @propertyMap({ type: InspectionConfig })
  public config: InspectionConfig;

  @propertyMap({ type: InspectionInfo })
  public info: InspectionInfo;

  @propertyMap({ type: InspectionPeriodicity })
  public periodicity: InspectionPeriodicity;

  @propertyMap({ type: InspectionMetadata })
  public metadata: InspectionMetadata;
}
