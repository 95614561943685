/** @format */

import {Pipe, PipeTransform} from '@angular/core';
import humanizeDuration from 'humanize-duration';
import {merge} from 'lodash-es';
import {Moment} from 'moment';

type InputData = number | {start: Date | number | Moment; end?: Date | number | Moment};
@Pipe({name: 'humanizeDuration'})
export class HumanizeDurationPipe implements PipeTransform {
  transform(data: InputData, options?: humanizeDuration.Options): string | undefined {
    if (!data) return;
    if (typeof data === 'number') return humanizeDuration(data, merge({language: 'fr', maxDecimalPoints: 0}, options));
    const from = typeof data.start === 'number' ? data.start : data.start.valueOf();
    const to = !data.end ? Date.now() : typeof data.end === 'number' ? data.end : data.start.valueOf();
    const diff = to - from;
    return humanizeDuration(diff, merge({language: 'fr', maxDecimalPoints: 0}, options));
  }
}
