/** @format */

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {IonicModule} from '@ionic/angular';
import {AbsolutePipe} from './absolute.pipe';
import {AveragePipe} from './average.pipe';
import {CountPipe} from './count.pipe';
import {DatePipe} from './date.pipe';
import {FilterPipe} from './filter.pipe';
import {FindPipe} from './find.pipe';
import {GetPipe} from './get.pipe';
import {HeadPipe} from './head.pipe';
import {HumanizeDurationPipe} from './humanize-duration.pipe';
import {IncludesPipe} from './includes.pipe';
import {MomentDurationPipe} from './moment-duration.pipe';
import {MomentPipe} from './moment.pipe';
import {NumberMatchPipe} from './number-match.pipe';
import {ReplacePipe} from './replace.pipe';
import {SincePipe} from './since.pipe';
import {SplitPipe} from './split.pipe';
import {StripPipe} from './strip.pipe';
import {ToStringPipe} from './to-string.pipe';
import {UnwindPipe} from './unwind.pipe';
import { LastPipe } from './last.pipe';
import { GreaterThanPipe } from './greater-than.pipe';

@NgModule({
  declarations: [
    AbsolutePipe,
    NumberMatchPipe,
    GetPipe,
    HeadPipe,
    FindPipe,
    MomentPipe,
    CountPipe,
    FilterPipe,
    MomentDurationPipe,
    HumanizeDurationPipe,
    AveragePipe,
    ToStringPipe,
    SplitPipe,
    UnwindPipe,
    IncludesPipe,
    ReplacePipe,
    StripPipe,
    SincePipe,
    DatePipe,
    LastPipe,
    GreaterThanPipe
  ],
  imports: [CommonModule, IonicModule],
  exports: [
    AbsolutePipe,
    NumberMatchPipe,
    GetPipe,
    HeadPipe,
    FindPipe,
    MomentPipe,
    CountPipe,
    FilterPipe,
    MomentDurationPipe,
    HumanizeDurationPipe,
    AveragePipe,
    ToStringPipe,
    SplitPipe,
    UnwindPipe,
    IncludesPipe,
    ReplacePipe,
    StripPipe,
    SincePipe,
    DatePipe,
    LastPipe,
    GreaterThanPipe
  ],
})
export class PipesModule {}
