/** @format */

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Moment} from 'moment';
import {Observable, map} from 'rxjs';
import {environment} from '../../environments/environment';
import {IDatatableOptions, IDatatableRecords} from '../_components/datagrid/datatable.class';

@Injectable({
  providedIn: 'root',
})
export class IntentService {
  constructor(private http: HttpClient) {}

  public getOperations(reference: string, start: Moment, end?: Moment): Observable<any[]> {
    const params: any = {reference, start: start.toISOString()};
    if (end) params.end = end.toISOString();
    return this.http.get<any[]>(`${environment.apiUrl}/intent/operations`, {
      params,
    });
  }

  public hasInProgressOperation(reference: string, date: Moment): Observable<boolean> {
    return this.http.get<boolean>(`${environment.apiUrl}/intent/has-in-progress-operation`, {
      params: {reference, date: date.toISOString()},
    });
  }

  public getMaintenances12Months(id: string): Observable<any[]> {
    const params: any = {equipmentId: id};
    return this.http.get<any[]>(`${environment.apiUrl}/intent/maintenances/12-months`, {
      params,
    });
  }

  public datatable(options: {
    equipmentReference: string;
    kind: string;
    query: IDatatableOptions;
    operationReference?: string;
  }): Observable<IDatatableRecords<any>> {
    return this.http.post<any>(`${environment.apiUrl}/intent/datatable`, options);
  }
}
