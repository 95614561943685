import { Inject, Injectable } from '@angular/core';
import { Contractor } from '../classes/contractor';
import { Observable } from 'rxjs';
import { last, map, mergeMap, tap } from 'rxjs/operators';
import { ModelMapper } from 'model-mapper';
import { set } from 'lodash-es';
import { LibConfig, LibConfigService } from '../config.service';
import { HttpClient, HttpProgressEvent } from '@angular/common/http';
import { Faq, Tutorial } from '../classes/help';

@Injectable({
  providedIn: 'root'
})
export class HelpService {

  constructor(
    @Inject(LibConfigService) protected config: LibConfig,
    protected http: HttpClient
  ) { }

  public tutorials(platform?: string, page?: string): Observable<Tutorial[]> {
    const params: any = {};
    if (platform) { params.platform = platform; }
    if (page) { params.page = page; }
    return this.http.get<any[]>(`${this.config.environment.apiUrl}/tutorials`, { params })
      .pipe(map(data => data.map(d => new ModelMapper(Tutorial).map(d))));
  }

  public createTutorial(data: any): Observable<Tutorial> {
    return this.http.post<any>(`${this.config.environment.apiUrl}/tutorials`, data)
      .pipe(map(data => new ModelMapper(Tutorial).map(data)));
  }

  public updateTutorial(id: string, data: any[]): Observable<boolean> {
    return this.http.patch<boolean>(`${this.config.environment.apiUrl}/tutorials/${id}`, data);
  }

  public moveTutorial(id: string, from: number, to: number): Observable<boolean> {
    return this.http.patch<boolean>(`${this.config.environment.apiUrl}/tutorials/${id}/move`, { from, to });
  }

  public pushTutorialFile(file: File, progress?: (event: HttpProgressEvent) => void): Observable<string> {
    return this.http.get(`${this.config.environment.apiUrl}/tutorials/upload-url`, { params: { name: file.name, type: file.type }, responseType: 'text' })
      .pipe(
        mergeMap(url => {
          return this.http.put(url, file, {
            reportProgress: true, observe: 'events',
            headers: {
              'Content-Type': file.type,
              'Content-Disposition': `attachment; filename=${encodeURIComponent(file.name)}`
            }
          }).pipe(tap((event: HttpProgressEvent) => progress ? progress(event) : null));
        }),
        last(),
        map(() => `help/tutorials/${file.name}`)
      );
  }

  public faqs(): Observable<Faq[]> {
    return this.http.get<any[]>(`${this.config.environment.apiUrl}/faqs`)
      .pipe(map(data => data.map(d => new ModelMapper(Faq).map(d))));
  }

  public createFaq(data: any): Observable<Faq> {
    return this.http.post<any>(`${this.config.environment.apiUrl}/faqs`, data)
      .pipe(map(data => new ModelMapper(Faq).map(data)));
  }

  public updateFaq(id: string, data: any): Observable<boolean> {
    return this.http.patch<boolean>(`${this.config.environment.apiUrl}/faqs/${id}`, data);
  }

  public moveFaq(id: string, from: number, to: number): Observable<boolean> {
    return this.http.patch<boolean>(`${this.config.environment.apiUrl}/faqs/${id}/move`, { from, to });
  }

  public deleteFaqFile(path: string): Observable<any> {
    const args = /^.*\/faqs\/([^\/]+)\/(.*)$/.exec(path);
    return this.http.delete(`${this.config.environment.apiUrl}/faqs/file`, { params: { folder: args[0], name: args[1] } })
  }

  public pushFaqFile(folder: string, file: File, progress?: (event: HttpProgressEvent) => void): Observable<string> {
    let path;
    return this.http.get(`${this.config.environment.apiUrl}/faqs/upload-url`, { params: { folder, name: file.name, type: file.type }, responseType: 'text' })
      .pipe(
        mergeMap(url => {
          return this.http.put(url, file, {
            reportProgress: true, observe: 'events',
            headers: {
              'Content-Type': file.type,
              'Content-Disposition': `attachment; filename=${encodeURIComponent(file.name)}`
            }
          }).pipe(tap((event: HttpProgressEvent) => progress ? progress(event) : null));
        }),
        last(),
        map(() => `help/faqs/${folder}/${file.name}`)
      );
  }

}
