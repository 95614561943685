/** @format */

import {Pipe, PipeTransform} from '@angular/core';
import striptags from 'striptags';

@Pipe({name: 'strip'})
export class StripPipe implements PipeTransform {
  transform(data: string): any {
    return striptags(data);
  }
}
