/** @format */

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {IElevatorSignatureSettings} from '../_classes/settings';
import {IDatatableOptions, IDatatableRecords} from '../_components/datagrid/datatable.class';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(private http: HttpClient) {}

  public getElevatorSignatureV2Settings(): Observable<IElevatorSignatureSettings> {
    return this.http.get<any>(`${environment.apiUrl}/settings/elevator-signature-v2`);
  }

  public updateElevatorSignatureV2Settings(data: {offline: number; suspicious: number}): Observable<boolean> {
    return this.http.patch<boolean>(`${environment.apiUrl}/settings/elevator-signature-v2`, data);
  }

  public datatable(query: IDatatableOptions): Observable<IDatatableRecords<any>> {
    return this.http.post<any>(`${environment.apiUrl}/settings/datatable`, query);
  }
}
