/** @format */

import { propertyMap } from 'model-mapper';
import { Family } from './family';

export class EquipmentManager {
  @propertyMap({ source: '_id' })
  public id: string;

  @propertyMap()
  public name: string;

  @propertyMap()
  public families: Family[];

  @propertyMap({ default: false })
  public default: boolean;
}

export class EmbededEquipmentManager {
  @propertyMap({ source: '_id' })
  public id: string;

  @propertyMap()
  public name: string;

  @propertyMap({ default: false })
  public default: boolean;
}
