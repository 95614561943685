<div id="container" fxLayoutAlign="start center">
  <svg *ngIf="!shrink" xmlns="http://www.w3.org/2000/svg" height="100%" viewBox="0 0 2560 512">
    <path d="M480 208H308L256 48l-52 160H32l140 96-54 160 138-100 138 100-54-160z" stroke-linejoin="round"
      stroke-width="20" />
    <path transform="translate(512, 0)" d="M480 208H308L256 48l-52 160H32l140 96-54 160 138-100 138 100-54-160z"
      stroke-linejoin="round" stroke-width="20" />
    <path transform="translate(1024, 0)" d="M480 208H308L256 48l-52 160H32l140 96-54 160 138-100 138 100-54-160z"
      stroke-linejoin="round" stroke-width="20" />
    <path transform="translate(1536, 0)" d="M480 208H308L256 48l-52 160H32l140 96-54 160 138-100 138 100-54-160z"
      stroke-linejoin="round" stroke-width="20" />
    <path transform="translate(2048, 0)" d="M480 208H308L256 48l-52 160H32l140 96-54 160 138-100 138 100-54-160z"
      stroke-linejoin="round" stroke-width="20" />
    <g id="value" [style.clip-path]="'polygon(0% 0%, ' + percent + '% 0%, ' + percent + '% 100%, 0% 100%)'">
      <path d="M480 208H308L256 48l-52 160H32l140 96-54 160 138-100 138 100-54-160z" stroke-linejoin="round"
        stroke-width="20" />
      <path transform="translate(512, 0)" d="M480 208H308L256 48l-52 160H32l140 96-54 160 138-100 138 100-54-160z"
        stroke-linejoin="round" stroke-width="20" />
      <path transform="translate(1024, 0)" d="M480 208H308L256 48l-52 160H32l140 96-54 160 138-100 138 100-54-160z"
        stroke-linejoin="round" stroke-width="20" />
      <path transform="translate(1536, 0)" d="M480 208H308L256 48l-52 160H32l140 96-54 160 138-100 138 100-54-160z"
        stroke-linejoin="round" stroke-width="20" />
      <path transform="translate(2048, 0)" d="M480 208H308L256 48l-52 160H32l140 96-54 160 138-100 138 100-54-160z"
        stroke-linejoin="round" stroke-width="20" />
    </g>
  </svg>
  <svg *ngIf="shrink" xmlns="http://www.w3.org/2000/svg" height="100%" viewBox="0 0 512 512">
    <path d="M480 208H308L256 48l-52 160H32l140 96-54 160 138-100 138 100-54-160z" stroke-linejoin="round"
      stroke-width="20" />
    <path id="value" d="M480 208H308L256 48l-52 160H32l140 96-54 160 138-100 138 100-54-160z" stroke-linejoin="round"
      stroke-width="20" [style.clip-path]="'ellipse(' + (percent / 2) + '% ' + (percent / 2) + '% at 50% 56%)'" />
    <text text-anchor="middle" dominant-baseline="middle" font-size="7.5em" x="50%"
      y="56%">{{value | number:'1.0-1'}}</text>
  </svg>
</div>
