/** @format */

import { AfterViewInit, ChangeDetectorRef, Component, forwardRef, Injector, Input, OnDestroy } from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  NgControl,
  Validators,
  NG_VALUE_ACCESSOR,
  FormControl,
  AbstractControl,
} from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { get, isEqual, isNil, trim } from 'lodash-es';
import { IntersectionObserverEvent } from 'ngx-intersection-observer/lib/intersection-observer-event.model';
import { NGXLogger } from 'ngx-logger';
import { debounceTime, exhaustMap, filter, Observable, scan, startWith, Subject, switchMap, tap } from 'rxjs';
import { SubSink } from 'subsink/dist/subsink';
import { EquipmentKind, fade, Family } from 'sesio-lib';
import { EquipmentKindService } from '../../_services/equipment-kind.service';

@Component({
  selector: 'app-equipment-kind-select',
  templateUrl: './equipment-kind-select.component.html',
  styleUrls: ['./equipment-kind-select.component.scss'],
  animations: [fade],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => EquipmentKindSelectComponent),
    },
  ],
})
export class EquipmentKindSelectComponent implements AfterViewInit, OnDestroy, ControlValueAccessor {
  ready = false;

  @Input('limit')
  set setLimitInput(limit: number) {
    this.setLimit(limit);
  }
  limit: number = 10;

  @Input()
  family: Family;

  @Input()
  appearance: MatFormFieldAppearance = 'outline';

  @Input('required')
  set setRequired(isRequired: boolean) {
    this.isRequired = isRequired === false ? false : true;
    if (this.control) this.setValidators();
  }
  isRequired = false;

  @Input('value')
  set setValue(value: EquipmentKind) {
    if (this.control && !isEqual(this.value, value)) {
      this.value = value;
      this.selectControl.setValue(value);
    }
  }
  value: EquipmentKind | null;

  @Input('disabled')
  set setDisabled(isDisabled: any) {
    this.setDisabledState(isDisabled !== false);
  }
  isDisabled: boolean;

  @Input('label')
  label: string;

  @Input('multiple')
  set setMultiple(multiple: boolean | string) {
    this.multiple = typeof multiple === 'boolean' ? multiple : !isNil(multiple);
  }
  multiple: boolean;

  selectControl = new FormControl();
  control: FormControl<EquipmentKind | null>;

  searching = false;
  options$: Observable<EquipmentKind[]>;
  searchMatched: number;

  private subsink = new SubSink();

  public onChange = () => {};

  public onTouched = () => {};

  constructor(
    private logger: NGXLogger,
    private injector: Injector,
    private changeDetectorRef: ChangeDetectorRef,
    private equipmentKindService: EquipmentKindService
  ) {}

  writeValue(value: EquipmentKind): void {
    if (typeof value !== 'string' && this.control && !isEqual(this.control.value, value)) {
      this.selectControl.setValue(value);
    }
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  async ngAfterViewInit(): Promise<void> {
    const ngControl: NgControl | null = this.injector.get(NgControl, null);
    if (ngControl) {
      this.control = ngControl.control as UntypedFormControl;
      this.isRequired = this.control.hasValidator(Validators.required);
      this.value = this.control.value;
    } else {
      this.control = new UntypedFormControl({
        value: this.value,
        disabled: this.isDisabled,
      });
    }
    this.setValidators();
    this.selectControl.setValue(this.control.value);
    this.subsink.add(
      this.selectControl.valueChanges.subscribe(async value => {
        if (typeof value !== 'string') this.control.setValue(value);
      })
    );
    this.buildOptions();
    this.ready = true;
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  displayOption(option: EquipmentKind): string {
    return option?.name;
  }

  setLimit(limit: number) {
    this.limit = limit;
    if (this.selectControl) {
      this.selectControl.setValue(typeof this.selectControl.value === 'string' ? this.selectControl.value : '');
    }
  }

  loadMore($event: IntersectionObserverEvent) {
    if (!$event.intersect) return;
    this.nextPage$.next();
  }

  onfocus() {
    if (!this.selectControl.value || typeof this.selectControl.value === 'string') {
      this.selectControl.setValue(get(this.selectControl.value, 'name', ''));
    }
  }

  private nextPage$ = new Subject<void>();
  private buildOptions() {
    const filter$: Observable<string> = this.selectControl.valueChanges.pipe(
      filter(value => typeof value === 'string'),
      debounceTime(300),
      tap(() => (this.searching = true))
    );
    this.options$ = filter$.pipe(
      switchMap((search: string) => {
        let skip = 0;
        return this.nextPage$.pipe(
          startWith(skip),
          exhaustMap(() =>
            this.equipmentKindService.search(this.limit, skip, {
              search: trim(search),
              families: this.family ? [this.family] : undefined,
            })
          ),
          tap(() => skip++),
          tap(list => (this.searchMatched = list.matchCount)),
          scan((data, list) => data.concat(list.data), [] as EquipmentKind[])
        );
      }),
      tap(() => (this.searching = false))
    );
  }

  private setValidators() {
    const validators = [];
    if (this.isRequired) validators.push(Validators.required);
    validators.push(this.validateEntity);
    this.control.setValidators(validators);
    this.control.updateValueAndValidity();
    this.setSelectValidators();
  }

  private setSelectValidators() {
    const validators = [];
    if (this.isRequired) validators.push(Validators.required);
    validators.push(this.validateEntity);
    this.selectControl.setValidators(validators);
    this.selectControl.updateValueAndValidity();
  }

  private validateEntity = (control: AbstractControl): { [key: string]: any } | null => {
    const forbidden = control.value && !control.value?.id;
    return forbidden ? { invalidEntity: { value: control.value } } : null;
  };
}
