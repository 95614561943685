/** @format */

import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {get, join, map, concat} from 'lodash-es';
import moment from 'moment';
import {lastValueFrom, Observable} from 'rxjs';
import {Equipment} from 'sesio-lib';
import {EquipmentReportService} from '../../../_services/equipment-report.service';
import {EquipmentService} from '../../../_services/equipment.service';

import {IntentService} from '../../../_services/intent.service';
import {DatagridComponent, IOptions} from '../../datagrid/datagrid.component';
import {IRecord} from '../../datagrid/datasource';
import {IDatatableOptions, IDatatableRecords} from '../../datagrid/datatable.class';
import {OperationLogComponent} from './operation-log/operation-log.component';

@Component({
  selector: 'app-intent-modal',
  templateUrl: './intent-modal.component.html',
  styleUrls: ['./intent-modal.component.scss'],
})
export class IntentModalComponent implements OnInit {
  @Input()
  id: string;

  @Input()
  assetReference: string;

  legacy: number;

  @Input()
  operation: number;

  @Input()
  event: number;

  @Input()
  tab: number = 2;

  @Input()
  historique = true;

  public legacyOptions: IOptions = {
    pagination: {default: 2, options: [10, 20, 50, 100]},
    service: (options: IDatatableOptions): Observable<IDatatableRecords<IRecord>> =>
      this.equipmentReportService.legacyDatatable({equipmentId: this.id, query: options}),
    columns: [
      {property: 'contractor.name', label: 'Prestataire', searchable: true, sortable: true},
      {property: 'providerElevatorName', label: 'N° Appareil', searchable: true, sortable: true},
      {
        property: 'offlineDuration',
        label: "Durée d'indisponibilité",
        displayWith: (record: any) =>
          record.offlineDuration ? moment.duration(record.offlineDuration).humanize() : '-',
        searchable: true,
        sortable: true,
      },
      {property: 'failureCause', label: 'Motif', searchable: true, sortable: true},
      {property: 'interventionCause', label: 'Cause', searchable: true, sortable: true},
      {
        property: 'failureReportedAt',
        label: 'Panne ou usagé bloqué',
        type: 'date',
        format: 'L LT',
        searchable: true,
        sortable: true,
      },
      {
        property: 'recoveryDate',
        label: 'Rétablissement',
        type: 'date',
        format: 'L LT',
        searchable: true,
        sortable: true,
      },
      {property: 'stopDate', label: "Mise à l'arrêt", type: 'date', format: 'L LT', searchable: true, sortable: true},
      {
        property: 'extricationDate',
        label: 'Désincarcération',
        type: 'date',
        format: 'L LT',
        searchable: true,
        sortable: true,
      },
      {property: 'comment', label: 'Commentaire', searchable: true, sortable: true},
    ],
    enableRowNumber: false,
    enableStickyColumn: false,
    enableReorderColumn: false,
    enableSelect: false,
    enableHideShowColumns: false,
    enableExport: true,
    enableFullscreen: false,
    disableScrollbarModule: true,
    sortedColumns: [{column: 5, dir: 'desc'}],
  };

  public operationOptions: IOptions = {
    pagination: {default: 2, options: [10, 20, 50, 100]},
    service: (options: IDatatableOptions): Observable<IDatatableRecords<IRecord>> =>
      this.service.datatable({equipmentReference: this.assetReference, kind: 'operation', query: options}),
    columns: [
      {property: 'value.location.assetReference', label: 'Référence', searchable: true, sortable: true},
      {property: 'value.reference', label: 'Ref. opération', searchable: true, sortable: true},
      {property: 'value.issuer.entity.label', label: 'Prestataire', searchable: true, sortable: true},
      {property: 'value.issuer.user.name', label: 'Technicien', searchable: true, sortable: true},
      {property: 'value.description', label: 'Description', searchable: true, sortable: true},
      {property: 'value.equipmentStatus', label: "État de l'équipement", searchable: true, sortable: true},
      {property: 'value.replacingEquipment', label: 'Équipement de remplacement', searchable: true, sortable: true},
      {
        property: 'value.suggestedProvisionalDate',
        label: 'Date de remplacement estimée',
        searchable: true,
        sortable: true,
      },
      {
        property: 'value.technicalReason',
        label: 'Raison technique',
        displayWith: (record: any) => this.translate.instant('intent-' + record.value.technicalReason),
        searchable: true,
        sortable: true,
      },
      {property: 'value.type', label: 'Type', searchable: true, sortable: true},
      {property: 'value.service.code', label: 'Code du service', searchable: true, sortable: true},
      {property: 'value.service.label', label: 'Label du service', searchable: true, sortable: true},
      {property: 'value.service.description', label: 'Description du service', searchable: true, sortable: true},
      {
        property: 'value.service.worktype',
        label: 'Prestation du service',
        displayWith: (record: any) => this.translate.instant('intent-' + record.value.service.workType),
        searchable: true,
        sortable: true,
      },
      {
        property: 'value.workType',
        label: 'Prestation',
        displayWith: (record: any) => this.translate.instant('intent-' + record.value.workType),
        searchable: true,
        sortable: true,
      },
      {
        property: 'value.status',
        label: 'État',
        displayWith: (record: any) => this.translate.instant(record.value.status, {gender: 'female'}),
        searchable: true,
        sortable: true,
      },
      {
        property: 'value.event',
        label: 'Évènement',
        displayWith: (record: any) => this.translate.instant(`intent-${record.value.status}-${record.value.event}`),
        searchable: true,
        sortable: true,
      },
      {property: 'value.firstEventDate', label: 'Date du premier évènement', searchable: true, sortable: true},
      {
        property: 'value.creationDate',
        label: 'Date de création',
        searchable: true,
        sortable: true,
        type: 'date',
        format: 'L LT',
      },
      {
        property: 'value.eventDate',
        label: "Date de l'évènement",
        searchable: true,
        sortable: true,
        type: 'date',
        format: 'L LT',
      },
      {
        property: 'value.responseTime',
        label: 'Temps de réponse',
        searchable: true,
        sortable: true,
        displayWith: (record: any) =>
          record.value?.responseTime ? moment.duration(record.value.responseTime).humanize() : '-',
      },
      {
        property: 'value.processingDuration',
        label: "Durée de l'intervention",
        searchable: true,
        sortable: true,
        displayWith: (record: any) =>
          record.value?.processingDuration ? moment.duration(record.value.processingDuration).humanize() : '-',
      },
      {
        property: 'value.resolutionDuration',
        label: 'Durée de résolution',
        searchable: true,
        sortable: true,
        displayWith: (record: any) =>
          record.value?.resolutionDuration ? moment.duration(record.value.resolutionDuration).humanize() : '-',
      },
      {
        property: 'value.photos',
        label: 'Photos',
        searchable: true,
        sortable: true,
        displayWith: (record: any) => join(map(get(record, 'photos', []), 'original'), ', '),
      },
    ],
    enableRowNumber: false,
    enableStickyColumn: false,
    enableReorderColumn: false,
    enableSelect: false,
    enableHideShowColumns: false,
    enableExport: true,
    enableFullscreen: false,
    disableScrollbarModule: true,
    sortedColumns: [{column: 18, dir: 'desc'}],
    rowClick: async (datagrid: DatagridComponent, record: any) => {
      const modal = await this.modalController.create({
        component: OperationLogComponent,
        cssClass: 'xwide-modal',
        componentProps: {
          assetReference: this.assetReference,
          operationReference: get(record, 'value.reference'),
        },
      });
      await modal.present();
    },
  };

  public eventOptions: IOptions = {
    pagination: {default: 2, options: [10, 20, 50, 100]},
    service: (options: IDatatableOptions): Observable<IDatatableRecords<IRecord>> =>
      this.service.datatable({equipmentReference: this.assetReference, kind: 'event', query: options}),
    columns: [
      {property: 'value.type', label: `Type d'évènement`, searchable: true, sortable: true},
      {property: 'value.status', label: `Statut de l'évènement`, searchable: true, sortable: true},
      {
        property: 'value.timestamp',
        label: `Date de l'évènement`,
        searchable: true,
        sortable: true,
        type: 'date',
        format: 'L LT',
      },
    ],
    enableRowNumber: false,
    enableStickyColumn: false,
    enableReorderColumn: false,
    enableSelect: false,
    enableHideShowColumns: false,
    enableExport: true,
    enableFullscreen: false,
    disableScrollbarModule: true,
    sortedColumns: [{column: 2, dir: 'desc'}],
  };

  equipment: Equipment;
  path: string;

  constructor(
    private modalController: ModalController,
    private service: IntentService,
    private equipmentReportService: EquipmentReportService,
    private equipmentService: EquipmentService,
    private translate: TranslateService
  ) {}

  async ngOnInit() {
    this.equipment = await lastValueFrom(this.equipmentService.get(this.id));
    this.path = join(
      concat(this.equipment?.organizationalUnit.pathNames, this.equipment?.realEstateStructure.pathNames),
      ' > '
    );
  }

  dismiss() {
    this.modalController.dismiss();
  }
}
