/** @format */

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTreeModule} from '@angular/material/tree';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {InViewportModule} from 'ng-in-viewport';
import {CountUpModule} from 'ngx-countup';
import {NgPipesModule} from 'ngx-pipes';
import {NgScrollbarModule} from 'ngx-scrollbar';
import {ChartModule, LoaderModule, PipesModule, TrackingModule} from 'sesio-lib';
import {DatagridModule} from '../datagrid/datagrid.module';
import {EquipmentKindSelectModule} from '../equipment-kind-select/equipment-kind-select.module';
import {EquipmentModule} from '../equipment/equipment.module';
import {OrganizationalUnitSelectModule} from '../organizational-unit-select/organizational-unit-select.module';
import {AccessControlSpaceComponent} from './access-control-space/access-control-space.component';
import {StatisticsDefaultComponent} from './dashboard/dashboard.component';
import {ElevatorSpaceComponent} from './elevator-space/elevator-space.component';
import {ConsumptionEmissionChartComponent} from './energy-fluid-space-group/consumption-emission-chart/consumption-emission-chart.component';
import {EcsChartComponent} from './energy-fluid-space-group/ecs-chart/ecs-chart.component';
import {StatisticsEnergyFluidSpaceGroupComponent} from './energy-fluid-space-group/energy-fluid-space-group.component';
import {HeatingChartComponent} from './energy-fluid-space-group/heating-chart/heating-chart.component';
import {PrimaryChartComponent} from './energy-fluid-space-group/primary-chart/primary-chart.component';
import {WindChartComponent} from './energy-fluid-space-group/sensor-chart/sensor-chart.component';
import {WeatherChartComponent} from './energy-fluid-space-group/weather-chart/weather-chart.component';
import {FireSafetySpaceComponent} from './fire-safety-space/fire-safety-space.component';
import {StatisticsModal} from './statistics.modal';

@NgModule({
  declarations: [
    StatisticsModal,
    StatisticsDefaultComponent,
    StatisticsEnergyFluidSpaceGroupComponent,
    WeatherChartComponent,
    EcsChartComponent,
    HeatingChartComponent,
    WindChartComponent,
    ConsumptionEmissionChartComponent,
    ElevatorSpaceComponent,
    FireSafetySpaceComponent,
    AccessControlSpaceComponent,
    PrimaryChartComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    TranslateModule.forChild(),
    FlexLayoutModule,
    InViewportModule,
    MatTabsModule,
    MatCardModule,
    MatTreeModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    MatSortModule,
    DatagridModule,
    LoaderModule,
    NgScrollbarModule,
    InViewportModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonToggleModule,
    NgPipesModule,
    PipesModule,
    CountUpModule,
    ChartModule,
    TrackingModule,
    MatExpansionModule,
    MatTooltipModule,
    EquipmentModule,
    MatPaginatorModule,
    MatTooltipModule,
    OrganizationalUnitSelectModule,
    EquipmentKindSelectModule,
    MatCheckboxModule,
    MatSelectModule,
    MatProgressSpinnerModule,
  ],
  exports: [StatisticsModal],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})
export class StatisticsModule {}
