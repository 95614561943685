import { Component, Input, OnInit } from '@angular/core';
import { filter, find, get } from 'lodash-es';
import { EquipmentDocumentKind } from 'sesio-lib';

interface IFile {
  kind: EquipmentDocumentKind;
  _id: string;
}

@Component({
  selector: 'app-document-datagrid-cell',
  templateUrl: './document-datagrid-cell.component.html',
  styleUrls: ['./document-datagrid-cell.component.scss'],
})
export class DocumentDatagridCellComponent implements OnInit {

  @Input('kind')
  public set setKind(kind: EquipmentDocumentKind) {
    this.kind = kind;
    this.load();
  }
  public kind: EquipmentDocumentKind;

  @Input('files')
  public set setFiles(files: IFile[]) {
    this.files = files;
    this.load();
  }
  public files: IFile[];

  @Input()
  public hideZeroValue = false;

  public count: number;

  constructor() { }

  ngOnInit(): void { }

  private load(): void {
    if (this.kind && this.files) {
      this.count = get(filter(this.files, f => f.kind === this.kind), 'length', 0);
    } else { this.count = 0; }
  }

}
