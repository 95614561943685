
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomIconService {

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) { }

  init(): void {
    this.matIconRegistry.setDefaultFontSetClass('material-symbols-outlined');
    this.matIconRegistry.addSvgIcon(
      'cool_face',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icon/cool2.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'admin',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icon/ico-admin.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'relay-antenna',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icon/relay-antenna.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'elevator',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icon/elevator.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'control-access',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icon/access-control.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'energy-fluid',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icon/energy-fluid.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'esi',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icon/ico-esi.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'fiber',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icon/optical-fiber.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'technical-support',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icon/isidesk.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'menu-expand',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icon/menu-expand.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'menu-minimize',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icon/menu-minimize.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'photovoltaic',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icon/photovoltaic-panel.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'parking-door',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icon/parking-door.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'alarm',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icon/alarm.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'alarm-check',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icon/alarm-check.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'alarm-cancel',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icon/alarm-cancel.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'alarm2',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icon/alarm2.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'checkmark',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icon/checkmark.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'stopwatch',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icon/stopwatch.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'warning',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icon/warning.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'fire-safety',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icon/fire.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'janitor',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icon/janitor.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'traffic-cone',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icon/traffic-cone.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'feed',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icon/feed.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'screwdriver',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icon/screwdriver.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'error-outline',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icon/error-outline.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'broken-link',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icon/broken-link.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'link-off',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icon/link-off.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'noflow',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icon/link-off.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'tree',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icon/tree.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'close',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icon/close.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'pin',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icon/pin.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'rating',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icon/rating.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'rating2',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icon/rating2.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'rating3',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icon/rating3.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'bell-check',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icon/bell-check.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'bell-cross',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icon/bell-cross.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'bell2',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icon/bell2.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'bell3',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icon/bell3.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'power',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icon/power.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'factory',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icon/factory.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'celsius',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icon/celsius.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'cloudy',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icon/cloudy.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'sun',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icon/sun.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'thermometer',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icon/thermometer.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'wind',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icon/wind.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'celsius',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icon/celsius.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'cloudy',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icon/cloudy.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'sun',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icon/sun.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'thermometer',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icon/thermometer.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'wind',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icon/wind.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'disconnected',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icon/disconnected.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'online',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icon/online.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'noflow',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icon/noflow.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'maintenance',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icon/maintenance.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'stopped',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icon/stopped.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'offline',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icon/offline.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'extinguisher',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icon/extinguisher.svg')
    );
  }
}
