/** @format */

import {animate, style, transition, trigger} from '@angular/animations';
import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import * as Highcharts from 'highcharts';
import {isEqual, join, map, max, min, orderBy} from 'lodash-es';
import moment, {Moment} from 'moment';
import {NgScrollbar} from 'ngx-scrollbar';
import {fadeOut, GroupTreeElement, highchartsOptions, InterventionStatusColor} from 'sesio-lib';
import {SubSink} from 'subsink';
import {convertHeaderSelection, IHeaderSearch} from '../../../_classes/header-search';
import {InterventionService} from '../../../_services/intervention.service';
import {IHeaderSelection, SessionService} from '../../../_services/session.service';

@Component({
  selector: 'app-statistics-fire-safety-space',
  templateUrl: './fire-safety-space.component.html',
  styleUrls: ['./fire-safety-space.component.scss'],
  animations: [
    fadeOut,
    trigger('elmt', [
      transition(':enter', [
        style({height: 0, overflow: 'hidden'}), // initial
        animate('0.2s', style({height: '*', overflow: 'hidden'})), // final
      ]),
      transition(':leave', [
        style({height: '*', overflow: 'hidden'}), // initial
        animate('0.2s', style({height: 0, overflow: 'hidden'})), // final
      ]),
    ]),
  ],
})
export class FireSafetySpaceComponent implements OnInit, AfterViewInit, OnDestroy {
  dateWidth = 120;
  InterventionStatusColor = InterventionStatusColor;
  loading = true;
  interventionTree: GroupTreeElement[];
  interventionsStart: Moment;
  interventionsEnd: Moment;
  dates: Moment[] = [];
  filter: IHeaderSearch;

  private subsink = new SubSink();

  constructor(
    private translate: TranslateService,
    private interventionService: InterventionService,
    private sessionService: SessionService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  async ngOnInit(): Promise<void> {
    Highcharts.setOptions(highchartsOptions[this.translate.getDefaultLang()]);
  }

  async ngAfterViewInit(): Promise<void> {
    this.loadFilter(this.sessionService.$headerSelection.value);
    this.interventionTree = orderBy(map(await this.interventionService.getTree(this.filter).toPromise()), [
      elmt => join(elmt.group, ' '),
    ]);
    this.interventionsStart = moment(min(map(this.interventionTree, 'start'))).startOf('day');
    this.interventionsEnd = moment(max(map(this.interventionTree, 'end')));
    let start: Moment = this.interventionsStart.clone();
    const end = this.interventionsEnd.clone();
    while (start.isBefore(end)) {
      this.dates.push(start.clone());
      start = start.add(1, 'day');
    }
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  private interventionScrolled = false;
  scrollToEndIntervention(visible: boolean, scrollbar: NgScrollbar) {
    if (visible && !this.interventionScrolled) {
      this.interventionScrolled = true;
      scrollbar.scrollTo({end: 0});
    }
  }

  private loadFilter(selection: IHeaderSelection): void {
    const filter: any = convertHeaderSelection(selection);
    if (isEqual(this.filter, filter)) return;
    this.filter = filter;
  }
}
