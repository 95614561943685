<ion-content>
  <mat-tab-group mat-align-tabs="start" (selectedIndexChange)="tabIndex = $event">
    <mat-tab>
      <ng-template mat-tab-label>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
          <mat-icon *ngIf="equipment" [style.color]="EquipmentStatusColor[equipment | get:'status']">
            {{EquipmentStatusIcon[equipment | get:'status']}}
          </mat-icon>
          <div class="label" fxLayout="column" fxLayoutAlign="center start">
            <span [class.sup]="formGroup?.controls.reference?.value">Équipement</span>
            <span *ngIf="formGroup?.controls.reference?.value">
              {{formGroup.controls.reference.value}}
            </span>
          </div>
        </div>
      </ng-template>
      <ng-scrollbar *ngIf="!loading; else loadingTmpl" visibility="hover">
        <form [formGroup]="formGroup">
          <app-equipment-edit @fade [formGroup]="formGroup" [administration]="administration" [equipment]="equipment"
            [organizationalUnit]="organizationalUnit" [kindsFilter]="{ families: families, categories: categories}"
            (isReady)="setReady($event)">
          </app-equipment-edit>
        </form>
      </ng-scrollbar>
    </mat-tab>
    <mat-tab *ngIf="equipment && kind?.inspectionConfig.kind !== 'none'">
      <ng-template mat-tab-label>
        <div class="label" fxLayout="column" fxLayoutAlign="center start">
          <span class="sup">Dernière VR</span>
          <div fxLayoutAlign="start center" fxLayoutGap="8px"
            [style.color]="InspectionStateOptions[equipment.inspectionSate]?.color">
            <mat-icon>
              {{InspectionStateOptions[equipment.inspectionSate]?.icon}}
            </mat-icon>
            <span translate>{{equipment.inspectionSate}}</span>
          </div>
        </div>
      </ng-template>
      <ng-scrollbar *ngIf="inspections; else loadingTmpl" visibility="hover">
        <div class="inspections" fxLayout="column">
          <div id="header" fxLayout="row" fxLayoutAlign="start center">
            <span class="block">RÉFÉRENCE VR</span>
            <span class="block">DATE RAPPORT</span>
            <span class="block">TECHNICIEN</span>
            <span class="block">ÉTAT</span>
            <span class="block">ANOMALIES</span>
            <span class="block">DANGERS</span>
            <span class="block">FICHIERS</span>
            <span class="block">COMMENTAIRE</span>
          </div>
          <ng-container *ngFor="let inspection of inspections">
            <ng-container
              *ngTemplateOutlet="reportTmpl; context: { report: (inspection.reports | find: { equipmentId: equipment.id }) }">
            </ng-container>
            <ng-template #reportTmpl let-report="report">
              <div *ngIf="report" class="inspection" fxLayout="column">
                <div fxLayout="row" fxLayoutAlign="start center">
                  <span class="block" color="primary"
                    matTooltip="Référence de la vérification réglementaire">{{inspection.reference}}</span>
                  <span class="block" color="primary" matTooltip="Date du rapport de l'équipement">{{report.startDate |
                    date:'shortDate'}}</span>
                  <span class="block" color="primary" matTooltip="Technicien">{{report.technicianName}}</span>
                  <span class="block" matTooltip="État de l'équipement"
                    [style.color]="report.status === 'ok' ? 'var(--ion-color-success)' : 'var(--ion-color-danger)'">{{report.status
                    | translate}}</span>
                  <span class="block" color="warning"
                    matTooltip="Nombre d'anomalies constatées dans l'équipement">{{report.interventions.length -
                    (report.interventions |
                    filter:{'unitPriceSchedule.priority': 1} | get: 'length') }}</span>
                  <span class="block" color="danger"
                    matTooltip="Nombre de dangers constatés dans l'équipement">{{report.interventions |
                    filter:{'unitPriceSchedule.priority': 1} |
                    get:'length'
                    }}</span>
                  <span class="block" fxLayout="row wrap" matTooltip="Fichiers du rapport">
                    <a *ngFor="let file of report.files" href="{{repo}}/{{file.path}}"
                      target="_blank">{{file.filename}}</a>
                  </span>
                  <span class="block" color="primary" matTooltip="Commentaire du technicien">{{report.comment}}</span>
                </div>
                <div class="interventions" fxLayout="column">
                  <div class="intervention"
                    *ngFor="let intervention of report.interventions | orderBy: 'unitPriceSchedule.priority'"
                    fxLayout="row" fxLayoutAlign="start center">
                    <span color="primary" matTooltip="Priorité de l'intervention">
                      <ng-container *ngIf="(intervention | get:'unitPriceSchedule.priority')">
                        P{{intervention | get:'unitPriceSchedule.priority'}}
                        <span *ngIf="(intervention | get:'unitPriceSchedule.priority') === 1"
                          color="danger">Danger</span>
                        <span *ngIf="(intervention | get:'unitPriceSchedule.priority') > 1"
                          color="warning">Anomalie</span>
                      </ng-container>
                    </span>
                    <span [style.color]="InterventionStatusColor[intervention.status]"
                      matTooltip="État de l'intervention : {{intervention.status === 'new' ? 'l\'intervention doit être devisé.' : intervention.status==='estimated'
                      ? 'l\'intervention fait l\'objet d\'un devis en attente de validation.' :
                      intervention.status==='validated'
                      ? 'la commande du devis a été validée, l\'intervention doit être réalisé.' :
                      intervention.status==='done' ? 'l\'intervention a été réalisée' : intervention.status==='infeasible' ? 'l\'intervention a été marquée comme infaisable' : 'l\'intervention a été marquée comme annulée' }}">{{intervention.status
                      |
                      translate}} ({{intervention.updatedAt | date:'shortDate'}})</span>
                    <span fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" color="primary"
                      matTooltip="Quantité, référence et label de l'intervention">
                      <span *ngIf="intervention?.unitPriceSchedule" fxLayout="row" fxLayoutGap="4px"
                        [style.white-space]="'nowrap'">
                        <span light>{{intervention.quantity}} x</span>
                        <span>{{intervention | get:'unitPriceSchedule.reference'}} :</span>
                      </span>
                      <span light>{{intervention | get:'unitPriceSchedule.name'}}</span>
                    </span>
                  </div>
                </div>
              </div>
            </ng-template>
          </ng-container>
        </div>
      </ng-scrollbar>
    </mat-tab>
    <mat-tab *ngIf="equipment && tabIndex === 1" @fade disabled>
      <ng-template mat-tab-label>
        <div fxLayout="row" fxLayoutGap="32px">
          <div class="label" fxLayout="column" fxLayoutAlign="center start">
            <span class="sup">Dernière VR</span>
            <span>{{(inspections | head | get:'reference') || '-'}}</span>
          </div>
          <div class="label" fxLayout="column" fxLayoutAlign="center start">
            <span class="sup">Date de la VR</span>
            <span>{{(inspections | head | get:'info.startDate' | date:'shortDate') || '-'}}</span>
          </div>
          <div class="label" fxLayout="column" fxLayoutAlign="center start">
            <span class="sup">État de la VR</span>
            <span>{{(inspections | head | get:'status' | translate) || '-'}}</span>
          </div>
          <div class="label" fxLayout="column" fxLayoutAlign="center start">
            <span class="sup">Prochaine VR</span>
            <span light italic>{{(equipment | get:'nextInspection.reference') || '-'}}</span>
          </div>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab *ngIf="equipment?.archived" disabled labelClass="push-right archived-at">
      <ng-template mat-tab-label>
        <span matTooltip="Archivé par {{equipment.archivedChangedBy?.email}}">Archivée le
          {{equipment.archivedChangedAt?.format('L LT')}}</span>
      </ng-template>
    </mat-tab>
    <mat-tab disabled labelClass="push-right close-btn">
      <ng-template mat-tab-label>
        <ion-buttons slot="end">
          <ion-button (click)="dismiss()">
            <ion-icon slot="icon-only" name="close"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</ion-content>

<ion-footer *ngIf="tabIndex === 0">
  <ion-toolbar>
    <ion-buttons *ngIf="administration && equipment" slot="start">
      <ion-button *ngIf="!equipment.archived" color="warn" fill="clear" (click)="setArchived(true)">
        Archiver l'équipement
      </ion-button>
      <ion-button *ngIf="equipment.archived" color="warn" fill="clear" (click)="setArchived(false)">
        Désarchiver l'équipement
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button *ngIf="id" color="primary" fill="clear" (click)="addNew()">
        Ajouter un autre équipement
      </ion-button>
      <ion-button color="primary" fill="solid" [disabled]="loading || updating || !formGroup?.valid" (click)="edit()">
        <span *ngIf="id">Enregistrer les modifications</span>
        <span *ngIf="!id">Enregistrer</span>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>

<sesio-loader *ngIf="updating" @fadeOut [backdrop]="true"></sesio-loader>

<ng-template #loadingTmpl>
  <sesio-loader @fadeOut></sesio-loader>
</ng-template>