import { propertyMap } from 'model-mapper';

export interface IIcon {
  name: string;
  svg?: boolean;
  color?: string;
}

export class Icon {

  @propertyMap()
  public name: string;

  @propertyMap({ default: false })
  public svg: boolean;

  @propertyMap()
  public color: string;

}
