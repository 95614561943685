/** @format */

import {Component, OnInit} from '@angular/core';
import * as Highcharts from 'highcharts';
import Boost from 'highcharts/modules/boost';
Boost(Highcharts);
import noData from 'highcharts/modules/no-data-to-display';
noData(Highcharts);
import More from 'highcharts/highcharts-more';
More(Highcharts);
import Exporting from 'highcharts/modules/exporting';
Exporting(Highcharts);
import ExportData from 'highcharts/modules/export-data';
ExportData(Highcharts);
import {TranslateService} from '@ngx-translate/core';
import {fadeOut, highchartsOptions} from 'sesio-lib';
import {HttpClient} from '@angular/common/http';
import {Observable, lastValueFrom, of} from 'rxjs';

@Component({
  selector: 'app-consumption-emission-chart',
  templateUrl: './consumption-emission-chart.component.html',
  styleUrls: ['./consumption-emission-chart.component.scss'],
  animations: [fadeOut],
})
export class ConsumptionEmissionChartComponent implements OnInit {
  private loaded = false;

  constructor(private translate: TranslateService, private http: HttpClient) {}

  ngOnInit() {
    Highcharts.setOptions(highchartsOptions[this.translate.getDefaultLang()]);
  }

  public load(visible: boolean) {
    if (!visible || this.loaded) {
      return;
    }
    this.loaded = true;
    lastValueFrom(this.chartData()).then(chart =>
      Highcharts.chart('consumption-emission-chart', this.buildChartOptions(chart))
    );
  }
  private buildChartOptions(data: any[]): any {
    return {
      chart: {},
      credits: {enabled: false},
      data: {dateFormat: 'dd/mm/YYYY'},
      title: {
        text: this.translate.instant('consumption-emission-chart-title'),
      },
      labels: {
        items: [
          {
            html: this.translate.instant('co2-emission'),
            style: {
              left: '50px',
              top: '18px',
              color:
                // theme
                (Highcharts.defaultOptions.title.style && Highcharts.defaultOptions.title.style.color) || 'black',
            },
          },
        ],
      },
      xAxis: {
        type: 'categories',
        categories: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre'],
      },
      yAxis: [
        {
          title: {text: 'kWh', style: {color: '#ffc100'}},
          lineWidth: 1,
        },
        {
          title: {text: 'm<sup>3</sup>', style: {color: '#80c0f8'}},
          lineWidth: 1,
        },
        {
          opposite: true,
          title: {
            text: 'CO² en Tonnes',
            style: {
              color: '#bd04e5',
            },
          },
          lineWidth: 1,
        },
      ],
      series: data,
    };
  }

  private chartData(): Observable<any[]> {
    return of([
      {
        type: 'column',
        name: 'Eau m3',
        data: [4620.0, 4870.0, 4950.0, 5280.0, 5610.0, 6270.0, 6930.0, 6600.0, 5610.0, 5620.0],
        color: '#80c0f8',
        yAxis: 1,
      },
      {
        type: 'column',
        name: 'ECS kWh ',
        data: [50480, 49806, 48806, 47647, 46987, 46587, 45211, 44327, 46941, 47157],
        color: '#ffc100',
        yAxis: 0,
      },
      {
        type: 'column',
        name: 'Chauffage kWh',
        data: [403840, 398448, 241324, 0, 0, 0, 0, 0, 0, 211696],
        color: '#e7235b',
        yAxis: 0,
      },
      {
        type: 'spline',
        yAxis: 2,
        name: 'CO²',
        data: [104.04, 102.65, 66.44, 10.91, 10.76, 10.67, 10.35, 10.15, 10.75, 59.28],
        color: '#bd04e5',
        marker: {
          lineWidth: 2,
          lineColor: '#bd04e5',
          fillColor: 'white',
        },
      },
    ]);
  }
}
