import { propertyMap } from 'model-mapper';
import { Equipment } from './equipment';

export type EquipmentFireSafetyStatus = 'not-verified' | 'issue' | 'warn' | 'valid' | 'late';

export class EquipmentFireSafetyMetadata {

  @propertyMap({ source: 'status' })
  public status: EquipmentFireSafetyStatus;

  @propertyMap({ source: 'state' })
  public state: number;

  @propertyMap({ type: 'Moment', source: 'lastInspectionDate' })
  public lastInspectionDate: Date;

  @propertyMap({ type: 'Moment', source: 'nextInspectionDate' })
  public nextInspectionDate: Date;

  @propertyMap({ source: 'anomalies' })
  public anomalies: number;

}

export class FireSafetyEquipment extends Equipment {

  @propertyMap({ type: EquipmentFireSafetyMetadata })
  public metadata: EquipmentFireSafetyMetadata;

}
