import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Moment } from 'moment';
import { Observable, of } from 'rxjs';
import { LibConfig, LibConfigService } from '../config.service';

export interface IWeatherInfo {
  temperatureAvg: number;
  temperatureMin: number;
  temperatureMax: number;
  windSpeedAvg: number;
  windgustMax: number;
  precip: number;
  sunhour: number;
  dju: number;
}

@Injectable({
  providedIn: 'root'
})
export class WeatherService {

  constructor(
    @Inject(LibConfigService) private config: LibConfig,
    private http: HttpClient
  ) { }

  public getWeatherInfo(start?: Moment, end?: Moment): Observable<IWeatherInfo> {
    const params: any = {};
    if (start) { params.start = start.toISOString(); }
    if (end) { params.end = end.toISOString(); }
    return this.http.get<any>(`${this.config.environment.apiUrl}/weather/info`, { params });
  }

}
