import { propertyMap } from 'model-mapper';
import { EmbededContractor } from './embeded-contractor';
import { EmbededOrganizationalUnit } from './embeded-organizational-unit';

export enum UserKind {
  USER = 'user',
  CONTRACTOR = 'contractor',
  ADMINISTRATOR = 'administrator',
  API = 'api',
}


export class User {
  @propertyMap({ source: '_id' })
  public id: string;

  @propertyMap()
  public username: string;

  @propertyMap()
  public email: string;

  @propertyMap()
  public firstname: string;

  @propertyMap()
  public lastname: string;

  @propertyMap()
  public phoneNumber: string;

  @propertyMap()
  public role: string;

  @propertyMap()
  public avatar: string;

  @propertyMap({ default: false })
  public administrator: boolean;

  @propertyMap({ default: [] })
  public spaces: string[];

  @propertyMap({ type: [EmbededOrganizationalUnit] })
  public perimeter: EmbededOrganizationalUnit[];

  @propertyMap()
  public tutorials: { [key: string]: boolean };

  @propertyMap()
  public kind: UserKind;

  @propertyMap({ type: EmbededContractor })
  public contractor: EmbededContractor;

  @propertyMap()
  public contractorPassword: string;
}

export class EmbededUser {
  @propertyMap({ source: '_id' })
  public id: string;

  @propertyMap()
  public username: string;

  @propertyMap()
  public email: string;

  @propertyMap()
  public firstname: string;

  @propertyMap()
  public lastname: string;

  @propertyMap({
    default:
      'https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y',
  })
  public avatar: string;
}
