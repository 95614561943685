<div *ngIf="!loading; else loadingTmpl" id="container" fxLayout="column" [style.--day-width]="dateWidth">
  <ng-scrollbar #interventionScrollbar id="interventions-scrollbar" visibility="hover" track="all"
    [autoHeightDisabled]="false" inViewport
    (inViewportAction)="scrollToEndIntervention($event.visible, interventionScrollbar)">
    <div id="interventions" fxLayout="column">
      <div id="header" fxLayout="row">
        <span class="name">Nom</span>
        <span *ngFor="let date of dates" class="date">
          {{date.format('DD/MM/YY')}}
        </span>
      </div>
      <ng-container *ngFor="let elmt of interventionTree">
        <ng-container *ngTemplateOutlet="groupTmpl; context: { elmt: elmt }"></ng-container>
      </ng-container>
    </div>
  </ng-scrollbar>
</div>

<ng-template #groupTmpl let-elmt="elmt">
  <div class="elmt elmt-group" fxLayout="column">
    <div class="header" fxLayout="row" (click)="elmt.open = !elmt.open">
      <div class="name" fxLayout="column">
        <span color="medium" [style.font-size.em]="0.8">{{elmt.group.slice(0, -1).join(' > ')}}</span>
        <span color="primary" [style.font-weight]="600">{{elmt.group[elmt.group.length - 1]}}</span>
      </div>
      <div class="blocks" fxLayout="row">
        <span *ngFor="let date of dates" class="date"></span>
        <div class="block block-group" [style.left.px]="elmt.start.diff(interventionsStart, 'days', true) * dateWidth"
          [style.width.px]="elmt.end.diff(elmt.start, 'days', true) * dateWidth"
          matTooltip="{{elmt.start.format('LL')}} - {{elmt.end.format('LL')}} : {{elmt.count}} / {{elmt.realEstateStructures?.length}}">
          <div class="inner-block" fxLayoutAlign="start center"
            [style.width.%]="(elmt.count / elmt.realEstateStructures.length) * 100">
            <span>{{elmt.count / elmt.realEstateStructures.length | percent}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="content" *ngIf="elmt.open" @elmt>
      <ng-container *ngFor="let child of elmt.realEstateStructures">
        <ng-container *ngTemplateOutlet="realEstateStructureTmpl; context: { elmt: child }"></ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #realEstateStructureTmpl let-elmt="elmt">
  <div class="elmt elmt-real-estate-structure" fxLayout="column">
    <div class="header" fxLayout="row" (click)="elmt.open = !elmt.open">
      <div class="name" fxLayout="column" [style.padding-left.px]="24">
        <span color="medium" [style.font-size.em]="0.8">{{elmt.kind | translate}}</span>
        <span color="primary" [style.font-weight]="500">{{elmt.reference}}</span>
      </div>
      <div class="blocks" fxLayout="row">
        <span *ngFor="let date of dates" class="date"></span>
        <div class="block block-real-estate-structure"
          [style.left.px]="elmt.start.diff(interventionsStart, 'days', true) * dateWidth"
          [style.width.px]="elmt.end.diff(elmt.start, 'days', true) * dateWidth"
          matTooltip="{{elmt.start.format('LL')}} - {{elmt.end.format('LL')}} : {{elmt.count}} / {{elmt.equipments?.length}}">
          <div class="inner-block" fxLayoutAlign="start center"
            [style.width.%]="(elmt.count / elmt.equipments.length) * 100">
            <span>{{elmt.count / elmt.equipments.length | percent}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="content" *ngIf="elmt.open" @elmt>
      <ng-container *ngFor="let child of elmt.equipments">
        <ng-container *ngTemplateOutlet="equipmentTmpl; context: { elmt: child }"></ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #equipmentTmpl let-elmt="elmt">
  <div class="elmt elmt-equipment" fxLayout="column">
    <div class="header" fxLayout="row" (click)="elmt.open = !elmt.open">
      <div class="name" fxLayout="column" [style.padding-left.px]="48"
        matTooltip="{{elmt.category | translate}} : {{elmt.kind}}">
        <span color="medium" [style.font-size.em]="0.8">
          {{elmt.category | translate}} : {{elmt.kind}}
        </span>
        <span color="primary">{{elmt.reference}}</span>
      </div>
      <div class="blocks" fxLayout="row">
        <span *ngFor="let date of dates" class="date"></span>
        <div class="block block-equipment"
          [style.left.px]="elmt.start.diff(interventionsStart, 'days', true) * dateWidth"
          [style.width.px]="elmt.end.diff(elmt.start, 'days', true) * dateWidth"
          matTooltip="{{elmt.start.format('LL')}} - {{elmt.end.format('LL')}} : {{elmt.count}} / {{elmt.interventions?.length}}">
          <div class="inner-block" fxLayoutAlign="start center"
            [style.width.%]="(elmt.count / elmt.interventions.length) * 100">
            <span>{{elmt.count / elmt.interventions.length | percent}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="content" *ngIf="elmt.open" @elmt>
      <ng-container *ngFor="let child of elmt.interventions">
        <ng-container *ngTemplateOutlet="interventionTmpl; context: { elmt: child }"></ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #interventionTmpl let-elmt="elmt">
  <div class="elmt elmt-intervention" fxLayout="column">
    <div class="header" fxLayout="row" (click)="elmt.open = !elmt.open">
      <div class="name" fxLayout="column" [style.padding-left.px]="72"
        matTooltip="P{{elmt.unitPriceSchedule?.priority}} {{elmt.quantity}}x {{elmt.unitPriceSchedule.name}}">
        <span color="medium" [style.font-size.em]="0.8">
          <span bold
            [attr.color]="elmt.unitPriceSchedule?.priority > 1 ? 'warning' : 'danger'">P{{elmt.unitPriceSchedule?.priority}}</span>
          {{elmt.quantity}}x {{elmt.unitPriceSchedule.name}}
        </span>
        <span color="primary">{{elmt.reference}}</span>
      </div>
      <div class="blocks" fxLayout="row">
        <span *ngFor="let date of dates" class="date"></span>
        <div *ngFor="let block of elmt.statusHistory" class="block block-intervention"
          [style.background-color]="InterventionStatusColor[block.status]"
          [style.left.px]="block.start.diff(interventionsStart, 'days', true) * dateWidth"
          [style.width.px]="(block.end || interventionsEnd).diff(block.start, 'days', true) * dateWidth"
          matTooltip="{{block.start.format('LL')}} - {{block.end?.format('LL')}} : {{block.status | translate: { gender: 'female' } }}"
          fxLayoutAlign="start center">
          <span *ngIf="block.duration" class="info">{{block.duration?.humanize()}}</span>
        </div>
      </div>
    </div>
    <div class="content" *ngIf="elmt.open" @elmt>
      <ng-container *ngFor="let child of elmt.interventions">
        <ng-container *ngTemplateOutlet="equipmentTmpl; context: { elmt: child }"></ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #loadingTmpl>
  <sesio-loader @fadeOut></sesio-loader>
</ng-template>
