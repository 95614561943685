import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatsBlockComponent } from './stats-block.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { SafePipeModule } from 'safe-pipe';
import { NgPipesModule } from 'ngx-pipes';
import { PipesModule } from 'sesio-lib';
import { MatGridListModule } from '@angular/material/grid-list';
import { IonicModule } from '@ionic/angular';
import { CountUpModule } from 'ngx-countup';



@NgModule({
  declarations: [StatsBlockComponent],
  imports: [
    CommonModule,
    IonicModule,
    FlexLayoutModule,
    MatIconModule,
    SafePipeModule,
    NgPipesModule,
    PipesModule,
    MatGridListModule,
    CountUpModule
  ],
  exports: [StatsBlockComponent],
})
export class StatsBlockModule { }
