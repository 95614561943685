import { Component, Input, OnInit } from '@angular/core';
import { ICell } from '../../datagrid/datagrid.component';

@Component({
  selector: 'app-organizational-unit-datagrid-cell',
  templateUrl: './organizational-unit-datagrid-cell.component.html',
  styleUrls: ['./organizational-unit-datagrid-cell.component.scss'],
})
export class OrganizationalUnitDatagridCellComponent implements OnInit {

  @Input('cell')
  public set setCell(cell: ICell) {
    if (cell) {
      this.pathNames = cell.rawValue;
    }
  };

  @Input()
  public pathNames: string[];

  constructor() { }

  ngOnInit(): void { }

}
