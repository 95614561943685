/** @format */

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { find, get, includes, keys, reduce, values } from 'lodash-es';
import { User } from '../_classes/user';
import { UserService } from '../_services/user.service';
import { Space } from 'sesio-lib';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanActivate {
  constructor(private router: Router, private userService: UserService) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const user = await this.userService.$user.value;
    if (get(user, 'administrator')) {
      return true;
    }
    if (includes(get(user, 'spaces'), Space.ADMINISTRATION)) {
      const blocks = state.url.split('/').slice(2, 4);
      if (blocks.length < 2) {
        return true;
      }
      return this.processAdminAccess(user, blocks[0], blocks[1]);
    }
    return false;
  }

  private async navigate(module: string, page: string): Promise<boolean> {
    if (module === 'other') {
      return this.router.navigate([`/administration/${page}`]);
    }
    return this.router.navigate([`/administration/${module}/${page}`]);
  }

  private async processAdminAccess(user: User, module: string, page: string): Promise<boolean> {
    if (['isitech', 'ulis'].includes(module)) {
      page = module;
      module = 'other';
    }

    if (module === 'contractor' && get(user, `adminAccess.${module}`, false)) return true;

    if (get(user, `adminAccess.${module}.${page}`, false)) return true;

    const moduleRights = get(user, `adminAccess.${module}`, {});
    const allowedPage = find(keys(moduleRights), k => !!moduleRights[k]);
    if (allowedPage) {
      this.navigate(module, allowedPage);
      return false;
    }

    const allowedModule = find(
      keys(get(user, 'adminAccess')),
      k => !!reduce(values(get(user, `adminAccess.${k}`)), (pv, cv) => pv || cv, false)
    );
    if (allowedModule) {
      const allowedModuleRights = get(user, `adminAccess.${allowedModule}`, {});
      const allowedModuleAllowedPage = find(keys(allowedModuleRights), k => !!allowedModuleRights[k]);
      if (allowedModuleAllowedPage) {
        this.navigate(allowedModule, allowedModuleAllowedPage);
        return false;
      }
    }

    return false;
  }
}
