/** @format */

import { Injectable } from '@angular/core';
import { Quotation, QuotationService as SesioQuotationService } from 'sesio-lib';
import { Observable } from 'rxjs';
import { IDatatableOptions, IDatatableRecords } from '../_components/datagrid/datatable.class';
import { buildHeaderSearchFilter, IHeaderSearch } from '../_classes/header-search';
import { map } from 'rxjs/operators';
import { map as lmap } from 'lodash-es';
import { ModelMapper } from 'model-mapper';

@Injectable({
  providedIn: 'root',
})
export class QuotationService extends SesioQuotationService {
  public list({ realEstateStructureIds }: { realEstateStructureIds?: string[] }): Observable<Quotation[]> {
    const params: any = {};
    if (realEstateStructureIds?.length) {
      params.realEstateStructureIds = realEstateStructureIds;
    }
    return this.http
      .get<any[]>(`${this.config.environment.apiUrl}/quotations`, { params })
      .pipe(map(data => lmap(data, d => new ModelMapper(Quotation).map(d))));
  }

  public getStatus(search?: IHeaderSearch): Observable<{ name: string; value: number }[]> {
    return this.http.post<any>(
      `${this.config.environment.apiUrl}/quotations/get-status`,
      this.buildInspectionSearchFilter(search)
    );
  }

  public getOrderStatus(search?: IHeaderSearch): Observable<{ name: string; value: number }[]> {
    return this.http.post<any>(
      `${this.config.environment.apiUrl}/quotations/get-order-status`,
      this.buildInspectionSearchFilter(search)
    );
  }

  public datatable(
    query: IDatatableOptions,
    search?: IHeaderSearch,
    box?: number[][]
  ): Observable<IDatatableRecords<any>> {
    const params: any = {};
    if (box?.length) params.box = box;
    return this.http.post<any>(
      `${this.config.environment.apiUrl}/quotations/datatable`,
      { query, filter: this.buildInspectionSearchFilter(search) },
      { params }
    );
  }

  private buildInspectionSearchFilter(search: IHeaderSearch): any {
    const filter = buildHeaderSearchFilter(search);
    return filter;
  }
}
