import { propertyMap } from 'model-mapper';
import moment from 'moment';

export class EventEquipmentKind {

  @propertyMap()
  public code: string;

}

export class EventEquipment {

  @propertyMap({ source: '_id' })
  public id: string;

  @propertyMap({ type: EventEquipmentKind })
  public kind: EventEquipmentKind;

}

export class EquipmentEvent {

  @propertyMap({ source: '_id' })
  public id: string;

  @propertyMap()
  public startCodeId: any;

  @propertyMap()
  public endCodeId: any;

  @propertyMap()
  public repairId: any;

  @propertyMap({ type: EventEquipment })
  public equipment: EventEquipment;

  @propertyMap()
  public transmittingEquipmentId: any;

  @propertyMap()
  public state: number;

  @propertyMap({ type: 'Moment' })
  public startDate: moment.Moment;

  @propertyMap({ type: 'Moment' })
  public endDate: moment.Moment;

  @propertyMap()
  public duration: number;

  @propertyMap()
  public reactivityDuration: number;

  @propertyMap()
  public rawStartData: string;

  @propertyMap()
  public rawEndData: string;

}
