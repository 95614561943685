/** @format */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EquipmentStatsComponent } from './equipment-stats-block/equipment-stats-block.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { StatsBlockModule } from '../stats-block/stats-block.module';
import { TranslateModule } from '@ngx-translate/core';
import { SafePipeModule } from 'safe-pipe';
import { ChartModule, LoaderModule, PipesModule, TrackingModule } from 'sesio-lib';
import { MatTooltipModule } from '@angular/material/tooltip';
import { StarRateModule } from '../star-rate/star-rate.module';
import { NgPipesModule } from 'ngx-pipes';
import { IonicModule } from '@ionic/angular';
import { EquipmentStatusComponent } from './equipment-status/equipment-status.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { InViewportModule } from 'ng-in-viewport';
import { HypervisionEditComponent } from './hypervision-edit/hypervision-edit.component';
import { MatCardModule } from '@angular/material/card';
import { EquipmentEditComponent } from './equipment-edit/equipment-edit.component';
import { MetricsEditComponent } from './metrics-edit/metrics-edit.component';
import { ColorsModule } from '../../_pipes/colors/colors.module';
import { OrganizationalUnitModule } from '../organizational-unit/organizational-unit.module';
import { EquipmentDatagridComponent } from './equipment-datagrid/equipment-datagrid.component';
import { EquipmentDatagridEditModal } from './equipment-datagrid/edit-modal/equipment-datagrid-edit.modal';
import { DatagridModule } from '../datagrid/datagrid.module';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MomentDateAdapter, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { CountUpModule } from 'ngx-countup';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { QuillModule } from '../../quill-config';
import { RouterModule } from '@angular/router';
import { AdminEquipmentDocumentComponent } from './admin-equipment-document/admin-equipment-document.component';
import { AdminEquipmentDocumentEditModal } from './admin-equipment-document/edit-modal/admin-equipment-document-edit.modal';
import { NgxFilesizeModule } from 'ngx-filesize';
import { DocumentModule } from '../document/document.module';
import { MatTableModule } from '@angular/material/table';
import { TextFieldModule } from '@angular/cdk/text-field';
import { RealEstateStructureModule } from '../real-estate-structure/real-estate-structure.module';
import { MapModule } from '../map/map.module';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { EquipmentStatusHistoryChartComponent } from './equipment-status-history-chart/equipment-status-history-chart.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { EquipmentAvailabilityFailureChartComponent } from './equipment-availability-failure-chart/equipment-availability-failure-chart.component';
import { EquipmentReportChartComponent } from './equipment-report-chart/equipment-report-chart.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ConfirmDialogModule } from '../confirm-dialog/confirm-dialog.module';
import { EquipmentTrafficChartComponent } from './equipment-traffic-chart/equipment-traffic-chart.component';
import { ContractorModule } from '../contractor/contractor.module';
import { IntentModalComponent } from './intent-modal/intent-modal.component';
import { OperationLogComponent } from './intent-modal/operation-log/operation-log.component';
import { EquipmentSelectComponent } from './equipment-select/equipment-select.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
  declarations: [
    EquipmentStatsComponent,
    EquipmentStatusComponent,
    EquipmentEditComponent,
    MetricsEditComponent,
    HypervisionEditComponent,
    EquipmentDatagridComponent,
    EquipmentDatagridEditModal,
    AdminEquipmentDocumentComponent,
    AdminEquipmentDocumentEditModal,
    EquipmentStatusHistoryChartComponent,
    EquipmentAvailabilityFailureChartComponent,
    EquipmentReportChartComponent,
    EquipmentTrafficChartComponent,
    IntentModalComponent,
    OperationLogComponent,
    EquipmentSelectComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatIconModule,
    StatsBlockModule,
    TranslateModule.forChild(),
    SafePipeModule,
    LoaderModule,
    MatTooltipModule,
    StarRateModule,
    NgPipesModule,
    MatFormFieldModule,
    MatSelectModule,
    MatDividerModule,
    MatInputModule,
    MatAutocompleteModule,
    SafePipeModule,
    MatCheckboxModule,
    InViewportModule,
    MatCardModule,
    ColorsModule,
    OrganizationalUnitModule,
    DatagridModule,
    MatChipsModule,
    MatDatepickerModule,
    CountUpModule,
    ChartModule,
    MaterialFileInputModule,
    MatTabsModule,
    MatBadgeModule,
    MatButtonModule,
    MatListModule,
    QuillModule,
    RouterModule,
    NgxFilesizeModule,
    DocumentModule,
    MatTableModule,
    MatButtonModule,
    NgPipesModule,
    TextFieldModule,
    RealEstateStructureModule,
    MapModule,
    NgScrollbarModule,
    PipesModule,
    MatButtonToggleModule,
    MatDialogModule,
    ConfirmDialogModule,
    TrackingModule,
    ContractorModule,
    MatProgressBarModule
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
  exports: [
    EquipmentStatsComponent,
    EquipmentStatusComponent,
    EquipmentEditComponent,
    MetricsEditComponent,
    HypervisionEditComponent,
    EquipmentDatagridComponent,
    EquipmentDatagridEditModal,
    AdminEquipmentDocumentComponent,
    AdminEquipmentDocumentEditModal,
    EquipmentStatusHistoryChartComponent,
    EquipmentAvailabilityFailureChartComponent,
    EquipmentReportChartComponent,
    EquipmentTrafficChartComponent,
    OperationLogComponent,
    EquipmentSelectComponent
  ],
})
export class EquipmentModule {}
