/** @format */

import { Injectable } from '@angular/core';
import { EquipmentKind, EquipmentKindCategory, EquipmentKindControl, Family, InteractiveSchema } from 'sesio-lib';
import { IDatatableOptions, IDatatableRecords } from '../_components/datagrid/datatable.class';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { last, map, mergeMap, tap } from 'rxjs/operators';
import { ModelMapper } from 'model-mapper';
import { HttpClient, HttpProgressEvent } from '@angular/common/http';
import { isNil, map as lmap } from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class EquipmentKindService {
  constructor(private http: HttpClient) {}

  public get(id: string): Observable<EquipmentKind> {
    return this.http
      .get<any[]>(`${environment.apiUrl}/equipment-kinds/${id}`)
      .pipe(map(data => new ModelMapper(EquipmentKind).map(data)));
  }

  public list({
    name,
    families,
    categories,
    supervision,
    hypervision,
    metrics,
  }: {
    name?:string,
    families?: Family[];
    categories?: EquipmentKindCategory[];
    supervision?: boolean;
    hypervision?: boolean;
    metrics?: boolean;
  }): Observable<EquipmentKind[]> {
    const params: any = {};
    if (name) params.name = name;
    if (families?.length) params.families = families;
    if (categories?.length) params.categories = categories;
    if (!isNil(supervision)) params.supervision = supervision;
    if (!isNil(hypervision)) params.hypervision = hypervision;
    if (!isNil(metrics)) params.metrics = metrics;
    return this.http
      .get<any[]>(`${environment.apiUrl}/equipment-kinds`, { params })
      .pipe(map(data => data.map(d => new ModelMapper(EquipmentKind).map(d))));
  }

  public listEnergyFluid(): Observable<EquipmentKind[]> {
    return this.http
      .get<any[]>(`${environment.apiUrl}/equipment-kinds/energy-fluid`)
      .pipe(map(data => data.map(d => new ModelMapper(EquipmentKind).map(d))));
  }

  public create(data: any): Observable<EquipmentKind> {
    return this.http
      .post<any>(`${environment.apiUrl}/equipment-kinds`, data)
      .pipe(map(res => new ModelMapper(EquipmentKind).map(res)));
  }

  // public search(name: string, category?: string): Observable<EquipmentKind[]> {
  //   const params: any = {};
  //   if (name) { params.name = name; }
  //   if (category) { { params.category = category; } }
  //   return this.http.get<any[]>(`${environment.apiUrl}/equipment-kinds`, { params })
  //     .pipe(map(data => data.map(d => new ModelMapper(EquipmentKind).map(d))));
  // }

  public search(
    limit: number,
    skip: number,
    filter?: {
      search?: string;
      families?: Family[];
    }
  ): Observable<{ data: EquipmentKind[]; matchCount: number }> {
    const params: any = { limit, skip };
    if (filter?.search) params.search = filter.search;
    if (filter?.families) params.families = filter.families;
    return this.http.get<any>(`${environment.apiUrl}/equipment-kinds/search`, { params }).pipe(
      map(res => ({
        matchCount: res.matchCount,
        data: lmap(res.data, (d: any) => new ModelMapper(EquipmentKind).map(d)),
      }))
    );
  }

  public datatable(options: {
    families?: Family[];
    categories?: EquipmentKindCategory[];
    query: IDatatableOptions;
  }): Observable<IDatatableRecords<any>> {
    return this.http.post<any>(`${environment.apiUrl}/equipment-kinds/datatable`, options);
  }

  public requestUploadUrl(id: string, name: string, type: string): Observable<string> {
    return this.http.post(
      `${environment.apiUrl}/equipment-kinds/${id}/get-upload-url`,
      { name, type },
      { responseType: 'text' }
    );
  }

  public addIcon(id: string, file: File, progress?: (event: HttpProgressEvent) => void): Observable<boolean> {
    let path: string;
    return this.requestUploadUrl(id, 'icon.svg', 'image/svg+xml').pipe(
      tap(url => (path = url.split('?')[0])),
      mergeMap(url => {
        return this.http
          .put(url, file, {
            reportProgress: true,
            observe: 'events',
            headers: { 'Content-Type': file.type },
          })
          .pipe(tap((event: HttpProgressEvent) => (progress ? progress(event) : null)));
      }),
      last(),
      mergeMap(() =>
        this.http.post<boolean>(`${environment.apiUrl}/equipment-kinds/${id}/icon`, { path, name: file.name })
      )
    );
  }

  public deleteIcon(id: string): Observable<boolean> {
    return this.http.delete<boolean>(`${environment.apiUrl}/equipment-kinds/${id}/icon`);
  }

  public update(id: string, data: any): Observable<boolean> {
    return this.http.patch<boolean>(`${environment.apiUrl}/equipment-kinds/${id}`, data);
  }

  public getControls(id: string): Observable<EquipmentKindControl[]> {
    return this.http
      .get<any[]>(`${environment.apiUrl}/equipment-kinds/${id}/controls`)
      .pipe(map(data => data.map(d => new ModelMapper(EquipmentKindControl).map(d))));
  }

  public createControl(id: string, name: string): Observable<EquipmentKindControl> {
    return this.http
      .post<any>(`${environment.apiUrl}/equipment-kinds/${id}/controls`, { name })
      .pipe(map(data => new ModelMapper(EquipmentKindControl).map(data)));
  }

  public deleteControl(id: string, controlId: string): Observable<boolean> {
    return this.http.delete<boolean>(`${environment.apiUrl}/equipment-kinds/${id}/controls/${controlId}`);
  }

  public createControlOption(id: string, controlId: string, name: string): Observable<boolean> {
    return this.http.post<any>(`${environment.apiUrl}/equipment-kinds/${id}/controls/${controlId}`, { name });
  }

  public deleteControlOption(id: string, controlId: string, name: string): Observable<boolean> {
    return this.http.delete<boolean>(
      `${environment.apiUrl}/equipment-kinds/${id}/controls/${controlId}/options/${name}`
    );
  }
}
