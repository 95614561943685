/** @format */

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { User } from '../_classes/user';
import { environment } from '../../environments/environment';
import { ModelMapper } from 'model-mapper';
import { StorageService } from './storage.service';
import { OrganizationalUnit } from 'sesio-lib';
import { IDatatableOptions, IDatatableRecords } from '../_components/datagrid/datatable.class';
import { cloneDeep, get, set, pick, map as lmap } from 'lodash-es';
import * as Sentry from '@sentry/angular';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  static USER_KEY = 'USER';
  public $user = new BehaviorSubject<User>(null);

  constructor(private http: HttpClient, private storageService: StorageService) {
    this.init();
  }

  public search(search: string): Observable<User[]> {
    return this.http
      .get<any>(`${environment.apiUrl}/users/search`, { params: { search } })
      .pipe(map(data => lmap(data, d => new ModelMapper(User).map(d))));
  }

  public get(id: string): Observable<User> {
    return this.http.get<any>(`${environment.apiUrl}/users/${id}`).pipe(map(data => new ModelMapper(User).map(data)));
  }

  public async clearUser(): Promise<void> {
    await this.storageService.remove(UserService.USER_KEY);
    this.$user.next(null);
    Sentry.configureScope(scope => scope.setUser(null));
    return;
  }

  public async loadUser(): Promise<User> {
    try {
      const res = await this.http
        .get<any>(`${environment.apiUrl}/users/me`)
        .pipe(map(data => new ModelMapper(User).map(data)))
        .toPromise();
      this.storageService.set(UserService.USER_KEY, new ModelMapper(User).serialize(res));
      this.$user.next(res);
      Sentry.configureScope(scope => scope.setUser(pick(res, 'id', 'username', 'email')));
      return res;
    } catch (err) {
      if (err.status !== 401) {
        throw err;
      }
    }
  }

  public update(id: string, data: any): Observable<boolean> {
    return this.http.patch<boolean>(`${environment.apiUrl}/users/${id}`, data);
  }

  public setTutorials(id: string, tutorials: any): Observable<boolean> {
    return this.http.put<boolean>(`${environment.apiUrl}/users/${id}/tutorials`, tutorials);
  }

  public updateSpaces(id: string, spaces: string[]): Observable<boolean> {
    return this.http.put<boolean>(`${environment.apiUrl}/users/${id}/spaces`, spaces);
  }

  public updateMyPerimeter(perimeter: string[]): Observable<boolean> {
    return this.updatePerimeter(this.$user.value.id, perimeter);
  }

  public getPerimeter(id?: string): Observable<OrganizationalUnit[]> {
    if (!id) {
      id = this.$user.value.id;
    }
    return this.http
      .get<any[]>(`${environment.apiUrl}/users/${id}/perimeter`)
      .pipe(map(data => data.map(d => new ModelMapper(OrganizationalUnit).map(d))));
  }

  public updatePerimeter(id: string, perimeter: string[]): Observable<boolean> {
    return this.http.put<boolean>(`${environment.apiUrl}/users/${id}/perimeter`, perimeter).pipe(
      tap(() => {
        if (this.$user.value.id === id) {
          this.loadUser();
        }
      })
    );
  }

  public async updateDatatableConfig(configKey: string, config: any): Promise<any[]> {
    const datatable = set(cloneDeep(this.$user.value.datatable), configKey, config);
    const res = await this.http.patch<any>(`${environment.apiUrl}/users/me/datatable/config`, datatable).toPromise();
    if (res) {
      this.$user.value.datatable = datatable;
      this.storageService.set(UserService.USER_KEY, new ModelMapper(User).serialize(this.$user.value));
      this.$user.next(this.$user.value);
    }
    return res;
  }

  public async getDatatableConfig(configKey: string): Promise<any[]> {
    return get(cloneDeep(this.$user.value.datatable), configKey, []);
  }

  public async addFavoriteEquipment(equipmentId: string): Promise<boolean> {
    const id = this.$user.value.id;
    const res = await this.http
      .post<boolean>(`${environment.apiUrl}/users/${id}/favorites/equipments`, {
        equipmentId,
      })
      .toPromise();
    if (res) {
      this.$user.value.addFavoriteEquipment(equipmentId);
      this.storageService.set(UserService.USER_KEY, new ModelMapper(User).serialize(this.$user.value));
      this.$user.next(this.$user.value);
    }
    return res;
  }

  public async removeFavoriteEquipment(equipmentId: string): Promise<boolean> {
    const id = this.$user.value.id;
    const res = await this.http
      .delete<boolean>(`${environment.apiUrl}/users/${id}/favorites/equipments/${equipmentId}`)
      .toPromise();
    if (res) {
      this.$user.value.removeFavoriteEquipment(equipmentId);
      this.storageService.set(UserService.USER_KEY, new ModelMapper(User).serialize(this.$user.value));
      this.$user.next(this.$user.value);
    }
    return res;
  }

  public async addFollowEquipment(equipmentId: string): Promise<boolean> {
    const id = this.$user.value.id;
    const res = await this.http
      .post<boolean>(`${environment.apiUrl}/users/${id}/follow/equipments`, {
        equipmentId,
      })
      .toPromise();
    if (res) {
      this.$user.value.addFollowEquipment(equipmentId);
      this.storageService.set(UserService.USER_KEY, new ModelMapper(User).serialize(this.$user.value));
      this.$user.next(this.$user.value);
    }
    return res;
  }

  public async removeFollowEquipment(equipmentId: string): Promise<boolean> {
    const id = this.$user.value.id;
    const res = await this.http
      .delete<boolean>(`${environment.apiUrl}/users/${id}/follow/equipments/${equipmentId}`)
      .toPromise();
    if (res) {
      this.$user.value.removeFollowEquipment(equipmentId);
      this.storageService.set(UserService.USER_KEY, new ModelMapper(User).serialize(this.$user.value));
      this.$user.next(this.$user.value);
    }
    return res;
  }

  public datatable(options: IDatatableOptions): Observable<IDatatableRecords<any>> {
    return this.http.post<any>(`${environment.apiUrl}/users/datatable`, options);
  }

  private async init(): Promise<void> {
    const data = await this.storageService.get(UserService.USER_KEY);
    this.$user.next(new ModelMapper(User).map(data));
    Sentry.configureScope(scope =>
      scope.setUser(this.$user.value ? pick(this.$user.value, 'id', 'username', 'email') : null)
    );
  }
}
