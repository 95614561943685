import { Component, Input, OnInit } from '@angular/core';
import { isNil } from 'lodash-es';

@Component({
  selector: 'app-star-rate',
  templateUrl: './star-rate.component.html',
  styleUrls: ['./star-rate.component.scss'],
})
export class StarRateComponent implements OnInit {

  @Input()
  public shrink = false;

  @Input('value')
  public set setValue(value: number) {
    if (isNil(value) || typeof value !== 'number') {
      this.value = 0;
    } else {
      this.percent = 0;
      this.value = value;
      this.percent = value ? ((Math.round((value / 5) * this.step) / this.step) * 100) : 0;
    }
  }
  public value = 0;
  public percent = 0;

  @Input()
  private step = 10;

  constructor() { }

  ngOnInit() { }

}
