/** @format */

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {map as lmap} from 'lodash-es';
import {ModelMapper} from 'model-mapper';
import hash from 'object-hash';
import {ConnectableObservable, Observable} from 'rxjs';
import {map, publish, share, shareReplay, tap} from 'rxjs/operators';
import {
  Agency,
  Cluster,
  Division,
  EsiMetrics,
  Group,
  Location,
  MapInfoOrganizationalUnit,
  OrganizationalUnit,
  OrganizationalUnitKind,
} from 'sesio-lib';
import {environment} from '../../environments/environment';
import {IHeaderSearch, buildHeaderSearchFilter} from '../_classes/header-search';
import {IDatatableRecords} from '../_components/datagrid/datatable.class';

@Injectable({
  providedIn: 'root',
})
export class OrganizationalUnitService {
  private metrics = {};

  constructor(private http: HttpClient) {}

  public datatable(options: any, params?: any): Observable<IDatatableRecords<any>> {
    return this.http.post<any>(`${environment.apiUrl}/organizational-unit/datatable`, options, {params});
  }

  public advancedSearchItems(search: string): Observable<{kind: string; value: string}[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/organizational-unit/advanced-search-items`, {
      params: {search},
    });
  }

  public treemap(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/organizational-unit/treemap`);
  }

  public mapInfo(headerSearch: IHeaderSearch): Observable<MapInfoOrganizationalUnit[]> {
    const params: any = {};
    return this.http
      .post<any[]>(`${environment.apiUrl}/organizational-unit/get-map-info`, buildHeaderSearchFilter(headerSearch), {
        params,
      })
      .pipe(map(data => lmap(data, d => new ModelMapper(MapInfoOrganizationalUnit).map(d))));
  }

  public groupList(headerSearch: IHeaderSearch): Observable<any[]> {
    const params: any = {};
    return this.http.post<any[]>(
      `${environment.apiUrl}/organizational-unit/get-group-list`,
      buildHeaderSearchFilter(headerSearch),
      {params}
    );
  }

  public groupListInfo(
    headerSearch: IHeaderSearch,
    pageIndex: number,
    pageSize: number
  ): Observable<{total: number; data: any[]}> {
    const params: any = {pageIndex,pageSize};
    return this.http.post<any>(
      `${environment.apiUrl}/organizational-unit/get-group-list-info`,
      buildHeaderSearchFilter(headerSearch),
      {params}
    );
  }

  public search(
    {
      ids,
      kind,
      search,
    }: {
      ids?: string[];
      kind?: OrganizationalUnitKind;
      search?: string;
    },
    fields?: string,
    limit?: number,
    skip?: number
  ): Observable<{data: OrganizationalUnit[]; matchCount: number}> {
    const params: any = {};
    if (ids?.length) params.ids = ids;
    if (kind) params.kind = kind;
    if (search) params.search = search;
    if (limit) params.limit = limit;
    if (fields) params.fields = fields;
    if (skip) params.skip = skip;
    return this.http.get<any>(`${environment.apiUrl}/organizational-unit/search`, {params}).pipe(
      map(res => ({
        matchCount: res.matchCount,
        data: lmap(res.data, (d: any) => new ModelMapper(OrganizationalUnit).map(d)),
      }))
    );
  }

  public list(name: string, kind?: string, ancestor?: string): Observable<OrganizationalUnit[]> {
    const params: any = {};
    if (name) params.name = name;
    if (kind) params.kind = kind;
    if (ancestor) params.ancestor = ancestor;

    return this.http
      .get<any[]>(`${environment.apiUrl}/organizational-unit`, {params})
      .pipe(map(data => data.map(d => new ModelMapper(OrganizationalUnit).map(d))));
  }

  public get<T extends OrganizationalUnit>(id: string, fields = ''): Observable<T> {
    return this.http
      .get<any>(`${environment.apiUrl}/organizational-unit/${id}`, {
        params: {fields},
      })
      .pipe(
        map(data => {
          if (!data) {
            return null;
          }
          let d: ModelMapper<any>;
          switch (data.kind) {
            case 'cluster':
              d = new ModelMapper(Cluster);
              break;
            case 'division':
              d = new ModelMapper(Division);
              break;
            case 'agency':
              d = new ModelMapper(Agency);
              break;
            case 'group':
              d = new ModelMapper(Group);
              break;
            default:
              d = new ModelMapper(OrganizationalUnit);
          }
          return d.map(data);
        })
      );
  }

  public getContactInfo(id: string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/organizational-unit/${id}/contact-info`);
  }

  public getLocation(id: string): Observable<Location> {
    return this.http
      .get<any>(`${environment.apiUrl}/organizational-unit/${id}/location`)
      .pipe(map(data => new ModelMapper(Location).map(data)));
  }

  public getClusters(): Observable<Cluster[]> {
    return this.http
      .get<any[]>(`${environment.apiUrl}/organizational-unit`, {
        params: {kind: 'cluster'},
      })
      .pipe(map(data => data.map(d => new ModelMapper(Cluster).map(d))));
  }

  public getDivisions(ancestor?: string): Observable<Division[]> {
    const params: any = {kind: 'division'};
    if (ancestor) {
      params.ancestor = ancestor;
    }
    return this.http
      .get<any[]>(`${environment.apiUrl}/organizational-unit`, {params})
      .pipe(map(data => data.map(d => new ModelMapper(Division).map(d))));
  }

  public getAgencies(ancestor?: string): Observable<Agency[]> {
    const params: any = {kind: 'agency'};
    if (ancestor) {
      params.ancestor = ancestor;
    }
    return this.http
      .get<any[]>(`${environment.apiUrl}/organizational-unit`, {params})
      .pipe(map(data => data.map(d => new ModelMapper(Agency).map(d))));
  }

  public getAgency(id: string): Observable<Agency> {
    return this.http
      .get<any[]>(`${environment.apiUrl}/organizational-unit/${id}`)
      .pipe(map(data => new ModelMapper(Agency).map(data)));
  }

  public getGroups(ancestor?: string): Observable<Group[]> {
    const params: any = {kind: 'group'};
    if (ancestor) {
      params.ancestor = ancestor;
    }
    return this.http
      .get<any[]>(`${environment.apiUrl}/organizational-unit`, {params})
      .pipe(map(data => data.map(d => new ModelMapper(Group).map(d))));
  }

  public getGroup(id: string): Observable<Group> {
    return this.http
      .get<any[]>(`${environment.apiUrl}/organizational-unit/${id}`)
      .pipe(map(data => new ModelMapper(Group).map(data)));
  }

  public getConsumption(headerSearch: IHeaderSearch = {}): Observable<EsiMetrics> {
    const filter = buildHeaderSearchFilter(headerSearch);
    const id = hash(filter);
    if (this.metrics[id]) {
      return this.metrics[id];
    }
    this.metrics[id] = this.http
      .post<any>(`${environment.apiUrl}/organizational-unit/get-metrics`, filter)
      .pipe(share())
      .pipe(
        tap(() => (this.metrics[id] = null)),
        map(data => new ModelMapper(EsiMetrics).map(data)),
        shareReplay(),
        publish()
      ) as ConnectableObservable<EsiMetrics>;
    this.metrics[id].connect();
    return this.metrics[id];
  }

  public update(id: string, update: any): Observable<boolean> {
    return this.http.patch<boolean>(`${environment.apiUrl}/organizational-unit/${id}`, update);
  }
}
