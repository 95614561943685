import { Transmitter, Equipment } from './equipment';
import { propertyMap } from 'model-mapper';
import moment from 'moment';

export enum ParkingDoorSignatureState {
  NOT_CONNECTED = 0,
  ONLINE = 1,
  CRITICAL_OPEN = 2,
  CRITICAL_CLOSE = 3,
  KO_OPEN = 4, // OFFLINE
  KO_CLOSE = 5, // OFFLINE
  MISSING_DATA = 10,
}

export enum ParkingDoorState {
  OPEN = 1,
  CLOSE = 0,
  OK = 0,
  KO = 1,
}

export enum EquipmentParkingDoorKind {
  DEFAULT = 0, 
  OVERHEAD_DOOR = 1, 
  ACCORDION_DOOR = 2, 
  FRENCH_DOOR = 3, 
  SECTIONAL_DOOR = 4, 
  SLIDING_GATE = 5, 
  LIFTING_BARRIER = 6, 
  RETRACTABLE_BOLLARD = 7, 
  TURNTABLE = 8,
}

export class ParkingDoorMetadata {

  @propertyMap()
  public batch: string;

  @propertyMap()
  public provider: string;

  @propertyMap()
  public failureRate: number;

  @propertyMap()
  public failureCount: number;

  @propertyMap()
  public availabilityRate: number;

  @propertyMap()
  public state: number;

  @propertyMap()
  public signatureState: ParkingDoorSignatureState;

  @propertyMap({ type: 'Moment' })
  public signatureLastChangeDate: moment.Moment;

  @propertyMap({ type: 'Moment' })
  public signatureLastMotionDate: moment.Moment;

  @propertyMap({ type: 'Moment' })
  public signatureLastOpeningMotionDate: moment.Moment;

  @propertyMap({ type: 'Moment' })
  public signatureLastClosingMotionDate: moment.Moment;

  @propertyMap({ type: Transmitter })
  public transmitter: Transmitter;




  @propertyMap()
  public motionCount: number;

  @propertyMap()
  public motionCountAverageYearly: number;

}

export class ParkingDoor extends Equipment {

  @propertyMap({ type: ParkingDoorMetadata })
  public metadata: ParkingDoorMetadata;

}
