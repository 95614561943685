export enum Space {
  // IOT = 'iot',
  JANITOR = 'janitor',
  EQUIPMENT = 'equipment',
  ELEVATOR = 'elevator',
  PARKING_DOOR = 'parking-door',
  OPTICAL_FIBER = 'optical-fiber',
  RELAY_ANTENNA = 'relay-antenna',
  METER = 'meter',
  PHOTOVOLTAIC_PANEL = 'photovoltaic-panel',
  ACCESS_CONTROL = 'access-control',
  CHARGING_STATION = 'charging-station',
  FIRE_SAFETY = 'fire-safety',
  ENERGY_FLUID = 'energy-fluid',
  ISIDECK = 'isidesk',
  ADMINISTRATION = 'administration',
}
