import { animate, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, ChangeDetectorRef, Component, Input } from '@angular/core';
import { isNil } from 'lodash-es';
import { fade } from '../../../constants/animations';

@Component({
  selector: 'app-climat-tag',
  templateUrl: './climat-tag.component.html',
  styleUrls: ['./climat-tag.component.scss'],
  animations: [
    fade,
    trigger('line', [
      transition(':leave', [
        style({ opacity: 1 }), // initial
        animate('0.3s', style({ opacity: 0 })) // final
      ]),
      transition('* => *', [
        style({ opacity: 0 }), // initial
        animate('0.3s 0.3s', style({ opacity: 1 })) // final
      ])
    ])
  ]
})
export class ClimatTagComponent implements AfterViewInit {

  @Input()
  public display: 'full' | 'letter' = 'full';

  @Input('value')
  public set setValue(value: number) {
    if (isNil(value) || typeof value !== 'number') {
      this.value = null;
      this.position = -60;
    } else {
      this.value = Math.floor(value);
      this.changed(this.value);
    }
  }
  public value = 0;
  public letter: string;
  public color: string;
  public position = -60;
  public isAnimationDisabled = true;

  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngAfterViewInit(): void {
    this.isAnimationDisabled = false;
    this.changeDetectorRef.detectChanges();
  }

  private changed(value: number): void {
    if (value <= 5) {
      this.position = -32;
      this.letter = 'A';
      this.color = '#ddcaf5';
    }
    else if (value <= 10) {
      this.position = -5;
      this.letter = 'B';
      this.color = '#cbb1ef';
    }
    else if (value <= 20) {
      this.position = 22;
      this.letter = 'C';
      this.color = '#bc98ea';
    }
    else if (value <= 35) {
      this.position = 49;
      this.letter = 'D';
      this.color = '#aa7de5';
    }
    else if (value <= 55) {
      this.position = 75;
      this.letter = 'E';
      this.color = '#9964df';
    }
    else if (value <= 80) {
      this.position = 102;
      this.letter = 'F';
      this.color = '#874ada';
    }
    else {
      this.position = 129;
      this.letter = 'G';
      this.color = '#7028d2';
    }
  }

}
