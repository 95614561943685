import { propertyMap } from 'model-mapper';
import { EmbededContractor } from './embeded-contractor';
import { Family } from './family';
import {
  UnitPriceScheduleAccessControlCategory,
  UnitPriceScheduleField,
} from './unit-price-schedule';

export class EmbededUnitPriceSchedule {
  @propertyMap({ source: '_id' })
  public id: string;

  @propertyMap()
  public reference: string;

  @propertyMap()
  public family: Family;

  @propertyMap()
  public category: UnitPriceScheduleAccessControlCategory;

  @propertyMap()
  public name: string;

  @propertyMap()
  public externalReference: string;

  @propertyMap()
  public priority: number;

  @propertyMap()
  public fare: number;

  @propertyMap()
  public fareType: string;

  @propertyMap()
  public vat: number;

  @propertyMap()
  public completionTime: string;

  @propertyMap()
  public marketNumber: string;

  @propertyMap()
  public providerNumber: string;

  @propertyMap()
  public delayTime: string;

  @propertyMap()
  public cancellationTime: string;

  @propertyMap({ type: EmbededContractor })
  public contractor: EmbededContractor;

  @propertyMap()
  public hasPurchaseOrder: boolean;

  @propertyMap({ type: [UnitPriceScheduleField] })
  public fields: UnitPriceScheduleField[];
}
