import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LibConfig, LibConfigService } from '../config.service';
import { AggregationValue } from '../classes/aggregation';
import { Granularity } from './sensor-data.service';
import moment, { Moment } from 'moment';
import 'moment-timezone';
import { DrilldownService } from './drilldown.service';
import { IChartData } from '../modules/chart/chart.interface';
import { merge } from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class EquipmentAvailabilityService {
  constructor(
    @Inject(LibConfigService) protected config: LibConfig,
    protected http: HttpClient,
    private drilldownService: DrilldownService
  ) {}

  public avg(
    op: 'sum' | 'avg',
    granularity: Granularity,
    match: { ouId?: string; eqId?: string },
    start?: moment.Moment,
    end?: moment.Moment
  ): Observable<AggregationValue[]> {
    const params: any = { op };
    if (match) {
      if (match.ouId) {
        params.ouId = match.ouId;
      }
      if (match.eqId) {
        params.eqId = match.eqId;
      }
    }
    switch (granularity) {
      case 'year':
        return this.http.get<any[]>(
          `${this.config.environment.apiUrl}/equipment-availabilities/by-year`,
          { params }
        );
      case 'month':
        params.year = start.year();
        return this.http.get<any[]>(
          `${this.config.environment.apiUrl}/equipment-availabilities/by-month`,
          { params }
        );
      case 'day':
        params.year = start.year();
        params.month = start.month() + 1;
        return this.http.get<any[]>(
          `${this.config.environment.apiUrl}/equipment-availabilities/by-day`,
          { params }
        );
    }
  }

  public getDrilldownData(
    eqId: string,
    granularity?: Granularity,
    date?: Moment
  ): Promise<IChartData[]> {
    return this.drilldownService.getDrilldown(
      this.avg.bind(this),
      'sum',
      { eqId },
      granularity,
      date || moment(),
      'day'
    );
  }

  public getRangeDate(
    search: { organizationalUnitId?: string; equipmentId?: string },
    start?: moment.Moment,
    end?: moment.Moment
  ): Observable<AggregationValue[]> {
    const filter: any = merge(search, { start, end });
    return this.http.post<any[]>(
      `${this.config.environment.apiUrl}/equipment-availabilities/get-range-date`,
      filter
    );
  }

  public all({
    organizationalUnitId,
    equipmentId,
    start,
    end,
  }: {
    organizationalUnitId?: string;
    equipmentId?: string;
    start?: moment.Moment;
    end?: moment.Moment;
  }): Observable<AggregationValue[]> {
    const params: any = {};
    if (organizationalUnitId) {
      params.organizationalUnitId = organizationalUnitId;
    }
    if (equipmentId) {
      params.equipmentId = equipmentId;
    }
    if (start) params.start = start.toISOString();
    if (end) params.end = end.toISOString();
    return this.http.get<any[]>(
      `${this.config.environment.apiUrl}/equipment-availabilities/all`,
      { params }
    );
  }
}
