import { propertyMap } from 'model-mapper';
import { Moment } from 'moment';
import { EmbededUser } from './user';
import { File } from './file';
import { EmbededOrganizationalUnit } from './embeded-organizational-unit';
import { UnitPriceSchedule } from './unit-price-schedule';
import { EmbededEsi } from './esi';

export enum AccessControlStatus {
  CREATION = 'creation',
  WAITING_PURCHASE_ORDER_GENERATION = 'waiting-purchase-order-generation',
  ERROR_PURCHASE_ORDER_REQUEST = 'error-purchase-order-request',
  ERROR_PURCHASE_ORDER_GENERATION = 'error-purchase-order-generation',
  NEW = 'new',
  QUALIFIED = 'qualified',
  PRE_PLANNED = 'pre-planned',
  PLANNED = 'planned',
  REALIZED = 'realized',
  VALIDATED = 'validated',
  CANCELED = 'canceled',
}

export class AccessControlComment {
  @propertyMap()
  public content: string;

  @propertyMap({ type: File })
  public file: File;

  @propertyMap({ type: EmbededUser })
  public user: EmbededUser;

  @propertyMap({ type: 'Moment' })
  public date: Moment;
}

export class AccessControlAction {
  @propertyMap()
  public status: AccessControlStatus;

  @propertyMap({ type: EmbededUser })
  public user: EmbededUser;

  @propertyMap({ type: 'Moment' })
  public date: Moment;
}

export class AccessControl {
  @propertyMap({ source: '_id' })
  public id: string;

  @propertyMap()
  public reference: string;

  @propertyMap({ type: UnitPriceSchedule })
  public kind: UnitPriceSchedule;

  @propertyMap()
  public status: AccessControlStatus;

  @propertyMap({ type: EmbededUser })
  public applicant: EmbededUser;

  @propertyMap({ type: EmbededOrganizationalUnit })
  public organizationalUnit: EmbededOrganizationalUnit;

  @propertyMap({ type: EmbededEsi })
  public esi: EmbededEsi;

  @propertyMap()
  public purchaseOrderNumber: string;

  @propertyMap()
  public praxedoReferenceUUID: string;

  @propertyMap()
  public purchaseOrderRequestNumber: string;

  @propertyMap()
  public purchaseOrderRequestMessage: string;

  @propertyMap({ type: [AccessControlComment] })
  public comments: AccessControlComment[];

  @propertyMap({ type: [AccessControlAction] })
  public actions: AccessControlAction[];

  @propertyMap({ type: 'Moment' })
  public createdAt: Moment;

  @propertyMap()
  public fields: any;

  @propertyMap({ type: 'Moment' })
  public validationDate: Moment;
}
