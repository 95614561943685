/** @format */

import { Inject, Injectable } from '@angular/core';
import { fromEvent, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { ModelMapper } from 'model-mapper';
import { LibConfig, LibConfigService } from '../config.service';
import { Inspection } from '../classes/inspection';
import { Moment } from 'moment';
import { EquipmentKindFireSafetyCategory } from '../classes/equipment-kind';
import hash from 'object-hash';
import { merge, pick, map as lmap, get, omit } from 'lodash-es';
import { File } from '../classes/file';

@Injectable({
  providedIn: 'root',
})
export class InspectionService {
  constructor(@Inject(LibConfigService) protected config: LibConfig, protected http: HttpClient) {
    // fromEvent(window, 'offline').pipe(map(() => console.log('offline')));
    // fromEvent(window, 'online').pipe(map(() => console.log('online')));
    // console.log('online state', navigator.onLine)
  }

  private cache = {};

  public list({
    search,
    organizationalUnitIds,
    realEstateStructureIds,
  }: {
    search?: string;
    organizationalUnitIds?: string[];
    realEstateStructureIds?: string[];
  }): Observable<Inspection[]> {
    const params: any = {};
    if (search?.length) params.search = search;
    if (organizationalUnitIds?.length) params.organizationalUnitIds = organizationalUnitIds;
    if (realEstateStructureIds?.length) params.realEstateStructureIds = realEstateStructureIds;
    const paramsHash = hash(params);
    if (!!this.cache[paramsHash]) return of(this.cache[paramsHash]);
    return this.http.get<any[]>(`${this.config.environment.apiUrl}/inspections`, { params }).pipe(
      map(data => data.map(d => new ModelMapper(Inspection).map(d))),
      map(data => (this.cache[paramsHash] = data)),
      tap(() => setTimeout(() => delete this.cache[paramsHash], 5000))
    );
  }

  public get(id: string): Observable<Inspection> {
    return this.http
      .get<any[]>(`${this.config.environment.apiUrl}/inspections/${id}`)
      .pipe(map(data => new ModelMapper(Inspection).map(data)));
  }

  public delete(id: string): Observable<boolean> {
    return this.http.delete<boolean>(`${this.config.environment.apiUrl}/inspections/${id}`);
  }

  public getNextReferenceIndex(prefix: string): Observable<number> {
    return this.http.get<number>(`${this.config.environment.apiUrl}/inspections/next-reference-index`, {
      params: { prefix },
    });
  }

  public referenceExists(reference: string): Observable<string> {
    const params: any = { reference };
    return this.http.get<string>(`${this.config.environment.apiUrl}/inspections/reference-exists`, { params });
  }

  public getRegisteredCategories(realEstateStructureId: string): Observable<EquipmentKindFireSafetyCategory[]> {
    const params: any = { realEstateStructureId };
    return this.http.get<any[]>(`${this.config.environment.apiUrl}/inspections/registered-categories`, { params });
  }

  public create(data: any): Observable<Inspection> {
    return this.http
      .post<any>(`${this.config.environment.apiUrl}/inspections`, this.serializeForDto(data))
      .pipe(map(res => new ModelMapper(Inspection).map(res)));
  }

  public update(id: string, data: any): Observable<boolean> {
    return this.http.patch<boolean>(`${this.config.environment.apiUrl}/inspections/${id}`, this.serializeForDto(data));
  }

  public updateFiles(id: string, files: File[]): Observable<boolean> {
    return this.http.patch<boolean>(`${this.config.environment.apiUrl}/inspections/${id}/files`, { files });
  }

  public cancel(id: string): Observable<boolean> {
    return this.http.patch<boolean>(`${this.config.environment.apiUrl}/inspections/${id}/cancel`, {});
  }

  public reopen(id: string): Observable<boolean> {
    return this.http.patch<boolean>(`${this.config.environment.apiUrl}/inspections/${id}/reopen`, {});
  }

  private serializeForDto(data: any): any {
    return merge(
      pick(
        data,
        'status',
        'reference',
        'label',
        'organizationalUnit.id',
        'realEstateStructure.id',
        'equipmentCategories',
        'contractor.id',
        'config',
        'info',
        'metadata',
        'periodicity',
        'files'
      ),
      {
        equipments: lmap(get(data, 'equipments'), d => pick(d, 'id')),
        reports: lmap(get(data, 'reports'), report =>
          merge(omit(report, 'interventions'), {
            interventions: lmap(get(report, 'interventions'), intervention => {
              if (intervention.id) return { id: intervention.id };
              return merge(omit(intervention, 'equipment', 'unitPriceSchedule'), {
                equipment: pick(get(intervention, 'equipment'), 'id'),
                unitPriceSchedule: pick(get(intervention, 'unitPriceSchedule'), 'id'),
              });
            }),
          })
        ),
      }
    );
  }
}
