/** @format */

import { propertyMap } from 'model-mapper';
import { EmbededContractor } from './embeded-contractor';
import { EmbededEquipment } from './equipment';
import { EmbededOrganizationalUnit } from './embeded-organizational-unit';
import { StatusHistory } from './status-history';

export enum EquipmentStatusHistoryField {
  STATUS = 'status',
  SIGNATURES_STATUS = 'signatureStatus',
  SIGNATURES_STATUS_V2 = 'signatureStatusV2',
  SIGNATURE_FLOW_STATE = 'signatureFlowState',
  RIVP_EVENT = 'rivpEvent',
  CONTRACTOR_EVENT = 'contractorEvent',
  SUPERVISOR_EVENT = 'supervisorEvent',
  MAINTENANCE = 'maintenance',
}

export class EquipmentStatusHistory extends StatusHistory {
  @propertyMap()
  public field: EquipmentStatusHistoryField;

  @propertyMap({ type: EmbededOrganizationalUnit })
  public organizationalUnit: EmbededOrganizationalUnit;

  @propertyMap({ type: EmbededContractor })
  public contractor: EmbededContractor;

  @propertyMap({ type: EmbededEquipment })
  public equipment: EmbededEquipment;
}
