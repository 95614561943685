<div id="container" inViewport (inViewportAction)="setVisible($event.visible)"
  [inViewportOptions]="{ threshold: [100] }">
  <form *ngIf="visible && ready;" [formGroup]="formGroup">
    <div fxLayout="row wrap" fxLayoutAlign="start center">

      <div fxLayout="row wrap" fxLayoutAlign="start center" [style.width.%]="100">
        <mat-form-field *ngIf="!equipment?.reference" class="has-prefix" [style.width.px]="200"
          [floatLabel]="prefixReference ? 'always' : undefined">
          <mat-label>Référence Isitech</mat-label>
          <input autocomplete="off" matInput formControlName="ref" type="number" min="1" step="1" required>
          <mat-label *ngIf="prefixReference" matPrefix>
            {{ prefixReference }}{{ '0'.repeat(
            $any([(prefixPad - formGroup.get('ref').value?.toString()?.length), 0] | max)
            ) }}
          </mat-label>
          <mat-error *ngIf="formGroup.get('ref').errors?.required">
            <mat-label>Obligatoire</mat-label>
          </mat-error>
          <mat-error *ngIf="formGroup.get('ref').errors?.referenceExists">
            <mat-label>Déjà attribuée</mat-label>
          </mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="equipment?.reference">
          <mat-label>Référence Isitech</mat-label>
          <input autocomplete="off" matInput formControlName="reference" required>
        </mat-form-field>
        <mat-form-field [style.width.px]="200">
          <mat-label>Référence externe</mat-label>
          <input autocomplete="off" matInput formControlName="externalReference">
        </mat-form-field>
        <mat-form-field [style.width.px]="400">
          <mat-label translate>label</mat-label>
          <input autocomplete="off" matInput formControlName="label">
        </mat-form-field>

        <ng-container
          *ngIf="[Family.OPTICAL_FIBER, Family.RELAY_ANTENNA, Family.ENERGY_FLUID, Family.IOT].includes(formGroup.get('kind').value?.family)">
          <mat-form-field [style.width.px]="200" *ngIf="!isHidden.status">
            <mat-label>Statut de l'équipement</mat-label>
            <mat-select formControlName="status">
              <mat-select-trigger *ngIf="formGroup.get('status').value">
                <mat-label [style.color]="EquipmentStatusColor[formGroup.get('status').value]">
                  <span translate>{{formGroup.get('status').value}}</span>
                </mat-label>
              </mat-select-trigger>
              <mat-option *ngFor="let status of equipmentStatuses" [value]="status">
                <mat-label [style.color]="EquipmentStatusColor[status]">
                  <span translate>{{status}}</span>
                </mat-label>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>

        <mat-form-field [style.width.px]="200">
          <mat-label>État</mat-label>
          <mat-select formControlName="state">
            <mat-option [value]="1">Très mauvais état</mat-option>
            <mat-option [value]="2">Mauvais état</mat-option>
            <mat-option [value]="3">Moyen</mat-option>
            <mat-option [value]="4">Bon état</mat-option>
            <mat-option [value]="5">Très bon état</mat-option>
          </mat-select>
          <mat-error *ngIf="formGroup.get('manager').errors?.required">
            <mat-label>Obligatoire</mat-label>
          </mat-error>
        </mat-form-field>

        <mat-form-field [style.width.px]="200">
          <mat-label>Gestionnaire</mat-label>
          <mat-select formControlName="manager" [compareWith]="compareWithEntity" required>
            <mat-option *ngFor="let option of managers" [value]="option">
              {{option?.name}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="formGroup.get('manager').errors?.required">
            <mat-label>Obligatoire</mat-label>
          </mat-error>
        </mat-form-field>
        <mat-form-field [style.width.px]="200">
          <mat-label translate>lot-number</mat-label>
          <input autocomplete="off" matInput formControlName="lotNumber">
        </mat-form-field>
      </div>

      <app-organizational-unit-select *ngIf="!isHidden.organizationalUnit" class="form-field"
        formControlName="organizationalUnit" required [style.width.px]="616">
      </app-organizational-unit-select>

      <app-real-estate-structure-select *ngIf="!isHidden.realEstateStructure" class="form-field"
        formControlName="realEstateStructure" [required]="isRealEstateStructureRequired"
        [organizationalUnit]="formGroup.get('organizationalUnit').value" [style.width.px]="300">
      </app-real-estate-structure-select>

      <mat-form-field *ngIf="!isHidden.kind" [style.width.px]="400">
        <mat-label>Type d'équipement</mat-label>
        <mat-select formControlName="kind" [compareWith]="compareWithEntity" required>
          <mat-optgroup *ngFor="let group of kinds | groupBy:'category' | pairs" [label]="group[0] | translate">
            <mat-option *ngFor="let kind of group[1]" [value]="kind">
              <mat-label translate>{{kind.name}}</mat-label>
            </mat-option>
          </mat-optgroup>
        </mat-select>
        <mat-error *ngIf="formGroup.get('kind').errors?.required">
          <mat-label>Obligatoire</mat-label>
        </mat-error>
      </mat-form-field>
      <mat-form-field [style.width.px]="300">
        <mat-label translate>brand</mat-label>
        <input autocomplete="off" matInput formControlName="brand">
      </mat-form-field>
      <mat-form-field [style.width.px]="300">
        <mat-label translate>model</mat-label>
        <input autocomplete="off" matInput formControlName="model">
      </mat-form-field>
      <mat-form-field [style.min-width.px]="200">
        <mat-label translate>date-of-modernization</mat-label>
        <input matInput [matDatepicker]="dpmodernizationDate" formControlName="modernizationDate">
        <mat-datepicker-toggle matSuffix [for]="dpmodernizationDate"></mat-datepicker-toggle>
        <mat-datepicker #dpmodernizationDate></mat-datepicker>
      </mat-form-field>
      <mat-form-field [style.min-width.px]="200">
        <mat-label translate>commissioning-date</mat-label>
        <input matInput [matDatepicker]="dpCommissioningDate" formControlName="commissioningDate">
        <mat-datepicker-toggle matSuffix [for]="dpCommissioningDate"></mat-datepicker-toggle>
        <mat-datepicker #dpCommissioningDate></mat-datepicker>
      </mat-form-field>
      <mat-form-field [style.width.px]="200">
        <mat-label translate>tag-type</mat-label>
        <mat-select formControlName="tagType">
          <mat-option *ngFor="let type of EquipmentTagType | values" [value]="type">
            <span translate>{{type}}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field [style.width.px]="200">
        <mat-label translate>tag-code</mat-label>
        <input autocomplete="off" matInput formControlName="tagCode">
      </mat-form-field>
      <mat-form-field [style.width.%]="100">
        <mat-label>Localisation précise de l'équipement</mat-label>
        <input autocomplete="off" matInput formControlName="location">
      </mat-form-field>
      <mat-form-field [style.width.%]="100">
        <mat-label>Commentaires, informations propres à l'équipement</mat-label>
        <textarea autocomplete="off" matInput formControlName="comment" cdkTextareaAutosize cdkAutosizeMinRows="2"
          cdkAutosizeMaxRows="5"></textarea>
      </mat-form-field>

      <div fxLayout="column" [style.width.%]="100">
        <div *ngIf="formGroup.get('kind')?.value?.family !== Family.ENERGY_FLUID" class="divider">
          <mat-divider></mat-divider>
          <ion-label color="primary">Prestataires</ion-label>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start center">
          <mat-form-field [style.width.px]="200" formGroupName="contractors">
            <mat-label>Prestataire</mat-label>
            <mat-select formControlName="maintenance" [compareWith]="compareWithEntity" required>
              <mat-option *ngFor="let option of contractors | orderBy:'name'" [value]="option">
                {{option?.name}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="formGroup.get('contractors.maintenance').errors?.required">
              <mat-label>Obligatoire</mat-label>
            </mat-error>
          </mat-form-field>
          <mat-form-field *ngIf="formGroup.get('kind')?.value?.family !== Family.ENERGY_FLUID"
            [style.min-width.px]="220">
            <mat-label *ngIf="formGroup.get('kind')?.value?.family === Family.RELAY_ANTENNA">
              Date début de convention cadre
            </mat-label>
            <mat-label *ngIf="formGroup.get('kind')?.value?.family !== Family.RELAY_ANTENNA">
              Date début de contrat
            </mat-label>
            <input matInput [matDatepicker]="dpContractStartDate" formControlName="contractStartDate">
            <mat-datepicker-toggle matSuffix [for]="dpContractStartDate"></mat-datepicker-toggle>
            <mat-datepicker #dpContractStartDate></mat-datepicker>
          </mat-form-field>
          <mat-form-field *ngIf="formGroup.get('kind')?.value?.family !== Family.ENERGY_FLUID"
            [style.min-width.px]="220">
            <mat-label *ngIf="formGroup.get('kind')?.value?.family === Family.RELAY_ANTENNA">
              Date fin de convention cadre
            </mat-label>
            <mat-label *ngIf="formGroup.get('kind')?.value?.family !== Family.RELAY_ANTENNA">
              Date fin de contrat
            </mat-label>
            <input matInput [matDatepicker]="dpContractEndDate" formControlName="contractEndDate">
            <mat-datepicker-toggle matSuffix [for]="dpContractEndDate"></mat-datepicker-toggle>
            <mat-datepicker #dpContractEndDate></mat-datepicker>
          </mat-form-field>
        </div>
        <div
          *ngIf="![Family.OPTICAL_FIBER, Family.RELAY_ANTENNA, Family.ENERGY_FLUID, Family.IOT].includes(formGroup.get('kind').value?.family)"
          fxLayout="row wrap" fxLayoutAlign="start center">
          <mat-form-field [style.width.px]="200" formGroupName="contractors">
            <mat-label>Installateur</mat-label>
            <mat-select formControlName="fitter" [compareWith]="compareWithEntity">
              <mat-option *ngFor="let option of contractors | orderBy:'name'" [value]="option">
                {{option?.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field [style.width.px]="200" formGroupName="contractors">
            <mat-label>Bureau de contrôle</mat-label>
            <mat-select formControlName="control" [compareWith]="compareWithEntity">
              <mat-option *ngFor="let option of contractors | orderBy:'name'" [value]="option">
                {{option?.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field [style.width.px]="200" formGroupName="contractors">
            <mat-label>Bureau d'étude</mat-label>
            <mat-select formControlName="study" [compareWith]="compareWithEntity">
              <mat-option *ngFor="let option of contractors | orderBy:'name'" [value]="option">
                {{option?.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field [style.width.px]="200" formGroupName="contractors">
            <mat-label>Télésurveilleur</mat-label>
            <mat-select formControlName="telemonitor" [compareWith]="compareWithEntity">
              <mat-option *ngFor="let option of contractors | orderBy:'name'" [value]="option">
                {{option?.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field [style.width.px]="200" formGroupName="contractors">
            <mat-label>Superviseur</mat-label>
            <mat-select formControlName="supervisor" [compareWith]="compareWithEntity">
              <mat-option *ngFor="let option of contractors | orderBy:'name'" [value]="option">
                {{option?.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field [style.width.px]="200" formGroupName="contractors">
            <mat-label>Hyperviseur</mat-label>
            <mat-select formControlName="hypervisor" [compareWith]="compareWithEntity">
              <mat-option *ngFor="let option of contractors | orderBy:'name'" [value]="option">
                {{option?.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div
        *ngIf="![Family.OPTICAL_FIBER, Family.RELAY_ANTENNA, Family.ENERGY_FLUID, Family.IOT].includes(formGroup.get('kind').value?.family)"
        fxLayout="column" [style.width.%]="100">
        <div class="divider">
          <mat-divider></mat-divider>
          <ion-label color="primary">Signature - Supervision</ion-label>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start center">
          <mat-form-field [style.width.px]="200" *ngIf="!isHidden.status">
            <mat-label>Statut de l'équipement</mat-label>
            <mat-select formControlName="status">
              <mat-option *ngFor="let status of equipmentStatuses" [value]="status">
                <mat-label [style.color]="EquipmentStatusColor[status]">
                  <span translate>{{status}}</span>
                </mat-label>
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field [style.width.px]="200">
            <mat-label>Connexion</mat-label>
            <mat-select formControlName="linked">
              <mat-option [value]="true">
                <mat-label>Connecté</mat-label>
              </mat-option>
              <mat-option [value]="false">
                <mat-label>Non connecté</mat-label>
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field [style.width.px]="200">
            <mat-label>Supervision</mat-label>
            <mat-select formControlName="supervised">
              <mat-option [value]="true">
                <mat-label>Supervisé</mat-label>
              </mat-option>
              <mat-option [value]="false">
                <mat-label>Non supervisé</mat-label>
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field [style.width.px]="200">
            <mat-label>État signature</mat-label>
            <mat-select formControlName="signatureStatus">
              <mat-option *ngFor="let status of signatureStatuses" [value]="status">
                <mat-label translate>{{status}}</mat-label>
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Évènement prestataire</mat-label>
            <input autocomplete="off" matInput formControlName="contractorEvent">
          </mat-form-field>
          <mat-form-field [style.width.px]="200">
            <mat-label>Évènement RIVP</mat-label>
            <mat-select formControlName="rivpEvent">
              <mat-option *ngFor="let event of rivpEvents" [value]="event">
                <mat-label translate>{{event}}</mat-label>
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-checkbox formControlName="propagateSupervision" [style.width.px]="250">
            <mat-label>Envoyer les alertes au superviseur</mat-label>
          </mat-checkbox>
          <mat-form-field>
            <mat-label>Trafic de la veille</mat-label>
            <input type="number" step="1" min="0" autocomplete="off" matInput
              formControlName="metrics.{{Metric.MOTION_COUNT}}">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Trafic moyen</mat-label>
            <input type="number" step="1" min="0" autocomplete="off" matInput formControlName="signatureMotionAverage">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Usage</mat-label>
            <input autocomplete="off" matInput formControlName="signatureSettingName">
          </mat-form-field>
        </div>
      </div>

      <div *ngIf="formGroup.value?.kind?.metadata?.length && formGroup.value?.metadata" formGroupName="metadata"
        fxLayout="column" [style.width.%]="100">
        <div *ngFor="let block of (formGroup.value.kind.metadata | groupBy: ['block']) | pairs" fxLayout="column"
          [style.width.%]="100">
          <div class="divider">
            <mat-divider></mat-divider>
            <ion-label color="primary">{{block[0] || 'Métadonnées'}}</ion-label>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="start center">
            <ng-container *ngFor="let data of block[1]" [ngSwitch]="data.kind">
              <mat-form-field *ngSwitchCase="EquipmentKindMetadataKind.STRING" [style.min-width.px]="300">
                <mat-label>{{data.label}}</mat-label>
                <input type="text" autocomplete="off" matInput [formControlName]="data.name"
                  [required]="data.isRequired">
              </mat-form-field>
              <mat-form-field *ngSwitchCase="EquipmentKindMetadataKind.NUMBER" [style.min-width.px]="300">
                <mat-label>{{data.label}}</mat-label>
                <input type="number" autocomplete="off" matInput [formControlName]="data.name"
                  [required]="data.isRequired">
              </mat-form-field>
              <mat-checkbox *ngSwitchCase="EquipmentKindMetadataKind.BOOLEAN" [formControlName]="data.name"
                [required]="data.isRequired" [style.min-width.px]="300">
                <mat-label>{{data.label}}</mat-label>
              </mat-checkbox>
              <mat-form-field *ngSwitchCase="EquipmentKindMetadataKind.DATE" [style.min-width.px]="300">
                <mat-label>{{data.label}}</mat-label>
                <input matInput [matDatepicker]="dp" [required]="data.isRequired" [formControlName]="data.name">
                <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                <mat-datepicker #dp></mat-datepicker>
              </mat-form-field>
              <mat-form-field *ngSwitchCase="EquipmentKindMetadataKind.STRING_ENUM" [style.min-width.px]="300">
                <mat-label>{{data.label}}</mat-label>
                <mat-select [formControlName]="data.name" [required]="data.isRequired">
                  <mat-option *ngFor="let option of data.options" [value]="option?.value">
                    {{option?.label}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field *ngSwitchCase="EquipmentKindMetadataKind.STRING_ARRAY" [style.width.%]="100">
                <mat-label>{{data.label}}</mat-label>
                <mat-chip-list #chipList [attr.aria-label]="data.label" multiple [formControlName]="data.name">
                  <mat-chip *ngFor="let chip of formGroup.get('metadata').get(data.name).value" [value]="chip"
                    (removed)="removeFrom(formGroup.get('metadata').get(data.name), chip)">
                    {{chip}}
                  </mat-chip>
                  <input type="text" [placeholder]="'new' | translate" [matChipInputFor]="chipList"
                    (matChipInputTokenEnd)="addTo(formGroup.get('metadata').get(data.name), $event)">
                </mat-chip-list>
              </mat-form-field>
              <mat-form-field *ngSwitchCase="EquipmentKindMetadataKind.NUMBER_ARRAY" [style.width.%]="100">
                <mat-label>{{data.label}}</mat-label>
                <mat-chip-list #chipList [attr.aria-label]="data.label" multiple [formControlName]="data.name">
                  <mat-chip *ngFor="let chip of formGroup.get('metadata').get(data.name).value" [value]="chip"
                    (removed)="removeFrom(formGroup.get('metadata').get(data.name), chip)">
                    {{chip}}
                  </mat-chip>
                  <input type="number" [placeholder]="'new' | translate" [matChipInputFor]="chipList"
                    (matChipInputTokenEnd)="addTo(formGroup.get('metadata').get(data.name), $event)">
                </mat-chip-list>
              </mat-form-field>
              <mat-form-field *ngSwitchCase="EquipmentKindMetadataKind.CONTRACTOR" [style.width.px]="300">
                <mat-label>{{data.label}}</mat-label>
                <mat-select [formControlName]="data.name" [required]="data.isRequired"
                  [compareWith]="compareMetadataWithEntity">
                  <mat-option *ngFor="let option of contractors" [value]="option">
                    {{option?.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </ng-container>
          </div>
        </div>
      </div>

      <app-metrics-edit *ngIf="kind?.metrics?.length" formGroupName="metrics" [metricsCodes]="kind?.metrics"
        [metrics]="equipment?.metrics">
      </app-metrics-edit>

      <app-hypervision-edit *ngIf="enableHypervision && formGroup.get('kind').value?.hypervision"
        formGroupName="hypervisionConfiguration" [equipmentId]="equipment?.id"
        [configuration]="equipment?.hypervisionConfiguration">
      </app-hypervision-edit>

      <ng-container *ngIf="allowFiles">
        <ng-container *ngIf="kind?.allowedDocumentKinds?.length > 0">
          <span color="primary" [style.width.%]="100" [style.margin-top.px]="16">
            Ajouter des documents liés à votre équipement <span [style.font-weight]="300">(photo, documentation, note
              technique…)</span>
          </span>
          <div fxLayoutAlign="space-between end" [style.width]="'calc(100% - 16px)'">
            <div [formGroup]="documentFormGroup" fxLayout="row wrap" fxLayoutAlign="start center"
              [style.width]="'calc(100% - 90px)'">
              <mat-form-field [style.width.px]="200">
                <mat-label>Type de document</mat-label>
                <mat-select formControlName="kind" required>
                  <mat-option *ngFor="let kind of kind?.allowedDocumentKinds" [value]="kind">
                    <mat-label translate>equipment-file.{{kind}}</mat-label>
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field [style.width.px]="400">
                <mat-label>Titre du document</mat-label>
                <input autocomplete="off" matInput formControlName="title">
              </mat-form-field>
              <mat-form-field [style.width]="'calc(100% - 648px)'">
                <mat-label>Fichier</mat-label>
                <ngx-mat-file-input formControlName="file">
                </ngx-mat-file-input>
                <mat-icon matSuffix>attach_file</mat-icon>
              </mat-form-field>
              <mat-form-field [style.width.%]="100">
                <mat-label>Commentaires, informations propres à l'équipement</mat-label>
                <textarea autocomplete="off" matInput formControlName="description" cdkTextareaAutosize></textarea>
              </mat-form-field>
            </div>
            <button id="add-document" mat-raised-button color="primary" [disabled]="!documentFormGroup.valid"
              (click)="addDocument()">
              Ajouter
            </button>
          </div>
        </ng-container>
        <table *ngIf="formGroup.get('files').value?.length" mat-table
          [dataSource]="formGroup.get('files').value | filterByImpure:['toDelete']:[false]" [style.width.%]="100">
          <ng-container matColumnDef="kind">
            <th mat-header-cell *matHeaderCellDef [style.width.px]="216"> Type de document </th>
            <td mat-cell *matCellDef="let element"><span translate> equipment-file.{{element.kind}} </span></td>
          </ng-container>
          <ng-container matColumnDef="filename">
            <th mat-header-cell *matHeaderCellDef [style.width.px]="416"> Fichier </th>
            <td mat-cell *matCellDef="let element" [style.font-weight]="400">
              <a *ngIf="element.path" [href]="repo + '/' + element.path" target="_blank">{{element.filename}}</a>
              <span *ngIf="!element.path">{{element.filename}}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef [style.width.px]="416"> Titre du document </th>
            <td mat-cell *matCellDef="let element"> {{element.title}} </td>
          </ng-container>
          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef> Description </th>
            <td mat-cell *matCellDef="let element"> {{element.description}} </td>
          </ng-container>
          <ng-container matColumnDef="remove">
            <th mat-header-cell *matHeaderCellDef [style.width.px]="90"></th>
            <td mat-cell *matCellDef="let element">
              <button mat-raised-button color="danger" (click)="element.toDelete = true">Supprimer</button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="filesDisplayColumn"></tr>
          <tr mat-row *matRowDef="let row; columns: filesDisplayColumn;" @fadeOut></tr>
        </table>
      </ng-container>

      <ng-container *ngIf="formGroup.get('kind')?.value?.family !== Family.ENERGY_FLUID">
        <h4 *ngIf="location?.address?.formatted" color="primary" [style.margin]="'8px 0'">
          {{location.address.formatted}}
        </h4>
        <app-map (ready)="showMarker()"></app-map>
        <app-organizational-unit-contact-info *ngIf="![ Family.IOT].includes(formGroup.get('kind').value?.family)"
          [id]="organizationalUnit?.id"></app-organizational-unit-contact-info>
      </ng-container>

    </div>
  </form>
</div>

<ng-template #loadingTmpl>
  <sesio-loader @fadeOut></sesio-loader>
</ng-template>