import { propertyMap } from 'model-mapper';
import { OrganizationalUnit } from './organizational-unit';

export class Group extends OrganizationalUnit {

  @propertyMap({ source: 'location.address.formatted' })
  public address: string;

  @propertyMap({ source: 'metadata.map' })
  public map: {
    marker: number[];
  };

}
