<div #container id="container" fxLayout="column" inViewport (inViewportAction)="ready($event.visible)"
  [class.has-floating-acionts]="actionsPosition === 'floating'" [class.fullscreen]="isFullscreen">

  <ng-container *ngTemplateOutlet="headerTmpl"></ng-container>

  <div id="content" class="inner-scroll">
    <table mat-table [fixedLayout]="false" [dataSource]="rows">

      <ng-container *ngFor="let header of headers; index as index" [matColumnDef]="header.label"
        [sticky]="header.sticky">
        <th mat-header-cell *matHeaderCellDef [style.--column-width]="header.width" class="column-{{header.type}}"
          [class.column-sticky]="header.sticky">
          <div fxLayoutAlign="space-between center" class="cell">
            <ng-container *ngTemplateOutlet="tableHeaderTmpl; context: { header: header }"></ng-container>
            <ng-container *ngIf="header.type !== 'action'">
              <ng-container *ngTemplateOutlet="tableHeaderActionsTmpl; context: { header: header }"></ng-container>
            </ng-container>
          </div>
        </th>
        <td mat-cell *matCellDef="let row" [style.--column-width]="header.width"
          [style.--column-padding-left]="header.paddingLeft" class="column-{{header.type}}"
          [class.column-sticky]="header.sticky" inViewport [inViewportOptions]="{ threshold: [10] }"
          (inViewportAction)="row.cells[header.index].render = row.cells[header.index].render || $event.visible"
          [style.color]="row.color" [matTooltip]="row.tooltip">
          <div *ngIf="row.cells[header.index].bgColor"
            [style]="{ position: 'absolute', 'background-color': row.cells[header.index].bgColor, top: 0, right: 0, bottom: 0, left: 0 }">
          </div>
          <ng-container *ngIf="row.cells[header.index].render === true">
            <ng-container
              *ngTemplateOutlet="tableCellTmpl; context: { header: header, row: row, cell: row.cells[header.index]  }">
            </ng-container>
          </ng-container>
        </td>
      </ng-container>

      <tr id="header" mat-header-row *matHeaderRowDef="(headers | pluck: 'label'); sticky: true"></tr>
      <tr class="row" [class.has-click-action]="!!rowClickOption" mat-row
        *matRowDef="let row; columns: (headers | pluck: 'label');" (click)="rowClick($event, row)"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td *ngIf="!loading" class="mat-cell no-data" [attr.colspan]="headers?.length">
          <span translate>no-data</span>
        </td>
      </tr>
    </table>

    <div id="spinner" *ngIf="loading" @fade>
      <div id="blur"></div>
      <div id="tmpl">
        <ng-container *ngTemplateOutlet="spinnerTmpl"></ng-container>
      </div>
    </div>
  </div>

  <mat-paginator id="paginator" [length]="dataSource?.recordsFiltered"
    [pageSize]="pagination.options[pagination.default]" [showFirstLastButtons]="true"
    [style.display]="(showPagination === 'never' || (showPagination === 'auto' && (loading || !dataSource || !rows || dataSource.recordsFiltered <= rows.length))) ? 'none' : undefined">
  </mat-paginator>
</div>

<!-- TOP of table -->
<ng-template #headerTmpl>
  <div id="top"
    *ngIf="['top', 'floating'].includes(actionsPosition) && (actions?.length || enableHideShowColumns || enableExport || enableFullscreen)"
    fxLayoutAlign="end center" fxLayoutGap="8px" [class.floating]="actionsPosition === 'floating'"
    [class.fullscreen]="isFullscreen">
    <ng-container *ngFor="let action of actions">
      <ng-container *ngIf="!action.hidden">
        <button mat-icon-button *ngIf="!action.label"
          [style.color]="(action.color || 'var(--ion-color-primary)') | safe:'style'"
          class="icon-only action align-{{action.align}}" [matTooltip]="action.tooltip && (action.tooltip | translate)"
          [disabled]="action.disabled" (click)="execAction(action)">
          <mat-icon *ngIf="action.icon && !action.icon.svg" [style.color]="action.color | safe:'style'">
            {{action.icon.name}}
          </mat-icon>
          <mat-icon *ngIf="action.icon && action.icon.svg" [svgIcon]="action.icon.name"
            [style.color]="action.color | safe:'style'">
          </mat-icon>
          <div class="loading" *ngIf="exporting" @fade>
            <ng-container *ngTemplateOutlet="spinnerTmpl"></ng-container>
          </div>
        </button>
        <button mat-button *ngIf="action.label"
          [style.color]="(action.color || 'var(--ion-color-primary)') | safe:'style'"
          class="icon-only action align-{{action.align}}" [matTooltip]="action.tooltip && (action.tooltip | translate)"
          [disabled]="action.disabled" (click)="execAction(action)">
          <div fxLayoutAlign="center center" fxLayoutGap="8px">
            <mat-icon *ngIf="action.icon && !action.icon.svg" [style.color]="action.color | safe:'style'">
              {{action.icon.name}}
            </mat-icon>
            <mat-icon *ngIf="action.icon && action.icon.svg" [svgIcon]="action.icon.name"
              [style.color]="action.color | safe:'style'">
            </mat-icon>
            <span>{{action.label}}</span>
            <div class="loading" *ngIf="exporting" @fade>
              <ng-container *ngTemplateOutlet="spinnerTmpl"></ng-container>
            </div>
          </div>
        </button>
      </ng-container>
    </ng-container>
    <div id="column-options" *ngIf="enableHideShowColumns || enableStickyColumn || enableReorderColumn">
      <button mat-icon-button color="primary" class="icon-only action" [matTooltip]="'hide-show-columns' | translate"
        [matMenuTriggerFor]="columnMenu" (menuOpened)="openHideShowMenu()" (menuClosed)="closeHideShowMenu()">
        <mat-icon>splitscreen_vertical_add</mat-icon>
      </button>
      <mat-menu #columnMenu="matMenu">
        <div fxLayout="column" cdkDropList [cdkDropListDisabled]="!enableReorderColumn"
          (cdkDropListDropped)="drop($event)">
          <div *ngFor="let column of updatedHidden" mat-menu-item cdkDrag fxLayoutAlign="start center"
            (click)="$event.stopPropagation()">
            <button *ngIf="enableHideShowColumns" mat-icon-button size="small" (click)="column.show=!column.show"
              fxLayoutAlign="start center" [style.margin-right.px]="8">
              <mat-icon color="accent" *ngIf="column.show !== false">visibility</mat-icon>
              <mat-icon *ngIf="column.show === false">visibility_off</mat-icon>
            </button>
            <button *ngIf="enableStickyColumn" mat-icon-button size="small" (click)="column.sticky = !column.sticky"
              fxLayoutAlign="start center" [style.margin-right.px]="8">
              <mat-icon color="accent" *ngIf="column.sticky === true">push_pin</mat-icon>
              <mat-icon *ngIf="column.sticky !== true">push_pin</mat-icon>
            </button>
            <span translate
              [style]="{ 'margin-right': '8px', overflow: 'hidden', 'text-overflow': 'ellipsis'}">{{column.label}}</span>
            <mat-icon *ngIf="enableReorderColumn" size="small" cdkDragHandle [style.margin]="'0 8px 0 auto'"
              [style.min-width.px]="18">
              open_with
            </mat-icon>
          </div>
        </div>
      </mat-menu>
    </div>
    <button *ngIf="enableExport" mat-icon-button color="primary" class="icon-only action"
      [matTooltip]="'xlsx-export' | translate" [disabled]="exporting" (click)="export()">
      <mat-icon>download</mat-icon>
      <div class="loading" *ngIf="exporting" @fade>
        <ng-container *ngTemplateOutlet="spinnerTmpl"></ng-container>
      </div>
    </button>
    <button *ngIf="enableFullscreen" mat-icon-button color="primary" class="icon-only action"
      [matTooltip]="'fullscreen' | translate" (click)="fullscreen()">
      <mat-icon *ngIf="!isFullscreen">fullscreen</mat-icon>
      <mat-icon *ngIf="isFullscreen">fullscreen_exit</mat-icon>
    </button>
  </div>
</ng-template>

<!-- table header actions -->
<ng-template #tableHeaderActionsTmpl let-header="header">
  <div class="cell-actions" fxLayoutAlign="end center">
    <ng-container *ngIf="header.sortable">
      <ng-container *ngTemplateOutlet="sortTemplate;context:{ column: header}">
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<!-- Header template -->
<ng-template #tableHeaderTmpl let-header="header">
  <div class="cell-content" [matTooltipDisabled]="!header.tooltip" [matTooltip]="header.tooltip | translate"
    matTooltipClass="datagrid-row-tooltip" matTooltipShowDelay="500"
    [style.width]="'calc(100% - ' + (header.sortable ? 32 : 0) + 'px)'">
    <ng-container [ngSwitch]="(header.type === 'action' || header.searchable) ? header.type : null">

      <!-- Action -->
      <ng-container *ngSwitchCase="'action'">
        <ng-container [ngSwitch]="header.label">
          <ng-container *ngSwitchCase="'_select'">
            <mat-checkbox [checked]="false" [formControl]="selectAllControl"
              [indeterminate]="selectAllControl.value === null" [matBadgeHidden]="!selected.length"
              [matBadge]="selected.length" matBadgeOverlap="false">
            </mat-checkbox>
          </ng-container>
          <ng-container *ngSwitchCase="'_rownumber'">#</ng-container>
        </ng-container>
      </ng-container>

      <!-- Text -->
      <ng-container *ngSwitchCase="'text'">
        <ng-container
          *ngTemplateOutlet="tableHeaderTextTmpl; context: { header: header, control: search.controls[header.searchProperty || header.displayProperty || header.property], type: 'text' }">
        </ng-container>
      </ng-container>

      <!-- Number -->
      <ng-container *ngSwitchCase="'number'">
        <ng-container
          *ngTemplateOutlet="tableHeaderTextTmpl; context: { header: header, control: search.controls[header.searchProperty || header.displayProperty || header.property], type: 'number' }">
        </ng-container>
      </ng-container>

      <!-- Date -->
      <ng-container *ngSwitchCase="'date'">
        <ng-container
          *ngTemplateOutlet="tableHeaderDateTmpl; context: { header: header, control: search.controls[header.searchProperty || header.displayProperty || header.property] }">
        </ng-container>
      </ng-container>

      <!-- Select -->
      <ng-container *ngSwitchCase="'select'">
        <ng-container
          *ngTemplateOutlet="tableHeaderSelectTmpl; context: { header: header, control: search.controls[header.searchProperty || header.displayProperty || header.property] }">
        </ng-container>
      </ng-container>

      <!-- Autocomplete -->
      <ng-container *ngSwitchCase="'autocomplete'">
        <ng-container
          *ngTemplateOutlet="tableHeaderAutocompleteTmpl; context: { header: header, control: search.controls[header.searchProperty || header.displayProperty || header.property] }">
        </ng-container>
      </ng-container>

      <!-- Button Toggle -->
      <ng-container *ngSwitchCase="'button-toggle'">
        <ng-container
          *ngTemplateOutlet="tableHeaderSelectTmpl; context: { header: header, control: search.controls[header.searchProperty || header.displayProperty || header.property] }">
        </ng-container>
      </ng-container>

      <!-- Checkbox -->
      <ng-container *ngSwitchCase="'checkbox'">
        <ng-container
          *ngTemplateOutlet="tableHeaderCheckboxTmpl; context: { header: header, control: search.controls[header.searchProperty || header.displayProperty || header.property] }">
        </ng-container>
      </ng-container>

      <!-- Default -->
      <div *ngSwitchDefault [style.text-align]="header.textAlign"
        [innerHTML]="(header.label | translate) | safe:'html'"></div>

    </ng-container>
  </div>
</ng-template>

<!-- Header text template -->
<ng-template #tableHeaderTextTmpl let-header="header" let-control="control" let-type="type">
  <mat-form-field appearance="standard">
    <mat-label [innerHTML]="(header.label | translate) | safe:'html'"></mat-label>
    <input [type]="type" matInput [formControl]="control" autocomplete="off">
    <button matSuffix size="small" *ngIf="control?.value && control?.value !== ''" class="clear-button" mat-icon-button
      (click)="control?.setValue(null); $event.stopPropagation()">
      <mat-icon>clear</mat-icon>
    </button>
  </mat-form-field>
</ng-template>

<!-- Header date template -->
<ng-template #tableHeaderDateTmpl let-header="header" let-control="control">

  <ng-template #datePickerPrefix>
    <button mat-icon-button [matMenuTriggerFor]="menu">
      <span [style.font-size.px]="18" [ngSwitch]="control.value.op">
        <ng-container *ngSwitchCase="'='">&equals;</ng-container>
        <ng-container *ngSwitchCase="'>'">&gt;</ng-container>
        <ng-container *ngSwitchCase="'>='">&ge;</ng-container>
        <ng-container *ngSwitchCase="'<'">&lt;</ng-container>
        <ng-container *ngSwitchCase="'<='">&le;</ng-container>
        <ng-container *ngSwitchCase="'<>'">&lt;&gt;</ng-container>
        <ng-container *ngSwitchCase="'<=>'">&le;&ge;</ng-container>
        <ng-container *ngSwitchCase="'>=<'">&le;&ge;</ng-container>
        <ng-container *ngSwitchCase="'>=<'">&le;&ge;</ng-container>
      </span>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="control.get('op').setValue('=', { emitEvent: !!control.value?.from })">
        <span value="=">&equals;</span>
        <span [style.font-size.em]="0.8" italic> Égale à</span>
      </button>
      <button mat-menu-item (click)="control.get('op').setValue('>', { emitEvent: !!control.value?.from })">
        <span value=">">&gt;</span>
        <span [style.font-size.em]="0.8" italic> Supérieur à</span>
      </button>
      <button mat-menu-item (click)="control.get('op').setValue('>=', { emitEvent: !!control.value?.from })">
        <span value=">=">&ge;</span>
        <span [style.font-size.em]="0.8" italic> Supérieur ou égale à</span>
      </button>
      <button mat-menu-item (click)="control.get('op').setValue('<', { emitEvent: !!control.value?.from })">
        <span value="<">&lt;</span>
        <span [style.font-size.em]="0.8" italic> Inférieure à</span>
      </button>
      <button mat-menu-item (click)="control.get('op').setValue('<=', { emitEvent: !!control.value?.from })">
        <span value="<=">&le;</span>
        <span [style.font-size.em]="0.8" italic> Inférieure ou égale à</span>
      </button>
      <button mat-menu-item
        (click)="control.get('op').setValue('<>', { emitEvent: !!control.value?.from && !!control.value?.to })">
        <span value="<>">&lt;&gt;</span>
        <span [style.font-size.em]="0.8" italic> Supérieure à et inférieur à</span>
      </button>
      <button mat-menu-item
        (click)="control.get('op').setValue('<=>', { emitEvent: !!control.value?.from && !!control.value?.to })">
        <span value="<=>">&le;&ge;</span>
        <span [style.font-size.em]="0.8" italic> Supérieure ou égale à et inférieur ou égale à</span>
      </button>
      <button mat-menu-item
        (click)="control.get('op').setValue('>=<', { emitEvent: !!control.value?.from && !!control.value?.to })">
        <span value=">=<">&ge;&lt;</span>
        <span [style.font-size.em]="0.8" italic> Supérieure ou égale à et inférieur à</span>
      </button>
      <button mat-menu-item
        (click)="control.get('op').setValue('>=<', { emitEvent: !!control.value?.from && !!control.value?.to })">
        <span value=">=<">&ge;&lt;</span>
        <span [style.font-size.em]="0.8" italic> Supérieure à et inférieur ou égale à</span>
      </button>
    </mat-menu>
  </ng-template>

  <ng-container *ngIf="['=', '>', '>=', '<', '<='].includes(control.value.op)" [formGroup]="control">
    <mat-form-field appearance="standard">
      <mat-label [innerHTML]="(header.label | translate) | safe:'html'"></mat-label>
      <input matInput formControlName="from" [matDatepicker]="picker">
      <mat-datepicker #picker></mat-datepicker>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <div matPrefix>
        <ng-container *ngTemplateOutlet="datePickerPrefix"></ng-container>
      </div>
    </mat-form-field>
  </ng-container>

  <mat-form-field *ngIf="['<>', '<=>'].includes(control.value.op)" appearance="standard">
    <mat-label [innerHTML]="(header.label | translate) | safe:'html'"></mat-label>
    <mat-date-range-input [formGroup]="control" [rangePicker]="picker">
      <input matStartDate formControlName="from" placeholder="Start date">
      <input matEndDate formControlName="to" placeholder="End date">
    </mat-date-range-input>
    <mat-date-range-picker #picker></mat-date-range-picker>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <div matPrefix>
      <ng-container *ngTemplateOutlet="datePickerPrefix"></ng-container>
    </div>
  </mat-form-field>

</ng-template>

<!-- Header select template -->
<ng-template #tableHeaderSelectTmpl let-header="header" let-control="control">
  <mat-form-field appearance="standard">
    <mat-label [innerHTML]="(header.label | translate) | safe:'html'"></mat-label>
    <mat-select [formControl]="control" [compareWith]="selectCompareWidth" [multiple]="header.multiple">
      <mat-select-trigger>
        <ng-container *ngIf="header.multiple">
          <ng-container *ngFor="let value of control?.value">
            <ng-container
              *ngTemplateOutlet="optionTmpl; context: { option: value, selected: true, hideNane: header.hideNane }">
            </ng-container>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!header.multiple">
          <ng-container *ngTemplateOutlet="optionTmpl; context: { option: control?.value, selected: true }">
          </ng-container>
        </ng-container>
      </mat-select-trigger>
      <mat-option *ngIf="!header.multiple" [value]="undefined">
        <mat-label [style.color]="'var(--ion-color-light-shade)'"
          [innerHTML]="(header.label | translate) | safe:'html'"></mat-label>
      </mat-option>
      <mat-option *ngFor="let option of header.options" [value]="option">
        <ng-container *ngTemplateOutlet="optionTmpl; context: { option: option }"></ng-container>
      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>

<!-- Header autocomplete template -->
<ng-template #tableHeaderAutocompleteTmpl let-header="header" let-control="control">
  <mat-form-field appearance="standard">
    <mat-label [innerHTML]="(header.label | translate) | safe:'html'"></mat-label>

    <input #autocompleteInput="matInput" autocomplete="off" type="text" matInput
      [formControl]="control.asynOptionsControl" [matAutocomplete]="auto"
      [style.visibility]="control.value ? 'hidden' : undefined">
    <ng-container *ngIf="control.value">
      <div class="autocomplete-selected-option" fxLayoutAlign="space-between center">
        <ng-container *ngTemplateOutlet="optionTmpl; context: { option: control.value, selected: true }"></ng-container>
        <button size="small" *ngIf="control.value && control.value !== ''" class="clear-button" mat-icon-button
          (click)="control.setValue(null); control.asynOptionsControl.setValue(null); $event.stopPropagation()">
          <mat-icon>clear</mat-icon>
        </button>
      </div>
    </ng-container>

    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="control.setValue($event.option?.value)">
      <mat-option *ngIf="control.optionsAsyncLoading" [style.--diameter]="'40px'" [disabled]="true">
        <ng-container *ngTemplateOutlet="spinnerTmpl"></ng-container>
      </mat-option>
      <mat-option *ngFor="let option of control.optionsAsync | async" [value]="option">
        <ng-container *ngTemplateOutlet="optionTmpl; context: { option: option }"></ng-container>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</ng-template>

<!-- Header checkbox template -->
<ng-template #tableHeaderCheckboxTmpl let-header="header" let-control="control">
  <div fxLayoutAlign="start center" fxLayoutGap="8px">
    <ng-container [ngSwitch]="control.value">
      <button *ngSwitchCase="true" mat-icon-button class="icon-only fit-icon" size="small"
        (click)="control.setValue(false)">
        <mat-icon color="primary">check_box</mat-icon>
      </button>
      <button *ngSwitchCase="false" mat-icon-button class="icon-only fit-icon" size="small"
        (click)="control.setValue(null)">
        <mat-icon color="primary">check_box_outline_blank</mat-icon>
      </button>
      <button *ngSwitchDefault mat-icon-button class="icon-only fit-icon" size="small" (click)="control.setValue(true)">
        <mat-icon>indeterminate_check_box</mat-icon>
      </button>
    </ng-container>
    <span [innerHTML]="(header.label | translate) | safe:'html'"></span>
  </div>
</ng-template>

<!-- Cell template -->
<ng-template #tableCellTmpl let-header="header" let-row="row" let-cell="cell">
  <div class="cell" [class.sticky]="header.sticky" [fxLayoutAlign]="header.textAlign + ' center'" fxLayoutGap="8px"
    [class.dialog]="header.click" (click)="cellClick($event, row, cell)" matRipple [matRippleCentered]="true"
    [matRippleDisabled]="!header.click">
    <!-- Column Content Template Display -->
    <div class="row-cell" *ngIf="header.content && templatesByName[header.content]" [style.color]="cell.color">
      <ng-container
        *ngTemplateOutlet="templatesByName[header.content].template; context: { column: header, record: data[row.index], row: row, cell: cell }">
      </ng-container>
    </div>
    <!-- Column Display -->
    <div class="row-cell" *ngIf="!header.content && header.type !== 'action'" [matTooltip]="cell.tooltip">
      <mat-icon *ngIf=" cell.icon && !cell.icon.svg" class="row-cell-icon"
        [style.color]="cell.icon?.color || cell.color">
        {{cell.icon.name}}
      </mat-icon>
      <mat-icon *ngIf="cell.icon && cell.icon.svg" class="row-cell-icon" [svgIcon]="cell.icon.name"
        [style.color]="cell.icon?.color || cell.color">
      </mat-icon>
      <span *ngIf="cell.value !== undefined" class="row-cell-content" [style.color]="cell.color"
        [style.min-width.px]="header.width / 2" [innerHTML]="cell.value"
        [style.text-align]="header.type === 'number' ? 'end' : header.textAlign">
      </span>
      <span *ngIf="cell.suffix" class="row-cell-suffix" [innerHTML]="cell.suffix | safe:'html'"
        [style.color]="cell.color"></span>
    </div>
    <!-- Action Display -->
    <ng-container *ngIf="header.type === 'action'" [ngSwitch]="header.label">
      <mat-checkbox *ngSwitchCase="'_select'" (change)="select(row)" [checked]="row.selected"></mat-checkbox>
      <span *ngSwitchCase="'_rownumber'" class="row-cell-content" [style.min-width.px]="header.width / 2">
        {{row.index + 1}}
      </span>
    </ng-container>

  </div>
</ng-template>

<!-- Sort template -->
<ng-template #sortTemplate let-column="column">
  <button class="sort-btn" mat-icon-button size="small" (click)="sortColumn(column)">
    <ng-container [ngSwitch]="column.sort?.dir">
      <mat-icon size="small" *ngSwitchCase="'asc'">arrow_upward</mat-icon>
      <mat-icon size="small" *ngSwitchCase="'desc'">arrow_downward</mat-icon>
      <mat-icon size="small" *ngSwitchDefault>sort</mat-icon>
    </ng-container>
    <span *ngIf="column.sort && sortedColumns.length > 1" class="sort-position">{{column.sort.position}}</span>
  </button>
</ng-template>

<ng-template #spinnerTmpl>
  <ng-content select="[datagrid-spinner]"></ng-content>
</ng-template>


<ng-template #optionTmpl let-option="option" let-selected="selected" let-hideNane="hideNane">
  <div fxLayoutAlign="start center" fxLayoutGap="8px">
    <mat-icon *ngIf="option?.icon && !option?.icon.svg"
      [style.color]="(option.icon.color || option.color) | safe:'style'" size="small">
      {{option?.icon.name}}
    </mat-icon>
    <mat-icon *ngIf="option?.icon && option?.icon.svg" [svgIcon]="option?.icon.name"
      [style.color]="(option.icon.color || option.color) | safe:'style'" size="small">
    </mat-icon>
    <mat-label *ngIf="!hideNane && option?.name"
      [style.color]="(option.color ? option.color : selected ? 'var(--ion-color-primary)' : undefined ) | safe:'style'">
      {{option.name | translate}}
    </mat-label>
  </div>
</ng-template>