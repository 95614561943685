/** @format */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EquipmentKindSelectComponent } from './equipment-kind-select.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { IntersectionObserverModule } from 'ngx-intersection-observer';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from 'sesio-lib';
import { NgPipesModule } from 'ngx-pipes';

@NgModule({
  declarations: [EquipmentKindSelectComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    MatAutocompleteModule,
    FlexLayoutModule,
    IntersectionObserverModule,
    MatIconModule,
    MatButtonModule,
    MatProgressBarModule,
    MatInputModule,
    TranslateModule.forChild(),
    PipesModule,
    NgPipesModule,
  ],
  exports: [EquipmentKindSelectComponent],
})
export class EquipmentKindSelectModule {}
