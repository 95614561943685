import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { RealEstateStructureDatatgridCellComponent } from './real-estate-structure-datatgrid-cell/real-estate-structure-datatgrid-cell.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { RealEstateStructureSelectComponent } from './real-estate-structure-select/real-estate-structure-select.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { NgPipesModule } from 'ngx-pipes';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { RealEstateStructureInfoComponent } from './real-estate-structure-info/real-estate-structure-info.component';
import { IonicModule } from '@ionic/angular';
import { LoaderModule, PipesModule } from 'sesio-lib';
import { MatTabsModule } from '@angular/material/tabs';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { OrganizationalUnitModule } from '../organizational-unit/organizational-unit.module';
import { MapModule } from '../map/map.module';
import { InViewportModule } from 'ng-in-viewport';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ColorsModule } from '../../_pipes/colors/colors.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { DateFilterModule } from '../date-filter/date-filter.module';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ConfirmDialogModule } from '../confirm-dialog/confirm-dialog.module';

@NgModule({
  declarations: [
    RealEstateStructureDatatgridCellComponent,
    RealEstateStructureSelectComponent,
    RealEstateStructureInfoComponent,
  ],
  imports: [
    IonicModule,
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatChipsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatProgressBarModule,
    MatToolbarModule,
    NgPipesModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    LoaderModule,
    MatTabsModule,
    NgScrollbarModule,
    OrganizationalUnitModule,
    MapModule,
    NgPipesModule,
    PipesModule,
    InViewportModule,
    MatTooltipModule,
    ColorsModule,
    MatExpansionModule,
    MatTableModule,
    DateFilterModule,
    ConfirmDialogModule
  ],
  exports: [
    RealEstateStructureDatatgridCellComponent,
    RealEstateStructureSelectComponent,
    RealEstateStructureInfoComponent,
  ],
})
export class RealEstateStructureModule {}
