/** @format */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'toString' })
export class ToStringPipe implements PipeTransform {
  transform(data: any, log: boolean = true): any {
    if (log) console.log(data);
    return data ? JSON.stringify(data, null, 2) : null;
  }
}
