/** @format */

export const energyKinds = [
  {
    name: 'Énergie nucléaire',
    data: [{name: 'Électricité (centrale nucléaire)', value: 0.006}],
  },
  {
    name: 'Énergie renouvelable',
    data: [
      {name: 'Électricité (centrale hydraulique)', value: 0.006},
      {name: 'Électricité (éolien terrestre)', value: 0.0141},
      {name: 'Électricité (éolien en mer)', value: 0.0156},
      {name: 'Bioéthanol', value: 0.144},
      {name: 'Biométhane', value: 0.0163},
      {name: 'Bois granulé', value: 0.027},
      {name: 'Bois bûches', value: 0.032},
      {name: 'Électricité (photovoltaïque)', value: 0.0439},
      {name: 'Électricité (géothermie)', value: 0.045},
    ],
  },
  {
    name: 'Énergie fossile',
    data: [
      {name: 'Gaz naturel', value: 0.243},
      {name: 'Propane', value: 0.27},
      {name: 'Butane', value: 0.273},
      {name: 'Fioul domestique', value: 0.314},
      {name: 'Électricité (centrale gaz)', value: 0.418},
      {name: 'Électricité (centrale fioul)', value: 0.73},
      {name: 'Électricité (centrale charbon)', value: 1.06},
    ],
  },
];

export function getEmission(name: string): number | null {
  for (let group of energyKinds) {
    for (let kind of group.data) {
      if (kind.name === name) return kind.value;
    }
  }
  return null;
}
