/** @format */

import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Injector,
  Input,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormControl,
  NgControl,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { TranslateService } from '@ngx-translate/core';
import {
  find,
  isEqual,
  merge,
  isNil,
  trim,
  map as lmap,
  filter as lfilter,
  sortBy,
  get,
  concat,
  differenceBy,
} from 'lodash-es';
import { Observable, of } from 'rxjs';
import { startWith, debounceTime, mergeMap, filter, map, tap } from 'rxjs/operators';
import {
  EmbededRealEstateStructure,
  fade,
  OrganizationalUnit,
  RealEstateStructure,
  RealEstateStructureKind,
} from 'sesio-lib';
import { RealEstateStructureService } from '../../../_services/real-estate-structure.service';

interface IRealEstateStructure extends RealEstateStructure {
  kindLabel: string;
}

@Component({
  selector: 'app-real-estate-structure-select',
  templateUrl: './real-estate-structure-select.component.html',
  styleUrls: ['./real-estate-structure-select.component.scss'],
  animations: [fade],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => RealEstateStructureSelectComponent),
    },
  ],
})
export class RealEstateStructureSelectComponent implements AfterViewInit, ControlValueAccessor {
  @Input('required')
  public set setRequired(isRequired: boolean) {
    this.isRequired = isRequired === false ? false : true;
    if (this.control) this.setValidators();
  }
  public isRequired = false;

  @Input('value')
  public set setValue(value: EmbededRealEstateStructure) {
    this.value = value;
    if (this.control) this.control.setValue(value);
  }
  public value: EmbededRealEstateStructure;

  @Input('disabled')
  public set setDisabled(isDisabled: boolean) {
    this.setDisabledState(isDisabled !== false);
  }
  public isDisabled: boolean;

  @Input('multiple')
  public set setMultiple(multiple: boolean) {
    this.multiple = multiple !== false;
  }
  multiple = false;

  @Input()
  private kinds: RealEstateStructureKind[];

  @Input()
  private ouLinked = true;

  @Input('label')
  public label: string;

  @Input('organizationalUnit')
  public set setOrganizationalUnit(organizationalUnit: OrganizationalUnit) {
    if (!isEqual(this.organizationalUnitId, organizationalUnit?.id)) {
      this.organizationalUnitId = organizationalUnit.id;
      if (this.ready) {
        setTimeout(() => {
          if (!this.organizationalUnitId) {
            this.control?.disable();
            this.control?.setValue(null);
          } else {
            this.control?.enable();
            if (this.control?.value?.organizationalUnit?.id !== this.organizationalUnitId) {
              this.control?.setValue(null);
            }
          }
          this.control.updateValueAndValidity();
        });
      }
    }
  }
  public organizationalUnitId: string;

  private ready = false;
  public loading = false;
  public filteredOptions: Observable<RealEstateStructure[]>;
  public control: UntypedFormControl;
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public valueCtrl = new UntypedFormControl();
  @ViewChild('valueInput') valueInput: ElementRef<HTMLInputElement>;

  public displayOption = (option: RealEstateStructure) => (option ? option.reference : '');

  public onChange = () => {};

  public onTouched = () => {};

  private validateOption = (control: AbstractControl): { [key: string]: any } | null => {
    let forbidden;
    if (this.multiple) {
      forbidden =
        control.value &&
        (!Array.isArray(control.value) ||
          get(
            lfilter(lmap(control.value, 'id'), v => !!v),
            'length',
            0
          ) !== get(control.value, 'length', 0));
    } else forbidden = control.value && isNil(control.value.id);
    return forbidden ? { invalidOption: { value: control.value } } : null;
  };

  constructor(
    private injector: Injector,
    private changeDetectorRef: ChangeDetectorRef,
    private translate: TranslateService,
    private realEstateStructureService: RealEstateStructureService
  ) {}

  writeValue(value: any): void {
    if (this.control && this.control.value !== value) {
      this.control.setValue(value, { emitEvent: false });
    }
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    if (this.control && this.control.disabled !== this.isDisabled) {
      if (this.isDisabled) this.control.disable();
      else this.control.enable();
    }
  }

  ngAfterViewInit(): void {
    const ngControl: NgControl = this.injector.get(NgControl, null);
    if (ngControl) {
      this.control = ngControl.control as UntypedFormControl;
      this.value = this.control?.value;
    } else {
      this.control = new UntypedFormControl({
        value: this.value,
        disabled: this.isDisabled,
      });
    }
    if (this.ouLinked && !this.organizationalUnitId) this.setDisabledState(true);
    this.setValidators();

    const obs = this.multiple ? this.valueCtrl.valueChanges : this.control.valueChanges;
    this.filteredOptions = obs.pipe(
      startWith(this.control.value?.reference || ''),
      filter(() => !this.isDisabled),
      debounceTime(300),
      filter(value => !value || typeof value === 'string'),
      tap(() => (this.loading = true)),
      mergeMap(reference => this.filterRealEstateStructure(reference)),
      tap(() => (this.loading = false))
    );
    this.ready = true;
    this.changeDetectorRef.detectChanges();
  }

  remove(index: number): void {
    const value = this.control.value;
    value.splice(index, 1);
    this.control.setValue(value);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (!!find(this.control.value, { id: event.option.value.id })) return;
    this.control.setValue(concat(this.control.value || [], [event.option.value]));
    this.valueInput.nativeElement.value = '';
    this.valueCtrl.setValue(null);
  }

  private setValidators() {
    const validators = [];
    if (this.control.hasValidator(Validators.required)) this.isRequired = true;
    if (this.isRequired) validators.push(Validators.required);
    validators.push(this.validateOption);
    this.control.setValidators(validators);
    this.control.updateValueAndValidity();
  }

  private filterRealEstateStructure(reference: string): Observable<IRealEstateStructure[]> {
    if (
      (this.ouLinked && !this.organizationalUnitId) ||
      (!this.organizationalUnitId && get(trim(reference), 'length') < 3)
    ) {
      return of([]);
    }
    return this.realEstateStructureService
      .search({
        search: trim(reference),
        organizationalUnitId: this.organizationalUnitId,
        kinds: this.kinds || [],
      })
      .pipe(
        map(data =>
          sortBy(
            lmap(differenceBy(data, this.control.value, 'id'), (d: RealEstateStructure) =>
              merge(d, {
                kindLabel: this.translate.instant(d.kind),
              })
            ),
            ['kindLabel', 'name']
          )
        )
      );
  }
}
