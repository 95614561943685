/** @format */

import { propertyMap } from 'model-mapper';
import { Family } from './family';

export class EmbededContractor {
  @propertyMap({ source: '_id' })
  public id: string;

  @propertyMap()
  public name: string;

  @propertyMap()
  public families: Family[];
}
