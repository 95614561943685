/** @format */

import {propertyMap} from 'model-mapper';
import {EmbededEquipment} from './equipment';
import {Metric} from './metrics';

export class Sensor {
  @propertyMap({source: '_id'})
  public id: string;

  @propertyMap()
  public code: string;

  @propertyMap()
  public name: string;

  @propertyMap()
  public kind: Metric;

  @propertyMap()
  public historized: boolean;

  @propertyMap()
  public calibration: number;

  @propertyMap({type: EmbededEquipment})
  public hypervisionEquipment: EmbededEquipment;

  @propertyMap()
  public supervisionEquipmentId: string;

  @propertyMap({type: EmbededEquipment})
  public equipment: EmbededEquipment;

  @propertyMap()
  public supervized: boolean;

  @propertyMap()
  public setpoint: string;

  @propertyMap()
  public color: string;

  @propertyMap()
  public lastValue: any;

  @propertyMap()
  public lastValueUnit: string;

  @propertyMap({type: Date})
  public lastValueDate: Date;

  @propertyMap()
  public metadata: any;
}

export class EmbededSensor {
  @propertyMap({source: '_id'})
  public id: string;

  @propertyMap()
  public code: string;

  @propertyMap()
  public name: string;

  @propertyMap()
  public kind: Metric;
}
