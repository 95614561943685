/** @format */

import { Transmitter, TestState, Equipment } from './equipment';
import { propertyMap } from 'model-mapper';
import moment, { Moment } from 'moment';

export enum ElevatorSignatureState {
  NOT_CONNECTED = 0,
  MISSING_DATA = 1,
  ONLINE = 2,
  CRITICAL = 3,
  OFFLINE = 4,
  MAINTENANCE = 5,
  STOPPED = 6,
}

export enum ElevatorState {
  OK = 0,
  MAINTENANCE = 1,
  FAILURE = 2,
  STOP = 3,
}

export enum ElevatorUsage {
  IS_LOW = 0,
  IS_AVERAGE = 1,
  IS_INTENSIVE = 2,
}

export class ElevatorMetadataSignatureThreshold {
  @propertyMap()
  offline: number;

  @propertyMap()
  suspicious: number;
}

export class ElevatorGlobalSignatureIndicator {
  @propertyMap()
  reference: string;

  @propertyMap()
  day: number;

  @propertyMap()
  idleDurationAvg: number;

  @propertyMap()
  startTime: number;

  @propertyMap()
  endTime: number;
}

export class ElevatorSignatureIndicator {
  @propertyMap()
  day: number;

  @propertyMap()
  hour: number;

  @propertyMap()
  minute: number;

  @propertyMap()
  count: number; // number of sensor data

  @propertyMap()
  sum: number; // sum mouvements

  @propertyMap()
  avg: number; // average mouvement of sensor data

  @propertyMap()
  min: number; // min mouvement of sensor data

  @propertyMap()
  max: number; // max mouvement of sensor data

  @propertyMap()
  dayCount: number; // number of day since 2020

  @propertyMap()
  mouvements: number; // average sum mouvement related to nb day since 2020
}

export class Specifications {
  @propertyMap()
  public installationDate: string;

  @propertyMap()
  public alarmType: string;

  @propertyMap()
  public levels: string;

  @propertyMap()
  public machineryLocation: string;

  @propertyMap()
  public sheathType: string;

  @propertyMap()
  public load: string;

  @propertyMap()
  public electricalEquipmentBoxMake: string;

  @propertyMap()
  public electricalEquipmentBoxTechnology: string;

  @propertyMap()
  public technology: string;

  @propertyMap()
  public machineryMake: string;

  @propertyMap()
  public doorType: string;

  @propertyMap()
  public renovationProvider: string;

  @propertyMap({ source: 'winch_type' })
  public winchType: 'electric' | 'hydrolic';

  @propertyMap()
  public speed: 'less_063' | 'equal_063' | 'equal_1' | 'equal_160' | 'more_163';
}

export class TechnicalCheck {
  @propertyMap({ type: 'Moment', default: null })
  public lastDate: moment.Moment;

  @propertyMap()
  public conclusionCe: string;

  @propertyMap({ default: null })
  public overallReportRobien: string;

  @propertyMap()
  public observationCount: number;

  @propertyMap()
  public observationNcRobienCount: number;

  @propertyMap()
  public ceMarkingVeritas: boolean;

  @propertyMap()
  public ceNumberVeritas: string;

  @propertyMap()
  public designationVeritas: string;

  @propertyMap()
  public clearPassageWidthDisabledAccessibility: string;

  @propertyMap()
  public machineryPositionVeritas: string;

  @propertyMap()
  public machineryAccessVeritas: string;

  @propertyMap()
  public otherMachineryAccessVeritas: string;

  @propertyMap()
  public accessToBowlVeritas: string;

  @propertyMap()
  public hangersVeritas: string;

  @propertyMap()
  public hangersCountVeritas: string;

  @propertyMap()
  public landingDoorsVeritas: string;

  @propertyMap()
  public levelCountVeritas: string;

  @propertyMap()
  public serviceCountVeritas: string;

  @propertyMap()
  public chargeKgVeritas: string;

  @propertyMap()
  public personCountVeritas: string;

  @propertyMap()
  public speedMSVeritas: string;

  @propertyMap()
  public electricDriveVeritas: string;

  @propertyMap()
  public otherElectricDriveVeritas: string;

  @propertyMap()
  public hydraulicDriveVeritas: string;

  @propertyMap()
  public cabinDoorsVeritas: string;
}

export class Technical {
  @propertyMap()
  public name: string;

  @propertyMap({ type: 'Moment' })
  public creationDate: moment.Moment;

  @propertyMap()
  public isCertified: boolean;

  @propertyMap()
  public hasPhotoBuildingEntry;

  @propertyMap()
  public buildingType: string;

  @propertyMap()
  public classification: string;

  @propertyMap({ type: 'Moment' })
  public visitedLastAt: moment.Moment;

  @propertyMap()
  public battery: string;

  @propertyMap()
  public commissionedAt: string;

  @propertyMap()
  public upgradedAt: string;

  @propertyMap()
  public markingCe: string;

  @propertyMap()
  public machineryLocalAccessPoint: string;

  @propertyMap()
  public hasPhotoAccessMachineryLocal: boolean;

  @propertyMap()
  public commandLockerLocation: string;

  @propertyMap()
  public hasSecurityStudyUpToDate: string;

  @propertyMap()
  public hasTechnicalDossierUpToDate: string;

  @propertyMap()
  public pulleyLocalLocation: string;

  @propertyMap()
  public pulleyLocalAccessPoint: string;

  @propertyMap()
  public hasPhotoAccessPulleyLocal: boolean;

  @propertyMap()
  public elevatorType: string;

  @propertyMap()
  public isMachineryLocalToBePaint: string;

  @propertyMap()
  public commandPanelLocation: string;

  @propertyMap()
  public isPhotoManoeuverLocker: boolean;

  @propertyMap()
  public manoeuverState: string;

  @propertyMap()
  public technology: string;

  @propertyMap()
  public isElectricManoeuverReminder: string;

  @propertyMap()
  public normalSpeed: number;

  @propertyMap()
  public tractionState: string;

  @propertyMap()
  public hasTractionPhoto: boolean;

  @propertyMap()
  public cableCount: string;

  @propertyMap()
  public diameter: string;

  @propertyMap()
  public limiterType: string;

  @propertyMap()
  public speedLimiterState: string;

  @propertyMap()
  public hasLimiterPhoto: boolean;

  @propertyMap()
  public limiterLocation: string;

  @propertyMap()
  public limiterRemoteReloading: string;

  @propertyMap()
  public descendingSpeed: string;

  @propertyMap()
  public ascendingSpeed: string;

  @propertyMap()
  public ascendingSpeedingProtectionType: string;

  @propertyMap()
  public hasAscendingSpeedPhoto: boolean;

  @propertyMap()
  public dataCaptureCaseRivp: string;

  @propertyMap()
  public weight: string;

  @propertyMap()
  public peopleCount: string;

  @propertyMap()
  public hasInsideCabinPhoto: boolean;

  @propertyMap()
  public hasCabinButtonBoxPhoto: boolean;

  @propertyMap()
  public electronicSurveillance: string;

  @propertyMap()
  public cabinCasingState: string;

  @propertyMap()
  public hearingImpairedMagneticLoop: string;

  @propertyMap()
  public cabinDoorType: string;

  @propertyMap()
  public cabinDoorState: string;

  @propertyMap()
  public hasCabinDoorPhoto: boolean;

  @propertyMap()
  public hasMultipleCabinDoorPhoto: boolean;

  @propertyMap()
  public panelCount: string;

  @propertyMap()
  public cabinDoorOperatorState: string;

  @propertyMap()
  public hasCabinDoorOperatorPhoto: boolean;

  @propertyMap()
  public serviceFaceCount: string;

  @propertyMap()
  public trapDoor: string;

  @propertyMap()
  public hasCabinRoofPhoto: boolean;

  @propertyMap()
  public parachuteState: string;

  @propertyMap()
  public parachuteType: string;

  @propertyMap()
  public driftProtection: string;

  @propertyMap()
  public emergencyDevice: string;

  @propertyMap()
  public fallingRiskProtection: string;

  @propertyMap()
  public hasCabinUnderneathPhoto: boolean;

  @propertyMap()
  public emergencyDeviceUnderneath: string;

  @propertyMap()
  public casingType: string;

  @propertyMap()
  public casingCabinGuideType: string;

  @propertyMap()
  public casingCabinGuideCounterbalanceType: string;

  @propertyMap()
  public bowlPenneCarreLowLevel: string;

  @propertyMap()
  public bowlHasBowlPhoto: boolean;

  @propertyMap()
  public bowlPulleyTightenerState: string;

  @propertyMap()
  public bowlCushioningType: string;

  @propertyMap()
  public bowlEmergencyDevice: string;

  @propertyMap()
  public landingDoorType: string;

  @propertyMap()
  public hasLandingDoorPhoto: boolean;

  @propertyMap()
  public hasLandingMultipleDoorPhoto: boolean;

  @propertyMap()
  public landingDoorState: string;

  @propertyMap()
  public landingFireProofDoor: string;

  @propertyMap()
  public landingLeafCount: string;

  @propertyMap()
  public landingFreePassage: number;

  @propertyMap()
  public landingDoorLockDevice: string;

  @propertyMap()
  public landingDoorLockDeviceSlslm: string;

  @propertyMap()
  public landingFiremanManoeuver: string;

  @propertyMap()
  public landingLocationIndicator: string;

  @propertyMap()
  public landingVocaleSynthese: string;

  @propertyMap()
  public globalElevatorState: string;

  @propertyMap()
  public validity2010: boolean;

  @propertyMap()
  public validity2013: boolean;

  @propertyMap()
  public validity2018: boolean;
}

export class DocumentCount {
  @propertyMap({ default: 0 })
  public total: number;

  @propertyMap({ default: 0 })
  public other: number;

  @propertyMap({ default: 0 })
  public picture: number;

  @propertyMap({ default: 0 })
  public contract: number;

  @propertyMap({ default: 0 })
  public bpu: number;

  @propertyMap({ default: 0 })
  public eds: number;

  @propertyMap({ default: 0 })
  public technicalCheck: number;

  public get documents(): number {
    return this.other + this.contract + this.bpu + this.eds + this.technicalCheck;
  }
}

export class Office {
  @propertyMap({ source: '_id' })
  public id: string;

  @propertyMap()
  public name: string;
}

export class ElevatorMetadata {
  @propertyMap()
  public failureRate: number;

  @propertyMap()
  public failureCount: number;

  @propertyMap()
  public failureCountFromProvider: number;

  @propertyMap()
  public failureRateFromProvider: number;

  @propertyMap()
  public failureCountFromReporting: number;

  @propertyMap()
  public availabilityRate: number;

  @propertyMap()
  public rollingAvailabilityRate: number;

  @propertyMap()
  public availabilityRateFromProvider: number;

  @propertyMap()
  public state: ElevatorState;

  @propertyMap()
  public phoneState: TestState;

  @propertyMap()
  public monitoringState: TestState;

  @propertyMap()
  public usage: number;

  @propertyMap()
  public signatureState: ElevatorSignatureState;

  @propertyMap({ type: 'Moment' })
  public signatureLastDate: moment.Moment;

  @propertyMap({ type: 'Moment' })
  public signatureLastChangeDate: moment.Moment;

  @propertyMap()
  public cabinNumber: number;

  @propertyMap()
  public manoeuverNumber: number;

  @propertyMap()
  public motionCount: number;

  @propertyMap()
  public motionCountAverageYearly: number;

  @propertyMap()
  public motionCountAverageDaily: number;

  @propertyMap({ type: 'Moment' })
  public lastMaintenanceDate: moment.Moment;

  @propertyMap()
  public isLateMaintenance: boolean;

  @propertyMap({ default: false })
  public isSpammed: boolean;

  @propertyMap()
  public janitorInterventionApplicationCount: number;

  @propertyMap()
  public lastJanitorPhoneTest: TestState;

  @propertyMap({ type: 'Moment' })
  public lastJanitorPhoneTestDate: moment.Moment;

  @propertyMap()
  public userLockedInCountFromReporting: number;

  @propertyMap({ type: 'Moment' })
  public connectedAt: moment.Moment;

  @propertyMap()
  public isTertiary: boolean;

  @propertyMap({ type: 'Moment' })
  public contractStart: moment.Moment;

  @propertyMap({ type: 'Moment' })
  public contractEnd: moment.Moment;

  @propertyMap()
  public market: number;

  @propertyMap()
  public share: number;

  @propertyMap()
  public subShare: number;

  @propertyMap({ type: Transmitter })
  public transmitter: Transmitter;

  @propertyMap({ type: Office })
  public controlOffice: Office;

  @propertyMap({ type: Office })
  public studyOffice: Office;

  @propertyMap({ type: DocumentCount })
  public documentCount: DocumentCount;

  @propertyMap({ type: Technical })
  technical: Technical;

  @propertyMap({ type: TechnicalCheck })
  technicalCheck: TechnicalCheck;

  @propertyMap({ type: Specifications })
  specifications: Specifications;

  @propertyMap({ type: [ElevatorGlobalSignatureIndicator] })
  globalSignatureIndicators: ElevatorGlobalSignatureIndicator[];

  @propertyMap({ type: [ElevatorSignatureIndicator] })
  signatureIndicators: ElevatorSignatureIndicator[];

  @propertyMap({ type: ElevatorMetadataSignatureThreshold })
  signatureThreshold?: ElevatorMetadataSignatureThreshold;

  @propertyMap()
  failureProbability?: number;

  @propertyMap({ type: 'Moment' })
  forecastFailureDate?: Moment;

  @propertyMap()
  globalSignatureIndicatorValue: number;
}

export class Elevator extends Equipment {
  @propertyMap({ type: ElevatorMetadata })
  public metadata: ElevatorMetadata;
}
