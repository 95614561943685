/** @format */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IDatatableOptions, IDatatableRecords } from '../_components/datagrid/datatable.class';
import { buildHeaderSearchFilter, IHeaderSearch } from '../_classes/header-search';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { GroupTreeElement, Intervention, InterventionStatus } from 'sesio-lib';
import { map } from 'rxjs/operators';
import { map as lmap } from 'lodash-es';
import { ModelMapper } from 'model-mapper';

export interface IInterventionSearch extends IHeaderSearch {
  statuses?: InterventionStatus[];
  realEstateStructureIds?: string[];
  inspectionIds?: string[];
}

@Injectable({
  providedIn: 'root',
})
export class InterventionService {
  constructor(protected http: HttpClient) {}

  public get(id: string): Observable<Intervention> {
    return this.http
      .get<any[]>(`${environment.apiUrl}/interventions/${id}`)
      .pipe(map(data => new ModelMapper(Intervention).map(data)));
  }

  public list({
    statuses,
    organizationalUnitId,
    realEstateStructureIds,
    inspectionIds,
    equipmentIds,
    contractorId,
  }: {
    statuses?: InterventionStatus[];
    organizationalUnitId?: string;
    realEstateStructureIds?: string[];
    inspectionIds?: string[];
    equipmentIds?: string[];
    contractorId?: string;
  }): Observable<Intervention[]> {
    const params: any = {};
    if (statuses?.length) {
      params.statuses = statuses;
    }
    if (organizationalUnitId) {
      params.organizationalUnitId = organizationalUnitId;
    }
    if (realEstateStructureIds?.length) {
      params.realEstateStructureIds = realEstateStructureIds;
    }
    if (inspectionIds?.length) {
      params.inspectionIds = inspectionIds;
    }
    if (equipmentIds?.length) {
      params.equipmentIds = equipmentIds;
    }
    if (contractorId) {
      params.contractorId = contractorId;
    }
    return this.http
      .get<any[]>(`${environment.apiUrl}/interventions`, { params })
      .pipe(map(data => lmap(data, d => new ModelMapper(Intervention).map(d))));
  }

  public getStatus(search?: IInterventionSearch): Observable<{ name: string; value: number }[]> {
    return this.http.post<any>(`${environment.apiUrl}/interventions/get-status`, this.buildFilter(search));
  }

  public create(data: any): Observable<Intervention> {
    return this.http
      .post<any>(`${environment.apiUrl}/interventions`, data)
      .pipe(map(data => new ModelMapper(Intervention).map(data)));
  }

  public update(id: string, data: any): Observable<boolean> {
    return this.http.patch<boolean>(`${environment.apiUrl}/interventions/${id}`, data);
  }

  public cancel(id: string): Observable<boolean> {
    return this.http.patch<boolean>(`${environment.apiUrl}/interventions/${id}/cancel`, {});
  }

  public getMapInfo(search: IHeaderSearch): Observable<
    {
      _id: string;
      coordinates: [number, number];
      status: string;
      state: string;
    }[]
  > {
    return this.http.post<any[]>(`${environment.apiUrl}/interventions/get-map-info`, buildHeaderSearchFilter(search));
  }

  public getTree(search: IHeaderSearch): Observable<GroupTreeElement[]> {
    return this.http
      .post<any[]>(`${environment.apiUrl}/interventions/get-tree`, buildHeaderSearchFilter(search))
      .pipe(map(data => data.map(d => new ModelMapper(GroupTreeElement).map(d))));
  }

  public datatable(
    query: IDatatableOptions,
    search?: IInterventionSearch,
    box?: number[][]
  ): Observable<IDatatableRecords<any>> {
    const params: any = {};
    if (box?.length) params.box = box;
    return this.http.post<any>(
      `${environment.apiUrl}/interventions/datatable`,
      { query, filter: this.buildFilter(search) },
      { params }
    );
  }

  private buildFilter(search: IInterventionSearch): any {
    const filter = buildHeaderSearchFilter(search);
    if (search?.statuses?.length) {
      filter.statuses = search.statuses;
    }
    if (search?.realEstateStructureIds?.length) {
      filter.realEstateStructureIds = search.realEstateStructureIds;
    }
    if (search?.inspectionIds?.length) {
      filter.inspectionIds = search.inspectionIds;
    }
    return filter;
  }
}
