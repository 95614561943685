/** @format */

export const ElevatorIndicators = [
  'report-indicator-flops-10',
  'report-indicator-disponibility-rate',
  'report-indicator-failure-rate',
  'report-indicator-average-failure-reponse-time',
  'report-indicator-voice-test',
  'report-indicator-new-failure',
  'report-indicator-closed-failure',
  'report-indicator-restarts-less-than-5-hours',
  'report-indicator-restarts-greater-than-5-hours-less-than-3-days',
  'report-indicator-restarts-greater-than-3-days',
  'report-indicator-out-of-time-interventions',
  'report-indicator-extrications',
  'report-indicator-failure-rate-greater-than-4',
  'report-indicator-disponibility-rate-less-than-98',
  'report-indicator-maintenance',
  'report-indicator-out-of-time-maintenance',
  'report-indicator-failure-between-30m-and-4h',
  'report-indicator-equipment-failure-between-4h-and-72h-count',
  'report-indicator-equipment-failure-gt-72h-count',
  'report-indicator-equipment-with-more-than-4-failures-of-more-than-72h',
  'report-indicator-equipment-with-more-than-8-failures-between-4h-and-72h',
  'report-indicator-equipment-with-more-than-16-failures-between-30m-and-4h',
  'report-indicator-equipment-with-more-than-7-failures',
  'report-indicator-average-failure-reponse-time-modulo',
  'report-indicator-average-immobilization-duration',
  'report-indicator-nb-immobilization-gt-30-d',
  'report-indicator-nb-cumulative-immobilization-gt-30-d',
];

export const ParkingDoorIndicators = [
  'report-indicator-flops-10',
  'report-indicator-disponibility-rate',
  'report-indicator-failure-rate',
  'report-indicator-average-failure-reponse-time',
  'report-indicator-new-failure',
  'report-indicator-closed-failure',
  'report-indicator-restarts-less-than-5-hours',
  'report-indicator-restarts-greater-than-5-hours-less-than-3-days',
  'report-indicator-restarts-greater-than-3-days',
  'report-indicator-out-of-time-interventions',
  'report-indicator-failure-rate-greater-than-4',
  'report-indicator-disponibility-rate-less-than-98',
];

export const ExcelReports = [
  'report-excel-indicators',
  'report-excel-status-evolution',
  'report-excel-rivp',
  'report-excel-isitech',
  'report-excel-elevators',
  'report-excel-telemonitor',
];
export const ChartReports = [
  'report-chart-traffic',
  'report-chart-failure',
  'report-chart-availability',
  'report-chart-test-phony',
  'report-chart-contractors',
];

export enum ReportStatus {
  CREATED = 'created',
  BUILDING = 'building',
  AVAILABLE = 'available',
  ERROR = 'error',
  CANCELED = 'canceled',
}

export enum PeriodicKind {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
  SEMESTER = 'semester',
  YEARLY = 'yearly',
}

export const ReportStatusIcon = {
  [ReportStatus.CREATED]: 'alarm',
  [ReportStatus.BUILDING]: 'alarm',
  [ReportStatus.AVAILABLE]: 'check_circle',
  [ReportStatus.ERROR]: 'cancel',
  [ReportStatus.CANCELED]: 'square',
};

export const ReportStatusColor = {
  [ReportStatus.CREATED]: 'var(--ion-color-medium)',
  [ReportStatus.BUILDING]: 'var(--ion-color-medium)',
  [ReportStatus.AVAILABLE]: 'var(--ion-color-success)',
  [ReportStatus.ERROR]: 'var(--ion-color-danger)',
  [ReportStatus.CANCELED]: 'var(--ion-color-dark)',
};

export enum ReportInstanceStatus {
  CREATED = 'created',
  BUILDING = 'building',
  AVAILABLE = 'available',
  ERROR = 'error',
}

export const ReportInstanceStatusIcon = {
  [ReportInstanceStatus.CREATED]: 'alarm',
  [ReportInstanceStatus.BUILDING]: 'alarm',
  [ReportInstanceStatus.AVAILABLE]: 'check_circle',
  [ReportInstanceStatus.ERROR]: 'cancel',
};

export const ReportInstanceStatusColor = {
  [ReportInstanceStatus.CREATED]: 'var(--ion-color-medium)',
  [ReportInstanceStatus.BUILDING]: 'var(--ion-color-medium)',
  [ReportInstanceStatus.AVAILABLE]: 'var(--ion-color-success)',
  [ReportInstanceStatus.ERROR]: 'var(--ion-color-danger)',
};
