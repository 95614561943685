import { InjectionToken } from '@angular/core';

export interface LibConfig {
  environment: {
    apiUrl: string;
    wsUrl: string;
    production: boolean;
  };
}

export const LibConfigService = new InjectionToken<LibConfig>('LibConfig');
