import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as Highcharts from 'highcharts';
import { fadeOut, highchartsOptions } from 'sesio-lib';
import { OrganizationalUnitService } from '../../../_services/organizational-unit.service';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { MatTabGroup } from '@angular/material/tabs';
import { deburr, each, lowerCase, merge, omit, sortBy, trim } from 'lodash-es';

import Boost from 'highcharts/modules/boost';
Boost(Highcharts);
import noData from 'highcharts/modules/no-data-to-display';
noData(Highcharts);
import More from 'highcharts/highcharts-more';
More(Highcharts);
import Exporting from 'highcharts/modules/exporting';
Exporting(Highcharts);
import ExportData from 'highcharts/modules/export-data';
ExportData(Highcharts);
import Tree from 'highcharts/modules/treemap';
Tree(Highcharts);
import Heatmap from 'highcharts/modules/heatmap';
Heatmap(Highcharts);
import HighchartsNetworkgraph from 'highcharts/modules/networkgraph';
HighchartsNetworkgraph(Highcharts);

interface INode {
  _id: string;
  code: string;
  name: string;
  kind: string;
  path: string;
  parent: string;
  children: INode[];
}

interface ITreeData {
  _id: string;
  parent: string;
  name: string;
  children?: ITreeData[];
  code: string;
  kind: string;
}

interface ITreeNode {
  expandable: boolean;
  name: string;
  level: number;
  code: string;
  kind: string;
}

@Component({
  selector: 'app-statistics-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  animations: [fadeOut]
})
export class StatisticsDefaultComponent implements OnInit, AfterViewInit {

  public loading = true;

  @ViewChild('patrimonyTabs', { static: true, read: MatTabGroup })
  private patrimonyTabsRef: MatTabGroup;

  private organizationalUnitNodes: INode[];

  private transformer = (node: ITreeData, level: number) => {
    return {
      expandable: node.children && node.children.length > 0,
      name: node.name, level, code: node.code, kind: node.kind
    };
  }

  // tslint:disable-next-line: member-ordering
  public treeControl = new FlatTreeControl<ITreeNode>(
    node => node.level, node => node.expandable);

  // tslint:disable-next-line: member-ordering
  private treeFlattener = new MatTreeFlattener(
    this.transformer, node => node.level, node => node.expandable, node => node.children);

  // tslint:disable-next-line: member-ordering
  public dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  public hasChild = (_: number, node: ITreeNode) => node.expandable;

  constructor(
    private translate: TranslateService,
    private organizationalUnitService: OrganizationalUnitService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    Highcharts.setOptions(highchartsOptions[this.translate.getDefaultLang()]);
  }

  async ngAfterViewInit(): Promise<void> {
    this.organizationalUnitNodes = await this.organizationalUnitService.treemap().toPromise();
    this.dataSource.data = this.organizationalUnitNodes;
    this.treemapChart();
    setTimeout(() => {
      this.patrimonyTabsRef.realignInkBar();
      this.loading = false;
      this.changeDetectorRef.detectChanges();
    }, 300);
  }

  private async treemapChart(): Promise<void> {
    let colorI = -1;
    const data = [];
    const unwind = (node: INode) => {
      if (node.kind === 'division') { ++colorI; }
      data.push({
        id: node._id, name: node.kind === 'group' ? node.name : `[${node.code}] ${node.name}`,
        parent: node.kind === 'division' ? null : node.parent,
        value: node.children?.length || 1,
        color: Highcharts.getOptions().colors[colorI]
      });
      each(node.children, unwind);
    };
    each(this.organizationalUnitNodes, unwind);
    Highcharts.chart('orga-units-treemap', {
      credits: false,
      chart: {
        spacingTop: 40
      },
      title: { text: '' },
      series: [{
        type: 'treemap',
        layoutAlgorithm: 'squarified',
        allowDrillToNode: true,
        drillUpButton: {
          text: `< ${await this.translate.get('back').toPromise()}`,
          relativeTo: 'spacingBox',
          position: { y: -34, x: 0 },
          theme: {
            class: 'drilldown-back-btn',
            fill: 'var(--ion-color-primary)',
            'stroke-width': 0,
            r: 8,
            style: {
              color: 'white'
            },
            states: {
              hover: { fill: 'var(--ion-color-primary-tint)' },
              select: { fill: 'var(--ion-color-primary-tint)' }
            }
          }
        },
        animationLimit: 1000,
        dataLabels: {
          enabled: false,
          style: {
            fontFamily: 'Roboto',
            textOutline: null,
            fontSize: '14px',
          }
        },
        levelIsConstant: false,
        levels: [
          {
            level: 1, dataLabels: { enabled: true }, borderWidth: 2,
            style: {
              fontFamily: 'Roboto',
              textOutline: null,
              fontSize: '14px',
            }
          },
          {
            level: 2, borderWidth: 0,
            style: {
              fontFamily: 'Roboto',
              textOutline: null,
              fontSize: '14px',
            }
          },
          {
            level: 3, borderWidth: 0,
            style: {
              fontFamily: 'Roboto',
              textOutline: null,
              fontSize: '14px',
            }
          },
          {
            level: 4, borderWidth: 0,
            style: {
              fontFamily: 'Roboto',
              textOutline: null,
              fontSize: '14px',
            }
          },
          {
            level: 5, borderWidth: 0,
            style: {
              fontFamily: 'Roboto',
              textOutline: null,
              fontSize: '14px',
            }
          },
          {
            level: 6, borderWidth: 0,
            style: {
              fontFamily: 'Roboto',
              textOutline: null,
              fontSize: '14px',
            }
          },
          {
            level: 7, borderWidth: 0,
            style: {
              fontFamily: 'Roboto',
              textOutline: null,
              fontSize: '14px',
            }
          },
          {
            level: 8, borderWidth: 0,
            style: {
              fontFamily: 'Roboto',
              textOutline: null,
              fontSize: '14px',
            }
          },
        ],
        turboThreshold: data.length,
        data
      }]
    } as any);
  }

}
