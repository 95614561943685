import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { v4 as uuidv4 } from 'uuid';
import { IPieChartData } from '../chart.interface';
import * as Highcharts from 'highcharts';
import Boost from 'highcharts/modules/boost';
Boost(Highcharts);
import noData from 'highcharts/modules/no-data-to-display';
noData(Highcharts);
import More from 'highcharts/highcharts-more';
More(Highcharts);
import Exporting from 'highcharts/modules/exporting';
Exporting(Highcharts);
import ExportData from 'highcharts/modules/export-data';
ExportData(Highcharts);

import Drilldown from 'highcharts/modules/drilldown';
import { highchartsOptions } from '../../../constants/highcharts';
import { cloneDeep, filter, isNil, map, reduce } from 'lodash-es';
Drilldown(Highcharts);


@Component({
  selector: 'sesio-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PieChartComponent implements OnInit {

  public id = `pie-chart-${uuidv4()}`;

  private loading = true;

  @Input()
  private enableExporting = false;

  @Input()
  private name: string;

  @Input()
  private labelDistance: any = 20;

  @Input()
  private labelBreakValue: any = 20;

  @Input()
  private labelDistanceBreakValue: any = '-50%';

  @Input()
  private format = '.0f';

  @Input()
  public pieSize: number | string = '100%';

  @Input('config')
  public set setConfig(config: any) {
    if (config && config.labels) { this.name = config.name; }
    if (config && config.format) { this.format = config.format; }
  }

  @Input('data')
  public set setData(data: IPieChartData[]) {
    this.updateData(data);
  }
  private data: IPieChartData[] = [];
  private series: any[];

  private chart: Highcharts.Chart;

  constructor(private translate: TranslateService) { }

  async ngOnInit(): Promise<void> {
    Highcharts.setOptions(highchartsOptions[this.translate.getDefaultLang()]);
  }

  public async loadChart($event: any): Promise<void> {
    if (!$event.visible || !this.loading) { return; }
    this.loading = false;
    this.chart = Highcharts.chart(this.id, {
      chart: {
        type: 'pie',
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        backgroundColor: 'transparent'
      },
      credits: false,
      exporting: {
        enabled: this.enableExporting
      },
      title: { text: '' },
      series: this.series,
      tooltip: {
        shared: true,
        pointFormat: `<b>{point.y:${this.format}}%</b>`
      },
      plotOptions: {
        pie: {
          size: this.pieSize,
          allowPointSelect: false,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: `{point.y:${this.format}}%`,
            connectorPadding: 0,
            overflow: 'allow',
            crop: false,
            style: {
              textOverflow: 'clip'
            }
          },
          showInLegend: false,
          verticalAlign: 'middle'
        }
      }
    } as any);
  }

  private async updateData(data: IPieChartData[]): Promise<void> {
    const promises = [];
    this.data = map(cloneDeep(filter(data, d => !!d.y)) || [], d => {
      d = cloneDeep(d);
      if (d.translate) {
        promises.push(this.translate.get(d.name).toPromise().then(name => d.name = name));
      }
      return d;
    });
    await Promise.all(promises);
    const total = reduce(map(this.data, 'y'), (p, c) => p + c);
    const newData = map(this.data, (el: any, index: number) => {
      el.count = el.y;
      el.y = el.y * 100 / total;
      el.color = el.color || Highcharts.getOptions().colors[index]
      return el;
    });
    let startAngle = -90;
    this.series = [];
    for (let i = 0; i < newData.length; ++i) {
      const endAngle = startAngle + 360 * newData[i].y / 100;
      this.series.push({
        name: this.name || 'data', type: 'pie', yAxis: 0,
        dataLabels: {
          distance: newData[i].y === 100 ? '-100%' :
            newData[i].y >= this.labelBreakValue ? this.labelDistanceBreakValue :
              this.labelDistance
        },
        startAngle, endAngle, data: [newData[i]]
      });
      startAngle = endAngle;
    };
    if (this.chart) {
      while (this.chart.series.length) { this.chart.series[0].remove(); }
      this.series.forEach(s => this.chart.addSeries(s));
      this.chart.redraw();
    }
  }

}
