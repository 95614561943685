/** @format */

import {Pipe, PipeTransform} from '@angular/core';
import humanizeDuration from 'humanize-duration';
import {merge} from 'lodash-es';
import {Moment} from 'moment';

@Pipe({name: 'since'})
export class SincePipe implements PipeTransform {
  transform(data: Date | Moment | number, options?: humanizeDuration.Options): string | null {
    if (!data) return null;
    const timestamp = typeof data === 'number' ? data : data.valueOf();
    return humanizeDuration(Date.now() - timestamp, merge({language: 'fr', maxDecimalPoints: 0}, options));
  }
}
