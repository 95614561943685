import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageTrackingComponent } from './page-tracking/page-tracking.component';
import { InViewportModule } from 'ng-in-viewport';



@NgModule({
    declarations: [PageTrackingComponent],
    imports: [
        CommonModule,
        InViewportModule
    ],
    exports: [PageTrackingComponent]
})
export class TrackingModule { }
