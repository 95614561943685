import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../_services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SsoGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const ssoToken = route.queryParamMap.get('sso_token');
    if (!ssoToken) { return true; } 
    try {
      await this.authService.signIn(ssoToken);
      await this.router.navigate(['/dashboard']);
      return false;
    } catch {}
    return true;
  }

}
