<app-page-tracking *ngIf="tracking" [origin]="tracking.origin" [page]="tracking.page" [id]="tracking.id">
</app-page-tracking>
<div class="container" [style]="style">
  <app-datagrid *ngIf="options" #datagrid [options]="options" (ready)="loadDatagrid()">
    <sesio-loader datagrid-spinner></sesio-loader>
    <ng-template appDatagridCell="group" let-cell="cell">
      <app-organizational-unit-datagrid-cell [cell]="cell"></app-organizational-unit-datagrid-cell>
    </ng-template>
    <ng-template appDatagridCell="realEstateStructure" let-cell="cell">
      <app-real-estate-structure-datatgrid-cell [cell]="cell"></app-real-estate-structure-datatgrid-cell>
    </ng-template>
    <ng-template appDatagridCell="lastInspection" let-cell="cell">
      <div fxLayout="column" *ngIf="cell.rawValue">
        <span>{{cell.rawValue.reference}}</span>
        <span>{{cell.rawValue?.reportDate | date: 'shortDate'}}</span>
      </div>
    </ng-template>
    <ng-template appDatagridCell="nextInspection" let-cell="cell">
      <div fxLayout="column" fxLayoutGap="4px" fxLayoutAlign="space-between start" *ngIf="cell.rawValue"
        [style.width.%]="100">
        <span>{{cell.rawValue.reference}}</span>
        <div fxLayout="row" fxLayoutGap="4px" fxLayoutAlign="space-between start" [style.width.%]="100">
          <div fxLayout="column" *ngIf="cell.rawValue.activeAt">
            <span [style.font-size.em]="0.8" [style.line-height.em]="0.8">Date d'activation</span>
            <span>{{cell.rawValue.activeAt | date: 'shortDate'}}</span>
          </div>
          <div fxLayout="column" *ngIf="cell.rawValue.deadline">
            <span [style.font-size.em]="0.8" [style.line-height.em]="0.8">Date limite</span>
            <span>{{cell.rawValue.deadline | date: 'shortDate'}}</span>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template appDatagridCell="files" let-column="column" let-cell="cell">
      <app-document-datagrid-cell [kind]="column.metadata.kind" [files]="cell.rawValue">
      </app-document-datagrid-cell>
    </ng-template>
  </app-datagrid>
</div>