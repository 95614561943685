<ion-content>
  <mat-tab-group mat-align-tabs="start">
    <!-- ESI -->
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="label" fxLayout="column" fxLayoutAlign="center start">
          <span class="sup">ESI</span>
          <span>{{esi ? esi.reference : ((formGroup.get('organizationalUnit').value?.code || '') +
            (RealEstateStructureKindReferencePrefix[formGroup.get('kind').value] || '') +
            formGroup?.get('reference').value)}}</span>
        </div>
      </ng-template>
      <ng-scrollbar *ngIf="!loading; else loadingTmpl" visibility="hover" track="all"
        [style.height]="disabled ? '100%' : 'calc(100% - 44px)'">
        <form [formGroup]="formGroup" fxLayout="row wrap">
          <div fxLayout="row wrap" [style.width.%]="100">
            <app-organizational-unit-select class="form-field" formControlName="organizationalUnit"
              [style.width.px]="500">
            </app-organizational-unit-select>
            <app-real-estate-structure-select class="form-field" formControlName="parent" [label]="'parent' | translate"
              [organizationalUnit]="formGroup.get('organizationalUnit').value" [style.width.px]="500">
            </app-real-estate-structure-select>
          </div>
          <mat-form-field class="form-field">
            <mat-label>Type</mat-label>
            <mat-select formControlName="kind">
              <mat-option *ngFor="let kind of RealEstateStructureKind | values" [value]="kind">
                <ion-label [translate]="kind"></ion-label>
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field *ngIf="esi" class="form-field">
            <mat-label translate>Référence</mat-label>
            <input matInput formControlName="reference">
          </mat-form-field>
          <mat-form-field *ngIf="!esi" class="form-field">
            <mat-label translate>Référence</mat-label>
            <mat-label color="primary" matPrefix [style.padding-right.px]="2">
              {{formGroup.get('organizationalUnit').value?.code ||
              ''}}{{RealEstateStructureKindReferencePrefix[formGroup.get('kind').value] || ''}}
            </mat-label>
            <input matInput formControlName="reference">
            <mat-error *ngIf="formGroup.get('reference').errors?.unique">
              <mat-label>Référence déjà attribuée</mat-label>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="form-field">
            <mat-label>Nom</mat-label>
            <input matInput formControlName="name">
          </mat-form-field>
          <mat-form-field class="form-field" [style.width.%]="100">
            <mat-label>Description</mat-label>
            <textarea matInput formControlName="description"></textarea>
          </mat-form-field>
          <ng-container *ngIf="disabled">
            <h4 *ngIf="esi?.location?.address?.formatted" color="primary" [style.margin-bottom.px]="8">
              {{esi.location.address.formatted}}
            </h4>
          </ng-container>
          <ng-container *ngIf="!disabled">
            <mat-form-field [style.width.%]="100">
              <mat-label color="primary" translate>address</mat-label>
              <mat-icon color="primary" matPrefix>place</mat-icon>
              <input #searchLocation matInput autocomplete="off" type="text" [formControl]="addressControl"
                [matAutocomplete]="auto">
              <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayOption"
                (optionSelected)="selected($event.option)">
                <mat-option *ngIf="searching">
                  <div [style.position]="'relative'" [style.height.px]="48">
                    <sesio-loader [style.--diameter]="'32px'"></sesio-loader>
                  </div>
                </mat-option>
                <mat-option *ngFor="let option of options | async" [value]="option">
                  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                    <span>{{option.address.formatted}}</span>
                  </div>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <mat-form-field [style.width.%]="100">
              <mat-label color="primary" translate>additional-address</mat-label>
              <input matInput autocomplete="off" type="text" [formControl]="additionalControl">
            </mat-form-field>
          </ng-container>
          <app-map (ready)="showMarker($event)"></app-map>
          <app-organizational-unit-contact-info [id]="formGroup.get('organizationalUnit').value?.id">
          </app-organizational-unit-contact-info>
        </form>
      </ng-scrollbar>
      <mat-toolbar class="actions" *ngIf="!disabled" fxLayoutAlign="space-between center">
        <button mat-raised-button color="danger" (click)="remove()" [disabled]="!esi">Supprimer</button>
        <div fxLayoutAlign="end center">
          <button mat-button color="primary" (click)="save()" [disabled]="!formGroup?.valid">Enregistrer</button>
          <button mat-button (click)="dismiss()">Fermer</button>
        </div>
      </mat-toolbar>
    </mat-tab>
    <!-- Equipments sécurité incendie -->
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="label" fxLayout="column" fxLayoutAlign="center start">
          <span class="sup">Équipements SI</span>
          <!-- <div *ngIf="esi?.equipment" [style.color]="InspectionStateOptions[esi.equipment.inspectionState].color"
            fxLayoutAlign="row center" fxLayoutGap="8px">
            <mat-icon>{{InspectionStateOptions[esi.equipment.inspectionState].icon}}</mat-icon>
            <span>{{esi?.equipment?.count || 0}}</span>
          </div> -->
          <!-- <span *ngIf="!esi?.equipment" color="primary">0</span> -->
        </div>
      </ng-template>
      <ng-scrollbar visibility="hover" track="all" inViewport (inViewportAction)="loadEquipments($event.visible)">
        <ng-container *ngTemplateOutlet="equipmentsTmpl; context: { equipments: equipments }"></ng-container>
      </ng-scrollbar>
    </mat-tab>
    <!-- VR -->
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="label" fxLayout="column" fxLayoutAlign="center start">
          <span class="sup">VR</span>
          <!-- <span>{{esi?.inspectionCount || 0}}</span> -->
        </div>
      </ng-template>
      <ng-scrollbar visibility="hover" track="all" inViewport (inViewportAction)="loadInspections($event.visible)">
        <ng-container *ngTemplateOutlet="inspectionsTmpl; context: { inspections: filteredInspections | async }">
        </ng-container>
      </ng-scrollbar>
    </mat-tab>
    <!-- Interventions -->
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="label" fxLayout="column" fxLayoutAlign="center start">
          <span class="sup">Interventions</span>
          <!-- <span>{{esi?.interventionCount || 0}}</span> -->
        </div>
      </ng-template>
      <ng-scrollbar visibility="hover" track="all" inViewport (inViewportAction)="loadInterventions($event.visible)">
        <ng-container *ngTemplateOutlet="interventionsTmpl; context: { interventions: interventions, full: true }">
        </ng-container>
      </ng-scrollbar>
    </mat-tab>
    <!-- Quotation / Order -->
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="label" fxLayout="column" fxLayoutAlign="center start">
          <span class="sup">Devis / Commandes</span>
          <!-- <span>{{esi?.quotation?.total?.count || 0}}</span> -->
        </div>
      </ng-template>
      <ng-scrollbar visibility="hover" track="all" inViewport (inViewportAction)="loadQuotations($event.visible)">
        <ng-container *ngTemplateOutlet="quotationsTmpl; context: { quotations: quotations }"></ng-container>
      </ng-scrollbar>
    </mat-tab>
    <!-- Security Register -->
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="label" fxLayout="column" fxLayoutAlign="center start">
          <span class="sup">Registres de sécurité</span>
          <!-- <span>{{esi?.securityRegisterDocuments?.length || 0}}</span> -->
        </div>
      </ng-template>
      <ng-scrollbar visibility="hover" track="all">
        <ng-container *ngTemplateOutlet="documentTmpl; context: { documents: esi?.securityRegisterDocuments }">
        </ng-container>
      </ng-scrollbar>
    </mat-tab>
    <mat-tab disabled>
      <ng-template mat-tab-label>
        <ion-buttons slot="end">
          <ion-button (click)="dismiss()">
            <ion-icon slot="icon-only" name="close"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</ion-content>

<ng-template #equipmentsTmpl let-equipments="equipments">
  <div *ngIf="equipments; else loadingTmpl" class="equipments" fxLayout="column">
    <div class="header" fxLayout="row" fxLayoutAlign="start center">
      <span class="block equipment-block" [style.max-width.px]="300">TYPE</span>
      <span class="block equipment-block">RÉFÉRENCE</span>
      <span class="block equipment-block">PRESTATAIRE</span>
      <span class="block equipment-block">ÉTAT</span>
      <span class="block equipment-block">ANOMALIES</span>
      <span class="block equipment-block">DANGERS</span>
      <span class="block equipment-block">DERNIÈRE VR</span>
      <span class="block equipment-block">VR LIÉE</span>
    </div>
    <mat-expansion-panel *ngFor="let equipment of equipments" class="equipment" fxLayout="column" [hideToggle]="true"
      [disabled]="equipment.anomalyCount + equipment.dangerCount === 0"
      (opened)="loadEquipmentInterventions(equipment)">
      <mat-expansion-panel-header fxLayout="row" fxLayoutAlign="start center">
        <span class="block equipment-block" matTooltip="Type d'équipement" fxLayout="column"
          [style.align-items]="'start'" [style.max-width.px]="300">
          <span [style.font-size.em]="0.8">{{equipment | get:'kind.category' | translate}}</span>
          <span>{{equipment | get:'kind.name' }}</span>
        </span>
        <span class="block equipment-block">{{equipment.reference}}</span>
        <span class="block equipment-block">{{equipment.contractor?.name}}</span>
        <span class="block equipment-block" [style.color]="InspectionStateOptions[equipment.inspectionSate].color"
          fxLayoutAlign="start center" fxLayoutGap="4px">
          <mat-icon>{{InspectionStateOptions[equipment.inspectionSate].icon}}</mat-icon>
          <span>{{equipment.inspectionSate | translate}}</span>
        </span>
        <span class="block equipment-block" color="warning">{{equipment.anomalyCount}}</span>
        <span class="block equipment-block" color="danger">{{equipment.dangerCount}}</span>
        <span class="block equipment-block" fxLayout="column" [style.align-items]="'start'">
          <span>{{equipment.lastInspection?.reference}}</span>
          <span>{{equipment.lastInspection?.reportDate | date}}</span>
        </span>
        <span class="block equipment-block" fxLayout="column" [style.align-items]="'start'">
          <span>{{equipment.lastInspection?.reference}}</span>
          <span>{{equipment.lastInspection?.deadline | date}}</span>
        </span>
      </mat-expansion-panel-header>
      <ng-container
        *ngTemplateOutlet="interventionsTmpl; context: { interventions: equipment.interventions | async | orderBy: 'unitPriceSchedule.priority', full: false }">
      </ng-container>
    </mat-expansion-panel>
  </div>
</ng-template>

<ng-template #inspectionsTmpl let-inspections="inspections">
  <div *ngIf="inspections; else loadingTmpl" class="inspections" fxLayout="column">
    <div class="header" fxLayout="row" fxLayoutAlign="start center" [formGroup]="inspectionFormGroup">
      <mat-form-field appearance="outline" class="block inspection-block" [style.width.px]="100">
        <mat-label>ÉTAT</mat-label>
        <mat-select formControlName="states" multiple>
          <mat-option *ngFor="let state of InspectionStateOptions | keys" [value]="state">
            {{state | translate:{ gender: 'female' } }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="block inspection-block">
        <mat-label>RÉFÉRENCE</mat-label>
        <input matInput formControlName="reference">
      </mat-form-field>
      <mat-form-field appearance="outline" class="block inspection-block" [style.width.px]="200">
        <mat-label>TYPE</mat-label>
        <mat-select formControlName="kinds" multiple>
          <mat-option *ngFor="let elmt of equipmentKindFireSafetyCategories" [value]="elmt.value">
            {{elmt.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="block inspection-block">
        <mat-label>STATUS</mat-label>
        <mat-select formControlName="statuses" multiple>
          <mat-option *ngFor="let elmt of inspectionStatuses" [value]="elmt.value" [style.color]="elmt.color">
            {{elmt.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <span class="block inspection-block" [style.width.px]="100">ANOMALIES</span>
      <span class="block inspection-block" [style.width.px]="80">DANGERS</span>
      <span class="block inspection-block">TAUX CONTRÔLE</span>
      <span class="block inspection-block">INDICE QUALITÉ</span>
      <span class="block inspection-block">PRESTATAIRE</span>
      <app-date-filter appearance="outline" class="block inspection-block" formControlName="plannedDate"
        label="PLANIFIÉ" [style.width.px]="210">
      </app-date-filter>
      <app-date-filter appearance="outline" class="block inspection-block" formControlName="startDate" label="DÉBUT"
        [style.width.px]="210">
      </app-date-filter>
      <app-date-filter appearance="outline" class="block inspection-block" formControlName="endDate" label="FIN"
        [style.width.px]="210">
      </app-date-filter>
      <app-date-filter appearance="outline" class="block inspection-block" formControlName="deadline"
        label="DATE LIMITE" [style.width.px]="210">
      </app-date-filter>
    </div>
    <mat-expansion-panel *ngFor="let inspection of inspections" class="inspection" [hideToggle]="true"
      [disabled]="!inspection.reports?.length">
      <mat-expansion-panel-header fxLayout="row" fxLayoutAlign="start center">
        <span class="block inspection-block" [style.width.px]="100"
          [style.color]="InspectionStateOptions[inspection.info?.state].color" fxLayoutAlign="start center"
          fxLayoutGap="4px">
          <mat-icon>{{InspectionStateOptions[inspection.info?.state].icon}}</mat-icon>
          <span>{{inspection.info?.state | translate}}</span>
        </span>
        <span class="block inspection-block" color="primary"
          matTooltip="Référence de la vérification réglementaire">{{inspection.reference}}</span>
        <span class="block inspection-block"
          [style]="{ 'width': '200px', 'overflow': 'hidden', 'text-overflow': 'ellipsis', 'white-space': 'nowrap', 'display': 'block' }"
          [matTooltip]="(inspection.equipmentCategories | translate | values).join(', ')">
          {{(inspection.equipmentCategories | translate | values).join(', ')}}
        </span>
        <span class="block inspection-block" [style.color]="InspectionStatusColor[inspection.status]">
          {{inspection.status | translate: { gender: 'female' } }}
        </span>
        <span class="block inspection-block" [style.width.px]="100"
          color="warning">{{inspection.info?.anomalyCount}}</span>
        <span class="block inspection-block" [style.width.px]="80"
          color="danger">{{inspection.info?.dangerCount}}</span>
        <span class="block inspection-block"
          [style.color]="inspection.info?.controlRate | controlRateColor">{{inspection.info?.controlRate}}<sup>%</sup></span>
        <span class="block inspection-block"
          [style.color]="inspection.info?.qualityIndex | qualityColor">{{inspection.info?.qualityIndex}}<sup>/5</sup></span>
        <span class="block inspection-block">{{inspection.contractor?.name}}</span>
        <span class="block inspection-block" [style.width.px]="210">{{inspection.info?.plannedDate | date}}</span>
        <span class="block inspection-block" [style.width.px]="210">{{inspection.info?.startDate | date}}</span>
        <span class="block inspection-block" [style.width.px]="210">{{inspection.info?.endDate | date}}</span>
        <span class="block inspection-block" [style.width.px]="210">{{inspection.config?.deadline | date}}</span>
      </mat-expansion-panel-header>
      <ng-container *ngTemplateOutlet="reportsTmpl; context: { inspection: inspection, reports: inspection.reports }">
      </ng-container>
    </mat-expansion-panel>
  </div>
</ng-template>

<ng-template #reportsTmpl let-inspection="inspection" let-reports="reports">
  <div *ngIf="reports?.length" class="reports">
    <div class="header" fxLayout="row" fxLayoutAlign="start center">
      <span class="block report-block">Équipement</span>
      <span class="block report-block">DATE RAPPORT</span>
      <span class="block report-block">TECHNICIEN</span>
      <span class="block report-block">ÉTAT</span>
      <span class="block report-block">ANOMALIES</span>
      <span class="block report-block">DANGERS</span>
      <span class="block report-block">FICHIERS</span>
      <span class="block report-block">COMMENTAIRE</span>
    </div>
    <mat-expansion-panel *ngFor="let report of reports" class="report" fxLayout="column" [hideToggle]="true"
      [disabled]="!report.interventions?.length">
      <mat-expansion-panel-header class="report-content" fxLayout="row" fxLayoutAlign="start center">
        <span class="block report-block" matTooltip="Équipement concerné" fxLayout="column"
          [style.align-items]="'start'">
          <span [style.font-size.em]="0.8">{{inspection.equipments | find:{id: report.equipmentId} |
            get:'kind.name'}}</span>
          <span>{{inspection.equipments | find:{id: report.equipmentId} | get:'reference' }}</span>
        </span>
        <span class="block report-block" color="primary" matTooltip="Date du rapport de l'équipement">{{report.startDate
          |
          date:'shortDate'}}</span>
        <span class="block report-block" color="primary" matTooltip="Technicien">{{report.technicianName}}</span>
        <span class="block report-block" matTooltip="État de l'équipement"
          [style.color]="report.status === 'ok' ? 'var(--ion-color-success)' : 'var(--ion-color-danger)'">{{report.status
          | translate }}</span>
        <span class="block report-block" color="warning"
          matTooltip="Nombre d'anomalies constatées dans l'équipement">{{report.interventions.length -
          (report.interventions |
          filter:{'unitPriceSchedule.priority': 1} | get: 'length') }}</span>
        <span class="block report-block" color="danger"
          matTooltip="Nombre de dangers constatés dans l'équipement">{{report.interventions |
          filter:{'unitPriceSchedule.priority': 1} |
          get:'length'
          }}</span>
        <span class="block report-block" fxLayout="row wrap" matTooltip="Fichiers du rapport">
          <a *ngFor="let file of report.files" href="{{repo}}/{{file.path}}" target="_blank">{{file.filename}}</a>
        </span>
        <span class="block report-block" color="primary"
          matTooltip="Commentaire du technicien">{{report.comment}}</span>
      </mat-expansion-panel-header>
      <ng-container
        *ngTemplateOutlet="interventionsTmpl; context: { interventions: report.interventions | orderBy: 'unitPriceSchedule.priority', full: false }">
      </ng-container>
    </mat-expansion-panel>
  </div>
</ng-template>

<ng-template #interventionsTmpl let-interventions="interventions" let-full="full">
  <div *ngIf="interventions" class="interventions" fxLayout="column">
    <div class="header" [class.full]="full" fxLayout="row" fxLayoutAlign="start center">
      <span *ngIf="full" class="block intervention-block" [style.max-width.px]="300">ÉQUIPEMENT</span>
      <span class="block intervention-block" [style.max-width.px]="200">PRIORITÉ</span>
      <span *ngIf="full" class="block intervention-block" [style.max-width.px]="120">CRÉÉE LE</span>
      <span class="block intervention-block" [style.max-width.px]="200">ÉTAT</span>
      <span class="block intervention-block">INTERVENTION</span>
    </div>
    <div class="intervention" [class.full]="full" *ngFor="let intervention of interventions" fxLayout="row"
      fxLayoutAlign="start center">
      <span *ngIf="full" class="block intervention-block" matTooltip="Équipement concerné" fxLayout="column"
        [style.align-items]="'start'" [style.max-width.px]="300">
        <span [style.font-size.em]="0.8">{{intervention.equipment | get:'kind.name'}}</span>
        <span>{{intervention.equipment | get:'reference' }}</span>
      </span>
      <span color="primary" class="block intervention-block" [style.max-width.px]="200"
        matTooltip="Priorité de l'intervention">
        <ng-container *ngIf="intervention.unitPriceSchedule">
          P{{intervention | get:'unitPriceSchedule.priority'}}&nbsp;
          <span *ngIf="(intervention | get:'unitPriceSchedule.priority') === 1" color="danger">Danger</span>
          <span *ngIf="(intervention | get:'unitPriceSchedule.priority') > 1" color="warning">Anomalie</span>
        </ng-container>
        <ng-container *ngIf="!intervention.unitPriceSchedule">-</ng-container>
      </span>
      <span *ngIf="full" color="primary" class="block intervention-block" [style.max-width.px]="120"
        matTooltip="Date de création">
        {{(intervention | get:'createdAt' | date)}}
      </span>
      <span class="block intervention-block" [style.max-width.px]="200"
        [style.color]="InterventionStatusColor[intervention.status]"
        [matTooltip]="InterventionStatusTooltip[intervention.status]">
        {{intervention.status | translate: { gender: 'female' } }} ({{intervention.updatedAt | date:'shortDate'}})
      </span>
      <span class="block intervention-block" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px"
        color="primary" matTooltip="Quantité, référence et label de l'intervention">
        <ng-container *ngIf="intervention.unitPriceSchedule">
          <span fxLayout="row" fxLayoutGap="4px" [style.white-space]="'nowrap'">
            <span light>{{intervention.quantity}} x</span>
            <span>{{intervention.unitPriceSchedule?.reference}} :</span>
          </span>
          <span light>{{intervention.unitPriceSchedule?.name}}</span>
        </ng-container>
        <ng-container *ngIf="!intervention.unitPriceSchedule">-</ng-container>
      </span>
    </div>
  </div>
</ng-template>

<ng-template #quotationsTmpl let-quotations="quotations">
  <div *ngIf="quotations" class="quotations" fxLayout="column">
    <div class="header" fxLayout="row" fxLayoutAlign="start center">
      <span class="block quotation-block">RÉFÉRENCE</span>
      <span class="block quotation-block">STATUS</span>
      <span class="block quotation-block">PRESTATAIRE</span>
      <span class="block quotation-block">MONTANT</span>
      <span class="block quotation-block">SIGNATAIRE</span>
      <span class="block quotation-block" [style.max-width.%]="100">COMMENTAIRE</span>
    </div>
    <mat-expansion-panel class="quotation" *ngFor="let quotation of quotations" fxLayout="column" [hideToggle]="true"
      [disabled]="!quotation.interventions?.length">
      <mat-expansion-panel-header fxLayout="row" fxLayoutAlign="start center">
        <span color="primary" class="block quotation-block">
          {{quotation.reference}}
        </span>
        <span color="primary" class="block quotation-block">
          {{quotation.status | translate: { gender: 'female' } }}&nbsp;
          ({{quotation.updatedAt | date}})
        </span>
        <span color="primary" class="block quotation-block">
          {{quotation.contractor?.name}}
        </span>
        <span color="primary" class="block quotation-block">
          {{quotation.amount | currency:'EUR'}} HT
        </span>
        <span color="primary" class="block quotation-block">
          {{quotation.signatory}}
        </span>
        <span color="primary" class="block quotation-block" [style.max-width.%]="100">
          {{quotation.comment}}
        </span>
      </mat-expansion-panel-header>
      <ng-container
        *ngTemplateOutlet="interventionsTmpl; context: { interventions: quotation.interventions | orderBy: 'unitPriceSchedule.priority', full: true }">
      </ng-container>
    </mat-expansion-panel>
  </div>
</ng-template>

<ng-template #documentTmpl let-documents="documents">
  <table *ngIf="documents?.length" mat-table [dataSource]="documents | orderBy: '-date'">
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef [style.width.px]="120"> Date </th>
      <td mat-cell *matCellDef="let element"><span translate> {{element.date.format('L')}} </span></td>
    </ng-container>
    <ng-container matColumnDef="kind">
      <th mat-header-cell *matHeaderCellDef [style.width.px]="200"> Type de document </th>
      <td mat-cell *matCellDef="let element"><span translate> {{element.file.kind}} </span></td>
    </ng-container>
    <ng-container matColumnDef="filename">
      <th mat-header-cell *matHeaderCellDef [style.width.px]="300"> Fichier </th>
      <td mat-cell *matCellDef="let element" [style.font-weight]="400">
        <a color="secondary" [href]="repo + '/' + element.file.path" target="_blank">{{element.file.filename}}</a>
      </td>
    </ng-container>
    <ng-container matColumnDef="contractor">
      <th mat-header-cell *matHeaderCellDef [style.width.px]="180"> Prestataire </th>
      <td mat-cell *matCellDef="let element"> {{element.contractor?.name}} </td>
    </ng-container>
    <ng-container matColumnDef="depositary">
      <th mat-header-cell *matHeaderCellDef [style.width.px]="180"> Dépositaire </th>
      <td mat-cell *matCellDef="let element"> {{element.depositary}} </td>
    </ng-container>
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef [style.width.px]="180"> Titre du document </th>
      <td mat-cell *matCellDef="let element"> {{element.file.title}} </td>
    </ng-container>
    <ng-container matColumnDef="vrs">
      <th mat-header-cell *matHeaderCellDef> VR liées </th>
      <td mat-cell *matCellDef="let element"> {{element.inspections | pluck:'reference'}} </td>
    </ng-container>
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef> Description / Commentaire </th>
      <td mat-cell *matCellDef="let element"> {{element.file.description}} </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="DocumentColumn"></tr>
    <tr mat-row *matRowDef="let row; columns: DocumentColumn;"></tr>
  </table>
</ng-template>


<ng-template #loadingTmpl>
  <sesio-loader @fadeOut></sesio-loader>
</ng-template>