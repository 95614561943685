<ion-header>
  <ion-toolbar color="primary">
    <ion-title size="small">
      <ion-label><ion-text translate>technical-control</ion-text> {{data?.date?.format('L')}}</ion-label>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list *ngIf="data" @fadeIn lines="full">
    <ion-item>
      <div fxLayoutAlign="space-between start" fxLayoutGap="16px" [style.width.%]="100">
        <ion-text [style.width.%]="100" translate>visitDate</ion-text>
        <ion-text color="primary" [style.width.px]="70" [style.min-width.px]="70">{{formatDate(data.date)}}</ion-text>
      </div>
    </ion-item>
    <ion-item *ngFor="let field of fields">
      <div fxLayoutAlign="space-between start" fxLayoutGap="16px" [style.width.%]="100">
        <ion-text [style.width.%]="100" translate>{{field}}</ion-text>
        <ion-text class="value" color="primary">{{value(field) | async}}</ion-text>
      </div>
    </ion-item>
  </ion-list>
  <sesio-loader *ngIf="!data" @fadeOut></sesio-loader>
</ion-content>
