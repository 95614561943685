/** @format */

import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LibConfig, LibConfigService } from '../config.service';
import { Observable } from 'rxjs';
import { Quotation, QuotationStatus } from '../classes/quotation';
import { map } from 'rxjs/operators';
import { ModelMapper } from 'model-mapper';
import { get, merge, omit, pick, map as lmap } from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class QuotationService {
  constructor(@Inject(LibConfigService) protected config: LibConfig, protected http: HttpClient) {}

  public get(id: string): Observable<Quotation> {
    return this.http
      .get<any[]>(`${this.config.environment.apiUrl}/quotations/${id}`)
      .pipe(map(data => new ModelMapper(Quotation).map(data)));
  }

  public delete(id: string): Observable<boolean> {
    return this.http.delete<boolean>(`${this.config.environment.apiUrl}/quotations/${id}`);
  }

  public create(data: any): Observable<Quotation> {
    return this.http
      .post<any>(`${this.config.environment.apiUrl}/quotations`, this.serializeForDto(data))
      .pipe(map(res => new ModelMapper(Quotation).map(res)));
  }

  public update(id: string, data: any): Observable<boolean> {
    return this.http.patch<boolean>(`${this.config.environment.apiUrl}/quotations/${id}`, this.serializeForDto(data));
  }

  public setStatus(id: string, status: QuotationStatus, canceledReason?: string): Observable<boolean> {
    return this.http.patch<boolean>(`${this.config.environment.apiUrl}/quotations/${id}/set-status`, {
      status,
      canceledReason,
    });
  }

  private serializeForDto(data: any): any {
    return merge(
      pick(
        data,
        'externalReference',
        'purchaseOrderNumber',
        'status',
        'contractor.id',
        'organizationalUnit.id',
        'realEstateStructure.id',
        'file',
        'files',
        'signatory',
        'comment',
        'canceledReason'
      ),
      {
        inspections: lmap(get(data, 'inspections'), d => pick(d, 'id')),
        interventions: lmap(get(data, 'interventions'), d => pick(d, 'id')),
      }
    );
  }
}
