/** @format */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AggregationValue,
  EquipmentKind,
  EquipmentStatusHistoryField,
  EquipmentStatusHistoryService as SesioEquipmentStatusHistoryService,
} from 'sesio-lib';
import { buildHeaderSearchFilter, IHeaderSearch } from '../_classes/header-search';
import { Moment } from 'moment';
import { isNil } from 'lodash-es';

// TODO add filter on qualification
export interface IFilter extends IHeaderSearch {
  equipmentId?: string;
  families?: string[];
  contractorIds?: string[];
  start?: Moment;
  end?: Moment;
  field?: EquipmentStatusHistoryField;
  statuses: string[];
  unit?: string;
  binSize?: number;
  equipmentKind?: EquipmentKind;
  signatureSettingName?: string;
  isTertiary?: boolean;
  supervised?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class EquipmentStatusHistoryService extends SesioEquipmentStatusHistoryService {
  public getByUnitData(search: IFilter): Observable<{ [key: string]: AggregationValue[] }> {
    return this.http.post<any>(
      `${this.config.environment.apiUrl}/equipment-status-history/get-by-unit-data`,
      this.buildSearchFilter(search)
    );
  }

  public getChartRangeData(search: IFilter): Observable<{ [key: string]: AggregationValue[] }> {
    return this.http.post<any>(
      `${this.config.environment.apiUrl}/equipment-status-history/get-chart-range-data`,
      this.buildSearchFilter(search)
    );
  }

  public count(search: IFilter): Observable<{ count: number }> {
    return this.http.post<any>(
      `${this.config.environment.apiUrl}/equipment-status-history/count`,
      this.buildSearchFilter(search)
    );
  }

  private buildSearchFilter(search: IFilter): any {
    const filter = buildHeaderSearchFilter(search);
    if (search?.equipmentId) filter.equipmentId = search.equipmentId;
    if (search?.families) filter.families = search.families;
    if (search?.contractorIds) filter.contractorIds = search.contractorIds;
    if (search?.start) filter.start = search.start.toISOString();
    if (search?.end) filter.end = search.end.toISOString();
    if (search?.field) filter.field = search.field;
    if (search?.statuses?.length) filter.statuses = search.statuses;
    if (search?.unit) filter.unit = search.unit;
    if (search?.binSize) filter.binSize = search.binSize;
    if (search?.equipmentKind) filter.equipmentKindId = search.equipmentKind.id;
    if (search?.signatureSettingName) filter.signatureSettingName = search.signatureSettingName;
    if (search?.isTertiary === true) filter.isTertiary = search.isTertiary;
    if (!isNil(search?.supervised)) filter.supervised = search.supervised;
    return filter;
  }
}
