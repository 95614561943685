import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { v4 as uuidv4 } from 'uuid';
import { IPieChartData, ISpidewebChartData } from '../chart.interface';
import * as Highcharts from 'highcharts';
import Boost from 'highcharts/modules/boost';
Boost(Highcharts);
import noData from 'highcharts/modules/no-data-to-display';
noData(Highcharts);
import More from 'highcharts/highcharts-more';
More(Highcharts);
import Exporting from 'highcharts/modules/exporting';
Exporting(Highcharts);
import ExportData from 'highcharts/modules/export-data';
ExportData(Highcharts);

import Drilldown from 'highcharts/modules/drilldown';
import { highchartsOptions } from '../../../constants/highcharts';
import { cloneDeep, filter, isNil, map, reduce } from 'lodash-es';
import { promise } from 'selenium-webdriver';
Drilldown(Highcharts);


@Component({
  selector: 'sesio-spiderweb-chart',
  templateUrl: './spiderweb-chart.component.html',
  styleUrls: ['./spiderweb-chart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SpiderwebChartComponent implements OnInit {

  public id = `spiderweb-chart-${uuidv4()}`;

  private loading = true;

  @Input()
  private enableExporting = false;

  @Input()
  public name = '';

  @Input()
  public color = '#4572A7';

  @Input()
  private format = '.0f';

  @Input('config')
  public set setConfig(config: any) {
    if (config && config.format) { this.format = config.format; }
  }

  @Input('data')
  public set setData(data: ISpidewebChartData[]) {
    this.updateData(data);
  }
  private data: ISpidewebChartData[] = [];

  private chart: Highcharts.Chart;

  constructor(private translate: TranslateService) { }

  async ngOnInit(): Promise<void> {
    Highcharts.setOptions(highchartsOptions[this.translate.getDefaultLang()]);
  }

  public async loadChart($event: any): Promise<void> {
    if (!$event.visible || !this.loading) { return; }
    this.loading = false;
    const series: any = [
      { name: this.name, color: this.color, yAxis: 0, data: this.data }
    ];
    this.chart = Highcharts.chart(this.id, {
      chart: {
        polar: true,
        type: 'line'
      },
      credits: false,
      exporting: {
        enabled: this.enableExporting
      },
      title: { text: '' },
      series,
      xAxis: {
        tickmarkPlacement: 'on',
        lineWidth: 0,
        categories: map(this.data, d => d.name)
      },
      yAxis: {
        gridLineInterpolation: 'polygon',
        lineWidth: 0,
        min: 0
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        shared: true,
        pointFormat: `<b>{point.y:${this.format}}</b>`
      },
    } as any);
  }

  private async updateData(data: ISpidewebChartData[]): Promise<void> {
    const promises = [];
    this.data = map(cloneDeep(filter(data, d => !!d.y)) || [], d => {
      d = cloneDeep(d);
      if (d.translate) {
        promises.push(this.translate.get(d.name).toPromise().then(name => d.name = name));
      }
      return d;
    });
    await Promise.all(promises);
    this.chart?.xAxis[0].setCategories(map(this.data, 'name'));
    if (this.chart) {
      this.chart.series[0].setData(this.data);
      this.chart.redraw();
    }
  }

}
