/** @format */

import {map, values} from 'lodash-es';
import {propertyMap} from 'model-mapper';
import {EmbededEquipment} from './equipment';
import {Metric} from './metrics';
import {EmbededRealEstateStructure} from './real-estate-structure';
import {EmbededSensor} from './sensor';

export type RealEstateStructureAlertLevel = 'anomaly' | 'failure';

export enum RealEstateStructureAlertKind {
  PRIMARY_TOO_LOW = 'primary_too_low', // Alerte basse température Primaire
  PRIMARY_TOO_HIGH = 'primary_too_high', // Alerte haute température Primaire
  TEMPERATURE_WATER_DEPARTURE_TOO_LOW = 'temperature_water_departure_too_low', // Alerte basse température Départ ECS
  TEMPERATURE_WATER_DEPARTURE_TOO_HIGH = 'temperature_water_departure_too_high', // Alerte haute température Départ ECS
  TEMPERATURE_WATER_ARRIVAL_TOO_LOW = 'temperature_water_arrival_too_low', // Alerte basse température Retour ECS
  TEMPERATURE_HEATING_DEPARTURE_TOO_LOW = 'temperature_heating_departure_too_low', // Alerte basse température Chauffage
  TEMPERATURE_HEATING_DEPARTURE_TOO_HIGH = 'temperature_heating_departure_too_high', // Alerte haute température Chauffage
  PRESSURE_TOO_HIGH = 'pressure_too_high', // Alerte Pression du réseau de chauffage
}

export const RealEstateStructureAlertKindName = {
  [RealEstateStructureAlertKind.PRIMARY_TOO_LOW]: 'Alerte basse température Primaire',
  [RealEstateStructureAlertKind.PRIMARY_TOO_HIGH]: 'Alerte haute température Primaire',
  [RealEstateStructureAlertKind.TEMPERATURE_WATER_DEPARTURE_TOO_LOW]: 'Alerte basse température Départ ECS',
  [RealEstateStructureAlertKind.TEMPERATURE_WATER_DEPARTURE_TOO_HIGH]: 'Alerte haute température Départ ECS',
  [RealEstateStructureAlertKind.TEMPERATURE_WATER_ARRIVAL_TOO_LOW]: 'Alerte basse température Retour ECS',
  [RealEstateStructureAlertKind.TEMPERATURE_HEATING_DEPARTURE_TOO_LOW]: 'Alerte basse température Chauffage',
  [RealEstateStructureAlertKind.TEMPERATURE_HEATING_DEPARTURE_TOO_HIGH]: 'Alerte haute température Chauffage',
  [RealEstateStructureAlertKind.PRESSURE_TOO_HIGH]: 'Alerte Pression du réseau de chauffage',
};

export const RealEstateStructureAlertKindOptions = map(values(RealEstateStructureAlertKind), value => ({
  value,
  name: RealEstateStructureAlertKindName[value],
}));

export const RealEstateStructureAlertThresholds: {
  key: RealEstateStructureAlertKind;
  name: string;
  description: string;
  kind: 'degree' | 'percent';
}[] = [
  {
    key: RealEstateStructureAlertKind.PRIMARY_TOO_LOW,
    name: "Seuil d'alerte basse température Primaire",
    description: `se déclenche si température Primaire <= <b>SEUIL</b> °C`,
    kind: 'degree',
  },
  {
    key: RealEstateStructureAlertKind.PRIMARY_TOO_HIGH,
    name: "Seuil d'alerte haute température Primaire",
    description: `se déclenche si température Primaire >= <b>SEUIL</b> °C`,
    kind: 'degree',
  },
  {
    key: RealEstateStructureAlertKind.TEMPERATURE_HEATING_DEPARTURE_TOO_LOW,
    name: "Seuil d'alerte basse température Chauffage",
    description: `se déclenche si la température du chauffage est inférieure de <b>-SEUIL</b> °C à la consigne calculée`,
    kind: 'degree',
  },
  {
    key: RealEstateStructureAlertKind.TEMPERATURE_HEATING_DEPARTURE_TOO_HIGH,
    name: "Seuil d'alerte haute température Chauffage",
    description: `se déclenche si la température du chauffage est supérieure de <b>SEUIL</b> °C à la consigne calculée`,
    kind: 'degree',
  },
  {
    key: RealEstateStructureAlertKind.TEMPERATURE_WATER_DEPARTURE_TOO_LOW,
    name: "Seuil d'alerte basse température Départ ECS",
    description: `se déclenche si température Départ ECS <= <b>SEUIL</b> °C`,
    kind: 'degree',
  },
  {
    key: RealEstateStructureAlertKind.TEMPERATURE_WATER_DEPARTURE_TOO_HIGH,
    name: "Seuil d'alerte haute température Départ ECS",
    description: `se déclenche si température Départ ECS >= <b>SEUIL</b> °C`,
    kind: 'degree',
  },
  {
    key: RealEstateStructureAlertKind.TEMPERATURE_WATER_ARRIVAL_TOO_LOW,
    name: "Seuil d'alerte basse température Retour ECS",
    description: `se déclenche si température Retour ECS < <b>SEUIL</b> °C`,
    kind: 'degree',
  },
  {
    key: RealEstateStructureAlertKind.PRESSURE_TOO_HIGH,
    name: "Seuil d'alerte Pression du réseau de chauffage",
    description: `se déclenche si la pression est supérieure à <b>SEUIL</b> % de la consigne générale de pression `,
    kind: 'percent',
  },
];

export const RealEstateStructureAlertMetricLink = {
  [Metric.PRIMARY]: [RealEstateStructureAlertKind.PRIMARY_TOO_LOW, RealEstateStructureAlertKind.PRIMARY_TOO_HIGH],
  [Metric.TEMPERATURE_WATER_DEPARTURE]: [
    RealEstateStructureAlertKind.TEMPERATURE_WATER_DEPARTURE_TOO_LOW,
    RealEstateStructureAlertKind.TEMPERATURE_WATER_DEPARTURE_TOO_HIGH,
  ],
  [Metric.TEMPERATURE_WATER_ARRIVAL]: [RealEstateStructureAlertKind.TEMPERATURE_WATER_ARRIVAL_TOO_LOW],
  [Metric.TEMPERATURE_HEATING_DEPARTURE]: [
    RealEstateStructureAlertKind.TEMPERATURE_HEATING_DEPARTURE_TOO_LOW,
    RealEstateStructureAlertKind.TEMPERATURE_HEATING_DEPARTURE_TOO_HIGH,
  ],
  [Metric.PRESSURE]: [RealEstateStructureAlertKind.PRESSURE_TOO_HIGH],
};

export class RealEstateStructureAlertTrigger {
  @propertyMap()
  public first?: number;

  @propertyMap()
  public last?: number;

  @propertyMap()
  public min?: number;

  @propertyMap()
  public max?: number;

  @propertyMap()
  public setpoint?: number;

  @propertyMap()
  public threshold?: number;
}

export class RealEstateStructureAlert {
  @propertyMap({source: '_id'})
  public id: string;

  @propertyMap({type: EmbededRealEstateStructure})
  public realEstateStructure: EmbededRealEstateStructure;

  @propertyMap()
  public level: RealEstateStructureAlertLevel;

  @propertyMap({type: Date})
  public start: Date;

  @propertyMap({type: Date})
  public end: Date;

  @propertyMap({type: EmbededEquipment})
  public equipment: EmbededEquipment;

  @propertyMap({type: EmbededSensor})
  public sensor: EmbededSensor;

  @propertyMap()
  public kind: RealEstateStructureAlertKind;

  @propertyMap()
  public trigger: RealEstateStructureAlertTrigger;
}
