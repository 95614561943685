/** @format */

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {v4 as uuidv4} from 'uuid';

import {Moment} from 'moment';
import 'moment-timezone';

import * as Highcharts from 'highcharts';
import * as Gantt from 'highcharts/highcharts-gantt';
import * as Highstock from 'highcharts/highstock';

import More from 'highcharts/highcharts-more';
import Boost from 'highcharts/modules/boost';
import Bullet from 'highcharts/modules/bullet';
import Drilldown from 'highcharts/modules/drilldown';
import ExportData from 'highcharts/modules/export-data';
import Exporting from 'highcharts/modules/exporting';
import noData from 'highcharts/modules/no-data-to-display';
import ParallelCoordinates from 'highcharts/modules/parallel-coordinates';
import SolidGauge from 'highcharts/modules/solid-gauge';
import Xrange from 'highcharts/modules/xrange';
Boost(Highcharts);
Boost(Highstock);
Boost(Gantt);
noData(Highcharts);
noData(Highstock);
noData(Gantt);
More(Highcharts);
More(Highstock);
More(Gantt);
Exporting(Highcharts);
Exporting(Highstock);
Exporting(Gantt);
ExportData(Highcharts);
ExportData(Highstock);
ExportData(Gantt);
SolidGauge(Highcharts);
Xrange(Highcharts);
ParallelCoordinates(Highcharts);
ParallelCoordinates(Highstock);
ParallelCoordinates(Gantt);
Drilldown(Highcharts);
Drilldown(Highstock);
Drilldown(Gantt);
Bullet(Highcharts);
Bullet(Highstock);
Bullet(Gantt);

import {each} from 'lodash-es';
import {take} from 'rxjs/operators';
import {highchartsOptions} from '../../../constants/highcharts';

export type ChartKind = 'highcharts' | 'highstock' | 'gantt';
@Component({
  selector: 'sesio-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ChartComponent implements OnInit, AfterViewInit {
  public id = `chart-${uuidv4()}`;

  @Output()
  public ready: EventEmitter<ChartComponent> = new EventEmitter();

  @Output()
  public extremesChanged: EventEmitter<{chart: ChartComponent; extremes: {start: Moment; end: Moment}}> =
    new EventEmitter();

  @Input('kind')
  public set setKind(kind: ChartKind) {
    this.kind = kind;
  }
  private kind: ChartKind = 'highcharts';

  @Input()
  private options: Highcharts.Options | Highstock.Options;

  @Input('loading')
  public loadingData = true;

  @Input('waitInView')
  public waitInView = true;

  public chart: Highcharts.Chart | Highstock.Chart;

  constructor(private translate: TranslateService, private changeDetectorRef: ChangeDetectorRef) {}

  async ngOnInit(): Promise<void> {
    Highcharts.setOptions(highchartsOptions[this.translate.getDefaultLang()]);
    Highstock.setOptions(highchartsOptions[this.translate.getDefaultLang()]);
    Gantt.setOptions(highchartsOptions[this.translate.getDefaultLang()]);
  }

  ngAfterViewInit(): void {
    if (!this.waitInView) {
      setTimeout(() => {
        this.loadChart({visible: true});
        this.changeDetectorRef.detectChanges();
      }, 300);
    }
  }

  async loadChart($event: any): Promise<void> {
    if (!$event.visible || this.chart) return;
    switch (this.kind) {
      case 'highstock':
        this.chart = Highstock.stockChart(this.id, this.options);
        break;
      case 'gantt':
        this.chart = Gantt.ganttChart(this.id, this.options);
        break;
      default:
        this.chart = Highcharts.chart(this.id, this.options);
        break;
    }
    this.ready.emit(this);
  }

  public setLoading(loading: boolean) {
    if (!this.chart) {
      this.ready.pipe(take(1)).subscribe(() => this.setLoading(loading));
      return;
    }
    this.loadingData = loading;
  }

  public setTitle(title: string) {
    if (!this.chart) {
      this.ready.pipe(take(1)).subscribe(() => this.setTitle(title));
      return;
    }
    this.chart.setTitle({text: title});
  }

  public setSeries(series: Highcharts.SeriesOptionsType[] | Highstock.SeriesOptionsType[]): void {
    if (!this.chart) {
      this.ready.pipe(take(1)).subscribe(() => this.setSeries(series));
      return;
    }
    while (this.chart?.series.length) {
      this.chart.series[0].remove();
    }
    series.forEach(s => this.chart?.addSeries(s));
    this.chart?.redraw();
  }

  public setSerieData(data: any, serieIndex = 0, options?: {redraw?: boolean}): void {
    if (!this.chart) {
      this.ready.pipe(take(1)).subscribe(() => this.setSerieData(data, serieIndex));
      return;
    }
    this.chart.series[serieIndex].setData(data, options?.redraw);
  }

  public setExtremes(start: Moment, end: Moment, redraw?: boolean): void {
    if (!this.chart) {
      this.ready.pipe(take(1)).subscribe(() => this.setExtremes(start, end, redraw));
      return;
    }
    each(this.chart.xAxis, axis => axis.setExtremes(start?.valueOf(), end?.valueOf(), redraw));
    this.extremesChanged.emit({chart: this, extremes: {start, end}});
  }

  public setXCategories(categories: string[], redraw?: boolean): void {
    if (!this.chart) {
      this.ready.pipe(take(1)).subscribe(() => this.setXCategories(categories, redraw));
      return;
    }
    each(this.chart.xAxis, axis => axis.setCategories(categories, redraw));
  }

  public setYCategories(categories: string[], redraw?: boolean): void {
    if (!this.chart) {
      this.ready.pipe(take(1)).subscribe(() => this.setYCategories(categories, redraw));
      return;
    }
    each(this.chart.yAxis, axis => axis.setCategories(categories, redraw));
  }
}
