/** @format */

import {Injectable} from '@angular/core';
import {merge} from 'lodash-es';
import {BehaviorSubject} from 'rxjs';
import {StorageService} from './storage.service';

export interface IHeaderSelectionOU {
  id: string;
  name: string;
  pathNames: string[];
}

export interface IHeaderSelection {
  cluster?: IHeaderSelectionOU;
  division?: IHeaderSelectionOU;
  agency?: IHeaderSelectionOU;
  group?: IHeaderSelectionOU;
  perimeter?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  static HEADER_SELECTION = 'HEADER_SELECTION';
  static MENU_STATE = 'MENU_STATE';

  public $headerSelection = new BehaviorSubject<IHeaderSelection>(null);
  public $statistics = new BehaviorSubject<{page: string; data?: any}>(null);

  constructor(private storageService: StorageService) {
    this.init();
  }

  public async setHeaderSelection(data: IHeaderSelection): Promise<void> {
    await this.storageService.set(SessionService.HEADER_SELECTION, JSON.stringify(data));
    this.$headerSelection.next(data);
  }

  public async clearHeaderSelectionLogout(): Promise<void> {
    const data: any = merge(this.$headerSelection.value, {search: false});
    await this.storageService.set(SessionService.HEADER_SELECTION, JSON.stringify(data));
    this.$headerSelection.next(data);
  }

  public async getHeaderSelection(): Promise<IHeaderSelection> {
    const data = await this.storageService.get(SessionService.HEADER_SELECTION);
    return data ? JSON.parse(data) : null;
  }

  public async setMenuState(open: boolean): Promise<void> {
    return this.storageService.set(SessionService.MENU_STATE, JSON.stringify(open));
  }

  public async getMenuState(): Promise<boolean> {
    const data = await this.storageService.get(SessionService.MENU_STATE);
    return data ? JSON.parse(data) : null;
  }

  private async init(): Promise<void> {
    this.$headerSelection.next(await this.getHeaderSelection());
  }
}
