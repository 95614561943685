<div class="container">
  <div *ngIf="!light" id="header" fxLayoutAlign="start center" fxLayoutGap="8px">
    <button mat-icon-button class="icon-only" (click)="dismiss()">
      <mat-icon color="primary">cancel</mat-icon>
    </button>
    <span color="primary">Fermer les sous opérations</span>
  </div>
  <app-datagrid [options]="options">
    <sesio-loader datagrid-spinner></sesio-loader>
  </app-datagrid>
</div>