/** @format */

import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Injector,
  Input,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormControl,
  NgControl,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { TranslateService } from '@ngx-translate/core';
import { find, isNil, trim, map as lmap, filter as lfilter, sortBy, get, concat, differenceBy } from 'lodash-es';
import { Observable, of } from 'rxjs';
import { startWith, debounceTime, mergeMap, filter, map, tap } from 'rxjs/operators';
import { EmbededEquipment, Equipment, EquipmentKind, fade, Family } from 'sesio-lib';
import { EquipmentService } from '../../../_services/equipment.service';

@Component({
  selector: 'app-equipment-select',
  templateUrl: './equipment-select.component.html',
  styleUrls: ['./equipment-select.component.scss'],
  animations: [fade],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => EquipmentSelectComponent),
    },
  ],
})
export class EquipmentSelectComponent implements AfterViewInit, ControlValueAccessor {
  @Input('required')
  public set setRequired(isRequired: boolean) {
    this.isRequired = isRequired === false ? false : true;
    if (this.control) this.setValidators();
  }
  public isRequired = false;

  @Input('value')
  public set setValue(value: EmbededEquipment) {
    this.value = value;
    if (this.control) this.control.setValue(value);
  }
  public value: EmbededEquipment;

  @Input('disabled')
  public set setDisabled(isDisabled: boolean) {
    this.setDisabledState(isDisabled !== false);
  }
  public isDisabled: boolean;

  @Input('multiple')
  public set setMultiple(multiple: boolean) {
    this.multiple = multiple !== false;
  }
  multiple = false;

  @Input()
  private family: Family;

  // @Input()
  // private kinds: EquipmentKind[];

  @Input()
  organizationalUnitId: string;

  @Input()
  realEstateStructureId: string;

  @Input('label')
  public label: string;

  private ready = false;
  public loading = false;
  public filteredOptions: Observable<Equipment[]>;
  public control: UntypedFormControl;
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public valueCtrl = new UntypedFormControl();
  @ViewChild('valueInput') valueInput: ElementRef<HTMLInputElement>;

  public displayOption = (option: Equipment) => (option ? option.reference : '');

  public onChange = () => {};

  public onTouched = () => {};

  private validateOption = (control: AbstractControl): { [key: string]: any } | null => {
    let forbidden;
    if (this.multiple) {
      forbidden =
        control.value &&
        (!Array.isArray(control.value) ||
          get(
            lfilter(lmap(control.value, 'id'), v => !!v),
            'length',
            0
          ) !== get(control.value, 'length', 0));
    } else forbidden = control.value && isNil(control.value.id);
    return forbidden ? { invalidOption: { value: control.value } } : null;
  };

  constructor(
    private injector: Injector,
    private changeDetectorRef: ChangeDetectorRef,
    private translate: TranslateService,
    private equipmentService: EquipmentService
  ) {}

  public load(reference: string) {
    this.control.setValue(reference);
  }

  writeValue(value: any): void {
    if (this.control && this.control.value !== value) {
      this.control.setValue(value, { emitEvent: false });
    }
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    if (this.control && this.control.disabled !== this.isDisabled) {
      if (this.isDisabled) this.control.disable();
      else this.control.enable();
    }
  }

  ngAfterViewInit(): void {
    const ngControl: NgControl = this.injector.get(NgControl, null);
    if (ngControl) {
      this.control = ngControl.control as UntypedFormControl;
      this.value = this.control?.value;
    } else {
      this.control = new UntypedFormControl({
        value: this.value,
        disabled: this.isDisabled,
      });
    }
    this.setValidators();

    const obs = this.multiple ? this.valueCtrl.valueChanges : this.control.valueChanges;
    this.filteredOptions = obs.pipe(
      startWith(this.control.value?.reference || ''),
      filter(() => !this.isDisabled),
      debounceTime(300),
      filter(value => !value || typeof value === 'string'),
      tap(() => (this.loading = true)),
      mergeMap(reference => this.filterEquipment(reference)),
      tap(() => (this.loading = false))
    );
    this.ready = true;
    this.changeDetectorRef.detectChanges();
  }

  remove(index: number): void {
    const value = this.control.value;
    value.splice(index, 1);
    this.control.setValue(value);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (!!find(this.control.value, { id: event.option.value.id })) return;
    this.control.setValue(concat(this.control.value || [], [event.option.value]));
    this.valueInput.nativeElement.value = '';
    this.valueCtrl.setValue(null);
  }

  private setValidators() {
    const validators = [];
    if (this.control.hasValidator(Validators.required)) this.isRequired = true;
    if (this.isRequired) validators.push(Validators.required);
    validators.push(this.validateOption);
    this.control.setValidators(validators);
    this.control.updateValueAndValidity();
  }

  private filterEquipment(reference: string): Observable<Equipment[]> {
    if (!reference?.length) return of([]);
    return this.equipmentService
      .search({
        search: trim(reference),
        family: this.family,
        organizationalUnitId: this.organizationalUnitId,
        realEstateStructureId: this.realEstateStructureId,
      })
      .pipe(map(data => sortBy(differenceBy(data, this.control.value, 'id'), ['kindLabel', 'name'])));
  }
}
