<div class="container" fxLayout="column" [fxLayoutGap]="shrink ? '0px' : '12px'" [ngClass]="{shrink: shrink}">
  <div class="container-arrowtag" *ngFor="let tag of tags; let i = index" fxLayout="row" fxLayoutAlign="start center">
    <div fxLayout="row" fxLayoutAlign="start center" class="arrowtag" [style.width]="arrowtagWidth(i) + 'px'"
      [ngClass]="{selected: tag.selected(consumption)}">
      <span *ngIf="!shrink && tag.rt" class="rt" [style.color]="tag.color">{{tag.rt}}</span>
      <div fxLayout="row" fxLayout="start center" fxFill [style.marginLeft]="shrink || tag.rt ? '0' : '30px'">
        <div class="arrow-content" fxLayout="row" fxLayoutAlign="space-between center"
          [fxLayoutGap]="shrink ? '0px' : '8px'" [style.backgroundColor]="tag.color">
          <span>{{tag.label}}</span><span>{{tag.letter}}</span>
        </div>
        <div class="arrow-right" [style.borderLeftColor]="tag.color"></div>
      </div>
    </div>
    <div *ngIf="tag.selected(consumption)" class="consumption-tag" fxLayout="row" fxLayoutAlign="start center"
      [style.left]="consumptiontagLeft(i) + 'px'" [style.width]="consumptiontagWidth(i) + 'px'">
      <div class="tag" fxLayout="row" fxLayout="start center" border-color="primary">
        <div class="arrow-left" border-right-color="primary"></div>
        <div class="arrow-content" bgColor="primary" fxLayout="row" fxLayoutAlign="end center">
          {{consumption | round}}
          <span class="label" color="primary">kWh <sub>EP</sub> / m<sup>2</sup> .year</span>
        </div>
      </div>
    </div>
  </div>
</div>
