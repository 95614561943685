/** @format */

import {Pipe, PipeTransform} from '@angular/core';
import {Moment} from 'moment';

@Pipe({name: 'greaterThan'})
export class GreaterThanPipe implements PipeTransform {
  transform(data: Date | Moment | number, than: Date | Moment | number): boolean | null {
    if (!data) return null;
    const dataTimestamp = typeof data === 'number' ? data : data.valueOf();
    const thanTimestamp = typeof than === 'number' ? than : than.valueOf();
    return dataTimestamp > thanTimestamp;
  }
}
