import { animate, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, ChangeDetectorRef, Component, Input } from '@angular/core';
import { isNil } from 'lodash-es';
import { fade } from '../../../constants/animations';

@Component({
  selector: 'app-energy-tag',
  templateUrl: './energy-tag.component.html',
  styleUrls: ['./energy-tag.component.scss'],
  animations: [
    fade,
    trigger('line', [
      transition('* => *', [
        style({ opacity: 0 }), // initial
        animate('0.3s 0.3s', style({ opacity: 1 })) // final
      ])
    ])
  ]
})
export class EnergyTagComponent implements AfterViewInit {

  @Input()
  public display: 'full' | 'letter' = 'full';

  @Input('value')
  public set setValue(value: number) {
    if (isNil(value) || typeof value !== 'number') {
      this.value = null;
      this.position = -60;
    } else {
      this.value = Math.floor(value);
      this.changed(this.value);
    }
  }
  public value = 0;
  public letter: string;
  public color: string;
  public position = -32;
  public height = 250;
  public isAnimationDisabled = true;

  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngAfterViewInit(): void {
    this.isAnimationDisabled = false;
    this.changeDetectorRef.detectChanges();
  }

  public onResized($event: { newWidth: number, newHeight: number }): void {
    this.height = $event.newHeight;
  }

  private changed(value: number): void {
    if (value <= 50) {
      this.position = -32;
      this.letter = 'A';
      this.color = '#33a357';
    } else if (value <= 90) {
      this.position = -5;
      this.letter = 'B';
      this.color = '#79b752';
    } else if (value <= 150) {
      this.position = 22;
      this.letter = 'C';
      this.color = '#c3d545';
    } else if (value <= 230) {
      this.position = 49;
      this.letter = 'D';
      this.color = '#fff12c';
    } else if (value <= 330) {
      this.position = 75;
      this.letter = 'E';
      this.color = '#edb731';
    } else if (value <= 450) {
      this.position = 102;
      this.letter = 'F';
      this.color = '#d66f2c';
    } else {
      this.position = 129;
      this.letter = 'G';
      this.color = '#cc232a';
    }

  }



}
