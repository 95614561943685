import { Component, OnInit, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Location } from '../_classes/location';
import { UntypedFormControl } from '@angular/forms';
import { MapComponent } from '../map.component/map.component';
import { LocationService } from '../location.service';
import { OrganizationalUnitService } from '../../../_services/organizational-unit.service';
import { map, tap, debounceTime, switchMap, filter } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { MatOption } from '@angular/material/core';

export interface IData {
  location: Location;
}

@Component({
  templateUrl: './location.dialog.html',
  styleUrls: ['./location.dialog.scss']
})
export class LocationDialog implements OnInit, AfterViewInit {


  @ViewChild('map', { static: true })
  public map: MapComponent;

  public addressControl = new UntypedFormControl();
  public searching = false;
  public options: Observable<Location[]>;

  public additionalControl = new UntypedFormControl();

  constructor(
    public dialogRef: MatDialogRef<LocationDialog>,
    @Inject(MAT_DIALOG_DATA) public data: IData,
    private locationService: LocationService,
    public service: OrganizationalUnitService
  ) { }

  async ngOnInit(): Promise<void> {
    this.options = this.addressControl.valueChanges
      .pipe(
        filter(value => typeof value === 'string' && value.length > 0),
        debounceTime(500),
        tap(() => this.searching = true),
        switchMap(async query => this.locationService.search({ query, countries: ['FR'] })),
        tap(() => this.searching = false)
      );
    this.additionalControl.valueChanges.subscribe(value => this.data.location.additional = value);
  }

  async ngAfterViewInit(): Promise<void> {
    if (!this.data.location) { return this.data.location = {} as any; }
    const location = this.data.location;

    if (location.address && location.address.formatted) {
      setTimeout(() => this.addressControl.setValue(location, { emitEvent: false }));
    } else if (location.coordinates && location.coordinates.length === 2) {
      this.setLocation(await this.locationService.reverseGeocode(location.coordinates));
    }

    if (location.coordinates && location.coordinates.length === 2) {
      this.map.addMarker({ text: '', color: 'var(--ion-color-primary)', position: location.coordinates });
    } else if (location.address && location.address.formatted) {
      this.setLocation(await this.locationService.geocode(location.address.formatted));
    }

    if (location.additional) {
      setTimeout(() => this.additionalControl.setValue(location.additional, { emitEvent: false }));
    }
  }

  public displayOption(option: Location): string {
    return option && option.address ? option.address.formatted : '';
  }

  public selected(option: MatOption): void {
    this.setLocation(option.value);
  }

  public update(): void {
    this.dialogRef.close(this.data);
  }

  private setLocation(location: Location): void {
    location.additional = this.data.location ? this.data.location.additional : null;
    this.data.location = location;
    this.addressControl.setValue(this.data.location, { emitEvent: false });
    this.map.removeMarkers();
    this.map.addMarker({ text: '', color: 'var(--ion-color-primary)', position: this.data.location.coordinates });
    this.additionalControl.setValue(location.additional, { emitEvent: false });
  }

}
