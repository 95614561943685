import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { OrganizationalUnitSelectComponent } from './organizational-unit-select/organizational-unit-select.component';
import { OrganizationalUnitDatagridCellComponent } from './organizational-unit-datagrid-cell/organizational-unit-datagrid-cell.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgPipesModule } from 'ngx-pipes';
import { OrganizationalUnitContactInfoComponent } from './organizational-unit-contact-info/organizational-unit-contact-info.component';
import { MatCardModule } from '@angular/material/card';



@NgModule({
  declarations: [
    OrganizationalUnitSelectComponent,
    OrganizationalUnitDatagridCellComponent,
    OrganizationalUnitContactInfoComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatChipsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressBarModule,
    NgPipesModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    MatCardModule
  ],
  exports: [
    OrganizationalUnitSelectComponent,
    OrganizationalUnitDatagridCellComponent,
    OrganizationalUnitContactInfoComponent
  ],
})
export class OrganizationalUnitModule { }
