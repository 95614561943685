<ng-template #datePickerPrefix>
  <button id="op-selector" mat-icon-button [matMenuTriggerFor]="menu">
    <div [style.font-size.px]="18" [ngSwitch]="formGroup?.value.op" fxLayoutAlign="center center">
      <ng-container *ngSwitchCase="'='">&equals;</ng-container>
      <ng-container *ngSwitchCase="'>'">&gt;</ng-container>
      <ng-container *ngSwitchCase="'>='">&ge;</ng-container>
      <ng-container *ngSwitchCase="'<'">&lt;</ng-container>
      <ng-container *ngSwitchCase="'<='">&le;</ng-container>
      <ng-container *ngSwitchCase="'<>'">&lt;&gt;</ng-container>
      <ng-container *ngSwitchCase="'<=>'">&le;&ge;</ng-container>
      <ng-container *ngSwitchCase="'>=<'">&le;&ge;</ng-container>
      <ng-container *ngSwitchCase="'>=<'">&le;&ge;</ng-container>
    </div>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="formGroup?.get('op').setValue('=', { emitEvent: !!formGroup?.value?.from })">
      <span value="=">&equals;</span>
      <span [style.font-size.em]="0.8" italic> Égale à</span>
    </button>
    <button mat-menu-item (click)="formGroup?.get('op').setValue('>', { emitEvent: !!formGroup?.value?.from })">
      <span value=">">&gt;</span>
      <span [style.font-size.em]="0.8" italic> Supérieur à</span>
    </button>
    <button mat-menu-item (click)="formGroup?.get('op').setValue('>=', { emitEvent: !!formGroup?.value?.from })">
      <span value=">=">&ge;</span>
      <span [style.font-size.em]="0.8" italic> Supérieur ou égale à</span>
    </button>
    <button mat-menu-item (click)="formGroup?.get('op').setValue('<', { emitEvent: !!formGroup?.value?.from })">
      <span value="<">&lt;</span>
      <span [style.font-size.em]="0.8" italic> Inférieure à</span>
    </button>
    <button mat-menu-item (click)="formGroup?.get('op').setValue('<=', { emitEvent: !!formGroup?.value?.from })">
      <span value="<=">&le;</span>
      <span [style.font-size.em]="0.8" italic> Inférieure ou égale à</span>
    </button>
    <button mat-menu-item
      (click)="formGroup?.get('op').setValue('<>', { emitEvent: !!formGroup?.value?.from && !!formGroup?.value?.to })">
      <span value="<>">&lt;&gt;</span>
      <span [style.font-size.em]="0.8" italic> Supérieure à et inférieur à</span>
    </button>
    <button mat-menu-item
      (click)="formGroup?.get('op').setValue('<=>', { emitEvent: !!formGroup?.value?.from && !!formGroup?.value?.to })">
      <span value="<=>">&le;&ge;</span>
      <span [style.font-size.em]="0.8" italic> Supérieure ou égale à et inférieur ou égale à</span>
    </button>
    <button mat-menu-item
      (click)="formGroup?.get('op').setValue('>=<', { emitEvent: !!formGroup?.value?.from && !!formGroup?.value?.to })">
      <span value=">=<">&ge;&lt;</span>
      <span [style.font-size.em]="0.8" italic> Supérieure ou égale à et inférieur à</span>
    </button>
    <button mat-menu-item
      (click)="formGroup?.get('op').setValue('>=<', { emitEvent: !!formGroup?.value?.from && !!formGroup?.value?.to })">
      <span value=">=<">&ge;&lt;</span>
      <span [style.font-size.em]="0.8" italic> Supérieure à et inférieur ou égale à</span>
    </button>
  </mat-menu>
</ng-template>

<ng-container *ngIf="['=', '>', '>=', '<', '<='].includes(formGroup?.value.op)" [formGroup]="formGroup">
  <mat-form-field [appearance]="appearance">
    <mat-label [innerHTML]="(label | translate) | safe:'html'"></mat-label>
    <input matInput formControlName="from" [matDatepicker]="picker">
    <mat-datepicker #picker></mat-datepicker>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <div matPrefix>
      <ng-container *ngTemplateOutlet="datePickerPrefix"></ng-container>
    </div>
  </mat-form-field>
</ng-container>

<mat-form-field *ngIf="['<>', '<=>', '>=<', '>=<'].includes(formGroup?.value.op)" [appearance]="appearance">
  <mat-label [innerHTML]="(label | translate) | safe:'html'"></mat-label>
  <mat-date-range-input [formGroup]="formGroup" [rangePicker]="picker">
    <input matStartDate formControlName="from" placeholder="Start date">
    <input matEndDate formControlName="to" placeholder="End date">
  </mat-date-range-input>
  <mat-date-range-picker #picker></mat-date-range-picker>
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <div matPrefix>
    <ng-container *ngTemplateOutlet="datePickerPrefix"></ng-container>
  </div>
</mat-form-field>
