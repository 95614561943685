/** @format */

import { propertyMap } from 'model-mapper';

export class EmbededOrganizationalUnit {
  @propertyMap({ source: '_id' })
  public id: string;

  @propertyMap()
  public path: string;

  @propertyMap()
  public pathNames: string[];
}
