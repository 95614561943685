/** @format */

import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LibConfig, LibConfigService } from '../config.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ModelMapper } from 'model-mapper';
import { DirectOrder, OrderStatus } from '../classes/order';
import { File } from '../classes/file';

@Injectable({
  providedIn: 'root',
})
export class DirectOrderService {
  constructor(@Inject(LibConfigService) protected config: LibConfig, protected http: HttpClient) {}

  public find(filter?: { statuses?: OrderStatus[]; organizationalUnitId?: string }): Observable<DirectOrder[]> {
    const params: any = {};
    if (filter.statuses?.length) params.statuses = filter.statuses;
    if (filter.organizationalUnitId) params.organizationalUnitId = filter.organizationalUnitId;
    return this.http
      .get<any[]>(`${this.config.environment.apiUrl}/direct-orders`, { params })
      .pipe(map(data => data.map(d => new ModelMapper(DirectOrder).map(d))));
  }

  public get(id: string): Observable<DirectOrder> {
    return this.http
      .get<any[]>(`${this.config.environment.apiUrl}/direct-orders/${id}`)
      .pipe(map(data => new ModelMapper(DirectOrder).map(data)));
  }

  public setStatus(
    id: string,
    data: { status: OrderStatus; interventionDate?: Date; interventionReport?: File; date?: Date }
  ): Observable<boolean> {
    return this.http.patch<boolean>(`${this.config.environment.apiUrl}/direct-orders/${id}/set-status`, data);
  }

  public cancel(id: string): Observable<boolean> {
    return this.http.patch<boolean>(`${this.config.environment.apiUrl}/direct-orders/${id}/cancel`, {});
  }
}
