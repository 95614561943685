import { environment as shared } from '../../../../environments/environment';

export const environment = Object.assign(shared, {
  title: 'Isitech [recette]',
  appName: 'isitect-webapp',
  production: true,
  gga: '',
  mapbox: 'pk.eyJ1IjoiZWhlcnZlIiwiYSI6ImNqeGVxejdpZTA1bmIzb21kd2oxYWNmcjcifQ.aa1Gfh-cCXZ2gngmKYsx0g',
  sentryUrl: 'https://ae7785204c3e4cf188ac30c598a2e625@o457875.ingest.sentry.io/6310752',
  qwatTrackerId: null
});
