import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TechnicalControlService } from '../../services/technical-control.service';
import { TechnicalControlObservation } from '../../classes/technical-control-observation';
import { fadeIn, fadeOut } from '../../constants/animations';
import { IPageTrackingInfo } from '../../services/user-tracking.service';

@Component({
  templateUrl: './technical-control-observation.modal.html',
  styleUrls: ['./technical-control-observation.modal.scss'],
  animations: [fadeIn, fadeOut]
})
export class TechnicalControlObservationModal implements OnInit {

  @Input()
  public id: string;

  @Input()
  public reference: string;

  @Input()
  public robienCompliant: boolean;

  @Input()
  public tracking: IPageTrackingInfo;

  public data: TechnicalControlObservation[];

  constructor(
    private modalController: ModalController,
    private technicalControlService: TechnicalControlService
  ) { }

  ngOnInit(): void {
    this.technicalControlService.observations(this.id, this.robienCompliant).toPromise()
      .then(data => this.data = data);
  }

  public async dismiss(): Promise<void> {
    await this.modalController.dismiss();
  }

}
