<div fxLayout="column" *ngIf="pathNames">
  <mat-label *ngIf="pathNames" id="path" [style.font-size.em]="0.8" [style.white-space]="'nowrap'">
    {{pathNames.slice(0, pathNames.length - 1).join(' > ')}}
  </mat-label>
  <mat-label id="code">{{pathNames[pathNames.length - 1]}}</mat-label>
</div>
<div fxLayout="column" *ngIf="realEstateStructure">
  <mat-label [style.font-size.em]="0.8" translate>{{realEstateStructure.kind}}
  </mat-label>
  <mat-label>{{realEstateStructure.reference}}</mat-label>
</div>
