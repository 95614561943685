/** @format */

import {Injectable} from '@angular/core';
import {BehaviorSubject, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {StorageService} from './storage.service';
import {UserService} from './user.service';
import {SessionService} from './session.service';
import {User} from '../_classes/user';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  static AUTH_KEY = 'AUTH';
  public $authToken = new BehaviorSubject(null);

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private sessionService: SessionService,
    private userService: UserService
  ) {
    this.init();
  }

  public async login(username: string, password: string): Promise<User> {
    const res = await this.http
      .post<{access_token: string}>(`${environment.apiUrl}/auth/login`, {
        username,
        password,
        kind: 'browser',
      })
      .toPromise();
    await this.storageService.set(AuthService.AUTH_KEY, res.access_token);
    this.$authToken.next(res.access_token);
    const user = await this.userService.loadUser();
    if (user.perimeter && user.perimeter.length > 0) {
      await this.sessionService.setHeaderSelection({perimeter: true});
    }
    return user;
  }

  public async signIn(accessToken: string): Promise<User> {
    await this.storageService.set(AuthService.AUTH_KEY, accessToken);
    this.$authToken.next(accessToken);
    const user = await this.userService.loadUser();
    if (user.perimeter && user.perimeter.length > 0) {
      await this.sessionService.setHeaderSelection({perimeter: true});
    }
    return user;
  }

  public async logout(): Promise<void> {
    await Promise.all([
      this.storageService.remove(AuthService.AUTH_KEY),
      this.sessionService.clearHeaderSelectionLogout(),
    ]);
    this.$authToken.next(null);
    await this.userService.clearUser();
  }

  public async isAuthenticated(): Promise<boolean> {
    const authToken = await this.storageService.get(AuthService.AUTH_KEY);
    if (!this.isValidToken(authToken)) {
      this.logout();
      return false;
    }
    if (authToken !== this.$authToken.value) {
      this.$authToken.next(authToken);
    }
    return !!authToken;
  }

  public isValidToken(authToken: string): boolean {
    if (authToken) {
      const payload = JSON.parse(atob(authToken.split('.')[1]));
      if (moment().isBefore(payload.exp * 1000)) return true;
    }
    return false;
  }

  private async init(): Promise<void> {
    const data = await this.storageService.get(AuthService.AUTH_KEY);
    this.$authToken.next(data);
  }
}
