<div id="container" fxLayout="column" fxLayout="row wrap">
  <ng-container *ngTemplateOutlet="userInfoTmpl; context: { label: 'RTS', user: info?.rts }"></ng-container>
  <ng-container *ngTemplateOutlet="userInfoTmpl; context: { label: 'Assistant(e) technique', user: info?.assistant }">
  </ng-container>
  <ng-container *ngTemplateOutlet="userInfoTmpl; context: { label: 'Gardien/Gardienne ', user: info?.janitor }">
  </ng-container>
  <mat-card class="mat-elevation-z2">
    <mat-card-title><span color="primary">Horaires de la loge</span></mat-card-title>
    <div fxLayoutAlign="space-evenly center">
      <div fxLayout="column">
        <div fxLayout="column" [style.padding.px]="8">
          <span class="label" color="primary" [style.margin-right.px]="8" [style.width.px]="250">
            <span [style.font-weight]="300">Horaires d'ouverture</span>
            lundi au vendredi
          </span>
          <div fxLayoutAlign="start center">
            <span class="time">{{info?.lodge?.metadata?.time?.week?.morning?.start}}</span>
            <span color="primary" [style.padding]="'0 8px'" [style.font-weight]="300"> à </span>
            <span class="time">{{info?.lodge?.metadata?.time?.week?.morning?.end}}</span>
            <span color="primary" [style.padding]="'0 8px'" [style.font-weight]="300"> et </span>
            <span class="time">{{info?.lodge?.metadata?.time?.week?.afternoon?.start}}</span>
            <span color="primary" [style.padding]="'0 8px'" [style.font-weight]="300"> à </span>
            <span class="time">{{info?.lodge?.metadata?.time?.week?.afternoon?.end}}</span>
          </div>
        </div>
        <div fxLayout="column" [style.padding.px]="8">
          <span class="label" color="primary" [style.margin-right.px]="8" [style.width.px]="250">
            <span [style.font-weight]="300">Horaires de pause</span>
            lundi au vendredi
          </span>
          <div fxLayoutAlign="start center">
            <span class="time">{{info?.lodge?.metadata?.time?.week?.morning?.breakStart}}</span>
            <span color="primary" [style.padding]="'0 8px'" [style.font-weight]="300"> à </span>
            <span class="time">{{info?.lodge?.metadata?.time?.week?.morning?.breakEnd}}</span>
            <span color="primary" [style.padding]="'0 8px'" [style.font-weight]="300"> et </span>
            <span class="time">{{info?.lodge?.metadata?.time?.week?.afternoon?.breakStart}}</span>
            <span color="primary" [style.padding]="'0 8px'" [style.font-weight]="300"> à </span>
            <span class="time">{{info?.lodge?.metadata?.time?.week?.afternoon?.breakEnd}}</span>
          </div>
        </div>
      </div>
      <div fxLayout="column">
        <div fxLayout="column" [style.padding.px]="8">
          <span class="label" color="primary" [style.margin-right.px]="8" [style.width.px]="200">
            <span [style.font-weight]="300">Horaires d'ouverture</span>
            samedi
          </span>
          <div fxLayoutAlign="start center">
            <span class="time">{{info?.lodge?.metadata?.time?.saturday?.morning?.start}}</span>
            <span color="primary" [style.padding]="'0 8px'" [style.font-weight]="300"> à </span>
            <span class="time">{{info?.lodge?.metadata?.time?.saturday?.morning?.end}}</span>
            <span color="primary" [style.padding]="'0 8px'" [style.font-weight]="300"> et </span>
            <span class="time">{{info?.lodge?.metadata?.time?.saturday?.afternoon?.start}}</span>
            <span color="primary" [style.padding]="'0 8px'" [style.font-weight]="300"> à </span>
            <span class="time">{{info?.lodge?.metadata?.time?.saturday?.afternoon?.end}}</span>
          </div>
        </div>
        <div fxLayout="column" [style.padding.px]="8">
          <span class="label" color="primary" [style.margin-right.px]="8" [style.width.px]="200">
            <span [style.font-weight]="300">Horaires de pause</span>
            samedi
          </span>
          <div fxLayoutAlign="start center">
            <span class="time">{{info?.lodge?.metadata?.time?.saturday?.morning?.breakStart}}</span>
            <span color="primary" [style.padding]="'0 8px'" [style.font-weight]="300"> à </span>
            <span class="time">{{info?.lodge?.metadata?.time?.saturday?.morning?.breakEnd}}</span>
            <span color="primary" [style.padding]="'0 8px'" [style.font-weight]="300"> et </span>
            <span class="time">{{info?.lodge?.metadata?.time?.saturday?.afternoon?.breakStart}}</span>
            <span color="primary" [style.padding]="'0 8px'" [style.font-weight]="300"> à </span>
            <span class="time">{{info?.lodge?.metadata?.time?.saturday?.afternoon?.breakEnd}}</span>
          </div>
        </div>

      </div>

    </div>
  </mat-card>
</div>

<ng-template #userInfoTmpl let-label="label" let-user="user">
  <mat-card class="mat-elevation-z2" fxLayout="column" fxLayoutGap="8px">
    <mat-card-title><span color="primary">{{label}}</span></mat-card-title>
    <div class="info" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
      <mat-icon>person</mat-icon>
      <span>{{user?.firstname}} {{user?.lastname}}</span>
    </div>
    <div class="info" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
      <mat-icon>email</mat-icon>
      <a color="secondary" [attr.href]="'mailto:' + user?.email">{{user?.email}}</a>
    </div>
    <div class="info" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
      <mat-icon>phone</mat-icon>
      <a color="secondary" [attr.href]="'tel:' + user?.phoneNumber">{{user?.phoneNumber}}</a>
      <a color="secondary" [attr.href]="'tel:' + user?.mobilePhoneNumber">{{user?.mobilePhoneNumber}}</a>
    </div>
  </mat-card>
</ng-template>
