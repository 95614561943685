import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { ModelMapper } from 'model-mapper';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LibConfigService, LibConfig } from '../config.service';
import { EquipmentStatusHistory } from '../classes/equipment-status-history';
import { Moment } from 'moment';

@Injectable({
  providedIn: 'root',
})
export class EquipmentStatusHistoryService {
  constructor(
    @Inject(LibConfigService) protected config: LibConfig,
    protected http: HttpClient
  ) {}

  public getEquipmentStatuses(
    id: string,
    { start, end }: { start: Moment; end: Moment }
  ): Observable<EquipmentStatusHistory[]> {
    const params: any = {};
    if (start) params.start = start.toISOString();
    if (end) params.end = end.toISOString();
    return this.http
      .get<any[]>(
        `${this.config.environment.apiUrl}/equipment-status-history/equipment/${id}`,
        { params }
      )
      .pipe(
        map((data) =>
          data.map((d) => new ModelMapper(EquipmentStatusHistory).map(d))
        )
      );
  }

}
