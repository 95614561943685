import { Component, Input } from '@angular/core';
import { isNil } from 'lodash-es';

@Component({
  selector: 'app-green-energy-tag',
  templateUrl: './green-energy-tag.component.html',
  styleUrls: ['./green-energy-tag.component.scss'],
})
export class GreenEnergyTagComponent {

  @Input('value')
  public set setValue(value: number) {
    if (isNil(value) || typeof value !== 'number') {
      this.value = 0;
    } else {
      this.value = Math.round(value);
    }
  }
  public value = 0;

  constructor() { }

}
