<app-page-tracking *ngIf="tracking" [origin]="tracking.origin" [page]="tracking.page" [id]="tracking.id">
</app-page-tracking>

<ion-header>
  <ion-toolbar color="primary">
    <ion-title>
      <ion-label>Équipement {{reference}}</ion-label>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list *ngIf="documents; else loadingTmpl" @fadeIn lines="none" fxLayout="row wrap"
    fxLayoutAlign="space-evenly start">
    <app-document *ngFor="let item of documents" [item]="item"></app-document>
  </ion-list>
</ion-content>

<ng-template #loadingTmpl>
  <sesio-loader @fadeOut></sesio-loader>
</ng-template>
