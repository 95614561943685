import { propertyMap } from 'model-mapper';

export class EsiMetrics {

  @propertyMap()
  public energy: number;

  @propertyMap()
  public climat: number;

  @propertyMap()
  public greenEnergy: number;

  @propertyMap()
  public ecs: number;

  @propertyMap()
  public heating: number;

}

export class ValueAndTrend {

  @propertyMap()
  public value: number;

  @propertyMap()
  public trend: number;

}