/** @format */

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {ModelMapper} from 'model-mapper';
import {BoilerRoom, GroupChartData} from 'sesio-lib';
import {map} from 'rxjs/operators';
import {Moment} from 'moment';
import {IRealEstateStructureFilter, RealEstateStructureService} from './real-estate-structure.service';

export interface IWeatherInfo {
  outdoor: {
    weatherTemperature: {min: number; avg: number; max: number};
    trendTemperature: {min: number; avg: number; max: number};
    humidity: {min: number; avg: number; max: number};
    wind: {speed: number; gust: number};
    precipitation: number;
    sunhour: number;
    airQuality: {
      index: {type: number};
      o3: {type: number};
      no2: {type: number};
      pm10: {type: number};
      pm25: {type: number};
      so2: {type: number};
    };
  };
  indoor: {
    temperature: {min: number; avg: number; max: number};
    humidity: {min: number; avg: number; max: number};
    airQuality: {min: number; avg: number; max: number};
    dju: number;
  };
}

export interface IBoilerRoomInfo {
  count: {
    boilers: number;
    substations: number;
    energy: number;
    climat: number;
    greenEnergy: number;
    ecs: number;
    heating: number;
    anomalies: number;
    failures: number;
  };
  byEnergykind: {name: string; count: number}[];
  byLinked: {linked: boolean; count: number}[];
  byMode: {mode: string; count: number}[];
}

@Injectable({
  providedIn: 'root',
})
export class BoilerRoomService {
  constructor(private realEstateStructureService: RealEstateStructureService, private http: HttpClient) {}

  public get(id: string): Observable<BoilerRoom> {
    return this.http
      .get<any>(`${environment.apiUrl}/real-estate-structure/${id}`)
      .pipe(map(data => new ModelMapper(BoilerRoom).map(data)));
  }

  public getChartData(id: string): Observable<GroupChartData> {
    return this.http
      .get<any>(`${environment.apiUrl}/real-estate-structure/boiler-rooms/${id}/chart-data`)
      .pipe(map(data => new ModelMapper(GroupChartData).map(data)));
  }

  public getBoilerRoom(id: string): Observable<BoilerRoom> {
    return this.http
      .get<any>(`${environment.apiUrl}/real-estate-structure/${id}`)
      .pipe(map(data => new ModelMapper(BoilerRoom).map(data)));
  }

  public boilerRoomInfo(headerSearch: IRealEstateStructureFilter): Observable<IBoilerRoomInfo> {
    return this.http.post<any>(
      `${environment.apiUrl}/real-estate-structure/boiler-rooms/get-info`,
      this.realEstateStructureService.buildSearchFilter(headerSearch)
    );
  }

  public updateBoilerRoom(id: string, data: any): Observable<BoilerRoom> {
    return this.http
      .patch<any>(
        id
          ? `${environment.apiUrl}/real-estate-structure/boiler-rooms/${id}`
          : `${environment.apiUrl}/real-estate-structure/boiler-rooms`,
        data
      )
      .pipe(map(data => new ModelMapper(BoilerRoom).map(data)));
  }

  public updateBoilerRoomEquipemnts(id: string, equipments: any[], toRemove: string[]): Observable<boolean> {
    return this.http.post<boolean>(`${environment.apiUrl}/real-estate-structure/boiler-rooms/${id}/equipments`, {
      equipments,
      toRemove,
    });
  }

  public getWeatherInfo(id: string, start: Moment, end: Moment): Observable<IWeatherInfo> {
    const params: any = {};
    if (start) params.start = start.toISOString();
    if (end) params.end = end.toISOString();
    return this.http.get<any>(`${environment.apiUrl}/real-estate-structure/boiler-rooms/${id}/weather-info`, {params});
  }

  public getOutdoorTemperature(
    id: string,
    start: Moment,
    end: Moment
  ): Observable<{max: number; min: number; avg: number}> {
    const params: any = {};
    if (start) {
      params.start = start.toISOString();
    }
    if (end) {
      params.end = end.toISOString();
    }
    return this.http.get<any>(`${environment.apiUrl}/real-estate-structure/boiler-rooms/${id}/outdoor-temperature`, {
      params,
    });
  }

  public getSensorChartData(id: string): Observable<{
    indoorTemperature: [number, number][];
    indoorHumidity: [number, number][];
    indoorAirQuality: [number, number][];
  }> {
    return this.http.get<any>(`${environment.apiUrl}/real-estate-structure/boiler-rooms/${id}/sensor-chart-data`);
  }
}
