<ion-header>
  <ion-toolbar color="primary">
    <ion-title>
      <ion-label translate>statistics</ion-label>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content [ngSwitch]="statistics?.page">
  <ng-container *ngSwitchCase="'dashboard'">
    <app-statistics-dashboard></app-statistics-dashboard>
  </ng-container>
  <ng-container *ngSwitchCase="'energy-fluid-space-group'">
    <app-statistics-energy-fluid-space-group [data]="statistics.data"></app-statistics-energy-fluid-space-group>
  </ng-container>
  <ng-container *ngSwitchCase="'elevator-space'">
    <app-statistics-elevator-space></app-statistics-elevator-space>
  </ng-container>
  <ng-container *ngSwitchCase="'fire-safety-space'">
    <app-statistics-fire-safety-space></app-statistics-fire-safety-space>
  </ng-container>
  <ng-container *ngSwitchCase="'access-control-space'">
    <app-access-control-space></app-access-control-space>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <div fxLayoutAlign="center center" [style]="{ height: '100%', width: '100%' }">
      <span color="primary" class="mat-title">Bientôt disponible</span>
    </div>
  </ng-container>
</ion-content>