/** @format */

import {HttpClient, HttpProgressEvent} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {isNil} from 'lodash-es';
import {ModelMapper} from 'model-mapper';
import {Observable} from 'rxjs';
import {last, map, mergeMap, tap} from 'rxjs/operators';
import {
  Equipment,
  EquipmentKindCategory,
  Family,
  InteractiveSchema,
  InteractiveSchemaKind,
  RealEstateStructure,
  RealEstateStructureKind,
} from 'sesio-lib';
import {environment} from '../../environments/environment';
import {IHeaderSearch, buildHeaderSearchFilter} from '../_classes/header-search';
import {IDatatableOptions, IDatatableRecords} from '../_components/datagrid/datatable.class';

interface IRequestUploadUrlData {
  realEstateStructureKind: RealEstateStructureKind;
  filename: string;
  contentType: string;
  prefix?: string;
}

export interface IRealEstateStructureFilter extends IHeaderSearch {
  kinds?: RealEstateStructureKind[];
  realEstateStructureId?: string;
  securityRegister?: boolean;
  withAlert?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class RealEstateStructureService {
  constructor(private http: HttpClient) {}

  public get(id: string): Observable<RealEstateStructure> {
    return this.http
      .get<any>(`${environment.apiUrl}/real-estate-structure/${id}`)
      .pipe(map(data => new ModelMapper(RealEstateStructure).map(data)));
  }

  public getEquipments(
    id: string,
    {
      fields,
      families,
      categories,
      supervision,
      hypervision,
      metrics,
    }: {
      fields?: string[];
      families?: Family[];
      categories?: EquipmentKindCategory[];
      supervision?: boolean;
      hypervision?: boolean;
      metrics?: string[];
    } = {}
  ): Observable<Equipment[]> {
    const params: any = {};
    if (fields?.length > 0) {
      params.fields = fields;
    }
    if (families?.length > 0) {
      params.families = families;
    }
    if (categories?.length > 0) {
      params.categories = categories;
    }
    if (!isNil(supervision)) {
      params.supervision = supervision;
    }
    if (!isNil(hypervision)) {
      params.hypervision = hypervision;
    }
    if (metrics?.length > 0) {
      params.metrics = metrics;
    }
    return this.http
      .get<any>(`${environment.apiUrl}/real-estate-structure/${id}/equipments`, {params})
      .pipe(map(data => data.map(d => new ModelMapper(Equipment).map(d))));
  }

  public getNextReferenceIndex(kind: RealEstateStructureKind, organizationalUnitId: string): Observable<number> {
    const params: any = {kind, organizationalUnitId};
    return this.http.get<number>(`${environment.apiUrl}/real-estate-structure/next-reference-index`, {params});
  }

  public referenceExists(reference: string): Observable<string> {
    const params: any = {reference};
    return this.http.get<string>(`${environment.apiUrl}/real-estate-structure/reference-exists`, {params});
  }

  public search(
    {
      search,
      kinds,
      organizationalUnitId,
      ids,
    }: {
      search?: string;
      kinds?: RealEstateStructureKind[];
      organizationalUnitId?: string;
      ids?: string[];
    },
    fields?: string,
    limit?: number
  ): Observable<RealEstateStructure[]> {
    const params: any = {};
    if (search) params.search = search;
    if (kinds) params.kinds = kinds;
    if (organizationalUnitId) params.organizationalUnitId = organizationalUnitId;
    if (ids?.length) params.ids = ids;
    if (limit) params.limit = limit;
    if (fields) params.fields = fields;

    return this.http
      .get<any[]>(`${environment.apiUrl}/real-estate-structure`, {params})
      .pipe(map(data => data.map(d => new ModelMapper(RealEstateStructure).map(d))));
  }

  public requestUploadUrl(id: string, data: IRequestUploadUrlData): Observable<string> {
    return this.http.post(`${environment.apiUrl}/real-estate-structure/${id}/get-upload-url`, data, {
      responseType: 'text',
    });
  }

  public addInteractiveSchema(
    id: string,
    kind: RealEstateStructureKind,
    data: InteractiveSchema,
    file: File,
    progress?: (event: HttpProgressEvent) => void
  ): Observable<boolean> {
    const requestUploadUrlData: IRequestUploadUrlData = {
      realEstateStructureKind: kind,
      filename: 'interactive-schema',
      contentType: data.kind === InteractiveSchemaKind.GLTF ? 'model/gltf+json' : 'image/svg+xml',
    };
    return this.requestUploadUrl(id, requestUploadUrlData).pipe(
      tap(url => (data.path = url.split('?')[0])),
      mergeMap(url => {
        return this.http
          .put(url, file, {
            reportProgress: true,
            observe: 'events',
            headers: {
              'Content-Type': file.type,
              'Content-Disposition': `attachment; filename=${encodeURIComponent(data.filename)}`,
            },
          })
          .pipe(tap((event: HttpProgressEvent) => (progress ? progress(event) : null)));
      }),
      last(),
      mergeMap(() =>
        this.http.post<boolean>(
          `${environment.apiUrl}/real-estate-structure/${id}/interactive-schema`,
          new ModelMapper(InteractiveSchema).serialize(data)
        )
      )
    );
  }

  public deleteInteractiveSchema(id: string): Observable<boolean> {
    return this.http.delete<boolean>(`${environment.apiUrl}/real-estate-structure/${id}/interactive-schema`);
  }

  public datatable(
    query: IDatatableOptions,
    headerSearch: IRealEstateStructureFilter = {},
    box?: number[][]
  ): Observable<IDatatableRecords<any>> {
    const params: any = {};
    if (box?.length) params.box = box;
    return this.http.post<any>(
      `${environment.apiUrl}/real-estate-structure/datatable`,
      {query, filter: this.buildSearchFilter(headerSearch)},
      {params}
    );
  }

  public getMapInfo(search: IRealEstateStructureFilter): Observable<
    {
      _id: string;
      coordinates: [number, number];
      state: string;
    }[]
  > {
    return this.http.post<any[]>(
      `${environment.apiUrl}/real-estate-structure/get-map-info`,
      this.buildSearchFilter(search)
    );
  }

  public create(update: any): Observable<RealEstateStructure> {
    return this.http
      .post<any>(`${environment.apiUrl}/real-estate-structure`, update)
      .pipe(map(data => new ModelMapper(RealEstateStructure).map(data)));
  }

  public update(id: string, update: any): Observable<boolean> {
    return this.http.patch<boolean>(`${environment.apiUrl}/real-estate-structure/${id}`, update);
  }

  public delete(id: string): Observable<boolean> {
    return this.http.delete<boolean>(`${environment.apiUrl}/real-estate-structure/${id}`);
  }

  public updateSecurityRegister(id: string, update: any): Observable<boolean> {
    return this.http.patch<boolean>(`${environment.apiUrl}/real-estate-structure/${id}/security-register`, update);
  }

  public geFireSafetyInfo(search: IRealEstateStructureFilter): Observable<{
    inspectionRatio: {count: number; total: number};
    inspectionCount: number;
    interventionCount: number;
    quotation: {
      confirmed: {count: number; amount: number};
      validated: {count: number; amount: number};
      refused: {count: number; amount: number};
      validatedNoOrder: {count: number; amount: number};
    };
    equipment: {
      count: number;
      anomalyCount: number;
      dangerCount: number;
      controlRate: number;
      qualityIndex: number;
    };
    states: {name: string; value: number}[];
    order: {
      toAcknowledge: number;
      acknowledged: number;
      ongoing: number;
      done: number;
    };
  }> {
    return this.http.post<any>(
      `${environment.apiUrl}/real-estate-structure/get-fire-safety-info`,
      this.buildSearchFilter(search)
    );
  }

  public getSecurityRegisterInfo(search: IRealEstateStructureFilter): Observable<{name: string; value: number}[]> {
    return this.http.post<any>(
      `${environment.apiUrl}/real-estate-structure/get-security-register-info`,
      this.buildSearchFilter(search)
    );
  }

  public buildSearchFilter(search: IRealEstateStructureFilter): any {
    const filter = buildHeaderSearchFilter(search);
    if (search?.kinds?.length) {
      filter.kinds = search.kinds;
    }
    if (search?.realEstateStructureId) {
      filter.realEstateStructureId = search.realEstateStructureId;
    }
    if (search?.securityRegister) {
      filter.securityRegister = search.securityRegister;
    }
    if (search?.withAlert === true) filter.withAlert = true;
    return filter;
  }
}
