import { Injectable } from '@angular/core';
import * as  mbxGeocoding from '@mapbox/mapbox-sdk/services/geocoding';
import { environment } from '../../../environments/environment';
import { Location } from './_classes/location';
const geocodingService = mbxGeocoding({ accessToken: environment.mapbox });

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor() { }

  public async geocode(address: string): Promise<Location> {
    const res = await geocodingService.forwardGeocode({
      query: address
    }).send();
    return this.buildLocation(res.body.features.find(f => f.place_type.includes('address')));
  }

  public async reverseGeocode(coordinates: [number, number]): Promise<Location> {
    const res = await geocodingService.reverseGeocode({
      query: coordinates
    }).send();
    return this.buildLocation(res.body.features.find(f => f.place_type.includes('address')));
  }

  public async search(
    options: { query: string; countries?: string[]; proximity?: [number, number]; limit?: number }
  ): Promise<Location[]> {
    const res = await geocodingService.forwardGeocode(options).send();
    return res.body.features.map(f => this.buildLocation(f));
  }

  private buildLocation(result: any): Location {
    if (!result) { return; }
    const context = result.context || [];
    const postcode = context.find(c => c.id.startsWith('postcode'));
    const city = context.find(c => c.id.startsWith('place'));
    const country = context.find(c => c.id.startsWith('country'));
    const location: Location = {
      address: {
        formatted: result.place_name,
        number: result.address,
        street: result.text,
        postalCode: postcode ? postcode.text : null,
        city: city ? city.text : null,
        country: country ? country.text : null,
        countryShort: country ? country.short_code : null,
        type: result.place_type[0]
      },
      coordinates: result.geometry.coordinates
    };
    return location;
  }

}
