<mat-form-field *ngIf="control">
  <mat-label>{{label ? label : multiple ? 'Prestataires' : 'Prestataire'}}</mat-label>
  <mat-select [compareWith]="compareWithEntity" [formControl]="control" [value]="value" [required]="isRequired">
    <ng-container [ngSwitch]="families?.length > 1">
      <ng-container *ngSwitchCase="true">
        <mat-optgroup *ngFor="let group of contractors | async | unwind:'families' | groupBy:'families' | pairs"
          [label]="group[0] | translate">
          <mat-option *ngFor="let option of group[1]" [value]="option">
            <ng-container *ngTemplateOutlet="optionTmpl; context: { option: option }"></ng-container>
          </mat-option>
        </mat-optgroup>
      </ng-container>
      <ng-container *ngSwitchCase="false">
        <mat-option *ngFor="let option of contractors | async" [value]="option">
          <ng-container *ngTemplateOutlet="optionTmpl; context: { option: option }"></ng-container>
        </mat-option>
      </ng-container>
    </ng-container>

  </mat-select>
</mat-form-field>


<ng-template #optionTmpl let-option="option">
  <mat-label>
    <span *ngIf="!families || families?.length > 1" [style.font-weight]="300" translate>
      {{option.category}}
    </span>
    {{option.name}}
  </mat-label>
</ng-template>