
export enum SecurityCallQualification {
  DES = 'DES',
  ERRMA = 'ERRMA',
  INTEMP = 'INTEMP',
  MAL = 'MAL',
  NOCOM = 'NOCOM',
  SILENCE = 'SILENCE',
  TESTOK_BUR = 'TESTOK_BUR',
  TESTOK_GAR = 'TESTOK_GAR',
  TESTOK_MNT = 'TESTOK_MNT',
  TESTPHOHS = 'TESTPHOHS',
  TESTPHOOK = 'TESTPHOOK',
  TPS = 'TPS',
  UB = 'UB',
}