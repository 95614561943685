import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Tutorial, TutorialPage, TutorialPlatform } from 'sesio-lib';
import { environment } from '../../../../environments/environment';
import { User } from '../../../_classes/user';
import { UserService } from '../../../_services/user.service';

export interface IData {
  tutorials: Tutorial[];
  platform: TutorialPlatform;
  page: TutorialPage;
}

@Component({
  selector: 'app-tutorial-dialog',
  templateUrl: './tutorial-dialog.component.html',
  styleUrls: ['./tutorial-dialog.component.scss'],
})
export class TutorialDialogComponent implements OnInit {
  public repo = environment.repoUrl;
  public locale = this.translate.currentLang;
  public user: User;

  constructor(
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: IData,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.user = this.userService.$user.value;
  }

  public async set(checked: boolean): Promise<void> {
    this.user.tutorials[`${this.data.platform}.${this.data.page}`] = checked;
    this.userService
      .setTutorials(this.user.id, this.user.tutorials)
      .toPromise();
  }
}
