import { propertyMap } from 'model-mapper';
import { EquipmentKindCategory } from './equipment-kind';
import { Moment } from 'moment';
import { Family } from './family';


export enum EquipmentDocumentKind {
  CONTRACT = 'contract',
  BPU = 'bpu',
  TECHNICAL_CHECK = 'technical_check',
  PICTURE = 'picture',
  EDS = 'eds',
  OTHER = 'other',
  DOCUMENT = 'document',
  CCTM = 'cctm',
  CONSUEL = 'consuel',
  CONTRACT_EDF = 'contract_edf',
  CONTRACT_ENEDIS = 'contract_enedis',
  CONVENTION = 'convention',
  CONVENTION_PARTICULAR = 'convention_particular',
  QUALITY_REVIEW = 'quality_review',
  SHARE_MUT = 'share_mut',
  SHARE_MUT_FREE = 'share_mut_free',
  SHARE_MUT_ORANGE = 'share_mut_orange',
  SHARE_MUT_SFR = 'share_mut_sfr',
  TECHNICAL = 'technical',
  TECHNICAL_REPORT = 'technical_report',

  MEASUREMENT = 'measurement',
  DIAGNOSTIC = 'diagnostic',
  EMAIL = 'email',
  NETWORK_2G = 'network_2g',
  NETWORK_3G = 'network_3g',
  NETWORK_4G = 'network_4g',
  NETWORK_5G = 'network_5g',

  CE_MARKING = 'ce_marking'
}

export class EquipmentDocument {

  @propertyMap()
  public family: Family;

  @propertyMap()
  public kind: EquipmentDocumentKind;

  @propertyMap()
  public title: string;

  @propertyMap()
  public description: string;

  @propertyMap()
  public filename: string;

  @propertyMap()
  public path: string;

  @propertyMap()
  public size: number;

  @propertyMap({ type: 'Moment' })
  public date: Moment;

  @propertyMap()
  public mimetypes: string[];

  @propertyMap()
  public userId: string;

  @propertyMap()
  public equipmentCount: number;

}
