<div id="container" [class]="display" fxLayout="column" (resized)="onResized($event)"
  [@.disabled]="isAnimationDisabled">
  <svg *ngIf="display === 'full'" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 168.862 190">
    <g id="Groupe_1019" data-name="Groupe 1019" transform="translate(-300.593 -331)">
      <g *ngIf="position !== null" [@line]="position" id="value-line-block" data-name="Climat Value Line"
        [attr.transform]="'translate(4 ' + position + ')'">
        <line x1="40" y1="0" x2="115" y2="0" stroke-dasharray="1"
          style="stroke:var(--ion-color-primary);stroke-width:0.5" transform="translate(303 373.5)" />
      </g>
      <g id="Groupe_964" data-name="Groupe 964" transform="translate(0 -5)">
        <path id="Tracé_661" data-name="Tracé 661" d="M-1681.151,9334.293l10.2,10.487-10.2,10.487h-41.256v-20.975Z"
          transform="translate(2023 -8998.293)" fill="#33a357" />
        <text id="_50" data-name="&lt; 50" transform="translate(303 349)" fill="#fff" font-size="8"
          font-family="Lato-Regular, Lato">
          <tspan x="0" y="0">&#8804; 50</tspan>
        </text>
        <text id="A" transform="translate(342 350)" fill="#fff" font-size="12" font-family="Lato-Semibold, Lato"
          font-weight="600">
          <tspan x="-8.238" y="0">A</tspan>
        </text>
      </g>
      <g id="Groupe_965" data-name="Groupe 965" transform="translate(0 -5)">
        <path id="Tracé_662" data-name="Tracé 662" d="M-1671.151,9334.293l10.2,10.487-10.2,10.487h-51.256v-20.975Z"
          transform="translate(2023 -8971.293)" fill="#79b752" />
        <text id="_51_à_90" data-name="51 à 90" transform="translate(303 376)" fill="#fff" font-size="8"
          font-family="Lato-Regular, Lato">
          <tspan x="0" y="0">51 à 90</tspan>
        </text>
        <text id="B" transform="translate(352 377)" fill="#fff" font-size="12" font-family="Lato-Semibold, Lato"
          font-weight="600">
          <tspan x="-7.836" y="0">B</tspan>
        </text>
      </g>
      <g id="Groupe_966" data-name="Groupe 966" transform="translate(0 -5)">
        <path id="Tracé_663" data-name="Tracé 663" d="M-1661.151,9334.293l10.2,10.487-10.2,10.487h-61.256v-20.975Z"
          transform="translate(2023 -8944.293)" fill="#c3d545" />
        <text id="_91_à_150" data-name="91 à 150" transform="translate(303 403)" fill="#fff" font-size="8"
          font-family="Lato-Regular, Lato">
          <tspan x="0" y="0">91 à 150</tspan>
        </text>
        <text id="C" transform="translate(362 404)" fill="#fff" font-size="12" font-family="Lato-Semibold, Lato"
          font-weight="600">
          <tspan x="-7.974" y="0">C</tspan>
        </text>
      </g>
      <g id="Groupe_967" data-name="Groupe 967" transform="translate(0 -5)">
        <path id="Tracé_664" data-name="Tracé 664" d="M-1658.151,9334.293l10.2,10.487-10.2,10.487h-71.256v-20.975Z"
          transform="translate(2030 -8917.293)" fill="#fff02d" />
        <text id="_150_à_230" data-name="150 à 230" transform="translate(303 430)" fill="#fff" font-size="8"
          font-family="Lato-Regular, Lato">
          <tspan x="0" y="0">150 à 230</tspan>
        </text>
        <text id="D" transform="translate(369 431)" fill="#fff" font-size="12" font-family="Lato-Semibold, Lato"
          font-weight="600">
          <tspan x="-9.126" y="0">D</tspan>
        </text>
      </g>
      <g id="Groupe_968" data-name="Groupe 968" transform="translate(0 -5)">
        <path id="Tracé_665" data-name="Tracé 665" d="M-1647.151,9334.293l10.2,10.487-10.2,10.487h-82.256v-20.975Z"
          transform="translate(2030 -8891.293)" fill="#edb730" />
        <text id="_231_à_330" data-name="231 à 330" transform="translate(303 456)" fill="#fff" font-size="8"
          font-family="Lato-Regular, Lato">
          <tspan x="0" y="0">231 à 330</tspan>
        </text>
        <text id="E" transform="translate(379 457)" fill="#fff" font-size="12" font-family="Lato-Semibold, Lato"
          font-weight="600">
          <tspan x="-6.912" y="0">E</tspan>
        </text>
      </g>
      <g id="Groupe_969" data-name="Groupe 969" transform="translate(0 -5)">
        <path id="Tracé_666" data-name="Tracé 666" d="M-1637.151,9334.293l10.2,10.487-10.2,10.487h-92.256v-20.975Z"
          transform="translate(2030 -8864.293)" fill="#d66f2b" />
        <text id="_331_à_450" data-name="331 à 450" transform="translate(303 483)" fill="#fff" font-size="8"
          font-family="Lato-Regular, Lato">
          <tspan x="0" y="0">331 à 450</tspan>
        </text>
        <text id="F" transform="translate(389 484)" fill="#fff" font-size="12" font-family="Lato-Semibold, Lato"
          font-weight="600">
          <tspan x="-6.786" y="0">F</tspan>
        </text>
      </g>
      <g id="Groupe_970" data-name="Groupe 970" transform="translate(0 -5)">
        <path id="Tracé_667" data-name="Tracé 667" d="M-1627.151,9334.293l10.2,10.487-10.2,10.487h-102.256v-20.975Z"
          transform="translate(2030 -8837.293)" fill="#cb242b" />
        <text id="_450" data-name="&gt; 450" transform="translate(303 510)" fill="#fff" font-size="8"
          font-family="Lato-Regular, Lato">
          <tspan x="0" y="0">&gt; 450</tspan>
        </text>
        <text id="G" transform="translate(399 511)" fill="#fff" font-size="12" font-family="Lato-Semibold, Lato"
          font-weight="600">
          <tspan x="-8.736" y="0">G</tspan>
        </text>
      </g>
      <g *ngIf="position !== null" @fade id="value-block" data-name="Energy Value"
        [attr.transform]="'translate(4 ' + position + ')'">
        <path id="Tracé_668" data-name="Tracé 668" d="M-1712.208,9334.293l-10.2,10.487,10.2,10.487h41.256v-20.975Z"
          transform="translate(2136.407 -8971.293)" fill="#295974" />
        <text *ngIf="value" id="_189" data-name="189"
          [attr.transform]="'translate(' + (481 - (value.toString().length * 7)) + ' 378)'" fill="#fff" font-size="12"
          font-family="Lato-Semibold, Lato" font-weight="600">
          <tspan x="-20.88" y="0">{{value}}</tspan>
        </text>
        <text id="kWh_EP_m_.an" data-name="kWh EP/m².an" transform="translate(424 391)" fill="#295974" font-size="7"
          font-family="Lato-Regular, Lato">
          <tspan x="0" y="0">kWh </tspan>
          <tspan y="0" font-size="5">EP</tspan>
          <tspan y="0">/</tspan>
          <tspan y="0" font-size="5">m²</tspan>
          <tspan y="0">.an</tspan>
        </text>
      </g>
    </g>
  </svg>
  <div *ngIf="display === 'letter'" fxLayoutAlign="space-between center" [style.width.%]="100" fxLayoutGap="8px">
    <div id="letter" [style.background-color]="color">{{letter}}</div>
    <span id="value">{{value}}</span>
  </div>
</div>
