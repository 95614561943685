/** @format */

import { Component, OnInit, Input, NgZone, Output, EventEmitter, HostListener } from '@angular/core';
// @ts-ignore
import mapboxgl from '!mapbox-gl';
import { GeoJSONSource, Map, Marker, NavigationControl, Popup } from 'mapbox-gl';
import * as uuid from 'uuid';
import { fadeOut } from 'sesio-lib';
import { environment } from '../../../../environments/environment';
import { cloneDeep, each, merge, filter, map } from 'lodash-es';
import { take } from 'rxjs/operators';

export interface ISource {
  type: 'geojson';
  data: {
    type: 'FeatureCollection';
    features: {
      type: 'Feature';
      properties: any;
      geometry: [number, number];
    }[];
  };
  cluster?: boolean;
  clusterRadius?: number;
  clusterProperties?: {
    [name: string]: {
      color?: string;
      filter: any;
    };
  };
}

export interface ITextMarker {
  position: [number, number];
  text: string;
  id?: number;
  type?: 'text';
  click?: () => void;

  color?: string;
  radius?: number;
  textColor?: string;
  textSize?: number;

  properties?: any;
}

export interface IDefaultMarker {
  position: [number, number];
  id?: number;
  type?: 'default';
  click?: () => void;
  color?: string;
  marker?: any;
}

export type IMarker = ITextMarker | IDefaultMarker;

export interface IPadding {
  top: number;
  bottom: number;
  left: number;
  right: number;
}

export type Padding = number | IPadding;

export interface IFitBoundsOptions {
  padding?: Padding;
  maxZoom?: number;
}

export interface IOptions {
  enableNavControl?: boolean;
  disableKeyboard?: boolean;
  disableZoom?: boolean;
  disableDrag?: boolean;
  zoomWithCtrl?: boolean;
  zoom?: number;
  minZoom?: number;
  maxZoom?: number;
  center?: [number, number];
  maxBounds?: [[number, number], [number, number]];
  cluster?: boolean;
  clusterMaxZoom?: number;
  clusterRadius?: number;
  clusterProperties?: { [key: string]: { color?: string; merge: any } };
  popup?: (markers: IMarker[]) => string;
}

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  animations: [fadeOut],
})
export class MapComponent implements OnInit {
  static DEFAULT_OPTIONS: IOptions = {
    enableNavControl: true,
    disableKeyboard: false,
    disableZoom: false,
    disableDrag: false,
    zoomWithCtrl: true,
    zoom: 10,
    maxZoom: 14,
    center: [2.3514619999900788, 48.856697000002384],
    cluster: false,
    clusterRadius: 50,
  };

  public loading = true;

  @Input('options')
  public set setOption(options: IOptions) {
    this.options = merge(cloneDeep(MapComponent.DEFAULT_OPTIONS), options);
  }
  private options: IOptions = MapComponent.DEFAULT_OPTIONS;

  @Output()
  public ready: EventEmitter<MapComponent> = new EventEmitter();

  @Output('moved')
  public movedEmitter: EventEmitter<any> = new EventEmitter();

  public id: string;

  private map: Map;
  private navigationControl: NavigationControl;
  private markers: { [id: string]: Marker } = {};
  private markersOnScreen: { [id: string]: Marker } = {};
  private markerPopups: Popup;

  private data: IMarker[] = [];

  @HostListener('window:keydown', ['$event'])
  onKeyPress($event: KeyboardEvent): void {
    if (this.map && this.options.zoomWithCtrl && ($event.ctrlKey || $event.metaKey)) {
      this.map.scrollZoom.enable();
    }
  }

  @HostListener('window:keyup')
  onKeyUp(): void {
    if (this.map && this.options.zoomWithCtrl) {
      this.map.scrollZoom.disable();
    }
  }

  constructor(private ngZone: NgZone) {}

  ngOnInit(): void {
    this.id = uuid.v4();
  }

  public resize(): void {
    if (this.map) {
      this.map.resize();
    }
  }

  public getBounds(): mapboxgl.LngLatBounds {
    return this.map?.getBounds();
  }

  public async fitBounds(coordinates: [number, number][], options: IFitBoundsOptions = {}): Promise<void> {
    if (coordinates.length) {
      return new Promise(resolve => {
        const bounds = coordinates.reduce(
          (bds, coord) => bds.extend(coord),
          new mapboxgl.LngLatBounds(coordinates[0], coordinates[0])
        );
        this.map?.fitBounds(bounds, {
          padding: options.padding || 0,
          maxZoom: options.maxZoom || this.options.maxZoom,
        });
        this.map?.once('moveend', resolve);
      });
    }
  }

  public async flyTo(center: [number, number], options?: IFitBoundsOptions): Promise<void> {
    return this.fitBounds([center], options);
  }

  public addMarker(marker: IMarker): any {
    if (this.loading) {
      return this.ready.pipe(take(1)).subscribe(() => this.addMarker(marker));
    }
    if (!marker.type) {
      marker.type = 'text';
    }
    this.data.push(merge({ id: this.data.length }, cloneDeep(marker)));
    this.updateSource();
  }

  public setMarkers(markers: IMarker[]): any {
    if (this.loading) {
      return this.ready.pipe(take(1)).subscribe(() => this.setMarkers(markers));
    }
    each(markers, m => {
      if (!m.type) {
        m.type = 'text';
      }
    });
    each(filter(this.data, { type: 'default' }), (d: IDefaultMarker) => d.marker.remove());
    this.data = map(cloneDeep(markers), (m, i) => merge({ id: i }, m));
    this.updateSource();
  }

  public removeMarkers(): any {
    if (this.loading) {
      return this.ready.pipe(take(1)).subscribe(() => this.removeMarkers());
    }
    each(filter(this.data, { type: 'default' }), (d: IDefaultMarker) => d.marker.remove());
    this.data = [];
    this.updateSource();
  }

  inview($event: any): void {
    if ($event.visible && !this.map) {
      this.initMap();
    }
  }

  private initMap(): void {
    mapboxgl.accessToken = environment.mapbox;
    this.map = new mapboxgl.Map({
      container: `map-${this.id}`,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: this.options.center,
      zoom: this.options.zoom,
      minZoom: this.options.minZoom,
      maxZoom: this.options.maxZoom,
      maxBounds: this.options.maxBounds,
      attributionControl: false,
    });
    this.map.on('moveend', event => this.movedEmitter.emit(event));

    this.enabledControls();

    this.map.on('load', async () => {
      this.map.resize();
      this.setSource();
      this.updateSource();
      this.map.on('render', () => {
        if (this.map.getSource('markers') && this.map.isSourceLoaded('markers')) {
          this.updateMarkers();
        }
      });
      this.loading = false;
      this.ready.emit(this);
    });
  }

  private enabledControls(): void {
    if (this.options.enableNavControl) {
      this.navigationControl = new NavigationControl();
      this.map.addControl(this.navigationControl);
    } else if (this.navigationControl) {
      this.map.removeControl(this.navigationControl);
      this.navigationControl = null;
    }
    if (this.options.disableKeyboard) {
      this.map.keyboard.disable();
    } else {
      this.map.keyboard.enable();
    }
    if (this.options.disableDrag) {
      this.map.dragPan.disable();
      this.map.dragRotate.disable();
    } else {
      this.map.dragPan.enable();
      this.map.dragRotate.enable();
    }
    if (this.options.disableZoom) {
      this.map.touchZoomRotate.disableRotation();
      this.map.boxZoom.disable();
      this.map.doubleClickZoom.disable();
    } else {
      this.map.touchZoomRotate.enableRotation();
      this.map.boxZoom.enable();
      this.map.doubleClickZoom.enable();
    }
    if (this.options.disableZoom || this.options.zoomWithCtrl) {
      this.map.scrollZoom.disable();
    } else {
      this.map.scrollZoom.enable();
    }
  }

  private setSource(): void {
    if (this.map.getSource('markers')) {
      this.map.removeLayer('marker_circle');
      this.map.removeLayer('marker_label');
      this.map.removeSource('markers');
    }
    const clusterProperties = this.options.clusterProperties
      ? Object.keys(this.options.clusterProperties).reduce(
          (pv, cv) => ((pv[cv] = this.options.clusterProperties[cv].merge), pv),
          {}
        )
      : {};
    this.map.addSource('markers', {
      type: 'geojson',
      data: { type: 'FeatureCollection', features: [] },
      cluster: this.options.cluster,
      clusterMaxZoom: this.options.clusterMaxZoom || this.options.maxZoom,
      clusterRadius: this.options.clusterRadius,
      clusterProperties: merge(clusterProperties, {
        ids: ['concat', ['concat', ['get', 'id'], '#']],
      }),
    });
    this.addLayers();
  }

  private addLayers(): void {
    this.map.addLayer({
      id: 'marker_circle',
      type: 'circle',
      source: 'markers',
      filter: ['all', ['!=', 'cluster', true], ['has', '__marker_type']],
      paint: {
        'circle-color': ['get', '__marker_color'],
        'circle-radius': ['get', '__marker_radius'],
      },
    });

    this.map.addLayer({
      id: 'marker_inner-circle',
      type: 'circle',
      source: 'markers',
      filter: ['all', ['!=', 'cluster', true], ['has', '__marker_type']],
      paint: {
        'circle-color': 'white',
        'circle-radius': ['*', ['get', '__marker_radius'], 0.6],
      },
    });

    this.map.addLayer({
      id: 'marker_label',
      type: 'symbol',
      source: 'markers',
      filter: ['all', ['!=', 'cluster', true], ['has', '__marker_type']],
      layout: {
        'text-field': ['get', '__marker_text'],
        'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
        'text-size': ['get', '__marker_text-size'],
      },
      paint: {
        'text-color': ['get', '__marker_text-color'],
      },
    });

    if (this.options.popup) {
      this.map.on('mouseenter', 'marker_circle', () => (this.map.getCanvas().style.cursor = 'pointer'));
      this.map.on('mouseleave', 'marker_circle', () => (this.map.getCanvas().style.cursor = ''));
      this.map.on('click', 'marker_circle', (e: any) => {
        const coords = e.features[0].geometry.coordinates.slice();
        const ids = e.features.map(f => f.properties.id);
        const html = this.options.popup(this.data.filter(d => ids.includes(d.id)));
        if (this.markerPopups) {
          this.markerPopups.remove();
        }
        this.markerPopups = new Popup().setLngLat(coords).setHTML(html).addTo(this.map);
      });
    }
  }

  private updateSource(): void {
    const source: GeoJSONSource = this.map.getSource('markers') as GeoJSONSource;
    if (!source) return;
    const data: any = {
      type: 'FeatureCollection',
      features: [],
    };
    each(this.data, d => {
      if (d.type === 'text') {
        data.features.push({
          type: 'Feature',
          properties: merge(cloneDeep(d.properties) || {}, {
            id: d.id,
            __marker_type: d.type,
            __marker_text: d.text,
            __marker_color: d.color || '#21698E',
            __marker_radius: d.radius || 16,
            '__marker_text-color': d.textColor || '#000000',
            '__marker_text-size': d.textSize || 14,
          }),
          geometry: {
            type: 'Point',
            coordinates: d.position,
          },
        });
      } else if (d.type == 'default') {
        d.marker = new mapboxgl.Marker({ color: d.color || '#21698E' }).setLngLat(d.position).addTo(this.map);
      }
    });
    source.setData(data);
  }

  private updateMarkers(): void {
    if (!this.options.cluster) {
      return;
    }
    const newMarkers = {};
    const features = this.map.querySourceFeatures('markers');
    features.forEach((feature: any) => {
      const properties = feature.properties;
      if (!properties.cluster) {
        return;
      }
      const coords = feature.geometry.coordinates;
      const id = properties.cluster_id;
      let marker = this.markers[id];
      if (!marker) {
        const el = this.createDonutChart(properties);
        el.addEventListener('click', (e: MouseEvent) => this.markerClickHandler(e, feature));
        marker = this.markers[id] = new mapboxgl.Marker({
          element: el,
        }).setLngLat(coords);
      }
      newMarkers[id] = marker;
      if (!this.markersOnScreen[id]) {
        marker.addTo(this.map);
      }
    });
    for (const id in this.markersOnScreen) {
      if (!newMarkers[id]) {
        this.markersOnScreen[id].remove();
      }
    }
    this.markersOnScreen = newMarkers;
  }

  private createDonutChart(properties: { [status: string]: number }): ChildNode {
    const counts = Object.keys(this.options.clusterProperties).map(k => ({
      color: this.options.clusterProperties[k].color || '#21698E',
      value: properties[k],
    }));
    const offsets = [];
    let total = 0;
    counts.forEach(c => (offsets.push(total), (total += c.value)));
    const fontSize = total >= 1000 ? 16 : 14;
    const r = total >= 1000 ? 34 : total >= 100 ? 25 : total >= 10 ? 20 : 16;
    const r0 = Math.round(r * 0.6);
    const w = r * 2;
    const html = `<div style="cursor: pointer;">
      <svg width="${w}" height="${w}" viewbox="0 0 ${w} ${w}" text-anchor="middle" style="font: ${fontSize}px sans-serif; display: block">
        ${counts
          .map((c, i) =>
            this.donutSegment(
              total ? offsets[i] / total : 0,
              total ? (offsets[i] + c.value) / total : 0,
              r,
              r0,
              c.color
            )
          )
          .join('\n')}
        <circle cx="${r}" cy="${r}" r="${r0}" fill="white" />
        <text dominant-baseline="central" transform="translate(${r}, ${r})"> ${total.toLocaleString()}</text>
      </svg>
    </div>`;
    const el = document.createElement('div');
    el.innerHTML = html;
    return el.firstChild;
  }

  private donutSegment(start, end, r, r0, color): string {
    if (end - start === 1) {
      end -= 0.00001;
    }
    const a0 = 2 * Math.PI * (start - 0.25);
    const a1 = 2 * Math.PI * (end - 0.25);
    const x0 = Math.cos(a0);
    const y0 = Math.sin(a0);
    const x1 = Math.cos(a1);
    const y1 = Math.sin(a1);
    const largeArc = end - start > 0.5 ? 1 : 0;
    return `<path
      d="M ${r + r0 * x0} ${r + r0 * y0} L ${r + r * x0} ${r + r * y0} A ${r} ${r} 0 ${largeArc} 1 ${r + r * x1} ${
      r + r * y1
    } L ${r + r0 * x1} ${r + r0 * y1} A ${r0} ${r0} 0 ${largeArc} 0 ${r + r0 * x0} ${r + r0 * y0}"
      fill="${color}"
    />`;
  }

  private markerClickHandler(e: MouseEvent, feature: any): void {
    const properties = feature.properties;
    const coords = feature.geometry.coordinates;
    const id = properties.cluster_id;
    if (this.options.popup && this.map.getZoom() === this.map.getMaxZoom()) {
      const ids = properties.ids.split('#');
      const html = this.options.popup(this.data.filter(d => ids.includes(d.id.toString())));
      if (this.markerPopups) {
        this.markerPopups.remove();
      }
      this.markerPopups = new Popup().setLngLat(coords).setHTML(html).addTo(this.map);
      e.stopPropagation();
    } else {
      (this.map.getSource('markers') as GeoJSONSource).getClusterExpansionZoom(id, (err, zoom) => {
        if (err) {
          throw err;
        }
        this.map.easeTo({ center: coords, zoom });
      });
    }
  }
}
