import { propertyMap } from 'model-mapper';
import { Moment } from 'moment';

export enum InteractiveSchemaKind {
  SVG = 'svg',
  GLTF = 'gltf'
}

export class InteractiveSchema {

  @propertyMap()
  public kind: InteractiveSchemaKind;

  @propertyMap()
  public filename: string;

  @propertyMap()
  public path: string;

  @propertyMap()
  public size: number;

  @propertyMap({ type: 'Moment' })
  public date: Moment;

  @propertyMap()
  public userId: string;

}
