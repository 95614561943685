<app-page-tracking *ngIf="tracking" [origin]="tracking.origin" [page]="tracking.page" [id]="tracking.id">
</app-page-tracking>

<ion-header>
  <ion-toolbar color="primary">
    <ion-title>
      <ion-label *ngIf="!robienCompliant"><span translate>observations</span> équipement {{reference}}</ion-label>
      <ion-label *ngIf="robienCompliant"><span translate>observations-nc-robien</span> équipement {{reference}}</ion-label>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-virtual-scroll *ngIf="data" @fadeIn [items]="data" approxItemHeight="70px">
    <ion-item *virtualItem="let d" lines="none">
      <div fxLayout="column" [style.width.%]="100">
        <div class="header" fxLayoutAlign="start center">
          <ion-label class="header-date">{{d.firstDateOfEmission?.format('L')}}</ion-label>
          <ion-label class="header-point ion-text-wrap">{{d.verificationPoint}}</ion-label>
        </div>
        <ion-label class="content ion-text-wrap">{{d.observation}}</ion-label>
      </div>
    </ion-item>
  </ion-virtual-scroll>
  <sesio-loader *ngIf="!data" @fadeOut></sesio-loader>
</ion-content>
