import { Component, Input, OnDestroy } from '@angular/core';
import { v4 } from 'uuid';
import {
  ITrackingOrigin,
  UserTrackingService,
} from '../../../services/user-tracking.service';

@Component({
  selector: 'app-page-tracking',
  templateUrl: './page-tracking.component.html',
})
export class PageTrackingComponent implements OnDestroy {
  @Input()
  public origin: ITrackingOrigin;

  @Input()
  public page: string;

  @Input()
  public id: string;

  private uuid: string;

  constructor(private userTrackingService: UserTrackingService) {}

  public track(visible: { visible: boolean }): void {
    if (this.uuid || !visible) {
      return;
    }
    this.uuid = v4();
    this.userTrackingService
      .openPage(
        this.page,
        this.id,
        this.uuid,
        this.origin || ITrackingOrigin.WEB
      )
      .toPromise();
  }

  ngOnDestroy(): void {
    this.userTrackingService.closePage(this.uuid).toPromise();
  }
}
