import {
  AfterViewInit,
  Component,
  ContentChild,
  ContentChildren,
  EventEmitter,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import moment, { Moment } from 'moment';
import { debounceTime, filter, tap } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { ChartComponent } from '../chart/chart.component';

@Component({
  selector: 'sesio-chart-range-selector',
  templateUrl: './chart-range-selector.component.html',
  styleUrls: ['./chart-range-selector.component.scss'],
})
export class ChartRangeSelectorComponent implements OnInit, AfterViewInit {
  max = moment();
  public zooms = [
    moment().subtract(2, 'year').year(),
    moment().subtract(1, 'year').year(),
    moment().year(),
    '1-year',
    '6-months',
    '3-months',
    '1-month',
    '1-week',
    'yesterday',
  ];
  public selectedZoom: string = '1-month';
  public range = new UntypedFormGroup({
    start: new UntypedFormControl(
      moment().subtract(1, 'month').add(1, 'day').startOf('day')
    ),
    end: new UntypedFormControl(moment().endOf('day')),
  });

  @Output('changed')
  private changedEmitter = new EventEmitter<{ start: Moment; end: Moment }>();

  @ContentChildren('rangeChart', { descendants: true })
  private charts: QueryList<{ setExtremes(start: Moment, end: Moment): void }>;

  private subsink = new SubSink();

  constructor() {}

  ngOnInit() {
    let previousValue = this.range.value;
    this.changedEmitter.emit({
      start: previousValue.start?.startOf('day'),
      end: previousValue.end?.endOf('day'),
    });
    this.subsink.add(
      this.range.valueChanges
        .pipe(
          filter((value) => {
            if (
              previousValue?.start?.valueOf() === value?.start?.valueOf() &&
              previousValue?.end?.valueOf() === value?.end?.valueOf()
            ) {
              return false;
            }
            previousValue = value;
            return true;
          }),
          tap(() => (this.selectedZoom = null)),
          debounceTime(300),
          tap((value) => {
            const extreme = {
              start: value.start?.startOf('day'),
              end: value.end?.endOf('day'),
            };
            this.charts?.forEach((chart) =>
              typeof chart.setExtremes === 'function'
                ? chart.setExtremes(extreme.start, extreme.end)
                : null
            );
            this.changedEmitter.emit(extreme);
          })
        )
        .subscribe()
    );
  }

  ngAfterViewInit(): void {
    const value = this.range.value;
    this.charts?.forEach((chart) =>
      typeof chart.setExtremes === 'function'
        ? chart.setExtremes(
            value.start?.startOf('day'),
            value.end?.endOf('day')
          )
        : null
    );
  }

  changeZoom(zoom): void {
    let rangeValue: { start: Moment; end: Moment };
    switch (zoom) {
      case moment().subtract(2, 'year').year():
        rangeValue = {
          start: moment().subtract(2, 'year').startOf('year'),
          end: moment().subtract(2, 'year').endOf('year'),
        };
        break;
      case moment().subtract(1, 'year').year():
        rangeValue = {
          start: moment().subtract(1, 'year').startOf('year'),
          end: moment().subtract(1, 'year').endOf('year'),
        };
        break;
      case moment().year():
        rangeValue = {
          start: moment().startOf('year'),
          end: moment().endOf('day'),
        };
        break;
      case 'yesterday':
        rangeValue = {
          start: moment().subtract(1, 'day').startOf('day'),
          end: moment().subtract(1, 'day').endOf('day'),
        };
        break;
      case '1-week':
        rangeValue = {
          start: moment().subtract(1, 'week').add(1, 'day').startOf('day'),
          end: moment().endOf('day'),
        };
        break;
      case '1-month':
        rangeValue = {
          start: moment().subtract(1, 'month').startOf('day'),
          end: moment().endOf('day'),
        };
        break;
      case '3-months':
        rangeValue = {
          start: moment().subtract(3, 'month').startOf('day'),
          end: moment().endOf('day'),
        };
        break;
      case '6-months':
        rangeValue = {
          start: moment().subtract(6, 'month').startOf('day'),
          end: moment().endOf('day'),
        };
        break;
      case '1-year':
        rangeValue = {
          start: moment().subtract(1, 'year').startOf('day'),
          end: moment().endOf('day'),
        };
        break;
      default:
        return;
    }
    this.range.setValue(rangeValue);
    this.selectedZoom = zoom;
  }
}
