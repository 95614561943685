/** @format */

import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ModelMapper } from 'model-mapper';
import {
  AggregationValue,
  EquipmentAvailabilityService as SesioEquipmentAvailabilityService,
  EquipmentKind,
} from 'sesio-lib';
import { buildHeaderSearchFilter, IHeaderSearch } from '../_classes/header-search';
import { Moment } from 'moment';
import { isNil } from 'lodash-es';

// TODO add filter on qualification
export interface IFilter extends IHeaderSearch {
  equipmentId?: string;
  families?: string[];
  contractorIds?: string[];
  start?: Moment;
  end?: Moment;
  equipmentKind?: EquipmentKind;
  signatureSettingName?: string;
  isTertiary?: boolean;
  supervised?:boolean
}

@Injectable({
  providedIn: 'root',
})
export class EquipmentAvailabilityService extends SesioEquipmentAvailabilityService {
  public getCount(search: IFilter, version?:string): Observable<{ count: number }> {
    return this.http.post<any>(
      `${this.config.environment.apiUrl}/equipment-availabilities${version || ''}/get-count`,
      this.buildSearchFilter(search)
    );
  }

  private buildSearchFilter(search: IFilter): any {
    const filter = buildHeaderSearchFilter(search);
    if (search?.equipmentId) filter.equipmentId = search.equipmentId;
    if (search?.families) filter.families = search.families;
    if (search?.contractorIds) filter.contractorIds = search.contractorIds;
    if (search.start) filter.start = search.start.utc(true).toISOString();
    if (search.end) filter.end = search.end.utc(true).toISOString();
    if (search.equipmentKind) filter.equipmentKindId = search.equipmentKind.id;
    if (search?.signatureSettingName) filter.signatureSettingName = search.signatureSettingName;
    if (search?.isTertiary === true) filter.isTertiary = search.isTertiary;
    if (!isNil(search?.supervised)) filter.supervised = search.supervised;
    return filter;
  }
}
