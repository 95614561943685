/** @format */

import {AfterViewInit, ChangeDetectorRef, Component, Injector, Input, forwardRef} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NgControl,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import {get, isNil, filter as lfilter, map as lmap} from 'lodash-es';
import {Observable} from 'rxjs';
import {Contractor, ContractorService, EmbededOrganizationalUnit, Family, fade} from 'sesio-lib';

@Component({
  selector: 'app-contractor-select',
  templateUrl: './contractor-select.component.html',
  styleUrls: ['./contractor-select.component.scss'],
  animations: [fade],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ContractorSelectComponent),
    },
  ],
})
export class ContractorSelectComponent implements AfterViewInit, ControlValueAccessor {
  @Input()
  families: Family[];

  @Input()
  categories: string[];

  @Input('required')
  public set setRequired(isRequired: boolean) {
    this.isRequired = isRequired === false ? false : true;
    if (this.control) {
      this.setValidators();
    }
  }
  public isRequired = false;

  @Input('value')
  public set setValue(value: EmbededOrganizationalUnit) {
    this.value = value;
    if (this.control) this.control.setValue(value);
  }
  public value: EmbededOrganizationalUnit;

  @Input('disabled')
  public set setDisabled(isDisabled: boolean) {
    this.setDisabledState(isDisabled !== false);
  }
  public isDisabled: boolean;

  @Input('multiple')
  public set setMultiple(multiple: boolean) {
    this.multiple = multiple !== false;
  }
  multiple = false;

  @Input()
  label: string;

  ready = false;
  loading = false;
  contractors: Observable<Contractor[]>;
  control: UntypedFormControl;

  compareWithEntity = (o1: any, o2: any) => o1?.id === o2?.id;

  public onChange = (contractor: Contractor) => {};

  public onTouched = () => {};

  private validateOption = (control: AbstractControl): {[key: string]: any} | null => {
    let forbidden;
    if (this.multiple) {
      forbidden =
        control.value &&
        (!Array.isArray(control.value) ||
          get(
            lfilter(lmap(control.value, 'id'), v => !!v),
            'length',
            0
          ) !== get(control.value, 'length', 0));
    } else forbidden = control.value && isNil(control.value.id);
    return forbidden ? {invalidOption: {value: control.value}} : null;
  };

  constructor(
    private injector: Injector,
    private changeDetectorRef: ChangeDetectorRef,
    private contractorService: ContractorService
  ) {}

  writeValue(value: any): void {}

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  ngAfterViewInit(): void {
    const ngControl: NgControl = this.injector.get(NgControl, null);
    if (ngControl) {
      this.control = ngControl.control as UntypedFormControl;
    } else {
      this.control = new UntypedFormControl({
        value: this.value,
        disabled: this.isDisabled,
      });
    }
    if (this.control.hasValidator(Validators.required)) this.isRequired = true;
    this.setValidators();
    this.loadContractors();
    this.ready = true;
    this.changeDetectorRef.detectChanges();
  }

  private setValidators() {
    const validators = [];
    if (this.isRequired) validators.push(Validators.required);
    validators.push(this.validateOption);
    this.control.setValidators(validators);
    this.control.updateValueAndValidity();
  }

  private async loadContractors() {
    this.contractors = await this.contractorService.list({
      families: this.families,
      categories: this.categories,
      fields: ['families', 'name'],
    });
  }
}
