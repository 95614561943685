import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AggregationValue } from '../classes/aggregation';
import { Granularity } from './sensor-data.service';
import { Moment } from 'moment';
import { IChartData } from '../modules/chart/chart.interface';
import { TranslateService } from '@ngx-translate/core';
import { highchartsOptions } from '../constants/highcharts';
import { padStart, sortBy } from 'lodash-es';
import moment from 'moment';

export type Count = (
  op: 'sum' | 'avg',
  granularity: Granularity,
  match?: any,
  start?: moment.Moment,
  end?: moment.Moment
) => Observable<AggregationValue[]>;

@Injectable({
  providedIn: 'root',
})
export class DrilldownService {
  private highchartsOptions: Highcharts.Options;

  constructor(private translate: TranslateService) {
    this.highchartsOptions = highchartsOptions[this.translate.getDefaultLang()];
  }

  public async getDrilldown(
    count: Count,
    op: 'sum' | 'avg',
    match: any,
    granularity: Granularity,
    date: Moment,
    max = 'minute'
  ): Promise<IChartData[]> {
    let start: Moment;
    let end: Moment;
    let data: AggregationValue[];
    let res: IChartData[];

    switch (granularity) {
      case 'minute':
        start = date.clone().startOf('hour');
        end = start.clone().endOf('hour');
        data = await count(op, granularity, match, start, end).toPromise();
        res = [];
        for (let i = 0; i < 60; ++i) {
          const md: any = data.find(
            (d: any) =>
              parseInt(d.name.substring(d.name.lastIndexOf('-') + 1), 10) === i
          );
          res.push({
            name: `${this.pad(i)}m`,
            y: md ? md.value : start.set('minute', i).isAfter() ? null : 0,
          });
        }
        break;

      case 'hour':
        start = date.clone().startOf('date');
        end = start.clone().endOf('date');
        data = await count(op, granularity, match, start, end).toPromise();
        res = [];
        for (let i = 0; i < 24; ++i) {
          const md: any = data.find(
            (d) =>
              parseInt(d.name.substring(d.name.lastIndexOf('-') + 1), 10) === i
          );
          res.push({
            name: `${this.pad(i)}h`,
            y: md ? md.value : start.set('hour', i).isAfter() ? null : 0,
            drilldown: max === granularity ? undefined : `${this.pad(i)}h`,
            asyncDrilldown:
              max === granularity
                ? undefined
                : () =>
                    this.getDrilldown(
                      count,
                      op,
                      match,
                      'minute',
                      date.set('hour', i),
                      max
                    ),
          });
        }
        break;

      case 'day':
        start = date.clone().startOf('month');
        end = start.clone().endOf('month');
        data = await count(op, granularity, match, start, end).toPromise();
        const nbDays = end.diff(start, 'day');
        res = [];
        for (let i = 0; i <= nbDays; ++i) {
          const md: any = data.find(
            (d) =>
              parseInt(d.name.substring(d.name.lastIndexOf('-') + 1), 10) ===
              i + 1
          );
          res.push({
            name: `${this.pad(i + 1)}`,
            y: md
              ? md.value
              : start.clone().add(i, 'day').isSameOrAfter(moment().startOf('day'))
              ? null
              : 0,
            drilldown: max === granularity ? undefined : `${this.pad(i + 1)}`,
            asyncDrilldown:
              max === granularity
                ? undefined
                : () =>
                    this.getDrilldown(
                      count,
                      op,
                      match,
                      'hour',
                      date.clone().set('date', i + 1),
                      max
                    ),
          });
        }
        break;

      case 'month':
        start = date.clone().startOf('year');
        end = start.clone().endOf('year');
        data = await count(op, granularity, match, start, end).toPromise();
        res = this.highchartsOptions.lang.months.map((m, i) => {
          const md: any = data.find(
            (d) =>
              parseInt(d.name.substring(d.name.lastIndexOf('-') + 1), 10) ===
              i + 1
          );
          return {
            name: m,
            y: md ? md.value : start.set('month', i).isAfter() ? null : 0,
            drilldown: max === granularity ? undefined : m,
            asyncDrilldown:
              max === granularity
                ? undefined
                : () =>
                    this.getDrilldown(
                      count,
                      op,
                      match,
                      'day',
                      date.set('month', i),
                      max
                    ),
          };
        });
        break;

      default:
        data = await count(op, 'year', match).toPromise();
        res = sortBy(data, ['name']).map((yd: AggregationValue) => ({
          name: yd.name,
          y: yd.value,
          drilldown: max === granularity ? undefined : yd.name,
          asyncDrilldown:
            max === granularity
              ? undefined
              : () =>
                  this.getDrilldown(
                    count,
                    op,
                    match,
                    'month',
                    date.set('year', parseInt(yd.name, 10)),
                    max
                  ),
        }));
        break;
    }

    return res;
  }

  private pad(i: string | number): string {
    return padStart(i.toString(), 2, '0');
  }
}
