import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LibConfigService, LibConfig } from '../config.service';
import { v4 } from 'uuid';
import { get, merge, set, keys, isNil, each } from 'lodash-es';

export enum ITrackingOrigin {
  WEB = 'web',
  MOBILE = 'mobile',
  API = 'api',
  OTHER = 'other',
}

export enum ITrackingEvent {
  PAGE = 'page',
}

export interface ILoggedInData {
  _id: string;
  firstname: string;
  lastname: string;
  position: string;
  count: number;
  data: {
    date: Date;
    origin: string;
  }[];
}

export interface IVisitedPage {
  count: number;
  name: string;
  origin: ITrackingOrigin;
  duration: {
    avg: number;
    min: number;
    max: number;
  };
}

export interface IPageTrackingInfo {
  origin: ITrackingOrigin;
  page: string;
  id?: string;
}

@Injectable({
  providedIn: 'root',
})
export class UserTrackingService {
  constructor(
    @Inject(LibConfigService) protected config: LibConfig,
    protected http: HttpClient
  ) {}

  public openPage(
    page: string,
    id: string,
    uuid: string,
    origin: ITrackingOrigin
  ): Observable<any> {
    return this.http.post<any>(
      `${this.config.environment.apiUrl}/user-tracking/open-page`,
      {
        origin,
        uuid,
        page,
        id,
      }
    );
  }

  public closePage(uuid: string): Observable<boolean> {
    return this.http.post<any>(
      `${this.config.environment.apiUrl}/user-tracking/close-page`,
      { uuid }
    );
  }

  public getLoggedInData(params: {
    search: string;
    pageIndex: number;
    pageSize: number;
    sortColumn: string;
    sortDirection: 'asc' | 'desc' | '';
  }): Observable<{ data: any[]; total: number }> {
    each(keys(params), (key: string) => {
      if (isNil(params[key])) delete params[key];
    });
    return this.http.get<any>(
      `${this.config.environment.apiUrl}/user-tracking/logged-in-data`,
      { params }
    );
  }

  public getUserData(userId: string): Observable<any> {
    return this.http.get<any>(
      `${this.config.environment.apiUrl}/user-tracking/user-data`,
      { params: { userId } }
    );
  }

  public getConnectionData(): Observable<
    {
      _id: string;
      data: { count: number; origin: string }[];
    }[]
  > {
    return this.http.get<any[]>(
      `${this.config.environment.apiUrl}/user-tracking/connection-data`
    );
  }

  public getVisitedPageData(): Observable<IVisitedPage[]> {
    return this.http.get<any[]>(
      `${this.config.environment.apiUrl}/user-tracking/visited-page-data`
    );
  }
}
