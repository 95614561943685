import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { StarRateComponent } from './star-rate.component';



@NgModule({
  declarations: [StarRateComponent],
  imports: [
    CommonModule,
    FlexLayoutModule
  ],
  exports: [StarRateComponent]
})
export class StarRateModule { }
