import { propertyMap } from 'model-mapper';
import { OrganizationalUnit } from './organizational-unit';

export class Agency extends OrganizationalUnit {

  @propertyMap({ source: 'metadata.map' })
  public map: {
    source: string;
    marker: number[];
    color: string;
  };

}
