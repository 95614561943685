<mat-tab-group [selectedIndex]="tab">
  <mat-tab disabled>
    <ng-template mat-tab-label><span [matTooltip]="path">{{assetReference}}</span></ng-template>
  </mat-tab>
  <mat-tab *ngIf="historique" label="Historique V4">
    <div class="container">
      <ng-template matTabContent>
        <app-datagrid [options]="legacyOptions">
          <sesio-loader datagrid-spinner></sesio-loader>
        </app-datagrid>
      </ng-template>
    </div>
  </mat-tab>
  <mat-tab label="Opérations ({{operation}})">
    <div class="container">
      <ng-template matTabContent>
        <app-datagrid [options]="operationOptions">
          <sesio-loader datagrid-spinner></sesio-loader>
        </app-datagrid>
      </ng-template>
    </div>
  </mat-tab>
  <mat-tab label="Évènements ({{event}})">
    <div class="container">
      <app-datagrid [options]="eventOptions">
        <sesio-loader datagrid-spinner></sesio-loader>
      </app-datagrid>
    </div>
  </mat-tab>
  <mat-tab disabled labelClass="push-right close-btn">
    <ng-template mat-tab-label>
      <ion-buttons slot="end">
        <ion-button (click)="dismiss()">
          <ion-icon slot="icon-only" name="close"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ng-template>
  </mat-tab>
</mat-tab-group>