<ion-card>
  <ion-card-header>
    <ion-card-subtitle *ngIf="description">{{description}}</ion-card-subtitle>
    <ion-card-subtitle *ngIf="date">Date de téléchargement : {{date.format('LL')}}</ion-card-subtitle>
    <ion-card-title>{{title}}</ion-card-title>
  </ion-card-header>
  <ion-card-content class="{{display}}">
    <ng-container [ngSwitch]="display">
      <ion-img class="img" *ngSwitchCase="'image'" [src]="path">
      </ion-img>
      <ng-container *ngSwitchCase="'pdf'">
        <ng-container *ngIf="kind !== 'file'">
          <ng2-pdfjs-viewer [pdfSrc]="path" [openFile]="false" [viewBookmark]="false" [locale]="locale">
          </ng2-pdfjs-viewer>
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'office'">
        <ng-container *ngIf="kind !== 'file'">
          <ngx-doc-viewer [url]="path" viewer="office">
          </ngx-doc-viewer>
        </ng-container>
      </ng-container>
      <a *ngSwitchDefault [href]="path" target="_blank">
        <span uppercase translate>download</span>
      </a>
    </ng-container>
  </ion-card-content>
</ion-card>