import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Injector,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  NgControl,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { Moment } from 'moment';
import { SubSink } from 'subsink';

export interface IValue {
  op: '=' | '>' | '>=' | '<' | '<=' | '<>' | '<=>' | '>=<' | '>=<';
  from: Moment;
  to: Moment;
}

@Component({
  selector: 'app-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DateFilterComponent),
    },
  ],
})
export class DateFilterComponent
  implements AfterViewInit, OnDestroy, ControlValueAccessor
{
  @Input()
  appearance: 'legacy' | 'standard' | 'fill' | 'outline' = 'standard';

  @Input('required')
  public set setRequired(isRequired: boolean) {
    this.isRequired = isRequired === false ? false : true;
    if (this.control) this.setValidators();
  }
  public isRequired = false;

  @Input('disabled')
  public set setDisabled(isDisabled: boolean) {
    this.setDisabledState(isDisabled !== false);
  }
  public isDisabled: boolean;

  @Input('label')
  public label: string = 'date';

  private ready = false;
  public control: UntypedFormControl;
  public formGroup: UntypedFormGroup;
  private subsink = new SubSink();

  public onChange = () => {};

  public onTouched = () => {};

  static filter(date: Moment, value: IValue): boolean {
    switch (value?.op) {
      case '=':
        return (
          !value.from || date?.isBetween(value.from, value.from, 'day', '[]')
        );
      case '>':
        return !value.from || date?.isAfter(value.from, 'day');
      case '>=':
        return !value.from || date?.isSameOrAfter(value.from, 'day');
      case '<':
        return !value.from || date?.isBefore(value.from, 'day');
      case '<=':
        return !value.from || date?.isSameOrBefore(value.from, 'day');
      case '<>':
        return (
          !value.from ||
          !value.to ||
          date?.isBetween(value.from, value.to, 'day', '()')
        );
      case '<=>':
        return (
          !value.from ||
          !value.to ||
          date?.isBetween(value.from, value.to, 'day', '[]')
        );
      case '>=<':
        return (
          !value.from ||
          !value.to ||
          date?.isBetween(value.from, value.to, 'day', '[)')
        );
      case '>=<':
        return (
          !value.from ||
          !value.to ||
          date?.isBetween(value.from, value.to, 'day', '(]')
        );
      default:
        return true;
    }
  }

  constructor(
    private injector: Injector,
    private changeDetectorRef: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder
  ) {}

  public filter(date: Moment): boolean {
    return DateFilterComponent.filter(date, this.control?.value);
  }

  writeValue(value: any): void {
    if (this.control?.value !== value) {
      this.control?.setValue(value);
    }
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  ngAfterViewInit(): void {
    const ngControl: NgControl = this.injector.get(NgControl, null);
    if (ngControl) {
      this.control = ngControl.control as UntypedFormControl;
    } else {
      this.control = new UntypedFormControl({
        value: null,
        disabled: this.isDisabled,
      });
    }
    this.setValidators();
    this.formGroup = this.formBuilder.group({ op: ['='], from: [], to: [] });
    this.subsink.add(
      this.formGroup.valueChanges.subscribe((value) =>
        this.control.setValue(value)
      )
    );
    this.ready = true;
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  private setValidators() {
    const validators = [];
    if (this.isRequired) {
      validators.push(Validators.required);
    }
    this.control.setValidators(validators);
    this.control.updateValueAndValidity();
  }
}
