import { propertyMap } from 'model-mapper';
import { Location } from './location';
import { EmbededOrganizationalUnit } from './embeded-organizational-unit';

// Natures
// S => parking
// B => bâtiment
// H => logement
// E => Escalier

export const NatureLabels = {
  S: 'Parking',
  B: 'Bâtiment',
  H: 'Logement',
  E: 'Escalier',
}

export class EsiType {

  @propertyMap()
  public id: string;

  @propertyMap()
  public label: string;

}

export class Esi {

  @propertyMap({ source: '_id' })
  public id: string;

  @propertyMap()
  public code: string;

  @propertyMap()
  public name: number;

  @propertyMap({ type: EsiType })
  public type: EsiType;

  @propertyMap()
  public nature: string;

  @propertyMap({ type: EmbededOrganizationalUnit })
  public organizationalUnit: EmbededOrganizationalUnit;

  @propertyMap({ type: Location })
  public location: Location;

  @propertyMap()
  public metadata: any;

}

export class EmbededEsi {

  @propertyMap({ source: '_id' })
  public id: string;

  @propertyMap()
  public code: string;

  @propertyMap()
  public nature: string;

}
