/** @format */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IDatatableOptions, IDatatableRecords } from '../_components/datagrid/datatable.class';
import { EquipmentManagerService as SesioEquipmentManagerService } from 'sesio-lib';

@Injectable({
  providedIn: 'root',
})
export class EquipmentManagerService extends SesioEquipmentManagerService {
  public datatable(query: IDatatableOptions): Observable<IDatatableRecords<any>> {
    return this.http.post<any>(`${this.config.environment.apiUrl}/equipment-managers/datatable`, query);
  }
}
