<div id="container">
  <ng-container *ngTemplateOutlet="multiple ? multiSelectTmpl : singleSelectTmpl"></ng-container>
</div>

<ng-template #singleSelectTmpl>
  <mat-form-field *ngIf="control" id="group" [class.has-prefix]="control.value?.pathNames">
    <mat-label>Groupe</mat-label>
    <input id="input" autocomplete="off" type="text" aria-label="Group" matInput [formControl]="control" [value]="value"
      [matAutocomplete]="auto" [required]="isRequired">
    <mat-progress-bar *ngIf="loading" @fade mode="buffer" color="primary"></mat-progress-bar>
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayOption">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
        {{option.pathNames.slice(1, option.pathNames.length).join(' > ')}}
      </mat-option>
    </mat-autocomplete>
    <mat-label *ngIf="control.value?.pathNames" matPrefix>
      {{control.value.pathNames.slice(1, 3).join(' > ')}} >&nbsp;
    </mat-label>
  </mat-form-field>
</ng-template>

<ng-template #multiSelectTmpl>
  <mat-form-field *ngIf="control" id="group" [class.has-prefix]="control.value?.pathNames">
    <mat-label>Groupes</mat-label>
    <mat-chip-list #chipList [formControl]="control" [value]="value">
      <mat-chip *ngFor="let v of control.value; index as i" (removed)="remove(i)">
        {{v.pathNames.slice(1, v.pathNames.length).join(' > ')}}
        <button matChipRemove>
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip>
      <input #valueInput [formControl]="valueCtrl" [matAutocomplete]="auto" [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
      <mat-progress-bar *ngIf="loading" @fade mode="buffer" color="primary"></mat-progress-bar>
    </mat-chip-list>
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayOption"
      (optionSelected)="selected($event)">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
        {{option.pathNames.slice(1, option.pathNames.length).join(' > ')}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</ng-template>
