import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import moment from 'moment';
import { Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { TechnicalControl } from '../../classes/technical-control';
import { TechnicalControlService } from '../../services/technical-control.service';
import { fadeIn, fadeOut } from '../../constants/animations';

@Component({
  templateUrl: './technical-control.modal.html',
  styleUrls: ['./technical-control.modal.scss'],
  animations: [
    fadeIn, fadeOut
  ]
})
export class TechnicalControlModal implements OnInit {

  public fields = [
    'deactivationAsked',
    'telemonitoringAttempt',
    'clearPassageWidthDisabledAccessibility',
    'magneticLoopForDeaf',
    'maintenanceQuality',
    'trainingState',
    'switchgearCabinetState',
    'overspeedDescentDeviceState',
    'screenDoorsState',
    'cabinDressingState',
    'picturePresence',
    'ce',
    'lockScreenDoors',
    'devicePreventingSuchActs',
    'deviceProtectingAgainstSlidingDoors',
    'fenceElevatorShaft',
    'cabinParachute',
    'deviceAgainstFall',
    'inspectionManeuverControl',
    'deviceEnablingSafeAccess',
    'lockingSystem',
    'overallReport2010',
    'controlSystemCabinLevelMaintenanceBefore1983',
    'alarmSystemBtwCabin',
    'sufficientResistanceScreenDoors',
    'preventionSystemOverspeed',
    'protectionAgainstUnprotectedConductors',
    'protectionAgainstTrapping',
    'fixedLighting',
    'overallReport2014',
    'controlSystemCabinLevelMaintenanceAfter1983',
    'protectionAgainstOverspeedUp',
    'overallReport2018',
    'overallReportRobien',
    'ceMarking',
    'declarationOfConformity',
    'instructionNotice',
    'conclusion',
    'protectionWalls',
    'servicePanels',
    'footGuards',
    'accessToBowl',
    'lighting15',
    'sheath',
    'generalState',
    'stoppingMechanism',
    'helpMechanism',
    'reclosingDoor',
    'shockAbsorber',
    'lighting26',
    'bowl',
    'guidingElements',
    'guiding',
    'cabinPresenceSignal',
    'displayCabMoving',
    'firemenManeuver',
    'controlUnitsWithIndicator',
    'bearingEquipment',
    'locksElectricalControlEfficiency',
    'electricalCondemnations',
    'emergencyUnlocking',
    'soundLightSignal',
    'componentsGlazing',
    'landingDoors',
    'features',
    'generalState62',
    'fasteners',
    'pulleysSprockets',
    'cylinders',
    'display',
    'suspensionsOrgans',
    'constitutiveElements',
    'emergencyHatches',
    'servicesFaces',
    'cabinWithoutDoor',
    'cabinDoor',
    'lockingMechanism',
    'doorClosingControl',
    'normalLighting',
    'ventilation',
    'display710',
    'emergencyLighting',
    'footGuardElectricalContact',
    'cabin',
    'commandOrgans',
    'stopMechanism',
    'reopeningDoorsButton',
    'emergencyRequestMechanism',
    'cabinCommandTool',
    'stopMechanismCabinRoof',
    'roofInspectionManeuver',
    'balustrade',
    'emergencyRequestTool',
    'cabinRooftop',
    'counterweights',
    'compensationOrgans',
    'counterweightsCompensationOrgans',
    'cabinParachuteForElectricalElevator',
    'counterweightParachute',
    'overspeedLimitator',
    'deviceAgainstOverspeedUp',
    'lockingMechanismForMaintenance',
    'stopLimiterCabin',
    'checkingRuptureDevice',
    'liaisonBody',
    'outOfNormalManeuver',
    'limiterOfStrokeInspection',
    'parachuteAndSpeedLimitator',
    'deviceAgainstDrifting',
    'securityMechanism',
    'accessToPremises',
    'ground',
    'internalAccessMachineRoom',
    'switchDrivingForce',
    'normalEmergencyLightning',
    'localSwitchPulleys',
    'machinePulleyLocations',
    'mechanisms',
    'manualEmergencyManeuver',
    'electricalManeuverRapel',
    'protectionMobileComponents',
    'cabStoppingAccuracy',
    'machine',
    'metalicMassesInterconnections',
    'generalStateConstituents',
    'protectionAgainstDirectContacts',
    'control-electricity',
    'documentaryAspect',
    'various',
    'otherPoints',
    'totalCountObservations',
    'designationVeritas',
    'rvratPresenceVeritas', // boolean
    'ceMarkingVeritas', // boolean
    'ceNumberVeritas',
    'machineryPositionVeritas',
    'machineryAccessVeritas',
    'otherMachineryAccessVeritas',
    'accessToBowlVeritas',
    'hangersVeritas',
    'hangersCountVeritas',
    'diameterStepMmVeritas',
    'levelCountVeritas',
    'serviceCountVeritas',
    'chargeKgVeritas',
    'personCountVeritas',
    'speedMSVeritas',
    'electricDriveVeritas',
    'otherElectricDriveVeritas',
    'hydraulicDriveVeritas',
    'cabinDoorsVeritas',
    'landingDoorsVeritas',
  ];

  @Input()
  public id: string;

  public data: TechnicalControl;

  constructor(
    private modalController: ModalController,
    private translate: TranslateService,
    private technicalControlService: TechnicalControlService
  ) { }

  ngOnInit(): void {
    this.technicalControlService.last(this.id).toPromise()
      .then(data => this.data = data);
  }

  public async dismiss(): Promise<void> {
    await this.modalController.dismiss();
  }

  public formatDate(data: any): string {
    return moment(data).format('L');
  }

  public value(key: string): Observable<any> {
    const val = this.data.metadata[key];
    if (['rvratPresenceVeritas', 'ceMarkingVeritas'].includes(key)) {
      return this.translate.get(val ? 'present' : 'missing');
    }
    return of(val);
  }

}
