/** @format */

import { propertyMap } from 'model-mapper';
import moment, { Duration, Moment } from 'moment';
import { Origin } from './origin';
import { EmbededUser } from './user';

export class StatusHistory {
  @propertyMap()
  public status: any;

  @propertyMap({ type: 'Moment' })
  public start: Moment;

  @propertyMap()
  public origin: Origin;

  @propertyMap({ type: 'Moment' })
  public end?: Moment;

  @propertyMap({ type: EmbededUser })
  public user?: EmbededUser;

  public duration: Duration;

  public afterMapping() {
    if (this.start && this.end) {
      this.duration = moment.duration(this.start.diff(this.end));
    }
  }
}
