/** @format */

import { Pipe, PipeTransform } from '@angular/core';
import { split } from 'lodash-es';

@Pipe({ name: 'split' })
export class SplitPipe implements PipeTransform {
  transform(data: string, splitOn: string = ','): any {
    return split(data, splitOn);
  }
}
