/** @format */

import {Injectable} from '@angular/core';
import {isNil} from 'lodash-es';
import {Moment} from 'moment';
import {Observable} from 'rxjs';
import {
  AggregationValue,
  EquipmentKind,
  SecurityCallQualification,
  EquipmentReportService as SesioEquipmentReportService,
} from 'sesio-lib';
import {IHeaderSearch, buildHeaderSearchFilter} from '../_classes/header-search';
import {IDatatableOptions, IDatatableRecords} from '../_components/datagrid/datatable.class';

// TODO add filter on qualification
export interface IFilter extends IHeaderSearch {
  equipmentId?: string;
  families?: string[];
  contractorIds?: string[];
  start?: Moment;
  end?: Moment;
  equipmentKind?: EquipmentKind;
  signatureSettingName?: string;
  isTertiary?: boolean;
  supervised?: boolean;
  qualifications?: SecurityCallQualification[];
}

@Injectable({
  providedIn: 'root',
})
export class EquipmentReportService extends SesioEquipmentReportService {
  public list(search: IFilter): Observable<any[]> {
    return this.http.post<any[]>(
      `${this.config.environment.apiUrl}/equipment-reports/get-list`,
      this.buildSearchFilter(search)
    );
  }

  public count(search: IFilter): Observable<number> {
    return this.http.post<number>(
      `${this.config.environment.apiUrl}/equipment-reports/count`,
      this.buildSearchFilter(search)
    );
  }

  public listLegacy(params: {equipmentId: string}): Observable<any[]> {
    return this.http.get<any[]>(`${this.config.environment.apiUrl}/equipment-reports/legacy`, {params});
  }

  public listBlockedUsers(params: {equipmentId: string}): Observable<any[]> {
    return this.http.get<any[]>(`${this.config.environment.apiUrl}/equipment-reports/blocked-users`, {params});
  }

  public getChartRangeData(search: IFilter): Observable<AggregationValue[]> {
    return this.http.post<any[]>(
      `${this.config.environment.apiUrl}/equipment-reports/get-chart-range`,
      this.buildSearchFilter(search)
    );
  }

  public getCountByContractor(search: IFilter): Observable<AggregationValue[]> {
    return this.http.post<any[]>(
      `${this.config.environment.apiUrl}/equipment-reports/count-by-contractor`,
      this.buildSearchFilter(search)
    );
  }

  public datatable(options: {equipmentId: string; query: IDatatableOptions}): Observable<IDatatableRecords<any>> {
    return this.http.post<any>(`${this.config.environment.apiUrl}/equipment-reports/datatable`, options);
  }

  public legacyDatatable(options: {equipmentId: string; query: IDatatableOptions}): Observable<IDatatableRecords<any>> {
    return this.http.post<any>(`${this.config.environment.apiUrl}/equipment-reports/datatable-legacy`, options);
  }

  private buildSearchFilter(search: IFilter): any {
    const filter = buildHeaderSearchFilter(search);
    if (search?.equipmentId) filter.equipmentId = search.equipmentId;
    if (search?.families) filter.families = search.families;
    if (search?.contractorIds?.length) filter.contractorIds = search.contractorIds;
    if (search.start) filter.start = search.start.toISOString();
    if (search.end) filter.end = search.end.toISOString();
    if (search.equipmentKind) filter.equipmentKindId = search.equipmentKind.id;
    if (search?.signatureSettingName) filter.signatureSettingName = search.signatureSettingName;
    if (search?.isTertiary === true) filter.isTertiary = search.isTertiary;
    if (!isNil(search?.supervised)) filter.supervised = search.supervised;
    if (search?.qualifications) filter.qualifications = search.qualifications;
    return filter;
  }
}
