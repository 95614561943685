<div mat-dialog-title id="header" fxLayoutAlign="space-between center">
  <span>LES TUTORIELS ISITECH</span>
  <button size="small" mat-button mat-dialog-close [routerLink]="['/isidesk-space']">OUVRIR L'ESPACE AIDE - FAQ ISITECH</button>
</div>

<div id="container" mat-dialog-content fxLayout="row wrap" fxLayoutAlign="space-evenly start">
  <div class="tutorial" *ngFor="let tutorial of data?.tutorials">
    <div class="header" fxLayout="column">
      <span color="primary">{{tutorial.title}}</span>
      <span color="primary">{{tutorial.caption}}</span>
    </div>
    <div class="content" fxLayout="column">
      <vg-player class="size-{{data.tutorials.length}}" *ngIf="tutorial.video" [matTooltip]="tutorial.description">
        <video *ngIf="tutorial.video" #media [vgMedia]="media" id="video-{{tutorial.id}}" preload="none" controls
          crossorigin [attr.poster]="repo + '/' + tutorial.image?.path">
          <source [src]="repo + '/' + tutorial.video.path" [type]="tutorial.video.mimetypes[0]">
          <track *ngIf="tutorial.videoSubtitle" kind="subtitles" label="Français"
            [src]="repo + '/' + tutorial.videoSubtitle.path" srclang="fr" default>
        </video>
      </vg-player>
      <a *ngIf="tutorial.documentation" target="_blank" [href]="repo + '/' + tutorial.documentation.path"
        translate>download-documentation</a>
    </div>
  </div>
</div>

<div id="footer" mat-dialog-actions *ngIf="data.page" fxLayout="row" fxLayoutAlign="space-between center"
  fxLayoutGap="8px">
  <mat-checkbox [checked]="user?.tutorials[data.platform + '.' + data.page] === true" (change)="set($event.checked)">
    <div fxLayout="row wrap">
      <span [style.margin-right.px]="4">Ne plus afficher la fenêtre de tutoriels au démarrage</span>
      <span [style.font-weight]="300">(vous pourrez ouvrir à tous moments les tutoriels depuis l'espace support
        technique accessible dans le menu principal)</span>
    </div>
  </mat-checkbox>
  <button mat-button mat-dialog-close size="small" color="primary">
    FERMER LA FENÊTRE
  </button>
</div>
