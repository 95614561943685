import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { ContractorService } from './services/contractor.service';
import { EquipmentDocumentService } from './services/equipment-document.service';
import { SafePipeModule } from 'safe-pipe';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { InViewportModule } from 'ng-in-viewport';
import { LibConfig, LibConfigService } from './config.service';
import { TechnicalControlService } from './services/technical-control.service';
import { SensorDataService } from './services/sensor-data.service';
import { DrilldownService } from './services/drilldown.service';
import { EquipmentAvailabilityService } from './services/equipment-availability.service';
import { EquipmentFailureService } from './services/equipment-failure.service';


export const translateModule = TranslateModule.forChild();

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    translateModule,
    InViewportModule,
    SafePipeModule,
    FlexLayoutModule,
  ],
})
export class SesioLibModule {

  static forRoot(config: LibConfig): ModuleWithProviders<SesioLibModule> {
    const providers: Provider[] = [
      ContractorService,
      EquipmentDocumentService,
      TechnicalControlService,
      SensorDataService,
      DrilldownService,
      EquipmentAvailabilityService,
      EquipmentFailureService,
      { provide: LibConfigService, useValue: config }
    ];
    return { ngModule: SesioLibModule, providers };
  }

}
