<div mat-dialog-title color="primary">
  <div [innerHTML]="title | translate:titleParams | safe:'html'"></div>
</div>

<div mat-dialog-content>
  <div fxLayout="column" fxLayoutGap="16px">
    <div [innerHTML]="message | translate:messageParams | safe:'html'"></div>
    <mat-form-field *ngIf="askForReason && reasonControl">
      <mat-label translate>{{reasonLabel}}</mat-label>
      <textarea matInput [formControl]="reasonControl"></textarea>
    </mat-form-field>
  </div>
</div>

<div mat-dialog-actions fxLayoutAlign="end center">
  <button mat-button (click)="onDismiss()">
    <mat-label translate>{{noLabel}}</mat-label>
  </button>
  <button mat-raised-button [color]="yesLabelColor" [disabled]="askForReason && reasonControl.invalid"
    (click)="onConfirm()">
    <mat-label translate>{{yesLabel}}</mat-label>
  </button>
</div>