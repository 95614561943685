/** @format */

import { Pipe, PipeTransform } from '@angular/core';
import { sum } from 'lodash-es';

@Pipe({ name: 'average' })
export class AveragePipe implements PipeTransform {
  transform(data: number[]): any {
    return data ? sum(data) / data.length : 0;
  }
}
