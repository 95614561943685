/** @format */

import {Injectable} from '@angular/core';
import {isNil} from 'lodash-es';
import {Moment} from 'moment';
import {Observable} from 'rxjs';
import {AggregationValue, EquipmentKind, EquipmentFailureService as SesioEquipmentFailureService} from 'sesio-lib';
import {IHeaderSearch, buildHeaderSearchFilter} from '../_classes/header-search';

export interface IFailureAggregationValue extends AggregationValue {
  opened: number;
  closed: number;
}

// TODO add filter on qualification
export interface IFilter extends IHeaderSearch {
  equipmentId?: string;
  families?: string[];
  contractorIds?: string[];
  start?: Moment;
  end?: Moment;
  equipmentKind?: EquipmentKind;
  signatureSettingName?: string;
  isTertiary?: boolean;
  supervised?: boolean;
  groupingUnit?: string;
  groupingBinSize?: number;
  new?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class EquipmentFailureService extends SesioEquipmentFailureService {
  public getCount(search: IFilter): Observable<{count: number; avgDuration: number}> {
    return this.http.post<any>(
      `${this.config.environment.apiUrl}/equipment-failures/get-count`,
      this.buildSearchFilter(search)
    );
  }

  public getChartRangeData(search: IFilter): Observable<IFailureAggregationValue[]> {
    return this.http.post<any[]>(
      `${this.config.environment.apiUrl}/equipment-failures/get-all`,
      this.buildSearchFilter(search)
    );
  }

  public getCountByContractor(filter: IFilter): Observable<AggregationValue[]> {
    return super.getCountByContractor(this.buildSearchFilter(filter));
  }

  public byHourOfDayOfWeek(
    filter: IFilter
  ): Observable<{_id: string; day: number; count: number; hours: {_id: string; hour: number; count: number}[]}[]> {
    return super.byHourOfDayOfWeek(this.buildSearchFilter(filter));
  }

  private buildSearchFilter(search: IFilter): any {
    const filter = buildHeaderSearchFilter(search);
    if (search?.equipmentId) filter.equipmentId = search.equipmentId;
    if (search?.families) filter.families = search.families;
    if (search?.contractorIds) filter.contractorIds = search.contractorIds;
    if (search.start) filter.start = search.start.utc(true).toISOString();
    if (search.end) filter.end = search.end.utc(true).toISOString();
    if (search.equipmentKind) filter.equipmentKindId = search.equipmentKind.id;
    if (search?.signatureSettingName) filter.signatureSettingName = search.signatureSettingName;
    if (search?.isTertiary === true) filter.isTertiary = search.isTertiary;
    if (!isNil(search?.supervised)) filter.supervised = search.supervised;
    if (!isNil(search?.groupingUnit)) filter.groupingUnit = search.groupingUnit;
    if (!isNil(search?.groupingBinSize)) filter.groupingBinSize = search.groupingBinSize;
    if (!isNil(search?.new)) filter.new = search.new;
    return filter;
  }
}
