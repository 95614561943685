/** @format */

import { Component, Input, AfterViewInit } from '@angular/core';
import {
  highchartsOptions,
  ContractorService,
  Contractor,
  fadeIn,
  fadeOut,
  IChartData,
  User,
  IPageTrackingInfo,
  EquipmentDocument,
  EquipmentDocumentService,
  EquipmentDocumentKind,
} from 'sesio-lib';
import { TranslateService } from '@ngx-translate/core';
import { padStart } from 'lodash-es';
import moment from 'moment';
import { ModalController } from '@ionic/angular';

@Component({
  templateUrl: './contractor.modal.html',
  styleUrls: ['./contractor.modal.scss'],
  animations: [fadeIn, fadeOut],
})
export class ContractorModal implements AfterViewInit {
  public Today = moment();

  @Input()
  public eqId: string;

  @Input()
  public id: string;

  @Input()
  public name: string;

  @Input()
  public tracking: IPageTrackingInfo;

  public contractor: Contractor;
  public users: User[];
  public documents: EquipmentDocument[];
  public failureRateLabels = {
    column: 'breakdowns-count',
    trend: 'annual-trend',
  };
  public contractorFailureRateData: IChartData[];
  public contractorFailureRateDate = this.Today.format('YYYY');
  public availabilityRateLabels = { column: 'availability-rate' };
  public contractorAvailabilityRateData: IChartData[];
  public contractorAvailabilityRateDate = this.Today.format('YYYY');

  private highchartsOptions: Highcharts.Options;

  constructor(
    private modalController: ModalController,
    private translate: TranslateService,
    private contractorService: ContractorService,
    private equipmentDocumentService: EquipmentDocumentService
  ) {
    this.highchartsOptions = highchartsOptions[this.translate.getDefaultLang()];
  }

  async ngAfterViewInit(): Promise<void> {
    this.contractor = await this.contractorService.get(this.id).toPromise();
    this.users = await this.contractorService.getUsers(this.id).toPromise();
    this.documents = await this.equipmentDocumentService
      .getEquipmentDocuments({
        eqId: this.eqId,
        kind: [EquipmentDocumentKind.BPU, EquipmentDocumentKind.CONTRACT],
      })
      .toPromise();
  }

  public async dismiss(): Promise<void> {
    await this.modalController.dismiss();
  }

  public async loadFailureRateData(year?: string): Promise<void> {
    if (this.contractorFailureRateData && year === this.contractorFailureRateDate) {
      return;
    }
    if (!year) {
      year = this.contractorFailureRateDate;
    } else {
      this.contractorFailureRateDate = year;
    }
    this.contractorFailureRateData = await this.contractorService
      .getFailureByMonth(this.eqId, year)
      .toPromise()
      .then(data => {
        return this.highchartsOptions.lang.months.map((m, i) => {
          const md = data.find(d => parseInt(d.name, 10) === i + 1);
          return {
            name: m,
            y: md ? md.value : moment().isBefore(`${year}-${this.pad(i + 1)}`) ? null : 0,
          };
        });
      });
  }

  public async loadAvailabilityRateData(year?: string): Promise<void> {
    if (this.contractorAvailabilityRateData && year === this.contractorAvailabilityRateDate) {
      return;
    }
    if (!year) {
      year = this.contractorAvailabilityRateDate;
    } else {
      this.contractorAvailabilityRateDate = year;
    }
    this.contractorAvailabilityRateData = await this.contractorService
      .getAvailabilityByMonth(this.eqId, year)
      .toPromise()
      .then(data => {
        return this.highchartsOptions.lang.months.map((m, i) => {
          const md = data.find(d => parseInt(d.name, 10) === i + 1);
          return {
            name: m,
            y: md ? md.value : moment().isBefore(`${year}-${this.pad(i + 1)}`) ? null : 0,
          };
        });
      });
  }

  private pad(i: string | number): string {
    return padStart(i.toString(), 2, '0');
  }
}
