<div fxLayout="row" fxFill *ngIf="!fullscreen">

  <div *ngIf="!!user && !fullscreen" id="sidenav" [@openCloseMenu]="isMenuOpen ? 'open' : 'closed'"
    [style.border-right]="'2px solid var(--ion-color-' + active?.color + ')'">
    <ng-scrollbar visibility="hover">
      <div id="sidenav-menu" fxLayout="column">

        <div id="burger-container" class="menu-item" fxLayout="row" fxLayoutAlign="space-between center">
          <ion-buttons [@openCloseMenuItem]="isMenuOpen ? 'open' : 'closed'">
            <ion-button class="clear-btn" fill="clear" color="primary" routerLink="dashboard">
              <mat-icon slot="icon-only">home</mat-icon>
            </ion-button>
          </ion-buttons>
          <ion-buttons>
            <ion-button id="open-menu-btn" fill="solid" aria-label="ouvrir menu latérale" (click)="toggleMenuOpen()">
              <ion-icon slot="icon-only" *ngIf="!isMenuOpen" src="assets/icon/menu-expand.svg"></ion-icon>
              <ion-icon slot="icon-only" *ngIf="isMenuOpen" src="assets/icon/menu-minimize.svg">
              </ion-icon>
            </ion-button>
          </ion-buttons>
        </div>

        <ng-container *ngFor="let app of apps">
          <ng-container *ngIf="!app.hidden">
            <div *ngIf="app.separator" class="menu-separator" [ngClass]="{'full': isMenuOpen}"></div>
            <ng-template #appTmpl>
              <ion-buttons>
                <ion-button fill="clear" [color]="app.color" [attr.aria-label]="app.name | translate"
                  matTooltipPosition="right" [matTooltip]="app.name | translate">
                  <ion-icon *ngIf="!app.material" slot="icon-only" [name]="!app.src ? app.icon : undefined"
                    [src]="app.src ? app.icon : undefined"></ion-icon>
                  <mat-icon *ngIf="app.material" class="material-icon" slot="icon-only">{{app.icon}}</mat-icon>
                </ion-button>
              </ion-buttons>
              <span class="only-full-menu" [@openCloseMenuItem]="isMenuOpen ? 'open' : 'closed'"
                translate>{{app.name}}</span>
            </ng-template>
            <a *ngIf="!app.extern" class="menu-item" fxLayout="row" fxLayoutAlign="start center"
              [routerLink]="app.access  ? undefined : app.link" routerLinkActive #rla="routerLinkActive"
              (click)="info(app)">
              <ng-container *ngTemplateOutlet="appTmpl"></ng-container>
            </a>
            <a *ngIf="app.extern" class="menu-item" fxLayout="row" fxLayoutAlign="start center" [attr.href]="app.link"
              target="_blank">
              <ng-container *ngTemplateOutlet="appTmpl"></ng-container>
            </a>
          </ng-container>
        </ng-container>

      </div>
    </ng-scrollbar>
  </div>

  <ng-scrollbar id="main-scroll" [class.has-user]="!!user && !fullscreen" [disabled]="!user"
    [@openCloseMenuContent]="(!!user && !fullscreen) ? (isMenuOpen ? 'open' : 'closed') : 'none'">
    <div #sidenavContent id="sidenav-content" fxLayout="column" [class.has-user]="!!user && !fullscreen">

      <mat-toolbar id="app-header" *ngIf="!!user && !fullscreen" color="light" fxLayout="row">
        <div fxLayoutAlign="start center" fxLayoutGap="16px">
          <ng-container *ngFor="let action of actions">
            <button *ngIf="action.visible" class="action" mat-raised-button [matTooltip]="action.name | translate"
              [color]="action.color" (click)="action.click()">
              <div [fxLayoutAlign]="sidenavContent.clientWidth >= 836 ? 'start center' : 'center center'"
                fxLayoutGap="8px">
                <mat-icon>{{action.icon}}</mat-icon>
                <mat-label *ngIf="sidenavContent.clientWidth >= 836" [translate]="action.name"></mat-label>
              </div>
            </button>
          </ng-container>
        </div>

        <button id="user-menu" [class.reduced]="sidenavContent.clientWidth < 986" mat-button
          [matMenuTriggerFor]="userMenu" aria-label="menu compte utilisateur">
          <div id="user-menu-label" fxLayoutAlign="space-between center" fxLayoutGap="8px">
            <ion-text *ngIf="sidenavContent.clientWidth >= 986" color="secondary">{{user?.fullname}}</ion-text>
            <ion-text *ngIf="sidenavContent.clientWidth < 986" color="secondary" uppercase>
              {{user?.firstname?.charAt(0)}}{{user?.lastname?.charAt(0)}}
            </ion-text>
            <ion-icon color="secondary" name="chevron-down-sharp"></ion-icon>
          </div>
        </button>
        <mat-menu #userMenu="matMenu">
          <button mat-menu-item (click)="logout()">
            <mat-icon color="accent">exit_to_app</mat-icon>
            <span translate>logout</span>
          </button>
          <button mat-menu-item (click)="tutorial()">
            <mat-icon color="primary">play_circle_outline</mat-icon>
            <span translate>tutorial</span>
          </button>
        </mat-menu>

        <img *ngIf="sidenavContent.clientWidth >= 910" class="home-logo" height="40" src="/assets/images/logo.png"
          routerLink="dashboard" />
      </mat-toolbar>

      <!-- <router-outlet></router-outlet> -->
      <ng-container *ngTemplateOutlet="routerTmpl"></ng-container>

    </div>
  </ng-scrollbar>
</div>

<ng-container *ngIf="fullscreen">
  <ng-container *ngTemplateOutlet="routerTmpl"></ng-container>
</ng-container>


<ng-template #routerTmpl>
  <router-outlet></router-outlet>
</ng-template>