/** @format */

import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import {MatChipInputEvent} from '@angular/material/chips';
import {TranslateService} from '@ngx-translate/core';
import {
  concat,
  filter,
  find,
  get,
  head,
  isNil,
  keys,
  map as lmap,
  merge,
  omit,
  orderBy,
  padStart,
  reduce,
  remove,
  replace,
  sortBy,
  split,
  trim,
  values,
} from 'lodash-es';
import moment from 'moment';
import {Observable, lastValueFrom, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {
  Contractor,
  ContractorService,
  Equipment,
  EquipmentDocumentService,
  EquipmentKind,
  EquipmentKindCategory,
  EquipmentKindMetadataKind,
  EquipmentManager,
  EquipmentStatus,
  EquipmentStatusColor,
  EquipmentStatusIcon,
  EquipmentTagType,
  Family,
  Metric,
  OrganizationalUnit,
  RIVPEvent,
  RealEstateStructure,
  SignatureStatus,
  fadeOut,
} from 'sesio-lib';
import {SubSink} from 'subsink';
import {environment} from '../../../../environments/environment';
import {EquipmentKindService} from '../../../_services/equipment-kind.service';
import {EquipmentManagerService} from '../../../_services/equipment-manager.service';
import {EquipmentService} from '../../../_services/equipment.service';
import {OrganizationalUnitService} from '../../../_services/organizational-unit.service';
import {RealEstateStructureService} from '../../../_services/real-estate-structure.service';
import {SettingsService} from '../../../_services/settings.service';
import {quillTextModules} from '../../../quill-config';
import {Location} from '../../map/_classes/location';
import {MapComponent} from '../../map/map.component/map.component';

interface IDisplayEquipmentKind extends EquipmentKind {
  categoryLabel: string;
}

@Component({
  selector: 'app-equipment-edit',
  templateUrl: './equipment-edit.component.html',
  styleUrls: ['./equipment-edit.component.scss'],
  animations: [fadeOut],
})
export class EquipmentEditComponent implements OnInit, OnDestroy {
  Family = Family;
  Metric = Metric;
  EquipmentStatusColor = EquipmentStatusColor;
  EquipmentStatusIcon = EquipmentStatusIcon;
  public quillModules = quillTextModules;
  public repo = environment.repoUrl;
  public EquipmentKindMetadataKind = EquipmentKindMetadataKind;
  public EquipmentTagType = EquipmentTagType;
  public signatureStatuses = values(SignatureStatus);
  public rivpEvents = values(RIVPEvent);
  public equipmentStatuses = values(EquipmentStatus);

  @Input()
  public administration: boolean;

  @Input()
  public equipment: Equipment;

  @Input()
  public organizationalUnit: OrganizationalUnit;

  @Input()
  public realEstateStructure: RealEstateStructure;

  @Input()
  public kind: EquipmentKind;

  @Input()
  public allowFiles = true;

  @Input()
  public enableHypervision = true;

  @Input('hide')
  public set setHidden(hide: string | string[] | {[field: string]: boolean}) {
    if (typeof hide === 'string') {
      this.isHidden = reduce(
        lmap(split(hide, ','), h => trim(h)),
        (pv, cv) => ((pv[cv] = true), pv),
        {}
      );
    } else if (Array.isArray(hide)) {
      this.isHidden = reduce(hide, (pv, cv) => ((pv[cv] = true), pv), {});
    } else {
      this.isHidden = hide;
    }
  }
  public isHidden: {[field: string]: boolean} = {status: true};

  @Input('disabled')
  public set setDisabled(disabled: string[]) {
    this.isDisabled = reduce(disabled, (pv, cv) => ((pv[cv] = true), pv), {});
  }
  public isDisabled: {[field: string]: boolean} = {};

  public prefixReference: string;
  public prefixPad = 0;

  @Input()
  public kindsFilter: {
    families: Family[];
    categories: EquipmentKindCategory[];
  } = null;

  public formGroup: UntypedFormGroup;
  public documentFormGroup: UntypedFormGroup = this.formBuilder.group({
    kind: [null, Validators.required],
    file: [null, Validators.required],
    title: [null],
    description: [null],
  });

  public kinds: IDisplayEquipmentKind[];

  public filesDisplayColumn = ['kind', 'filename', 'title', 'description', 'remove'];

  public ready = false;
  @Output()
  public isReady: EventEmitter<boolean> = new EventEmitter(false);
  public visible = false;
  public saved = false;

  public contractors: Contractor[];
  public managers: EquipmentManager[];
  public isRealEstateStructureRequired = false;

  @ViewChild(MapComponent)
  private mapComponentRef: MapComponent;
  location: Location;

  private subsink = new SubSink();

  public displayContractor = (contractor: Contractor) => (contractor ? contractor.name : '');

  public compareWithEntity = (o1: any, o2: any) => o1?.id === o2?.id;
  public compareMetadataWithEntity = (o1: any, o2: any) =>
    get(o1, 'id', get(o1, '_id')) === get(o2, 'id', get(o2, '_id'));

  public displayGroup = (group: OrganizationalUnit) => (group ? group.name : '');

  private validateEntity = (control: AbstractControl): {[key: string]: any} | null => {
    const forbidden = control.value && isNil(control.value.id);
    return forbidden ? {invalidGroup: {value: control.value}} : null;
  };

  private validateReference = (control: AbstractControl): Observable<ValidationErrors | null> => {
    return !control.dirty || !this.visible
      ? of(null)
      : this.equipmentService
          .referenceExists(this.buildReference(control.value))
          .pipe(map(res => (res?.length > 0 && res !== this.equipment?.id ? {referenceExists: true} : null)));
  };

  constructor(
    private translate: TranslateService,
    private formBuilder: UntypedFormBuilder,
    private controlContainer: ControlContainer,
    private equipmentKindService: EquipmentKindService,
    private equipmentService: EquipmentService,
    private contractorService: ContractorService,
    private equipmentManagerService: EquipmentManagerService,
    private organizationalUnitService: OrganizationalUnitService,
    private realEstateStructureService: RealEstateStructureService,
    private changeDetectorRef: ChangeDetectorRef,
    private equipmentDocumentService: EquipmentDocumentService,
    private settingsService: SettingsService
  ) {}

  ngOnInit(): void {
    this.formGroup = this.controlContainer.control as UntypedFormGroup;
    this.load();
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  public async save(): Promise<any> {
    const value = this.formGroup.value;
    const contractors = reduce(
      filter(keys(get(value, 'contractors')), key => !!get(value, `contractors.${key}.id`)),
      (pv, cv) => ((pv[cv] = {id: get(value, `contractors.${cv}.id`)}), pv),
      {}
    );
    const data = merge(omit(value, 'contractors', 'files'), {contractors});
    if (this.equipment?.id) {
      await lastValueFrom(
        this.equipmentService.update(
          this.equipment.id,
          merge(omit(data, 'reference'), {
            reference: this.equipment.reference,
          })
        )
      );
    } else {
      this.equipment = await lastValueFrom(this.equipmentService.create(data));
    }
    const files: any[] = this.formGroup.get('files').value;
    await Promise.all(
      concat(
        lmap(
          filter(files, d => d.toDelete && d.id),
          document =>
            this.equipmentDocumentService.removeEquipmentDocument([this.equipment.id], document.id).toPromise()
        ) as Promise<any>[],
        lmap(
          filter(files, d => !d.toDelete && !d.id && d.file),
          async (document, index) =>
            merge(
              document,
              await this.equipmentDocumentService
                .addEquipmentsDocument([this.equipment.id], {
                  file: document.file,
                  family: this.equipment.kind.family,
                  kind: document.kind,
                  title: document.title,
                  description: document.description,
                })
                .toPromise()
            )
        ) as Promise<any>[]
      )
    );
    remove(files, f => f.toDelete === true);
    this.formGroup.get('files').setValue(files);
    this.saved = true;
    return this.equipment;
  }

  public async reset(): Promise<void> {
    this.documentFormGroup.reset();
    this.equipment = null;
    [
      'ref',
      'reference',
      'label',
      'status',
      'comment',
      'location',
      'files',
      'commissioningDate',
      'tagCode',
      'externalReference',
      'linked',
    ].forEach(controlName => this.formGroup.get(controlName)?.reset());
    this.formGroup.get('reference').enable();
    this.formGroup.get('linked').setValue(true);
    this.formGroup.get('ref').setValue(await this.getRef());
  }

  async setVisible(visible: boolean): Promise<void> {
    if (!visible || this.visible) return;
    this.visible = true;
  }

  addDocument() {
    const file: File = head(this.documentFormGroup.value.file._files);
    const value = merge(this.documentFormGroup.value, {
      file,
      filename: file.name,
      toDelete: false,
    });
    const files = this.formGroup.get('files').value;
    files.push(value);
    this.formGroup.get('files').setValue(files);
    this.documentFormGroup.reset();
  }

  addTo(control: AbstractControl, event: MatChipInputEvent) {
    if (event.value) {
      const values: any[] = control.value || [];
      values.push(event.value);
      control.setValue(values);
      event.chipInput!.clear();
    }
  }

  removeFrom(control: AbstractControl, data: string) {
    const values: any[] = control.value || [];
    remove(values, value => value === data);
    control.setValue(values);
  }

  showMarker() {
    if (this.mapComponentRef) {
      this.mapComponentRef.removeMarkers();
      if (this.location?.coordinates) {
        this.mapComponentRef.addMarker({
          type: 'default',
          color: 'var(--ion-color-primary)',
          position: this.location.coordinates,
        });
        this.mapComponentRef.flyTo(this.location.coordinates);
      }
    }
  }

  private async load(): Promise<void> {
    await this.loadEquipment();
    await this.loadOrganizationalUnit();
    await this.loadRealEstateStructure();
    await this.loadKind();
    await this.loadPrefixReference();
    await this.loadManagers();
    await this.loadContractors();
    await this.buildForm();

    this.subsink.add(
      this.formGroup.get('ref').valueChanges.subscribe(value => {
        this.formGroup.get('reference').setValue(this.buildReference(value));
      }),
      this.formGroup.get('organizationalUnit').valueChanges.subscribe({
        next: (value: OrganizationalUnit) => {
          if (!value || value instanceof OrganizationalUnit) {
            this.organizationalUnit = value;
            this.updateReference();
            this.toggleEnable();
            this.toggleRequired();
            this.location = value?.location;
            this.showMarker();
          }
        },
      }),
      this.formGroup.get('realEstateStructure').valueChanges.subscribe({
        next: async (value: RealEstateStructure) => {
          if (!value || value instanceof RealEstateStructure) {
            this.realEstateStructure = value;
            this.formGroup.get('realEstateStructureId').setValue(this.realEstateStructure?.id || null);
            this.updateReference();
            this.location = value?.location || this.formGroup.get('organizationalUnit').value?.location;
            this.showMarker();
          }
        },
      }),
      this.formGroup.get('kind').valueChanges.subscribe({
        next: (value: EquipmentKind) => {
          this.kind = value;
          this.loadContractors();
          this.updateReference();
          this.toggleEnable();
          this.toggleRequired();
          this.loadMetadata();
        },
      }),
      this.formGroup.get('contractors.maintenance').valueChanges.subscribe(value => {
        this.formGroup.get('contractStartDate').setValue(value.marketStartDate);
        this.formGroup.get('contractEndDate').setValue(value.marketEndDate);
      }),
      this.formGroup.get('linked').valueChanges.subscribe(value => {
        if (value === false) {
          this.formGroup.get('supervised').disable();
          this.formGroup.get('supervised').setValue(false);
        } else {
          this.formGroup.get('supervised').enable();
        }
      }),
      this.formGroup.get('supervised').valueChanges.subscribe(value => {
        if (value === false) {
          this.formGroup.get('propagateSupervision').disable();
          this.formGroup.get('propagateSupervision').setValue(false);
        } else {
          this.formGroup.get('propagateSupervision').enable();
          this.formGroup.get('propagateSupervision').setValue(true);
        }
      }),
      this.formGroup.get('rivpEvent').valueChanges.subscribe(value => {
        if (value !== this.equipment?.rivpEvent) {
          this.formGroup.get('rivpEventDate').setValue(moment());
        } else {
          this.formGroup.get('rivpEventDate').setValue(get(this.equipment, 'rivpEventDate'));
        }
      })
    );
    this.ready = true;
    this.changeDetectorRef.detectChanges();
    setTimeout(() => this.isReady.emit(true));
  }

  private async loadEquipment(): Promise<void> {
    if (!this.equipment && this.formGroup.value?.id) {
      this.equipment = await this.equipmentService.get(this.formGroup.value.id, false).toPromise();
    }
  }

  private async loadOrganizationalUnit(): Promise<void> {
    if (!this.organizationalUnit || this.organizationalUnit.kind !== 'group') {
      this.organizationalUnit = this.equipment?.organizationalUnit
        ? await this.organizationalUnitService.get(this.equipment.organizationalUnit.id).toPromise()
        : this.formGroup.get('organizationalUnit')?.value
        ? await this.organizationalUnitService.get(this.formGroup.get('organizationalUnit').value.id).toPromise()
        : null;
    }
  }

  private async loadRealEstateStructure(): Promise<void> {
    if (!this.realEstateStructure) {
      this.realEstateStructure = this.equipment?.realEstateStructure
        ? await this.realEstateStructureService.get(this.equipment.realEstateStructure.id).toPromise()
        : this.equipment?.realEstateStructureId
        ? await this.realEstateStructureService.get(this.equipment.realEstateStructureId).toPromise()
        : this.formGroup.get('realEstateStructureId')?.value
        ? await this.realEstateStructureService.get(this.formGroup.get('realEstateStructureId').value).toPromise()
        : null;
    }
  }

  private async loadKind(): Promise<void> {
    if (!this.kind) {
      this.kinds = sortBy(
        lmap(await this.equipmentKindService.list(this.kindsFilter || {}).toPromise(), kind =>
          merge(kind, {
            categoryLabel: this.translate.instant(kind.category),
          })
        ),
        ['categoryLabel', 'name'],
        ['asc', 'asc']
      );
      this.kind = find(this.kinds, {id: this.equipment?.kind?.id});
    } else {
      this.kinds = [
        merge(this.kind, {
          categoryLabel: this.translate.instant(this.kind.category),
        }),
      ];
    }
  }

  private async loadPrefixReference(): Promise<any> {
    if (this.equipment?.reference) {
      return (this.prefixReference = replace(
        this.equipment.reference,
        new RegExp(`^(.*${this.equipment.kind?.referenceCode || '-'})[0-9]+$`),
        '$1'
      ));
    }
    const kind = this.kind || this.equipment?.kind;
    if (kind) {
      let prefixReference =
        kind.referenceBase === 'real-estate-structure'
          ? padStart(this.realEstateStructure?.reference, 10, '0')
          : kind.referenceBase === 'organizational-unit'
          ? padStart(this.organizationalUnit?.code, 6, '0')
          : '';
      if (prefixReference && kind?.referenceCode) {
        prefixReference += kind.referenceCode;
      } else if (!kind.referenceCode) {
        prefixReference += '-';
      }
      this.prefixReference = prefixReference;
    } else {
      this.prefixReference = '';
    }
  }

  private async getRef(): Promise<number> {
    if (this.equipment?.reference) {
      return parseInt(replace(this.equipment.reference, /^.*([0-9]+)$/, '$1'), 10);
    } else if (this.prefixReference) {
      return lastValueFrom(this.equipmentService.getNextReferenceIndex(`${this.prefixReference}`, this.equipment?.id));
    }
  }

  private buildControl(
    name: string,
    {value, validators, asyncValidator}: {value?: any; validators?: any[]; asyncValidator?: any[]}
  ): AbstractControl<any> {
    let control = this.formGroup.get(name);
    if (!control) {
      control = this.formBuilder.control({value, disabled: this.isDisabled[name]}, validators, asyncValidator);
      this.formGroup.setControl(name, control);
    } else {
      if (value !== undefined) control.setValue(value);
      if (this.isDisabled[name] === true) control.disable();
      if (validators?.length) control.addValidators(validators);
      if (asyncValidator?.length) control.addAsyncValidators(asyncValidator);
      control.updateValueAndValidity();
    }
    return control;
  }

  private async buildForm(): Promise<void> {
    const ref = await this.getRef();
    this.formGroup.setControl('id', this.formBuilder.control(this.equipment?.id));
    this.formGroup.setControl(
      'organizationalUnit',
      this.formBuilder.control({value: this.organizationalUnit, disabled: this.isDisabled.organizationalUnit})
    );
    this.formGroup.setControl('realEstateStructureId', this.formBuilder.control(this.realEstateStructure?.id));
    this.formGroup.setControl(
      'realEstateStructure',
      this.formBuilder.control({value: this.realEstateStructure, disabled: this.isDisabled.realEstateStructure})
    );
    this.formGroup.setControl(
      'kind',
      this.formBuilder.control(
        {value: this.kind || this.formGroup.value?.kind || this.equipment?.kind, disabled: this.isDisabled.kind},
        [Validators.required, this.validateEntity]
      )
    );

    this.buildControl('ref', {value: ref, validators: [Validators.required], asyncValidator: [this.validateReference]});
    this.buildControl('reference', {value: this.buildReference(ref)});
    this.buildControl('status', {value: get(this.equipment, 'status', EquipmentStatus.ND)});
    this.buildControl('state', {value: get(this.equipment, 'state') || 3});

    this.formGroup.setControl(
      'label',
      this.formBuilder.control({value: this.equipment?.label, disabled: this.isDisabled.label})
    );
    this.formGroup.setControl(
      'location',
      this.formBuilder.control({value: this.equipment?.location, disabled: this.isDisabled.location})
    );
    this.formGroup.setControl(
      'comment',
      this.formBuilder.control({value: this.equipment?.comment, disabled: this.isDisabled.comment})
    );
    this.formGroup.setControl(
      'brand',
      this.formBuilder.control({value: this.equipment?.brand, disabled: this.isDisabled.brand})
    );
    this.formGroup.setControl(
      'model',
      this.formBuilder.control({value: this.equipment?.model, disabled: this.isDisabled.model})
    );
    this.formGroup.setControl(
      'modernizationDate',
      this.formBuilder.control({value: this.equipment?.modernizationDate, disabled: this.isDisabled.modernizationDate})
    );
    this.formGroup.setControl(
      'commissioningDate',
      this.formBuilder.control({value: this.equipment?.commissioningDate, disabled: this.isDisabled.commissioningDate})
    );
    this.formGroup.setControl(
      'tagType',
      this.formBuilder.control({value: this.equipment?.tagType, disabled: this.isDisabled.tagType})
    );
    this.formGroup.setControl(
      'tagCode',
      this.formBuilder.control({value: this.equipment?.tagCode, disabled: this.isDisabled.tagCode})
    );
    this.formGroup.setControl('linked', this.formBuilder.control(get(this.equipment, 'linked', true)));
    this.formGroup.setControl(
      'supervised',
      this.formBuilder.control({
        value: get(this.equipment, 'supervised', false),
        disabled: !get(this.equipment, 'linked', true),
      })
    );
    this.formGroup.setControl(
      'signatureStatus',
      this.formBuilder.control({value: this.equipment?.signatureStatus, disabled: true})
    );
    this.formGroup.setControl(
      'contractorEvent',
      this.formBuilder.control({value: this.equipment?.contractorEvent, disabled: true})
    );
    this.formGroup.setControl('rivpEvent', this.formBuilder.control(this.equipment?.rivpEvent));
    this.formGroup.setControl('rivpEventDate', this.formBuilder.control(this.equipment?.rivpEventDate));
    this.formGroup.setControl(
      'propagateSupervision',
      this.formBuilder.control({
        value: get(this.equipment, 'propagateSupervision', false),
        disabled: !get(this.equipment, 'supervised', false),
      })
    );
    this.formGroup.setControl(
      `metrics.${Metric.MOTION_COUNT}`,
      this.formBuilder.control({
        value: get(this.equipment, `metrics.${Metric.MOTION_COUNT}`, 0),
        disabled: true,
      })
    );
    this.formGroup.setControl(
      'signatureMotionAverage',
      this.formBuilder.control(get(this.equipment, 'signatureMotionAverage', 0))
    );
    this.formGroup.setControl(
      'signatureSettingName',
      this.formBuilder.control({value: get(this.equipment, 'signatureSettingName'), disabled: true})
    );
    this.formGroup.setControl(
      'lotNumber',
      this.formBuilder.control({value: this.equipment?.lotNumber, disabled: this.isDisabled.lotNumber})
    );
    this.formGroup.setControl(
      'contractors',
      this.formBuilder.group({
        maintenance: [this.equipment?.contractors?.maintenance, [Validators.required, this.validateEntity]],
        telemonitor: [this.equipment?.contractors?.telemonitor, this.validateEntity],
        fitter: [this.equipment?.contractors?.fitter, this.validateEntity],
        study: [this.equipment?.contractors?.study, this.validateEntity],
        control: [this.equipment?.contractors?.control, this.validateEntity],
        hypervisor: [this.equipment?.contractors?.hypervisor, this.validateEntity],
        supervisor: [this.equipment?.contractors?.supervisor, this.validateEntity],
      })
    );
    this.formGroup.setControl(
      'manager',
      this.formBuilder.control(
        {
          value: get(this.equipment, 'manager', find(this.managers, {default: true})),
          disabled: this.isDisabled.manager,
        },
        this.validateEntity
      )
    );
    this.formGroup.setControl(
      'externalReference',
      this.formBuilder.control({value: this.equipment?.externalReference, disabled: this.isDisabled.externalReference})
    );
    this.formGroup.setControl('contractStartDate', this.formBuilder.control(this.equipment?.contractStartDate));
    this.formGroup.setControl('contractEndDate', this.formBuilder.control(this.equipment?.contractEndDate));
    this.formGroup.setControl(
      'files',
      this.formBuilder.control(lmap(this.equipment?.files, f => merge(f, {toDelete: false})) || [])
    );
    this.loadMetadata();
    this.formGroup.setControl('metrics', this.formBuilder.group({}));
    if (this.enableHypervision) this.formGroup.setControl('hypervisionConfiguration', this.formBuilder.group({}));
    this.toggleEnable();
    this.toggleRequired();
  }

  private loadMetadata(): void {
    this.formGroup.setControl(
      'metadata',
      this.formBuilder.group(
        reduce(
          get(this.formGroup.value, 'kind.metadata'),
          (pv, cv) => {
            const validators = [];
            if (cv.isRequired) {
              validators.push(Validators.required);
            }
            pv[cv.name] = this.formBuilder.control(
              {
                value: get(this.equipment, `metadata.${cv.name}`),
                disabled: !cv.isEditable,
              },
              validators
            );
            return pv;
          },
          {}
        )
      )
    );
  }

  private async updateReference(): Promise<void> {
    if (this.equipment) return;
    this.loadPrefixReference();
    const ref = await this.getRef();
    this.formGroup.get('ref').setValue(ref);
    this.formGroup.get('reference').setValue(this.buildReference(ref));
  }

  private toggleEnable(): void {
    let action =
      this.formGroup.get('organizationalUnit').value && this.formGroup.get('kind').value ? 'enable' : 'disable';
    this.formGroup.get('ref')[action]();
    this.formGroup.get('reference')[!this.equipment?.reference ? action : 'disable']();
    if (this.formGroup.get('kind').value?.hypervision) {
      this.formGroup.get('hypervisionConfiguration')?.enable();
    } else {
      this.formGroup.get('hypervisionConfiguration')?.disable();
    }
    this.changeDetectorRef.detectChanges();
  }

  private toggleRequired(): void {
    const kind = this.formGroup.get('kind').value;
    this.isRealEstateStructureRequired = kind?.family === Family.FIRE_SAFETY;
  }

  private buildReference(ref: number): string {
    if (this.equipment?.reference) return this.equipment.reference;
    this.prefixPad = this.formGroup.get('kind')?.value?.referencePad || 0;
    return this.prefixReference ? `${this.prefixReference}${padStart(ref?.toString(), this.prefixPad, '0')}` : null;
  }

  private async loadContractors() {
    const families = [];
    if (this.kind) families.push(this.kind.family);
    else if (this.kindsFilter?.families?.length) {
      families.push(...this.kindsFilter.families);
    }
    this.contractors = orderBy(await lastValueFrom(this.contractorService.list({families, fields: ['name']})), [
      'name',
    ]);
  }

  private async loadManagers() {
    this.managers = orderBy(
      await this.equipmentManagerService.list().toPromise(),
      ['default', 'name'],
      ['desc', 'asc']
    );
  }
}
