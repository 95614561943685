<app-page-tracking page="Espace Ascenseur > Accueil > Statistiques"></app-page-tracking>
<div id="container" fxLayout="column">

  <span color="primary" [style.font-weight]="600" [style.padding.px]="4">
    {{filter?.value?.search ? ('advanced-search' | translate) : filter?.value?.perimeter ? ('my-perimeter' | translate)
    :
    filter?.value?.organizationalUnit ? filter.value.organizationalUnit.name : '' }}
  </span>


  <sesio-chart-range-selector (changed)="setExtremes($event)">
    <form *ngIf="filter" additionalFilter [formGroup]="filter" fxLayout="column">
      <div fxLayout="row wrap">
        <mat-button-toggle-group formControlName="contractorIds" aria-label="Prestataires" multiple fxLayout="row wrap"
          fxLayoutAlign="start center">
          <mat-button-toggle *ngFor="let contractor of contractors" [value]="contractor.value"
            [style.--button-toggle-checked-color]="contractorColors[contractor.name]?.color"
            [style.--button-toggle-checked-color-contrast]="contractorColors[contractor.name]?.contrast">
            {{contractor.name}}
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div fxLayout="row wrap" fxLayoutAlign="start center">
        <app-organizational-unit-select class="form-field" formControlName="organizationalUnit"
          label="direction-agency-group" [style.width.px]="300">
        </app-organizational-unit-select>
        <app-equipment-kind-select class="form-field" formControlName="equipmentKind" label="equipment-kind"
          [family]="Family.ELEVATOR" [style.width.px]="300">
        </app-equipment-kind-select>
        <div fxLayoutAlign="start center" [style.font-size.em]="1.5">
          <span *ngIf="elevatorCount !== null" color="primary" [countUp]="elevatorCount"
            [options]="{ duration: 1, separator: ' ' }" [reanimateOnClick]="false"></span>
          <mat-spinner *ngIf="elevatorCount === null" [diameter]="16"></mat-spinner>
          <span color="primary">&nbsp;Ascenseurs</span>
        </div>
      </div>
    </form>

    <mat-tab-group content fill id="content">


      <mat-tab label="{{'breakdowns-&-attendance' | translate}}">
        <ng-scrollbar visibility="hover">
          <div fxLayout="column" [style.width.%]="100">

            <div fxLayout="row wrap" fxLayoutAlign="space-between start" [style.width.%]="100">
              <div class="block" fxLayout="column" fxFlex="50">
                <h2 class="chart-title" color="primary" fxLayoutAlign="start center" fxLayoutGap="8px">
                  <span>Historique des <span bold>trafics</span></span>
                  <div fxLayoutAlign="start center">
                    (
                    <mat-spinner *ngIf="noflowCount === null" [diameter]="16"></mat-spinner>
                    <span *ngIf="noflowCount !== null" [style.color]="'var(--ion-color-noflow)'" [countUp]="noflowCount"
                      [options]="{ duration: 1, separator: ' ' , prefix: ' Absences de flux : '}"
                      [reanimateOnClick]="false">
                    </span>
                    )
                  </div>
                </h2>
                <app-equipment-traffic-chart #rangeChart class="chart"
                  [filter]="filter?.value"></app-equipment-traffic-chart>
              </div>
              <div class="block" fxLayout="column" fxFlex="50">
                <h2 class="chart-title" color="primary" fxLayout="start center">
                  Tendance des <span bold>&nbsp;fréquentations</span>&nbsp;
                  <div fxLayoutAlign="start center">
                    (
                    <mat-spinner *ngIf="trafficCount === null" [diameter]="16"></mat-spinner>
                    <span *ngIf="trafficCount !== null" [countUp]="trafficCount"
                      [options]="{ duration: 1, separator: ' ' }" [reanimateOnClick]="false">
                    </span>
                    <span>&nbsp;sur la période</span>
                    )
                  </div>
                </h2>
                <sesio-chart #trafficChart class="chart" [options]="trafficChartOptions">
                </sesio-chart>
              </div>
            </div>

            <div fxLayout="row wrap" fxLayoutAlign="space-between start" [style.width.%]="100">
              <div class="block" fxLayout="column" fxFlex="50">
                <h2 class="chart-title" color="primary" fxLayoutAlign="space-between center" fxLayoutGap="16px">
                  <span>Historique des <span bold color="danger">pannes</span></span>

                  <div [style.display]="'inline-block'" fxLayoutAlign="start center" fxLayoutGap="8px"
                    [style.font-size.em]="0.7">
                    <div [style.display]="'inline-block'">
                      <mat-spinner *ngIf="newFailureCount === null" [diameter]="16" color="danger"></mat-spinner>
                      <span *ngIf="newFailureCount !== null" color="danger" [countUp]="newFailureCount"
                        [options]="{ duration: 1, separator: ' ', suffix: ' nouvelles pannes' }"
                        [reanimateOnClick]="false"></span>
                    </div>
                    <div [style.display]="'inline-block'">
                      <mat-spinner *ngIf="newFailureCount === null || elevatorCount === null" [diameter]="16"
                        color="danger"></mat-spinner>
                      <span *ngIf="newFailureCount !== null && elevatorCount !== null" color="danger"
                        [countUp]="newFailureCount / elevatorCount"
                        [options]="{ duration: 1, separator: ' ', decimalPlaces: 2, suffix: ' / asc' }"
                        [reanimateOnClick]="false"></span>
                    </div>
                  </div>
                </h2>
                <sesio-chart #failureHistoryChart class="chart" kind="highstock" [options]="failureHistoryChartOptions">
                </sesio-chart>
              </div>
              <div class="block" fxLayout="column" fxFlex="50">
                <h2 class="chart-title" color="primary" fxLayout="start center">
                  Tendance des <span bold color="danger">&nbsp;pannes</span>&nbsp;
                  <div fxLayoutAlign="start center">
                    (
                    <mat-spinner *ngIf="failureCount === null || elevatorCount === null" [diameter]="16"></mat-spinner>
                    <div fxLayoutAlign="start center" fxLayoutGap="4px"
                      *ngIf="failureCount !== null && elevatorCount !== null">
                      <span [countUp]="failureCount" [options]="{ duration: 1, separator: ' ' }"
                        [reanimateOnClick]="false"></span>
                      <span>&nbsp;sur la période&nbsp;</span>
                      <span>-</span>
                      <span [countUp]="failureCount / elevatorCount"
                        [options]="{ duration: 1, separator: ' ', decimalPlaces: 2, suffix: ' / asc' }"
                        [reanimateOnClick]="false"></span>
                      <span>- Durée moyenne : </span>
                      <span> {{failureAvgDuration | humanizeDuration:{round: true, largest: 1} }}</span>
                    </div>
                    )
                  </div>
                </h2>
                <sesio-chart #failureChart class="chart" [options]="failureChartOptions">
                </sesio-chart>
              </div>
            </div>

            <div fxLayout="row wrap" fxLayoutAlign="space-between start" [style.width.%]="100">
              <div class="block" fxLayout="column" fxFlex="50">
                <h2 class="chart-title" color="primary" fxLayoutAlign="space-between center" fxLayoutGap="16px">
                  <span>Taux de <span bold>Disponibilité</span></span>

                  <div [style.display]="'inline-block'" fxLayoutGap="8px" [style.font-size.em]="0.7">
                    <div [style.display]="'inline-block'">
                      <mat-spinner *ngIf="disponibilityRate === null" [diameter]="16"></mat-spinner>
                      <span *ngIf="disponibilityRate !== null" color="primary" [countUp]="disponibilityRate"
                        [options]="{ duration: 1, separator: ' ', suffix: ' %', decimalPlaces: 2 }"
                        [reanimateOnClick]="false"></span>
                    </div>
                  </div>
                </h2>
                <sesio-chart #availabilityHistoryChart class="chart" kind="highstock"
                  [options]="availabilityHistoryChartOptions">
                </sesio-chart>
              </div>
              <div class="block" fxLayout="column" fxFlex="50">
                <h2 class="chart-title" color="primary">Répartition des <span bold color="danger">pannes</span> par jour
                </h2>
                <sesio-chart #failureDayChart class="chart" [options]="failureDayChartOptions">
                </sesio-chart>
              </div>
            </div>

          </div>
        </ng-scrollbar>
      </mat-tab>

      <mat-tab label="{{'performance' | translate: { gender: 'female', plurial: true } }}">
        <ng-scrollbar visibility="hover">
          <div fxLayout="column" [style.width.%]="100">

            <div fxLayout="row wrap" fxLayoutAlign="space-between start" [style.width.%]="100">
              <div class="block" fxLayout="column" fxFlex="100">
                <h2 class="chart-title" color="primary">Tests <span bold>Phonie</span> & <span bold
                    color="danger">Incarcération</span></h2>
                <app-equipment-report-chart #rangeChart class="chart" [filter]="filter?.value">
                </app-equipment-report-chart>
              </div>
            </div>

            <div fxLayout="row wrap" fxLayoutAlign="space-between start" [style.width.%]="100">
              <div class="block" fxLayout="column" fxFlex="50">
                <h2 class="chart-title" color="primary">Appels phonie</h2>
                <sesio-chart #callByContractorChart class="chart" [options]="pieChartOptions">
                </sesio-chart>
              </div>
              <div class="block" fxLayout="column" fxFlex="50">
                <h2 class="chart-title" color="primary">Comparatif des <span bold>Performances</span> par <span
                    bold>Prestataires</span></h2>
                <sesio-chart #contractorPerfChart class="chart" [options]="stackChartOptions">
                </sesio-chart>
              </div>
            </div>

            <div fxLayout="row wrap" fxLayoutAlign="space-between start" [style.width.%]="100">
              <div class="block" fxLayout="column" fxFlex="50">
                <h2 class="chart-title" color="primary">Répartition des ascenseurs par ascensoristes</h2>
                <sesio-chart #elevatorByContractorChart class="chart" [options]="pieChartOptions">
                </sesio-chart>
              </div>
              <div class="block" fxLayout="column" fxFlex="50">
                <h2 class="chart-title" color="primary">Répartition des <span bold color="danger">pannes</span> par
                  ascensoristes</h2>
                <sesio-chart #failureByContractorChart class="chart" [options]="pieChartOptions">
                </sesio-chart>
              </div>
            </div>

          </div>
        </ng-scrollbar>
      </mat-tab>

    </mat-tab-group>
  </sesio-chart-range-selector>

</div>