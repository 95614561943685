<div id="container" fxLayout="column" fxLayoutAlign="start center" >
  <span id="value-text">{{value}}%</span>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 141 194.512">
    <defs>
      <linearGradient id="linear-gradient" x1="0.503" y1="0.022" x2="0.502" y2="0.976"
        gradientUnits="objectBoundingBox">
        <stop [attr.offset]="(100 - value) + '%'" stop-color="#333" />
        <stop offset="100%" stop-color="currentColor" />
      </linearGradient>
    </defs>
    <g id="Groupe_1026" data-name="Groupe 1026" transform="translate(-960 -336)">
      <g id="Groupe_1025" data-name="Groupe 1025">
        <line id="Ligne_88" data-name="Ligne 88" x2="8" transform="translate(1079.5 349.5)" fill="none" stroke="#aaa"
          stroke-width="0.5" />
        <text id="_100" data-name="100" transform="translate(1090 351)" fill="#aaa" font-size="6"
          font-family="Roboto-Regular, Roboto">
          <tspan x="0" y="0">100</tspan>
        </text>
        <line id="Ligne_89" data-name="Ligne 89" x2="8" transform="translate(1079.5 361.5)" fill="none" stroke="#aaa"
          stroke-width="0.5" />
        <text id="_90" data-name="90" transform="translate(1090 363)" fill="#aaa" font-size="6"
          font-family="Roboto-Regular, Roboto">
          <tspan x="0" y="0">90</tspan>
        </text>
        <line id="Ligne_90" data-name="Ligne 90" x2="8" transform="translate(1079.5 373.5)" fill="none" stroke="#aaa"
          stroke-width="0.5" />
        <text id="_80" data-name="80" transform="translate(1090 375)" fill="#aaa" font-size="6"
          font-family="Roboto-Regular, Roboto">
          <tspan x="0" y="0">80</tspan>
        </text>
        <line id="Ligne_91" data-name="Ligne 91" x2="8" transform="translate(1079.5 385.5)" fill="none" stroke="#aaa"
          stroke-width="0.5" />
        <text id="_70" data-name="70" transform="translate(1090 387)" fill="#aaa" font-size="6"
          font-family="Roboto-Regular, Roboto">
          <tspan x="0" y="0">70</tspan>
        </text>
        <line id="Ligne_92" data-name="Ligne 92" x2="8" transform="translate(1079.5 397.5)" fill="none" stroke="#aaa"
          stroke-width="0.5" />
        <text id="_60" data-name="60" transform="translate(1090 399)" fill="#aaa" font-size="6"
          font-family="Roboto-Regular, Roboto">
          <tspan x="0" y="0">60</tspan>
        </text>
        <line id="Ligne_93" data-name="Ligne 93" x2="8" transform="translate(1079.5 409.5)" fill="none" stroke="#aaa"
          stroke-width="0.5" />
        <text id="_50" data-name="50" transform="translate(1090 411)" fill="#aaa" font-size="6"
          font-family="Roboto-Regular, Roboto">
          <tspan x="0" y="0">50</tspan>
        </text>
        <line id="Ligne_94" data-name="Ligne 94" x2="8" transform="translate(1079.5 420.5)" fill="none" stroke="#aaa"
          stroke-width="0.5" />
        <text id="_40" data-name="40" transform="translate(1090 422)" fill="#aaa" font-size="6"
          font-family="Roboto-Regular, Roboto">
          <tspan x="0" y="0">40</tspan>
        </text>
        <line id="Ligne_95" data-name="Ligne 95" x2="8" transform="translate(1079.5 432.5)" fill="none" stroke="#aaa"
          stroke-width="0.5" />
        <text id="_30" data-name="30" transform="translate(1090 434)" fill="#aaa" font-size="6"
          font-family="Roboto-Regular, Roboto">
          <tspan x="0" y="0">30</tspan>
        </text>
        <line id="Ligne_96" data-name="Ligne 96" x2="8" transform="translate(1079.5 444.5)" fill="none" stroke="#aaa"
          stroke-width="0.5" />
        <g id="Groupe_992" data-name="Groupe 992" transform="translate(324 15)">
          <text id="_20" data-name="20" transform="translate(766 431)" fill="#aaa" font-size="6"
            font-family="Roboto-Regular, Roboto">
            <tspan x="0" y="0">20</tspan>
          </text>
        </g>
        <line id="Ligne_97" data-name="Ligne 97" x2="8" transform="translate(1079.5 456.5)" fill="none" stroke="#aaa"
          stroke-width="0.5" />
        <text id="_10" data-name="10" transform="translate(1090 458)" fill="#aaa" font-size="6"
          font-family="Roboto-Regular, Roboto">
          <tspan x="0" y="0">10</tspan>
        </text>
        <line id="Ligne_98" data-name="Ligne 98" x2="8" transform="translate(1079.5 468.5)" fill="none" stroke="#aaa"
          stroke-width="0.5" />
        <text id="_0" data-name="0" transform="translate(1090 470)" fill="#aaa" font-size="6"
          font-family="Roboto-Regular, Roboto">
          <tspan x="0" y="0">0</tspan>
        </text>
        <line id="Ligne_99" data-name="Ligne 99" x2="4" transform="translate(1079.5 355.5)" fill="none" stroke="#aaa"
          stroke-width="0.5" />
        <line id="Ligne_100" data-name="Ligne 100" x2="4" transform="translate(1079.5 367.5)" fill="none" stroke="#aaa"
          stroke-width="0.5" />
        <line id="Ligne_101" data-name="Ligne 101" x2="4" transform="translate(1079.5 379.5)" fill="none" stroke="#aaa"
          stroke-width="0.5" />
        <line id="Ligne_102" data-name="Ligne 102" x2="4" transform="translate(1079.5 391.5)" fill="none" stroke="#aaa"
          stroke-width="0.5" />
        <line id="Ligne_103" data-name="Ligne 103" x2="4" transform="translate(1079.5 403.5)" fill="none" stroke="#aaa"
          stroke-width="0.5" />
        <line id="Ligne_104" data-name="Ligne 104" x2="4" transform="translate(1079.5 414.5)" fill="none" stroke="#aaa"
          stroke-width="0.5" />
        <line id="Ligne_105" data-name="Ligne 105" x2="4" transform="translate(1079.5 426.5)" fill="none" stroke="#aaa"
          stroke-width="0.5" />
        <line id="Ligne_106" data-name="Ligne 106" x2="4" transform="translate(1079.5 438.5)" fill="none" stroke="#aaa"
          stroke-width="0.5" />
        <line id="Ligne_107" data-name="Ligne 107" x2="4" transform="translate(1079.5 450.5)" fill="none" stroke="#aaa"
          stroke-width="0.5" />
        <line id="Ligne_108" data-name="Ligne 108" x2="4" transform="translate(1079.5 462.5)" fill="none" stroke="#aaa"
          stroke-width="0.5" />
      </g>
      <path id="Tracé_676" data-name="Tracé 676"
        d="M56.823,25H14.988A2.823,2.823,0,0,0,12,27.988a2.823,2.823,0,0,0,2.988,2.988H56.823a2.823,2.823,0,0,0,2.988-2.988A3.2,3.2,0,0,0,56.823,25Zm0,11.953H14.988a2.988,2.988,0,1,0,0,5.976H56.823a2.823,2.823,0,0,0,2.988-2.988A3.2,3.2,0,0,0,56.823,36.953ZM17.976,48.906v5.976a5.646,5.646,0,0,0,5.976,5.976H47.859a5.646,5.646,0,0,0,5.976-5.976V48.906Z"
        transform="translate(992.503 455.141)" />
      <path id="Tracé_677" data-name="Tracé 677"
        d="M56.907,1.8C24.634,1.8,7.9,26.9,7.9,50.209c0,25.7,23.906,41.835,23.906,84.268H79.617c.6-42.433,24.5-59.167,24.5-84.865C104.718,28.1,89.179,1.8,56.907,1.8Z"
        transform="translate(972.1 339.688)" fill="url(#linear-gradient)" />
    </g>
  </svg>

</div>
