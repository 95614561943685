import { Injectable } from '@angular/core';

export interface IEnergyTag {
  letter: string;
  label: string;
  rt?: string;
  color: string;
  selected: (v: number) => boolean;
}

@Injectable({
  providedIn: 'root'
})
export class EnergyTagService {

  private tags: IEnergyTag[] = [
    { letter: 'A', label: '< 50', rt: 'RT 2012', color: '#33a357', selected: v => v < 51 },
    { letter: 'B', label: '51 à 91', rt: 'RT 2005', color: '#79b752', selected: v => v >= 51 && v < 91 },
    { letter: 'C', label: '91 à 150', rt: 'RT 2000', color: '#c3d545', selected: v => v >= 91 && v < 151 },
    { letter: 'D', label: '151 à 230', color: '#fff12c', selected: v => v >= 151 && v < 231 },
    { letter: 'E', label: '231 à 330', color: '#edb731', selected: v => v >= 231 && v < 331 },
    { letter: 'F', label: '331 à 450', color: '#d66f2c', selected: v => v >= 331 && v < 451 },
    { letter: 'G', label: '> à 450', color: '#cc232a', selected: v => v > 450 }
  ];

  constructor() { }

  public getTags(): IEnergyTag[] {
    return this.tags;
  }

  public getTag(value: number): IEnergyTag {
    return this.tags.find(t => t.selected(value));
  }

}
