/** @format */

import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LibConfig, LibConfigService } from '../config.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ModelMapper } from 'model-mapper';
import { Order, OrderStatus } from '../classes/order';
import { Moment } from 'moment';
import { File } from '../classes/file';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  constructor(@Inject(LibConfigService) protected config: LibConfig, protected http: HttpClient) {}

  public get(id: string): Observable<Order> {
    return this.http
      .get<any[]>(`${this.config.environment.apiUrl}/orders/${id}`)
      .pipe(map(data => new ModelMapper(Order).map(data)));
  }

  public setStatus(
    id: string,
    data: { status: OrderStatus; interventionDate?: Date; interventionReport?: File, date?: Date }
  ): Observable<boolean> {
    return this.http.patch<boolean>(`${this.config.environment.apiUrl}/orders/${id}/set-status`, data);
  }

  public cancel(id: string): Observable<boolean> {
    return this.http.patch<boolean>(`${this.config.environment.apiUrl}/orders/${id}/cancel`, {});
  }
}
