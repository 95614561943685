import { propertyMap } from 'model-mapper';
import { EmbededContractor } from './embeded-contractor';
import { EmbededEquipment } from './equipment';
import { File } from './file';
import { EmbededInspection } from './embeded-inspection';
import { EmbededIntervention } from './embeded-intervention';
import { EmbededRealEstateStructure } from './real-estate-structure';
import { EmbededUnitPriceSchedule } from './embeded-unit-price-schedule';
import { Moment } from 'moment';
import { EmbededOrder } from './embeded-order';
import { StatusHistory } from './status-history';
import { EmbededOrganizationalUnit } from './embeded-organizational-unit';

export enum QuotationStatus {
  NEW = 'new',
  CONFIRMED = 'confirmed',
  VALIDATED = 'validated',
  CANCELED = 'canceled',
  REFUSED = 'refused',
  ARCHIVED = 'archived',
}

export enum QuotationFileKind {
  QUOTATION = 'quotation',
  PHOTO = 'photo',
  OTHER = 'other',
}

export const QuotationStatusColor = {
  [QuotationStatus.NEW]: '#999',
  [QuotationStatus.CONFIRMED]: 'var(--ion-color-primary)',
  [QuotationStatus.VALIDATED]: 'var(--ion-color-success)',
  [QuotationStatus.CANCELED]: 'var(--ion-color-medium)',
  [QuotationStatus.REFUSED]: 'var(--ion-color-danger)',
};

export class Quotation {
  @propertyMap({ source: '_id' })
  public id: string;

  @propertyMap()
  public reference: string;

  @propertyMap()
  public externalReference: string;

  @propertyMap()
  public status: QuotationStatus;

  @propertyMap({ type: [StatusHistory] })
  public statusHistory: StatusHistory[];

  @propertyMap({ type: EmbededContractor })
  public contractor: EmbededContractor;

  @propertyMap({ type: EmbededOrganizationalUnit })
  public organizationalUnit: EmbededOrganizationalUnit;

  @propertyMap({ type: EmbededRealEstateStructure })
  public realEstateStructure: EmbededRealEstateStructure;

  @propertyMap({ type: [EmbededInspection] })
  public inspections: EmbededInspection[];

  @propertyMap({ type: [EmbededIntervention] })
  public interventions: EmbededIntervention[];

  @propertyMap({ default: 0 })
  public amount: number;

  @propertyMap({ type: File })
  public file: File;

  @propertyMap({ type: [File] })
  public files: File[];

  @propertyMap()
  public signatory: string;

  @propertyMap()
  public comment: string;

  @propertyMap()
  public canceledReason: string;

  @propertyMap({ type: [EmbededUnitPriceSchedule] })
  public additionalUnitPriceSchedules: EmbededUnitPriceSchedule[];

  @propertyMap({ type: EmbededOrder })
  public order: EmbededOrder;

  @propertyMap({ type: 'Moment' })
  public createdAt: Moment;

  @propertyMap({ type: 'Moment' })
  public updatedAt: Moment;
}
