/** @format */

import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {cloneDeep, each, filter, find, get, includes, isEqual, map, merge, reduce, sortBy, toLower} from 'lodash-es';
import {Moment} from 'moment';
import {ChartComponent, SecurityCallQualification} from 'sesio-lib';
import {EquipmentReportService} from '../../../_services/equipment-report.service';
import {lastValueFrom} from 'rxjs';

@Component({
  selector: 'app-equipment-report-chart',
  templateUrl: './equipment-report-chart.component.html',
  styleUrls: ['./equipment-report-chart.component.scss'],
})
export class EquipmentReportChartComponent implements OnInit {
  @Input()
  id: string;

  @Input('filter')
  public set setFilter(filter: any) {
    if (!isEqual(this.filter, filter)) {
      this.filter = cloneDeep(filter);
      if (this.chart) this.loadData();
    }
  }
  private filter: any;

  private chart: ChartComponent;

  private qualifications = [
    SecurityCallQualification.TESTPHOHS,
    SecurityCallQualification.TESTPHOOK,
    SecurityCallQualification.TESTOK_BUR,
    SecurityCallQualification.TESTOK_MNT,
    SecurityCallQualification.UB,
  ];

  options = {
    chart: {zoomType: 'x'},
    credits: {enabled: false},
    exporting: {enabled: false},
    title: {text: ''},
    rangeSelector: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      split: false,
      useHTML: true,
      headerFormat: '<small>{point.key}</small><br/>',
      pointFormat: '<span style="color:{point.color}">\u25CF</span> ' + '{series.name}: <b>{point.y:.1f}</b><br/>',
    },
    plotOptions: {
      series: {
        pointPlacement: 'between',
        dataGrouping: {
          forced: true,
          smoothed: false,
          groupPixelWidth: 40,
          approximation: 'sum',
          units: [
            ['hour', [1]],
            ['day', [1]],
            ['week', [1]],
            ['month', [1, 3, 6]],
          ],
          dateTimeLabelFormats: {
            millisecond: ['%A %e %b %H:%M:%S.%L', '%A %e %b %H:%M:%S.%L', '-%H:%M:%S.%L'],
            second: ['%A %e %b %H:%M:%S', '%A %e %b %H:%M:%S', '-%H:%M:%S'],
            minute: ['%A %e %b %H:%M', '%A %e %b %H:%M', '-%H:%M'],
            hour: ['%A %e %b %H:%M', '%A %e %b %H:%M', '-%H:%M'],
            day: ['%A %e %b %Y', '%A %e %b', '-%A %e %b %Y'],
            week: ['Semaine à partir du %A %e %b %Y', '%A %e %b', '-%A %e %b, %Y'],
            month: ['%B %Y', '%B %Y', '-%B %Y'],
            year: ['%Y', '%Y', '-%Y'],
          },
        },
      },
    },
    legend: {
      enabled: true,
    },
    xAxis: {
      type: 'category',
      tickmarkPlacement: 'on',
      startOnTick: true,
      endOnTick: true,
      ordinal: false,
    },
    yAxis: [
      {
        startOnTick: false,
        endOnTick: false,
        showLastLabel: true,
        opposite: false,
        lineColor: 'var(--ion-color-primary)',
        labels: {style: {color: 'var(--ion-color-primary)'}},
        lineWidth: 1,
        resize: {enabled: true},
        height: '75%',
        min: 0,
      },
      {
        startOnTick: false,
        endOnTick: false,
        showLastLabel: true,
        opposite: true,
        lineColor: 'var(--ion-color-danger)',
        labels: {align: 'left', style: {color: 'var(--ion-color-danger)'}},
        lineWidth: 1,
        resize: {enabled: true},
        top: '75%',
        height: '25%',
        offset: 0,
      },
    ],
  };
  private extremes: {start?: Moment; end?: Moment};

  constructor(private equipmentReportService: EquipmentReportService, private translate: TranslateService) {}

  ngOnInit() {}

  public setExtremes(start?: Moment, end?: Moment) {
    this.extremes = {start, end};
    if (this.chart) {
      this.loadData(this.chart);
    }
  }

  async loadData(chartComponent?: ChartComponent): Promise<void> {
    if (!this.chart) this.chart = chartComponent;
    this.chart.setLoading(true);
    const search = merge({equipmentId: this.id}, this.filter, {
      start: this.extremes?.start,
      end: this.extremes?.end,
    });
    const data = await lastValueFrom(this.equipmentReportService.getChartRangeData(search));
    const ubSerie: any = {
      name: this.translate.instant(`qualification-ub`),
      data: get(
        data.find(d => d.name === SecurityCallQualification.UB),
        'value'
      ),
      type: 'column',
      yAxis: 1,
      marker: {enabled: true, radius: 5},
      color: 'var(--ion-color-danger)',
    };
    const testSerie: any = {
      name: this.translate.instant(`qualification-test-phony`),
      data: sortBy(
        reduce(
          filter(data, d => d.name !== SecurityCallQualification.UB),
          (pv, cv) => {
            each(cv.value, value => {
              const found = find(pv, v => v[0] === value[0]);
              if (found) found[1] += value[1];
              else pv.push(value);
            });
            return pv;
          },
          []
        ),
        '0'
      ),
      type: 'spline',
      yAxis: 0,
      marker: {enabled: true, radius: 5},
      color: 'var(--ion-color-secondary)',
    };
    if (this.extremes) this.chart.setExtremes(this.extremes.start, this.extremes.end);
    this.chart.setSeries([ubSerie, testSerie]);
    this.chart.setLoading(false);
  }
}
