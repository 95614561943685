/** @format */

import { propertyMap } from 'model-mapper';
import { Moment } from 'moment';
import { EmbededOrganizationalUnit } from './embeded-organizational-unit';
import { EmbededUser } from './user';

export class TechnicalControlObservationEquipmentKind {
  @propertyMap()
  public code: string;
}

export class TechnicalControlObservationEquipment {
  @propertyMap({ source: '_id' })
  public id: string;

  @propertyMap({ type: TechnicalControlObservationEquipmentKind })
  public kind: TechnicalControlObservationEquipmentKind;
}

export class TechnicalControlObservation {
  @propertyMap({ source: '_id' })
  public id: string;

  @propertyMap({ type: TechnicalControlObservationEquipment })
  public equipment: TechnicalControlObservationEquipment;

  @propertyMap({ type: EmbededOrganizationalUnit })
  public organizationalUnit: EmbededOrganizationalUnit;

  @propertyMap()
  public verificationPoint: string;

  @propertyMap()
  public observation: string;

  @propertyMap({ type: 'Moment' })
  public firstDateOfEmission: Moment;

  @propertyMap()
  public ncRobienConformity: boolean;

  @propertyMap({ default: false })
  public archived: boolean;

  @propertyMap({ type: 'Moment' })
  public archivedAt: Moment;

  @propertyMap({ type: EmbededUser })
  public archivedBy: EmbededUser;
}
