<div id="container" fxLayout="column">
  <div fxLayout="row" fxLayoutAlign="space-between center" class="header">
    <div fxLaout="row">
      <button class="action contractor" mat-button [attr.color]="contractor.selected ? 'secondary' : 'medium'"
        *ngFor="let contractor of contractors" (click)="toggleContractor(contractor)">
        {{contractor.name}}
      </button>
    </div>
    <div fxLaout="row" fxLayoutGap="16px">
      <button class="action year" mat-raised-button [attr.color]="year === selectedYear ? 'secondary' : 'medium'"
        *ngFor="let year of years" (click)="selectYear(year)">
        {{year}}
      </button>
    </div>
  </div>
  <div fxLayout="row wrap">
    <sesio-chart class="chart contractors" #contractorChart [options]="pieChartOptions">
    </sesio-chart>
    <sesio-chart class="chart mean-of-access" #meanOfAccessChart [options]="pieChartOptions">
    </sesio-chart>
    <sesio-chart class="chart mean-of-access-followup" #meanOfAccessFollowupChart [options]="stackedChartOptions">
    </sesio-chart>
  </div>
  <mat-tab-group #datagridTabs inViewport (inViewportAction)="initDatagridTabs($event)"
    [selectedIndex]="datagridSelectedIndex" (selectedIndexChange)="datagridTabChanged($event)">

    <mat-tab>
      <ng-template mat-tab-label>
        <h2 color="medium" [style.margin-top.px]="16">NOMBRE DE COMMANDES PAR GROUPES</h2>
      </ng-template>
      <ng-container *ngTemplateOutlet="datagridHeaderTmpl; context: { by: 'groupe' }"></ng-container>
      <section class="mat-elevation-z8" tabindex="0">
        <table mat-table multiTemplateDataRows [dataSource]="byGroupDataSource" matSort #byGroupSort="matSort">
          <ng-container matColumnDef="group">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="upper-header"
              sortActionDescription="Trier par groupe">
              {{byGroupDisplayedColumnNames.group}}
            </th>
            <td mat-cell *matCellDef="let element" [style.color]="'var(--ion-color-primary)'"
              [matTooltip]="element.groupAddress">
              {{element.group}}
            </td>
          </ng-container>

          <ng-container matColumnDef="count">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="upper-header"
              sortActionDescription="Trier par nombre de commande">
              {{byGroupDisplayedColumnNames.count}}
            </th>
            <td mat-cell *matCellDef="let element"
              [style.color]="element.count > countTop ? 'var(--ion-color-danger)' : element.count < countFlop ? 'var(--ion-color-success)' : 'var(--ion-color-primary)'">
              {{element.count}}
            </td>
          </ng-container>

          <ng-container matColumnDef="quantity">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="upper-header"
              sortActionDescription="Trier par quantité">
              {{byGroupDisplayedColumnNames.quantity}}
            </th>
            <td mat-cell *matCellDef="let element"
              [style.color]="element.quantity > quantityTop ? 'var(--ion-color-danger)' : element.quantity < quantityFlop ? 'var(--ion-color-success)' : 'var(--ion-color-primary)'">
              {{element.quantity}} </td>
          </ng-container>

          <ng-container matColumnDef="avg">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="upper-header"
              sortActionDescription="Trier par quantité">
              {{byGroupDisplayedColumnNames.avg}}
            </th>
            <td mat-cell *matCellDef="let element"
              [style.color]="element.avg < 5 ? 'var(--ion-color-primary)' : element.avg < 7 ? 'var(--ion-color-warning)' : 'var(--ion-color-danger)'">
              {{element.avg}}
            </td>
          </ng-container>

          <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="upper-header"
              sortActionDescription="Trier par montant">
              {{byGroupDisplayedColumnNames.amount}}
            </th>
            <td mat-cell *matCellDef="let element"> {{element.amount | currency:'EUR'}} </td>
          </ng-container>

          <ng-container matColumnDef="amountByBuilding">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="upper-header"
              sortActionDescription="Trier par montant / habitation">
              {{byGroupDisplayedColumnNames.amountByBuilding}}
            </th>
            <td mat-cell *matCellDef="let element" fxLayoutGap="8px">
              <span>{{element.amountByBuilding | currency:'EUR'}}</span>
              <sup [style.color]="'#999'">{{element.housingCount}} habitations</sup>
            </td>
          </ng-container>

          <ng-container matColumnDef="amountBySurface">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="upper-header"
              sortActionDescription="Trier par montant / M2">
              {{byGroupDisplayedColumnNames.amountBySurface}}
            </th>
            <td mat-cell *matCellDef="let element" fxLayoutGap="8px">
              <span>{{element.amountBySurface | currency:'EUR'}}</span>
              <sup [style.color]="'#999'">{{element.livingSurface | number:'1.0-0'}} m<sup>2</sup></sup>
            </td>
          </ng-container>

          <ng-container matColumnDef="rate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="upper-header"
              sortActionDescription="Trier par fréquence moyenne">
              {{byGroupDisplayedColumnNames.rate}}
            </th>
            <td mat-cell *matCellDef="let element" [style.color]="'#999'"> {{element.rateStr}} </td>
          </ng-container>

          <ng-container matColumnDef="badge">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="upper-header"
              sortActionDescription="Trier par badge">
              {{byGroupDisplayedColumnNames.badge}}
            </th>
            <td mat-cell *matCellDef="let element" [style.color]="'var(--ion-color-primary)'"> {{element.badge}} </td>
          </ng-container>

          <ng-container matColumnDef="biTech">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="upper-header"
              sortActionDescription="Trier par bi-technologie">
              {{byGroupDisplayedColumnNames.biTech}}
            </th>
            <td mat-cell *matCellDef="let element" [style.color]="'var(--ion-color-success)'"> {{element.biTech}}
            </td>
          </ng-container>

          <ng-container matColumnDef="triTech">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="upper-header"
              sortActionDescription="Trier par tri-technologie">
              {{byGroupDisplayedColumnNames.triTech}}
            </th>
            <td mat-cell *matCellDef="let element" [style.color]="'var(--ion-color-secondary)'"> {{element.triTech}}
            </td>
          </ng-container>

          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="byGroupDisplayedColumns.length">
              <div class="element-detail" [@detailExpand]="element == expandedRow ? 'expanded' : 'collapsed'">
                <section *ngIf="element === expandedRow" tabindex="0">
                  <table mat-table [dataSource]="expandedRowDatasource">
                    <ng-container matColumnDef="delay">
                      <th mat-header-cell *matHeaderCellDef>
                        ÉCART
                      </th>
                      <td mat-cell *matCellDef="let element"> {{element.delayStr}} </td>
                    </ng-container>
                    <ng-container matColumnDef="date">
                      <th mat-header-cell *matHeaderCellDef>
                        DATE
                      </th>
                      <td mat-cell *matCellDef="let element"> {{element.date | date}} </td>
                    </ng-container>
                    <ng-container matColumnDef="esi">
                      <th mat-header-cell *matHeaderCellDef>
                        ESI
                      </th>
                      <td mat-cell *matCellDef="let element"> {{element.esi}} </td>
                    </ng-container>
                    <ng-container matColumnDef="kind">
                      <th mat-header-cell *matHeaderCellDef>
                        COMMANDE
                      </th>
                      <td mat-cell *matCellDef="let element"> {{element.kind}} </td>
                    </ng-container>
                    <ng-container matColumnDef="quantity">
                      <th mat-header-cell *matHeaderCellDef>
                        QUANTITÉ
                      </th>
                      <td mat-cell *matCellDef="let element"> {{element.quantity}} </td>
                    </ng-container>
                    <ng-container matColumnDef="amount">
                      <th mat-header-cell *matHeaderCellDef>
                        MONTANT
                      </th>
                      <td mat-cell *matCellDef="let element"> {{element.amount | currency:'EUR'}} </td>
                    </ng-container>
                    <ng-container matColumnDef="applicant">
                      <th mat-header-cell *matHeaderCellDef>
                        DEMANDEUR
                      </th>
                      <td mat-cell *matCellDef="let element"> {{element.applicant}} </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="expandedDisplayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: expandedDisplayedColumns;"></tr>
                  </table>
                </section>
              </div>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="byGroupDisplayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: byGroupDisplayedColumns;" class="element-row"
            [class.example-expanded-row]="expandedRow === row" (click)="toggleExpandedRow(row)">
          </tr>
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
        </table>
        <mat-paginator #byGroupPaginator [pageSizeOptions]="[50, 100, 200]" showFirstLastButtons
          aria-label="Sélectionnez page">
        </mat-paginator>
      </section>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <h2 color="medium" [style.margin-top.px]="16">NOMBRE DE COMMANDES PAR GARDIENS</h2>
      </ng-template>
      <ng-container *ngTemplateOutlet="datagridHeaderTmpl; context: { by: 'gardien' }"></ng-container>
      <section class="mat-elevation-z8" tabindex="0">
        <table mat-table multiTemplateDataRows [dataSource]="byApplicantDataSource" matSort #byApplicantSort="matSort">

          <ng-container matColumnDef="applicant">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="upper-header"
              sortActionDescription="Trier par gardien">
              {{byApplicantDisplayedColumnNames.applicant}}
            </th>
            <td mat-cell *matCellDef="let element" [style.color]="'var(--ion-color-primary)'">
              {{element.applicant}}
            </td>
          </ng-container>

          <ng-container matColumnDef="groups">
            <th mat-header-cell *matHeaderCellDef class="upper-header" sortActionDescription="Trier par groupe">
              {{byApplicantDisplayedColumnNames.groups}}
            </th>
            <td mat-cell *matCellDef="let element">
              <span *ngIf="element.groups?.length > 2" [matTooltip]="element.groups?.join(', ')" light>
                {{element.groups.length}} groupes
              </span>
              <span *ngIf="element.groups?.length <= 2">{{element.groups?.join(', ')}}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="count">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="upper-header"
              sortActionDescription="Trier par nombre de commande">
              {{byApplicantDisplayedColumnNames.count}}
            </th>
            <td mat-cell *matCellDef="let element"
              [style.color]="element.count > countTop ? 'var(--ion-color-danger)' : element.count < countFlop ? 'var(--ion-color-success)' : 'var(--ion-color-primary)'">
              {{element.count}}
            </td>
          </ng-container>

          <ng-container matColumnDef="quantity">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="upper-header"
              sortActionDescription="Trier par quantité">
              {{byApplicantDisplayedColumnNames.quantity}}
            </th>
            <td mat-cell *matCellDef="let element"
              [style.color]="element.quantity > quantityTop ? 'var(--ion-color-danger)' : element.quantity < quantityFlop ? 'var(--ion-color-success)' : 'var(--ion-color-primary)'">
              {{element.quantity}} </td>
          </ng-container>

          <ng-container matColumnDef="avg">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="upper-header"
              sortActionDescription="Trier par quantité">
              {{byApplicantDisplayedColumnNames.avg}}
            </th>
            <td mat-cell *matCellDef="let element"
              [style.color]="element.avg < 5 ? 'var(--ion-color-primary)' : element.avg < 7 ? 'var(--ion-color-warning)' : 'var(--ion-color-danger)'">
              {{element.avg}}
            </td>
          </ng-container>

          <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="upper-header"
              sortActionDescription="Trier par montant">
              {{byApplicantDisplayedColumnNames.amount}}
            </th>
            <td mat-cell *matCellDef="let element"> {{element.amount | currency:'EUR'}} </td>
          </ng-container>

          <ng-container matColumnDef="rate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="upper-header"
              sortActionDescription="Trier par fréquence moyenne">
              {{byApplicantDisplayedColumnNames.rate}}
            </th>
            <td mat-cell *matCellDef="let element" [style.color]="'#999'"> {{element.rateStr}} </td>
          </ng-container>

          <ng-container matColumnDef="badge">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="upper-header"
              sortActionDescription="Trier par badge">
              {{byApplicantDisplayedColumnNames.badge}}
            </th>
            <td mat-cell *matCellDef="let element" [style.color]="'var(--ion-color-primary)'"> {{element.badge}} </td>
          </ng-container>

          <ng-container matColumnDef="biTech">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="upper-header"
              sortActionDescription="Trier par bi-technologie">
              {{byApplicantDisplayedColumnNames.biTech}}
            </th>
            <td mat-cell *matCellDef="let element" [style.color]="'var(--ion-color-success)'"> {{element.biTech}}
            </td>
          </ng-container>

          <ng-container matColumnDef="triTech">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="upper-header"
              sortActionDescription="Trier par tri-technologie">
              {{byApplicantDisplayedColumnNames.triTech}}
            </th>
            <td mat-cell *matCellDef="let element" [style.color]="'var(--ion-color-secondary)'"> {{element.triTech}}
            </td>
          </ng-container>

          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="byApplicantDisplayedColumns.length">
              <div class="element-detail" [@detailExpand]="element == expandedRow ? 'expanded' : 'collapsed'">
                <section *ngIf="element === expandedRow" tabindex="0">
                  <table mat-table [dataSource]="expandedRowDatasource">
                    <ng-container matColumnDef="delay">
                      <th mat-header-cell *matHeaderCellDef>
                        ÉCART
                      </th>
                      <td mat-cell *matCellDef="let element"> {{element.delayStr}} </td>
                    </ng-container>
                    <ng-container matColumnDef="date">
                      <th mat-header-cell *matHeaderCellDef>
                        DATE
                      </th>
                      <td mat-cell *matCellDef="let element"> {{element.date | date}} </td>
                    </ng-container>
                    <ng-container matColumnDef="esi">
                      <th mat-header-cell *matHeaderCellDef>
                        ESI
                      </th>
                      <td mat-cell *matCellDef="let element"> {{element.esi}} </td>
                    </ng-container>
                    <ng-container matColumnDef="kind">
                      <th mat-header-cell *matHeaderCellDef>
                        COMMANDE
                      </th>
                      <td mat-cell *matCellDef="let element"> {{element.kind}} </td>
                    </ng-container>
                    <ng-container matColumnDef="quantity">
                      <th mat-header-cell *matHeaderCellDef>
                        QUANTITÉ
                      </th>
                      <td mat-cell *matCellDef="let element"> {{element.quantity}} </td>
                    </ng-container>
                    <ng-container matColumnDef="amount">
                      <th mat-header-cell *matHeaderCellDef>
                        MONTANT
                      </th>
                      <td mat-cell *matCellDef="let element"> {{element.amount | currency:'EUR'}} </td>
                    </ng-container>
                    <ng-container matColumnDef="group">
                      <th mat-header-cell *matHeaderCellDef>
                        GROUPE
                      </th>
                      <td mat-cell *matCellDef="let element"> {{element.group}} </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="expandedDisplayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: expandedDisplayedColumns;"></tr>
                  </table>
                </section>
              </div>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="byApplicantDisplayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: byApplicantDisplayedColumns;" class="element-row"
            [class.example-expanded-row]="expandedRow === row" (click)="toggleExpandedRow(row)">
          </tr>
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
        </table>
        <mat-paginator #byApplicantPaginator [pageSizeOptions]="[50, 100, 200]" showFirstLastButtons
          aria-label="Sélectionnez page">
        </mat-paginator>
      </section>
    </mat-tab>

  </mat-tab-group>
</div>

<ng-template #datagridHeaderTmpl let-by="by">
  <div fxLayout="row" fxLayoutAlign="space-between center" [style.padding.px]="8">
    <div fxLayoutGap="16px" [style.color]="'var(--ion-color-primary)'">
      <span>Nombre total de commandes <strong [countUp]="countSum" [options]="{ duration: 1, separator: ' ' }"
          [reanimateOnClick]="false"></strong></span>
      <span>Quantité totale de produits <strong [countUp]="quantitySum" [options]="{ duration: 1, separator: ' ' }"
          [reanimateOnClick]="false"></strong></span>
      <span>Moyenne de commandes par {{by}} <strong [countUp]="countAvg | number:'1.0-0'"
          [options]="{ duration: 1, separator: ' ' }" [reanimateOnClick]="false"></strong></span>
      <span>Moyenne de produits par commande <strong [countUp]="quantityAvg | number:'1.0-0'"
          [options]="{ duration: 1, separator: ' ' }" [reanimateOnClick]="false"></strong></span>
    </div>
    <button mat-icon-button color="primary" class="icon-only action" [matTooltip]="'xlsx-export' | translate"
      [disabled]="exporting" (click)="export(by)">
      <mat-icon>download</mat-icon>
      <div class="loading" *ngIf="exporting" @fade>
        <sesio-loader datagrid-spinner></sesio-loader>
      </div>
    </button>
  </div>
</ng-template>