import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SafePipeModule } from 'safe-pipe';
import { DocumentModal } from './document.modal/document.modal';
import { IonicModule } from '@ionic/angular';
import { LoaderModule, TrackingModule } from 'sesio-lib';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { DocumentDatagridCellComponent } from './document-datagrid-cell/document-datagrid-cell.component';
import { MatIconModule } from '@angular/material/icon';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { DocumentComponent } from './document.component';



@NgModule({
  declarations: [DocumentModal, DocumentDatagridCellComponent, DocumentComponent],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule.forChild(),
    FlexLayoutModule,
    SafePipeModule,
    LoaderModule,
    PdfJsViewerModule,
    MatIconModule,
    NgxDocViewerModule,
    TrackingModule
  ],
  exports: [DocumentModal, DocumentDatagridCellComponent, DocumentComponent]
})
export class DocumentModule { }
