/** @format */

import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AdminGuard} from './_guards/admin.guard';
import {AuthGuard} from './_guards/auth.guard';
import {DashboardGuard} from './_guards/home.guard';
import {SsoGuard} from './_guards/sso.guard';
import {LandingComponent} from './landing.component';

const routes: Routes = [
  {
    path: 'login',
    canActivate: [SsoGuard],
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'access-control',
    canActivate: [AuthGuard],
    loadChildren: () => import('./access-control/access-control.module').then(m => m.AccessControlPageModule),
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard, DashboardGuard],
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: 'janitor-space',
    redirectTo: 'dashboard',
    // canActivate: [AuthGuard],
    // loadChildren: () => import('./janitor-space/janitor-space.module').then(m => m.JanitorSpaceModule),
  },
  {
    path: 'elevator-space',
    canActivate: [AuthGuard],
    loadChildren: () => import('./elevator-space/elevator-space.module').then(m => m.ElevatorSpaceModule),
  },
  {
    path: 'parking-door-space',
    canActivate: [AuthGuard],
    loadChildren: () => import('./parking-door-space/parking-door-space.module').then(m => m.ParkingDoorPageModule),
  },
  {
    path: 'optical-fiber-space',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./optical-fiber-space/optical-fiber-space.module').then(m => m.OpticalFiberSpacePageModule),
  },
  {
    path: 'relay-antenna-space',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./relay-antenna-space/relay-antenna-space.module').then(m => m.RelayAntennaSpacePageModule),
  },
  {
    path: 'fire-safety-space',
    canActivate: [AuthGuard],
    loadChildren: () => import('./fire-safety-space/fire-safety-space.module').then(m => m.FireSafetySpacePageModule),
  },
  {
    path: 'administration',
    canActivate: [AuthGuard, AdminGuard],
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
  },
  {
    path: 'report',
    canActivate: [AuthGuard],
    loadChildren: () => import('./report/report.module').then(m => m.ReportModule),
  },
  {
    path: 'access-control-space',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./access-control-space/access-control-space.module').then(m => m.AccessControlSpacePageModule),
  },
  {
    path: 'energy-fluid-space',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./energy-fluid-space/energy-fluid-space.module').then(m => m.EnergyFluidSpacePageModule),
  },
  {
    path: 'isidesk-space',
    canActivate: [AuthGuard],
    loadChildren: () => import('./isidesk-space/isidesk-space.module').then(m => m.IsideskSpaceModule),
  },
  {path: 'landing', component: LandingComponent},
  {path: '**', redirectTo: 'dashboard'},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: 'corrected',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
