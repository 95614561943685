/** @format */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'availabilityRateColor',
})
export class AvailabilityRateColorPipe implements PipeTransform {
  transform(value: number): string {
    return value > 99
      ? 'var(--ion-color-success)'
      : value > 98
      ? 'var(--ion-color-primary)'
      : value > 97
      ? 'var(--ion-color-warning)'
      : value > 96
      ? 'var(--ion-color-danger)'
      : 'var(--ion-color-black)';
  }
}
