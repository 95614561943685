/** @format */

import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { last, map, mergeMap, tap } from 'rxjs/operators';
import { ModelMapper } from 'model-mapper';
import { EquipmentDocument, EquipmentDocumentKind } from '../classes/equipment-document';
import { LibConfig, LibConfigService } from '../config.service';
import { v4 } from 'uuid';
import { EquipmentKindCategory } from '../classes/equipment-kind';
import { Moment } from 'moment';
import { HttpClient, HttpProgressEvent } from '@angular/common/http';
import { Family } from '../classes/family';
import { Equipment } from '../classes/equipment';
import { merge, omit } from 'lodash-es';

export interface IFileData {
  file: File;
  family: Family;
  kind: EquipmentDocumentKind;
  description: string;
  title?: string;
}

@Injectable({
  providedIn: 'root',
})
export class EquipmentDocumentService {
  constructor(@Inject(LibConfigService) protected config: LibConfig, protected http: HttpClient) {}

  public getEquipmentDocuments(filter: { eqId: string; kind?: string | string[] }): Observable<EquipmentDocument[]> {
    const params: any = { eqId: filter.eqId };
    if (filter.kind) {
      params.kinds = Array.isArray(filter.kind) ? filter.kind : [filter.kind];
    }
    return this.http
      .get<any[]>(`${this.config.environment.apiUrl}/equipment-documents`, {
        params,
      })
      .pipe(map(data => data.map(d => new ModelMapper(EquipmentDocument).map(d))));
  }

  public get(id: string): Observable<EquipmentDocument> {
    return this.http
      .get<any>(`${this.config.environment.apiUrl}/equipment-documents/${id}`)
      .pipe(map(data => new ModelMapper(EquipmentDocument).map(data)));
  }

  public getEquipments(id: string): Observable<Equipment[]> {
    return this.http
      .get<any>(`${this.config.environment.apiUrl}/equipment-documents/${id}/equipments`)
      .pipe(map(data => data.map(d => new ModelMapper(Equipment).map(d))));
  }

  public addEquipmentsDocument(
    ids: string[],
    data: IFileData,
    progress?: (progress: number) => void
  ): Observable<EquipmentDocument> {
    const id = v4();
    return this.requestUploadUrl(`equipment/${data.family}/${id}`, data.file).pipe(
      mergeMap(url =>
        this.http
          .put<any>(url, data.file, {
            reportProgress: true,
            observe: 'events',
            headers: { 'Content-Type': data.file.type },
          })
          .pipe(
            tap((event: HttpProgressEvent) =>
              progress
                ? progress((event as any).type === 0 ? 0 : event.type !== 1 ? 1 : event.loaded / event.total)
                : null
            )
          )
      ),
      last(),
      mergeMap(() =>
        this.http.post<any>(`${this.config.environment.apiUrl}/equipment-documents`, {
          family: data.family,
          kind: data.kind,
          title: data.title || data.file.name,
          description: data.description,
          filename: data.file.name,
          path: `equipment/${data.family}/${id}/${data.file.name}`,
          size: data.file.size,
          checksum: null,
          date: data.file.lastModified,
          mimetypes: [data.file.type],
          equipmentIds: ids,
        })
      ),
      map(d => new ModelMapper(EquipmentDocument).map(d))
    );
  }

  public updateEquipmentDocument(
    id: string,
    data: {
      family: Family;
      kind: EquipmentDocumentKind;
      title: string;
      description: string;
    },
    equipmentIds?: string[],
    file?: File
  ): Observable<boolean> {
    if (file) {
      const fileId = v4();
      return this.requestUploadUrl(`equipment/${data.family}/${fileId}`, file).pipe(
        mergeMap(url =>
          this.http.put<any>(url, file, {
            headers: { 'Content-Type': file.type },
          })
        ),
        mergeMap(() =>
          this.http.patch<boolean>(`${this.config.environment.apiUrl}/equipment-documents/${id}`, {
            family: data.family,
            kind: data.kind,
            title: data.title || file.name,
            description: data.description,
            filename: file.name,
            path: `equipment/${data.family}/${fileId}/${file.name}`,
            size: file.size,
            checksum: null,
            date: file.lastModified,
            mimetypes: [file.type],
            equipmentIds,
          })
        )
      );
    }
    return this.http.patch<boolean>(
      `${this.config.environment.apiUrl}/equipment-documents/${id}`,
      merge(omit(data, 'id'), { equipmentIds })
    );
  }

  public removeEquipmentDocument(eqIds: string[], id: string): Observable<boolean> {
    return this.http.delete<boolean>(`${this.config.environment.apiUrl}/equipment-documents/${id}`, {
      params: { eqIds },
    });
  }

  private requestUploadUrl(prefix: string, file: File): Observable<string> {
    const data: any = { prefix, name: file.name, type: file.type };
    return this.http.post(`${this.config.environment.apiUrl}/equipment-documents/get-upload-url`, data, {
      responseType: 'text',
    });
  }
}
