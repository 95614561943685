export const TechnicalCheckLabels = [
    { key: 'visitDate', value: 'Date de la visite' },
    { key: 'deactivationAsked', value: "Mise à l'arrêt demandée (O/N)" },
    { key: 'telemonitoringAttempt', value: 'Essai de la télésurveillance (OK/KO)' },
    {
      key: 'clearPassageWidthDisabledAccessibility',
      value: 'Largeur du passage libre en cm (accessibilité handicapé)',
    },
    { key: 'magneticLoopForDeaf', value: 'Boucle magnétique malentendant en cabine (OK/KO/NA)' },
    { key: 'maintenanceQuality', value: 'Qualité de la maintenance (1 à 5)' },
    { key: 'trainingState', value: 'Etat entrainement (A/B/C/D)' },
    { key: 'switchgearCabinetState', value: 'Etat armoire de manœuvre (A/B/C/D)' },
    { key: 'overspeedDescentDeviceState', value: 'Etat dispositif de survitesse descente et montée (A/B/C/D)' },
    { key: 'screenDoorsState', value: 'Etat des portes palières (A/B/C/D)' },
    { key: 'cabinDressingState', value: 'Etat habillage cabine (A/B/C/D)' },
    { key: 'picturePresence', value: 'Présence des photos (O/N)' },
    { key: 'ce', value: 'CE / NON CE' },
    {
      key: 'lockScreenDoors',
      value: '1. Serrures munies de dispositifs de contrôle de la fermeture et du verrouillage des portes palières',
    },
    {
      key: 'devicePreventingSuchActs',
      value:
        '2. Lorsqu’il est nécessaire de prévenir des actes de nature à porter atteinte au verrouillage de la porte palière, un dispositif empêchant ou limitant de tels actes',
    },
    {
      key: 'deviceProtectingAgainstSlidingDoors',
      value:
        '3. Dispositif de détection de la présence des personnes destiné à les protéger contre le choc des portes coulissantes lors de leur fermeture',
    },
    {
      key: 'fenceElevatorShaft',
      value:
        '4. Clôture de la gaine d’ascenseur empêchant l’accès à cette gaine et aux éléments de déverrouillage des serrures de portes palières',
    },
    {
      key: 'cabinParachute',
      value: '5. Pour les ascenseurs électriques, un parachute de cabine et un limiteur de vitesse en descente',
    },
    {
      key: 'deviceAgainstFall',
      value:
        '6. Dispositif destiné à éviter toute chute en gaine lorsque la cabine est immobilisée en dehors de la zone de déverrouillage',
    },
    {
      key: 'inspectionManeuverControl',
      value:
        '7. Commande de manœuvre d’inspection et d’arrêt de la cabine en vue de protéger les personnels d’intervention opérant sur le toit de la cabine, en gaine ou en cuvette',
    },
    {
      key: 'deviceEnablingSafeAccess',
      value:
        '8. Dispositifs permettant aux personnels d’intervention d’accéder sans danger aux locaux de machines ou de poulies',
    },
    {
      key: 'lockingSystem',
      value:
        '9. Un système de verrouillage des portes et portillons destinés à la visite technique de la gaine et de la cuvette ainsi que des portes de secours, avec une commande automatique de l’arrêt de l’ascenseur lors de l’ouverture de ces portes et portillons par les personnels d’intervention',
    },
    { key: 'overallReport2010', value: 'Avis global 2010' },
    {
      key: 'controlSystemCabinLevelMaintenanceBefore1983',
      value:
        '1. Dans les ascenseurs installés dans les établissements recevant du public avant le 01/01/1983, système de contrôle, d’arrêt et maintien à niveau de la cabine afin d’assurer l’accessibilité sans danger à tous les niveaux desservis',
    },
    {
      key: 'alarmSystemBtwCabin',
      value:
        '2. Un système de téléalarme entre la cabine et un service d’intervention et un éclairage de secours en cabine',
    },
    {
      key: 'sufficientResistanceScreenDoors',
      value: '3. Une résistance mécanique suffisante des portes palières lorsqu’elles comportent un vitrage',
    },
    {
      key: 'preventionSystemOverspeed',
      value:
        '4. Pour les ascenseurs hydrauliques, un système de prévention des risques de chute libre, de dérive et d’excès de vitesse de la cabine end',
    },
    {
      key: 'protectionAgainstUnprotectedConductors',
      value:
        '5. Une protection avec marquage ou signalisation éliminant le risque de contact direct des personnels d’intervention avec des composants ou conducteurs nus sous tension, dans les armoires de commande, les armoires électriques et les tableaux d’arrivée de courant',
    },
    {
      key: 'protectionAgainstTrapping',
      value:
        '6. Un dispositif de protection des personnels d’intervention contre le risque de happement par les organes mobiles de transmission, notamment les poulies câbles ou courroies',
    },
    {
      key: 'fixedLighting',
      value:
        '7. Un éclairage fixe du local de machines ou de poulies assurant un éclairement suffisant des zones de travail et de circulation',
    },
    { key: 'overallReport2014', value: '  Avis global 2014' },
    {
      key: 'controlSystemCabinLevelMaintenanceAfter1983',
      value:
        '1. Dans les ascenseurs installés dans les établissements recevant du public après le 31/12/1982, système de contrôle, d’arrêt et maintien à niveau de la cabine afin d’assurer l’accessibilité sans danger à tous les niveaux desservis',
    },
    {
      key: 'protectionAgainstOverspeedUp',
      value:
        '2. Dans les ascenseurs électriques à adhérence, un système de protection contre la vitesse excessive de la cabine en montée',
    },
    { key: 'overallReport2018', value: '  Avis global 2018' },
    { key: 'overallReportRobien', value: ' Avis global De Robien (Non CE)' },
    { key: 'ceMarking', value: "1. Marquage CE en cabine accompagné du numéro d'organisme notifié" },
    { key: 'declarationOfConformity', value: "2. Déclaration de conformité de l'installation" },
    { key: 'instructionNotice', value: "3. Notice d'instructions" },
    { key: 'conclusion', value: 'Conclusion (CE)' },
    { key: 'protectionWalls', value: '_1.1_ Parois de protection' },
    { key: 'servicePanels', value: '_1.2_ Panneaux de service, portes, portillons de visite, portes de secours' },
    { key: 'footGuards', value: '_1.3_ Garde-pieds, seuils' },
    { key: 'accessToBowl', value: '_1.4_ Moyen d’accès à la cuvette' },
    { key: 'lighting15', value: '_1.5_ Eclairage' },
    { key: 'sheath', value: '_1_ Gaine' },
    { key: 'generalState', value: '_2.1_ Etat général' },
    { key: 'stoppingMechanism', value: "_2.2_ Dispositif d'arrêt" },
    { key: 'helpMechanism', value: '_2.3_ Dispositif de demande de secours' },
    { key: 'reclosingDoor', value: '_2.4_ Refermeture porte palière (pêne carré)' },
    { key: 'shockAbsorber', value: '_2.5_ Amortisseurs, socles, butées…' },
    { key: 'lighting26', value: '_2.6_ Eclairage' },
    { key: 'bowl', value: '_2_ Cuvette' },
    { key: 'guidingElements', value: '_3.1_ Eléments de guidage' },
    { key: 'guiding', value: '_3_ Guidages' },
    { key: 'cabinPresenceSignal', value: '_4.1_ Signalisation présence cabine, sens de déplacement' },
    { key: 'displayCabMoving', value: '_4.2_ Affichage (déplacement de la cabine)' },
    { key: 'firemenManeuver', value: '_4.3_ Manœuvre pompiers' },
    { key: 'controlUnitsWithIndicator', value: '_4.4_ Organes de commande avec voyant' },
    { key: 'bearingEquipment', value: '_4_ Equipement des paliers' },
    {
      key: 'locksElectricalControlEfficiency',
      value: '_5.1_ Serrures, contrôle électrique, efficacité, inaccessibilité, protection projections de liquides',
    },
    { key: 'electricalCondemnations', value: '_5.2_ Condamnations électriques contrôle de fermeture' },
    { key: 'emergencyUnlocking', value: '_5.3_ Déverrouillage de secours' },
    { key: 'soundLightSignal', value: '_5.4_ Signal sonore et lumineux' },
    { key: 'componentsGlazing', value: '_5.5_ Eléments constitutifs (vitrage)' },
    { key: 'landingDoors', value: '_5_ Portes palières' },
    { key: 'features', value: '_6.1_ Caractéristiques' },
    { key: 'generalState62', value: '_6.2_ Etat général' },
    { key: 'fasteners', value: '_6.3_ Attaches' },
    { key: 'pulleysSprockets', value: '_6.4_ Poulies, pignons, protecteurs' },
    { key: 'cylinders', value: '_6.5_ Vérin' },
    { key: 'display', value: '_6.6_ Affichage' },
    { key: 'suspensionsOrgans', value: '_6_ Organes de suspension' },
    { key: 'constitutiveElements', value: '_7.1_ Eléments constitutifs (parois, plancher, toit)' },
    { key: 'emergencyHatches', value: '_7.2_ Portes ou trappes de secours  (contrôle de fermeture, verrouillage)' },
    { key: 'servicesFaces', value: '_7.3_ Faces de service (jeux)' },
    { key: 'cabinWithoutDoor', value: '_7.4_ Baie de cabine sans porte (dispositif équivalent)' },
    { key: 'cabinDoor', value: '_7.5_ Porte de cabine (protection passage)' },
    { key: 'lockingMechanism', value: '_7.6_ Dispositif de verrouillage' },
    { key: 'doorClosingControl', value: '_7.7_ Contrôle de fermeture de la porte de cabine' },
    { key: 'normalLighting', value: '_7.8_ Eclairage normal' },
    { key: 'ventilation', value: '_7.9_ Ventilation' },
    { key: 'display710', value: '_7.10_ Affichage' },
    { key: 'emergencyLighting', value: '_7.11_ Eclairage de secours' },
    { key: 'footGuardElectricalContact', value: ' _7.12_ Garde pieds (déploiement contact électrique)' },
    { key: 'cabin', value: '_7_ Cabine' },
    { key: 'commandOrgans', value: '_8.1_ Organes de commande' },
    { key: 'stopMechanism', value: "_8.2_ Dispositif d'arrêt" },
    { key: 'reopeningDoorsButton', value: '_8.3_ Bouton de réouverture des portes' },
    { key: 'emergencyRequestMechanism', value: '_8.4_ Dispositif de demande de secours' },
    { key: 'cabinCommandTool', value: '_8_ Organes de commande en cabine' },
    { key: 'stopMechanismCabinRoof', value: '_9.1_ Dispositif d’arrêt sur toit de cabine' },
    { key: 'roofInspectionManeuver', value: '_9.2_ Manoeuvre d’inspection sur le toit' },
    { key: 'balustrade', value: '_9.3_ Balustrade' },
    { key: 'emergencyRequestTool', value: '_9.4_ Dispositif de demande de secours' },
    { key: 'cabinRooftop', value: '_9_ Toit de cabine' },
    { key: 'counterweights', value: '_10.1_ Eléments constitutifs des contre - poids' },
    { key: 'compensationOrgans', value: '_10.2_ Eléments constitutifs des organes de compensation' },
    { key: 'counterweightsCompensationOrgans', value: '_10_ Contrepoids, organes de compensation' },
    { key: 'cabinParachuteForElectricalElevator', value: '_11.1_ Parachute cabine pour ascenseurs électriques' },
    { key: 'counterweightParachute', value: '_11.2_ Parachute contre-poids' },
    { key: 'overspeedLimitator', value: '_11.3_ Limiteur de vitesse si ascenseur électrique' },
    {
      key: 'deviceAgainstOverspeedUp',
      value: "_11.4_ Dispositif s'opposant à la vitesse excessive de la cabine en montée",
    },
    {
      key: 'lockingMechanismForMaintenance',
      value: ' _11.5_ Dispositif de verrouillage de la cabine pour les opérations de maintenance',
    },
    { key: 'stopLimiterCabin', value: '_11.6_ Butée ou limiteur cabine (maintenance)' },
    { key: 'checkingRuptureDevice', value: '_11.7_ Dispositif de contrôle de rupture ou de mou de suspente' },
    { key: 'liaisonBody', value: '_11.8_ Organe de liaison (position cabine)' },
    { key: 'outOfNormalManeuver', value: '_11.9_ Hors course en manœuvre normale' },
    { key: 'limiterOfStrokeInspection', value: '_11.10_ Limiteur de course inspection' },
    { key: 'parachuteAndSpeedLimitator', value: '_11.11_ Parachute et limiteur de vitesse si ascenseur hydraulique' },
    { key: 'deviceAgainstDrifting', value: '_11.12_ Dispositif s’opposant à la dérive si ascenseur hydraulique' },
    { key: 'securityMechanism', value: '_11_ Dispositifs de sécurité' },
    { key: 'accessToPremises', value: '_12.1_ Accès aux locaux' },
    { key: 'ground', value: '_12.2_ Sol' },
    { key: 'internalAccessMachineRoom', value: '_12.3_ Accès intérieur(s) au local machine' },
    { key: 'switchDrivingForce', value: '_12.4_ Interrupteur force motrice' },
    { key: 'normalEmergencyLightning', value: '_12.5_ Eclairage normal et de secours' },
    { key: 'localSwitchPulleys', value: "_12.6_ Interrupteur d'arrêt local des poulies" },
    { key: 'machinePulleyLocations', value: '_12_ Locaux de la machine et des poulies' },
    { key: 'mechanisms', value: '_13.1_ Mécanismes' },
    { key: 'manualEmergencyManeuver', value: '_13.2_ Manœuvre de secours manuelle' },
    { key: 'electricalManeuverRapel', value: ' _13.3_ Manœuvre électrique de rappel' },
    { key: 'protectionMobileComponents', value: '_13.4_ Protection des organes mobiles de transmission' },
    { key: 'cabStoppingAccuracy', value: '_13.5_ Précision d’arrêt de la cabine' },
    { key: 'machine', value: '_13_ Machine' },
    { key: 'metalicMassesInterconnections', value: '_14.1_ Interconnexion des masses métalliques' },
    { key: 'generalStateConstituents', value: '_14.2_ Etat général des éléments constitutifs' },
    { key: 'protectionAgainstDirectContacts', value: ' _14.3_ Protection contre les contacts directs' },
    { key: 'electricity', value: '_14_ Electricité' },
    { key: 'documentaryAspect', value: '_15.1_ Aspect documentaire' },
    { key: 'various', value: '_15.2_ Divers' },
    { key: 'otherPoints', value: '_15_ Autres points' },
    { key: 'totalCountObservations', value: "Nombre total d'observations" },
    { key: 'designationVeritas', value: 'Désignation' },
    { key: 'rvratPresenceVeritas', value: "Présence d'un RVRAT" },
    { key: 'ceMarkingVeritas', value: 'Marquage CE' },
    { key: 'ceNumberVeritas', value: 'Numéro CE' },
    { key: 'machineryPositionVeritas', value: 'Position de la machinerie' },
    { key: 'machineryAccessVeritas', value: 'Accès à la machinerie' },
    { key: 'otherMachineryAccessVeritas', value: 'Autre accès à  la machinerie' },
    { key: 'accessToBowlVeritas', value: 'Accès à la cuvette' },
    { key: 'hangersVeritas', value: 'Suspentes' },
    { key: 'hangersCountVeritas', value: 'Nombre de suspentes' },
    { key: 'diameterStepMmVeritas', value: 'Diamètre/Pas (mm)' },
    { key: 'levelCountVeritas', value: 'Nombre de niveau' },
    { key: 'serviceCountVeritas', value: 'Nombre de services' },
    { key: 'chargeKgVeritas', value: 'Charge (kg)' },
    { key: 'personCountVeritas', value: 'Nombre de personnes' },
    { key: 'speedMSVeritas', value: 'Vitesse (m/s)' },
    { key: 'electricDriveVeritas', value: 'Entraînement électrique' },
    { key: 'otherElectricDriveVeritas', value: 'Autre entraînement électrique' },
    { key: 'hydraulicDriveVeritas', value: 'Entraînement hydraulique' },
    { key: 'cabinDoorsVeritas', value: 'Portes cabines' },
    { key: 'landingDoorsVeritas', value: 'Portes palières' },
  ];