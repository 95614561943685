/** @format */

import { HttpClient } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import More from 'highcharts/highcharts-more';
import * as Highcharts from 'highcharts/highstock';
import Boost from 'highcharts/modules/boost';
import ExportData from 'highcharts/modules/export-data';
import Exporting from 'highcharts/modules/exporting';
import noData from 'highcharts/modules/no-data-to-display';
import { cloneDeep, isEqual, isNil } from 'lodash-es';
import moment, { Moment } from 'moment';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ChartComponent, fadeOut, highchartsOptions } from 'sesio-lib';
import { environment } from '../../../../../../../../environments/environment';
Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
Exporting(Highcharts);
ExportData(Highcharts);

interface IRange {
  start: Moment;
  end: Moment;
}

@Component({
  selector: 'app-weather-chart',
  templateUrl: './weather-chart.component.html',
  styleUrls: ['./weather-chart.component.scss'],
  animations: [fadeOut],
})
export class WeatherChartComponent implements OnInit, OnDestroy {
  @Input('range')
  public set setRange(range: UntypedFormGroup) {
    if (this.rangeSub) {
      this.rangeSub.unsubscribe();
      this.rangeSub = null;
    }
    this.range = range;
    if (this.range) {
      this.rangeValue = this.range.value;
      this.rangeSub = this.range.valueChanges.pipe(debounceTime(100)).subscribe({
        next: value => {
          if (value && value.start && value.end && !isEqual(this.rangeValue, value) && this.chart) {
            this.rangeValue = value;
            this.loadData();
          }
        },
      });
    } else {
      this.rangeValue = null;
    }
  }

  options = {
    chart: {
      zoomType: 'x',
    },
    title: '',
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            'viewFullscreen',
            'printChart',
            'separator',
            'downloadPNG',
            'downloadJPEG',
            'downloadPDF',
            'downloadSVG',
            'separator',
            'downloadCSV',
            'downloadXLS',
            'openInCloud',
          ],
        },
      },
    },
    credits: {enabled: false},
    rangeSelector: {enabled: false, inputEnabled: false},
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              itemWidth: 200,
            },
          },
        },
      ],
    },
    legend: {
      enabled: true,
      verticalAlign: 'top',
    },
    tooltip: {
      shared: true,
      split: false,
      useHTML: true,
      headerFormat: '<small>{point.key}</small><br/>',
    },
    xAxis: {
      type: 'category',
      tickmarkPlacement: 'on',
      startOnTick: true,
      endOnTick: true,
      events: {
        afterSetExtremes: event => {
          if (event.type !== 'setExtremes') return;
          this.drawWeatherSymbols();
          if (event.min !== this.rangeValue?.start.valueOf() || event.max !== this.rangeValue?.end.valueOf()) {
            this.rangeValue = {start: moment(event.min), end: moment(event.max)};
            this.range.setValue(cloneDeep(this.rangeValue));
          }
        },
      },
    },
    yAxis: [
      {
        // temperature axis
        opposite: false,
        allowDecimals: false,
        title: {
          text: '°C',
          offset: 0,
          align: 'high',
          rotation: 0,
          style: {
            fontSize: '10px',
            color: '#FF3333',
          },
          textAlign: 'left',
          x: -18,
        },
        labels: {
          format: '{value:.1f}',
          style: {
            fontSize: '8px',
            color: '#FF3333',
          },
          x: -3,
        },
        plotLines: [
          {
            // zero plane
            value: 0,
            color: '#BBBBBB',
            width: 1,
            zIndex: 2,
          },
        ],
        maxPadding: 0.3,
        minRange: 8,
        tickInterval: 1,
        gridLineColor: 'rgba(128, 128, 128, 0.1)',
        lineWidth: 1,
      },
      {
        // precipitation axis
        title: {
          text: null,
        },
        labels: {
          enabled: false,
        },
        gridLineWidth: 0,
        tickLength: 0,
        minRange: 10,
        min: 0,
      },
      {
        // Air pressure
        allowDecimals: false,
        title: {
          // Title on top of axis
          text: 'hPa',
          offset: 0,
          align: 'high',
          rotation: 0,
          style: {
            fontSize: '10px',
            color: Highcharts.getOptions().colors[2],
          },
          textAlign: 'left',
          x: 3,
        },
        labels: {
          format: '{value:.0f}',
          style: {
            fontSize: '8px',
            color: Highcharts.getOptions().colors[2],
          },
          y: 2,
          x: 3,
        },
        gridLineWidth: 0,
        opposite: true,
        showLastLabel: false,
        lineWidth: 1,
      },
      {
        // Wind
        allowDecimals: false,
        title: {
          // Title on top of axis
          text: 'km/h',
          offset: 0,
          align: 'high',
          rotation: 0,
          style: {
            fontSize: '10px',
            color: Highcharts.getOptions().colors[3],
          },
          textAlign: 'left',
          x: 8,
        },
        labels: {
          format: '{value:.0f}',
          style: {
            fontSize: '8px',
            color: Highcharts.getOptions().colors[3],
          },
          y: 2,
          x: 8,
        },
        gridLineWidth: 0,
        opposite: true,
        showLastLabel: false,
      },
    ],
    series: [
      {
        name: this.translate.instant('temperature'),
        type: 'spline',
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
        tooltip: {
          pointFormat:
            '<span style="color:{point.color}">\u25CF</span> ' + '{series.name}: <b>{point.y:.1f}°C</b><br/>',
        },
        zIndex: 1,
        color: '#FF3333',
        negativeColor: '#48AFE8',
      },
      {
        name: this.translate.instant('precipitation'),
        type: 'column',
        color: '#68CFE8',
        yAxis: 1,
        groupPadding: 0,
        pointPadding: 0,
        grouping: false,
        dataLabels: {
          // enabled: !this.hasPrecipitationError,
          // tslint:disable-next-line: object-literal-shorthand typedef space-before-function-paren
          formatter: function () {
            if (this.y > 0) {
              return this.y.toFixed(1);
            }
          },
          style: {
            fontSize: '8px',
            color: 'gray',
          },
        },
        tooltip: {
          pointFormat:
            '<span style="color:{point.color}">\u25CF</span> ' + '{series.name}: <b>{point.y:.1f}mm</b><br/>',
        },
      },
      {
        name: this.translate.instant('air-pressure'),
        color: Highcharts.getOptions().colors[2],
        marker: {
          enabled: false,
        },
        shadow: false,
        tooltip: {
          pointFormat:
            '<span style="color:{point.color}">\u25CF</span> ' + '{series.name}: <b>{point.y:.1f}hPa</b><br/>',
        },
        dashStyle: 'shortdot',
        yAxis: 2,
      },
      {
        name: this.translate.instant('wind-avg'),
        color: Highcharts.getOptions().colors[3],
        type: 'spline',
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
        tooltip: {valueSuffix: ' km/h'},
        yAxis: 3,
      },
      {
        name: this.translate.instant('windgust'),
        color: Highcharts.getOptions().colors[3],
        type: 'spline',
        dashStyle: 'shortdot',
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
        tooltip: {valueSuffix: ' km/h'},
        yAxis: 3,
      },
    ],
  };

  private range: UntypedFormGroup;
  private rangeValue: IRange;
  private rangeSub: Subscription;

  private hasPrecipitationError: boolean;
  private symbols: string[] = [];
  private precipitations: [number, number][] = [];
  private temperatures: [number, number][] = [];
  private pressures: [number, number][] = [];
  private winds: [number, number][] = [];
  private windgusts: [number, number][] = [];
  private images: Highcharts.SVGElement[] = [];
  private loaded = false;

  constructor(private http: HttpClient, private translate: TranslateService) {}

  ngOnInit(): void {
    Highcharts.setOptions(highchartsOptions[this.translate.getDefaultLang()]);
  }

  ngOnDestroy(): void {
    if (this.rangeSub) this.rangeSub.unsubscribe();
  }

  private chart: ChartComponent;
  public async load(chart: ChartComponent) {
    if (chart) this.chart = chart;
    else if (!this.chart) return;
    await this.loadData();
  }

  private drawWeatherSymbols(): void {
    if (!this.chart) {
      return;
    }
    this.images.forEach(i => i.destroy());
    this.images = [];
    this.temperatures.forEach((point: any, i) => {
      if (isNil(point.plotX)) {
        return;
      }
      const x = point.plotX + this.chart.chart.plotLeft - 15;
      if (x < 0) {
        return;
      }
      const y = point.plotY + this.chart.chart.plotTop - 30;
      this.images.push(this.chart.chart.renderer.image(this.symbols[i], x, y, 30, 30).attr({zIndex: 5}).add());
    });
  }

  private async loadData(): Promise<void> {
    this.chart.setLoading(true);

    const data = await this.http.get<any>(`${environment.apiUrl}/weather/weather-chart`).toPromise();
    this.temperatures = data.temperatures.map(d => [d.x, d.y]);
    this.symbols = data.temperatures.map(t => this.weatherIconFromCode(t.weatherCode));
    this.precipitations = data.precipitations.map(d => [d.x, d.y]);
    this.pressures = data.pressures.map(d => [d.x, d.y]);
    this.winds = data.winds.map(d => [d.x, d.y]);
    this.windgusts = data.windgusts.map(d => [d.x, d.y]);

    this.chart.setSerieData(this.temperatures, 4);
    this.chart.setSerieData(this.precipitations, 3);
    this.chart.setSerieData(this.pressures, 2);
    this.chart.setSerieData(this.winds, 1);
    this.chart.setSerieData(this.windgusts, 0);

    this.chart.setExtremes(this.rangeValue.start, this.rangeValue.end);
    this.chart.setLoading(false);
  }

  private weatherIconFromCode(code: number): string {
    let icon: string;
    switch (code) {
      case 113:
        icon = 'wi-day-sunny';
        break;
      case 116:
        icon = 'wi-cloud';
        break;
      case 119:
        icon = 'wiw-cloudy';
        break;
      case 122:
        icon = 'wi-fog';
        break;
      case 143:
        icon = 'wi-sprinkle';
        break;
      case 176:
        icon = 'wi-sprinkle';
        break;
      case 179:
        icon = 'wi-snow';
        break;
      case 182:
        icon = 'wi-sleet';
        break;
      case 185:
        icon = 'wi-sleet';
        break;
      case 200:
        icon = 'wi-storm-showers';
        break;
      case 227:
        icon = 'wi-snow';
        break;
      case 230:
        icon = 'wi-snow-wind';
        break;
      case 248:
        icon = 'wi-fog';
        break;
      case 260:
        icon = 'wi-fog';
        break;
      case 263:
        icon = 'wi-sprinkle';
        break;
      case 266:
        icon = 'wi-sprinkle';
        break;
      case 281:
        icon = 'wi-sleet';
        break;
      case 284:
        icon = 'wi-sleet';
        break;
      case 293:
        icon = 'wi-sprinkle';
        break;
      case 296:
        icon = 'wi-sprinkle';
        break;
      case 299:
        icon = 'wi-showers';
        break;
      case 302:
        icon = 'wi-showers';
        break;
      case 305:
        icon = 'wi-rain';
        break;
      case 308:
        icon = 'wi-rain';
        break;
      case 311:
        icon = 'wi-sleet';
        break;
      case 314:
        icon = 'wi-sleet';
        break;
      case 317:
        icon = 'wi-sleet';
        break;
      case 320:
        icon = 'wi-sleet';
        break;
      case 323:
        icon = 'wi-snow';
        break;
      case 326:
        icon = 'wi-snow';
        break;
      case 329:
        icon = 'wi-snow';
        break;
      case 332:
        icon = 'wi-snow';
        break;
      case 335:
        icon = 'wi-snow';
        break;
      case 338:
        icon = 'wi-snow';
        break;
      case 350:
        icon = 'wi-hail';
        break;
      case 353:
        icon = 'wi-sprinkle';
        break;
      case 356:
        icon = 'wi-rain';
        break;
      case 359:
        icon = 'wi-rain';
        break;
      case 362:
        icon = 'wi-sleet';
        break;
      case 365:
        icon = 'wi-sleet';
        break;
      case 368:
        icon = 'wi-snow';
        break;
      case 371:
        icon = 'wi-snow';
        break;
      case 374:
        icon = 'wi-sleet';
        break;
      case 377:
        icon = 'wi-hail';
        break;
      case 386:
        icon = 'wi-storm-showers';
        break;
      case 389:
        icon = 'wi-thunderstorm';
        break;
      case 392:
        icon = 'wi-storm-showers';
        break;
      case 395:
        icon = 'wi-storm-showers';
        break;
      default:
        return null;
    }
    return `assets/icon/weather-code-icon/${icon}.svg`;
  }
}
