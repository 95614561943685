<mat-form-field *ngIf="control" [appearance]="appearance" [class.has-prefix-top]="!!selectControl.value?.id">
  <mat-label translate>{{label}}</mat-label>
  <input matInput type="text" [formControl]="selectControl" [matAutocomplete]="auto" (focus)="onfocus()"
    autocomplete="off">
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayOption">
    <mat-option *ngIf="searching" class="full-width">
      <mat-progress-bar @fade mode="buffer"></mat-progress-bar>
    </mat-option>
    <ng-container *ngIf="options$ | async as options">
      <mat-option *ngFor="let option of options" [value]="option">
        <div fxLayout="column" fxLayoutAlign="center start">
          <div *ngIf="option?.pathNames?.length > 2" class="option-prefix-top">
            {{option.pathNames.slice(1, option.pathNames.length - 1).join(' > ')}}
          </div>
          <span><span light color="primary">{{option.parent ? option.code : ''}}</span>&nbsp;{{option.name}}</span>
        </div>
      </mat-option>
      <mat-option *ngIf="!searching && searchMatched > options.length" class="full-width">
        <mat-progress-bar @fade mode="buffer" intersectionObserver (intersection)="loadMore($event)"></mat-progress-bar>
      </mat-option>
    </ng-container>
  </mat-autocomplete>
  <mat-label *ngIf="selectControl.value?.pathNames?.length > 2" matPrefix class="prefix-top">
    {{selectControl.value.pathNames.slice(1, selectControl.value.pathNames.length - 1).join(' > ')}}
  </mat-label>
  <button *ngIf="selectControl.value" mat-icon-button size="small" matSuffix class="form-field-clear-btn"
    (click)="selectControl.setValue(null)">
    <mat-icon>close</mat-icon>
  </button>
  <mat-error *ngIf="control.errors && selectControl.touched" [translateParams]="control.errors | values | head"
    translate="form-field-error-{{control.errors | keys | head}}"></mat-error>
</mat-form-field>