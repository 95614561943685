/** @format */

import { Pipe, PipeTransform } from '@angular/core';
import { get } from 'lodash';

@Pipe({ name: 'get' })
export class GetPipe implements PipeTransform {
  transform(data: any, field: string, defaultValue?: any): any {
    return get(data, field, defaultValue);
  }
}
