import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { TechnicalControlObservationModal } from './technical-control-observation.modal';
import { LoaderModule } from '../loader/loader.module';
import { TrackingModule } from '../tracking/tracking.module';


@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        TranslateModule.forChild(),
        FlexLayoutModule,
        LoaderModule,
        TrackingModule
    ],
    declarations: [TechnicalControlObservationModal],
    exports: [TechnicalControlObservationModal]
})
export class TechnicalControlObservationModule { }
