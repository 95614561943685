import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatagridComponent } from './datagrid.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { SafePipeModule } from 'safe-pipe';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { InViewportModule } from 'ng-in-viewport';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatBadgeModule } from '@angular/material/badge';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { DatagridCellDirective } from './datagrid-cell.directive';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { HttpClientModule } from '@angular/common/http';
import { AngularResizeEventModule } from 'angular-resize-event';
import { NgPipesModule } from 'ngx-pipes';
import { MatTableModule } from '@angular/material/table';
import { MatDatepickerModule } from '@angular/material/datepicker';

@NgModule({
  declarations: [DatagridComponent, DatagridCellDirective],
  imports: [
    CommonModule,
    HttpClientModule,
    TranslateModule.forChild(),
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    NgScrollbarModule,
    SafePipeModule,
    MatRippleModule,
    MatIconModule,
    InViewportModule,
    ScrollingModule,
    DragDropModule,
    NgPipesModule,
    FilterPipeModule,
    MatButtonModule,
    MatCheckboxModule,
    MatBadgeModule,
    MatTooltipModule,
    MatMenuModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatAutocompleteModule,
    AngularResizeEventModule,
    MatTableModule,
    MatDatepickerModule,
  ],
  exports: [DatagridComponent, DatagridCellDirective],
})
export class DatagridModule {}
