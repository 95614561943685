import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  Family,
  EquipmentDocumentKind,
} from 'sesio-lib';
import { TranslateService } from '@ngx-translate/core';
import { get, map, values } from 'lodash-es';
import { ModalController } from '@ionic/angular';
import { DatagridComponent, IOptions } from '../../datagrid/datagrid.component';
import { IRecord } from '../../datagrid/datasource';
import { AdminEquipmentDocumentEditModal } from './edit-modal/admin-equipment-document-edit.modal';
import {
  IDatatableOptions,
  IDatatableRecords,
} from '../../datagrid/datatable.class';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { SubSink } from 'subsink';
import { EquipmentDocumentervice } from '../../../_services/equipment-document.service';

@Component({
  selector: 'app-equipment-datagrid-document',
  templateUrl: './admin-equipment-document.component.html',
  styleUrls: ['./admin-equipment-document.component.scss'],
})
export class AdminEquipmentDocumentComponent implements OnInit, OnDestroy {
  public families: Family[];
  public options: IOptions;
  private modal: HTMLIonModalElement;
  private subsink = new SubSink();

  constructor(
    public service: EquipmentDocumentervice,
    private translate: TranslateService,
    private modalController: ModalController,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.subsink.add(
      this.route.data.subscribe({
        next: (data) => {
          this.families = get(data, 'families', values(Family));
          this.loadOptions();
        },
      })
    );
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  public async edit(
    datagrid: DatagridComponent,
    record?: IRecord
  ): Promise<void> {
    if (this.modal) {
      return;
    }
    this.modal = await this.modalController.create({
      component: AdminEquipmentDocumentEditModal,
      cssClass: 'xwide-modal',
      componentProps: {
        id: record?._id,
        families: this.families,
      },
    });
    await this.modal.present();
    this.modal.onWillDismiss().then(() => (this.modal = null));
    const res = await this.modal.onDidDismiss();
    if (res.data) {
      datagrid.loadPage();
    }
  }

  private loadOptions() {
    this.options = {
      pagination: { default: 2, options: [10, 20, 50, 100] },
      service: (
        query: IDatatableOptions
      ): Observable<IDatatableRecords<IRecord>> =>
        this.service.datatable({ families: this.families, query }),
      columns: [
        {
          label: 'kind',
          property: 'kind',
          type: 'select',
          options: map(values(EquipmentDocumentKind), (value) => ({
            value,
            name: this.translate.instant(value),
          })),
          sortable: true,
          searchable: true,
        },
        {
          property: 'filename',
          label: 'filename',
          searchable: true,
          sortable: true,
        },
        { property: 'title', label: 'title', searchable: true, sortable: true },
        {
          property: 'description',
          label: 'description',
          searchable: true,
          sortable: true,
        },
        {
          property: 'equipmentCount',
          label: "Nombre d'équipement lié",
          type: 'number',
          searchable: true,
          sortable: true,
        },
        {
          property: 'size',
          label: 'size',
          content: 'filesize',
          sortable: true,
        },
        {
          property: 'date',
          label: 'Date',
          type: 'date',
          searchable: true,
          sortable: true,
        },
        {
          property: 'mimetypes',
          label: 'mimetypes',
          searchable: true,
          sortable: true,
        },
      ],
      actions: [
        {
          icon: { name: 'add_circle_outline' },
          exec: (datagrid) => this.edit(datagrid),
        },
      ],
      enableRowNumber: false,
      enableStickyColumn: false,
      enableReorderColumn: false,
      enableSelect: false,
      enableHideShowColumns: false,
      enableExport: true,
      enableFullscreen: false,
      disableScrollbarModule: false,
      rowClick: (datagrid: DatagridComponent, record: IRecord) =>
        this.edit(datagrid, record),
    };
    if (this.families?.length > 1) {
      this.options.columns.splice(0, 0, {
        property: 'family',
        label: 'family',
        type: 'select',
        searchable: true,
        sortable: true,
        options: map(this.families, (c) => ({
          value: c,
          name: this.translate.instant(c),
        })),
      });
    }
  }
}
