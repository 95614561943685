/** @format */

import { property } from 'lodash-es';
import { propertyMap } from 'model-mapper';
import { Moment } from 'moment';
import { EmbededContractor } from './embeded-contractor';
import { EmbededInspection } from './embeded-inspection';
import { EmbededIntervention } from './embeded-intervention';
import { EmbededOrganizationalUnit } from './embeded-organizational-unit';
import { EmbededEsi } from './esi';
import { File } from './file';
import { EmbededRealEstateStructure } from './real-estate-structure';
import { StatusHistory } from './status-history';

export enum OrderStatus {
  // NEW = 'new',
  TO_ACKNOWLEDGE = 'to-acknowledge',
  ACKNOWLEDGED = 'acknowledged', // intervention::ACKNOWLEDGED if VALIDATED

  PLANNED = 'planned',
  WIP = 'wip',
  DONE = 'done',
  END_OF_WORK = 'end-of-work',
  BILLED = 'billed',
  CLOSED = 'closed',

  CANCELED = 'canceled',

  // every decembre 31 at 23h59 if CLOSED
  ARCHIVED = 'archived', // quotation::ARCHIVED if all interventions END_OF_WORK | CANCELED
}

export const DirectOrderStatusList = [
  OrderStatus.TO_ACKNOWLEDGE,
  OrderStatus.ACKNOWLEDGED,
  OrderStatus.WIP,
  OrderStatus.DONE,
  OrderStatus.CLOSED,
  OrderStatus.CANCELED,
  OrderStatus.ARCHIVED,
];

export const OrderStatusColor = {
  [OrderStatus.TO_ACKNOWLEDGE]: 'var(--ion-color-warning)',
  [OrderStatus.ACKNOWLEDGED]: 'var(--ion-color-primary)',
  [OrderStatus.PLANNED]: 'var(--ion-color-primary)',
  [OrderStatus.WIP]: 'var(--ion-color-primary)',
  [OrderStatus.DONE]: 'var(--ion-color-success)',
  [OrderStatus.END_OF_WORK]: 'var(--ion-color-success)',
  [OrderStatus.BILLED]: 'var(--ion-color-success)',
  [OrderStatus.CLOSED]: 'var(--ion-color-success)',
  [OrderStatus.CANCELED]: 'var(--ion-color-medium)',
};

export class Order {
  @propertyMap({ source: '_id' })
  public id: string;

  @propertyMap()
  public reference: string;

  @propertyMap()
  public externalReference: string;

  @propertyMap()
  public nexxioReference: string;

  @propertyMap()
  public status: OrderStatus;

  @propertyMap({ type: [StatusHistory] })
  public statusHistory: StatusHistory[];

  @propertyMap()
  public organizationalUnit: EmbededOrganizationalUnit;

  @propertyMap()
  public contractor: EmbededContractor;

  @propertyMap()
  public realEstateStructure: EmbededRealEstateStructure;

  @propertyMap()
  public inspections: EmbededInspection[];

  @propertyMap()
  public interventions: EmbededIntervention[];

  @propertyMap()
  public metadata: {
    rawMessage: any;
  };

  @propertyMap()
  public nexxioData: {
    nexxioDocumentId?: string;
    nexxioNumber?: string;
    nexxioOrderNumber?: string;
  };

  @propertyMap({ source: 'metadata.rawMessage.purchase_orders' })
  public files: any[];

  @propertyMap({ type: 'Moment' })
  public createdAt: Moment;

  @propertyMap({ type: 'Moment' })
  public updatedAt: Moment;

  @propertyMap({ type: 'Moment' })
  interventionDate: Moment;

  @propertyMap({ type: File })
  interventionReport: File;
}

export class DirectOrder {
  @propertyMap({ source: '_id' })
  public id: string;

  @propertyMap()
  public reference: string;

  @propertyMap()
  public externalReference: string;

  @propertyMap()
  public orderTotal: number;

  @propertyMap({ type: Date })
  public createdAt: Date;

  @propertyMap()
  public status: OrderStatus;

  @propertyMap()
  public cancelReason: string;

  @propertyMap({ type: [StatusHistory] })
  public statusHistory: StatusHistory[];

  @propertyMap({ type: EmbededContractor })
  public contractor: EmbededContractor;

  @propertyMap({ type: EmbededOrganizationalUnit })
  public organizationalUnit: EmbededOrganizationalUnit;

  @propertyMap({ type: EmbededEsi })
  public esi: EmbededEsi;

  // @propertyMap({ type: [File] })
  // public files: File[];

  @propertyMap()
  public nexxioData: {
    nexxioDocumentId?: string;
    nexxioNumber?: string;
    nexxioOrderNumber?: string;
  };

  @propertyMap({ source: 'metadata.rawMessage.works' })
  public informations: any[];

  @propertyMap({ source: 'metadata.rawMessage.purchase_orders' })
  public files: any[];
}
