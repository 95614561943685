/** @format */

import {AfterViewInit, Component, ComponentFactoryResolver, Injector, NgZone} from '@angular/core';
// @ts-ignore
import mapboxgl from '!mapbox-gl';
import {animate, style, transition, trigger} from '@angular/animations';
import {Router} from '@angular/router';
import {isEqual} from 'lodash-es';
import {EsiMetrics, MapInfoOrganizationalUnit, OrganizationalUnitKind} from 'sesio-lib';
import {SubSink} from 'subsink';
import {environment} from '../../../../environments/environment';
import {convertHeaderSelection, IHeaderSearch} from '../../../_classes/header-search';
import {OrganizationalUnitService} from '../../../_services/organizational-unit.service';
import {SessionService} from '../../../_services/session.service';
import {EnergyTagService} from '../../tag/energy-tags/energy-tag.service';
import {EnergyTagsComponent} from '../../tag/energy-tags/energy-tags.component';

class Marker {
  public id: string;
  public name: string;
  public position: number[];
  public metrics: EsiMetrics;
  public instance: mapboxgl.Marker;

  public isPopupInitialized: boolean;
}

class Layer {
  static COLORS = [
    '#808000',
    '#00FF7F',
    '#FFFF00',
    '#BA55D3',
    '#7CFC00',
    '#FFE4E1',
    '#00FA9A',
    '#EE82EE',
    '#8A2BE2',
    '#3CB371',
    '#F5DEB3',
    '#9370DB',
    '#9966CC',
    '#0000FF',
    '#E6E6FA',
    '#228B22',
    '#5F9EA0',
    '#32CD32',
    '#E0FFFF',
    '#90EE90',
  ];

  public id: string;
  public sourceData: any;
  public color: string;
  public center: number[];
  public over: boolean;
  public item: MapInfoOrganizationalUnit;
  public bounds: [[number, number], [number, number]];
}

@Component({
  selector: 'app-dashboard-map',
  templateUrl: './map-dashboard.component.html',
  styleUrls: ['./map-dashboard.component.scss'],
  animations: [
    trigger('loading', [
      transition(':leave', [
        style({opacity: 1}), // initial
        animate('0.3s', style({opacity: 0})), // final
      ]),
    ]),
  ],
})
export class MapDashboardComponent implements AfterViewInit {
  static center = [2.3514619999900788, 48.856697000002384];

  public loading = true;
  public loadingData = false;

  public id = Date.now();

  private map: mapboxgl.Map;
  private layers: Layer[] = [];
  private markers: Marker[] = [];

  private markerElmt: HTMLDivElement = this.buildMarkerElement();
  private loadingMapTm: any;
  private filter: IHeaderSearch;
  private subsink = new SubSink();

  constructor(
    private resolver: ComponentFactoryResolver,
    private injector: Injector,
    private router: Router,
    private ngZone: NgZone,
    private energyTagService: EnergyTagService,
    private organizationalUnitService: OrganizationalUnitService,
    private sessionService: SessionService
  ) {}

  async ngAfterViewInit(): Promise<void> {
    await this.initMap();
    this.subsink.add(
      this.sessionService.$headerSelection.subscribe(selection => {
        const filter: any = convertHeaderSelection(selection);
        this.loadMapData(filter);
      })
    );
  }

  private buildMarkerElement(): HTMLDivElement {
    const el = document.createElement('div');
    el.className = 'map-marker';
    el.style.backgroundColor = 'grey';

    const letter = document.createElement('div');
    letter.className = 'map-marker-letter';
    letter.innerText = '?';
    el.appendChild(letter);

    const arrow = document.createElement('div');
    arrow.className = 'map-marker-arrow';
    arrow.style.borderTopColor = 'grey';
    el.appendChild(arrow);

    return el;
  }

  private async initMap(): Promise<void> {
    mapboxgl.accessToken = environment.mapbox;
    this.map = new mapboxgl.Map({
      container: `map-dashboard-${this.id}`,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: MapDashboardComponent.center,
      maxZoom: 15,
      zoom: 10,
      attributionControl: false,
    });
    this.map.keyboard.disable();
    this.map.dragRotate.disable();
    this.map.touchZoomRotate.disableRotation();
    this.map.boxZoom.disable();
    return new Promise((resolve, reject) => {
      this.map.on('load', () => {
        this.map.resize();
        this.loading = false;
        resolve();
      });
    });
  }

  private async loadMapData(filter: any): Promise<void> {
    if (isEqual(this.filter, filter)) {
      return;
    }
    if (this.loadingMapTm) {
      clearTimeout(this.loadingMapTm);
    }
    this.loadingData = true;
    this.loadingMapTm = setTimeout(() => {
      this.filter = filter;
      this.ngZone.runOutsideAngular(async () => {
        let items: MapInfoOrganizationalUnit[];
        await Promise.all([
          this.clearData(),
          this.organizationalUnitService
            .mapInfo(this.filter)
            .toPromise()
            .then(data => (items = data)),
        ]);
        items.forEach((item, index) => {
          if (item.layer && item.layer.geojson) {
            this.addLayer(this.buildLayer(item, index));
          }
          if (item.coordinates?.length === 2) {
            this.addMarker(this.buildMarker(item));
          }
        });
        this.fitBounds();
        this.ngZone.run(async () => (this.loadingData = false));
      });
    }, 300);
  }

  private fitBounds(): void {
    const coordinates = [];
    this.layers
      .filter(d => d.sourceData && d.sourceData.geometry)
      .forEach(d => d.sourceData.geometry.coordinates.forEach(cs => cs.forEach(c => coordinates.push(c))));
    this.markers.filter(d => d.position).forEach(d => coordinates.push(d.position));
    if (coordinates.length) {
      const bounds = coordinates.reduce(
        (bds, coord) => bds.extend(coord),
        new mapboxgl.LngLatBounds(coordinates[0], coordinates[0])
      );
      this.map.fitBounds(bounds, {padding: {top: 60, right: 40, bottom: 20, left: 40}});
    }
  }

  private async clearData(): Promise<any> {
    return Promise.all<void>([
      ...this.markers.map(m => {
        m.instance.getElement().className = m.instance.getElement().className.replace(' display', '');
        return new Promise<void>(resolve => setTimeout(() => (m.instance.remove(), resolve()), 300));
      }),
      ...this.layers.map(l => {
        this.map.setPaintProperty(l.id, 'fill-opacity', 0);
        return new Promise<void>(resolve =>
          setTimeout(() => (this.map.removeLayer(l.id), this.map.removeSource(l.id), resolve()), 300)
        );
      }),
    ]).then(() => ((this.layers = []), (this.markers = [])));
  }

  private isCursorOverLayer(): boolean {
    for (const l of this.layers) {
      if (l.over) {
        return true;
      }
    }
    return false;
  }

  private buildLayer(item: MapInfoOrganizationalUnit, index: number): Layer {
    const id = `layer-${item.id}`;
    const layer = new Layer();
    layer.id = id;
    layer.sourceData = item.layer.geojson;
    layer.color = item.layer.color || Layer.COLORS[index % Layer.COLORS.length];
    layer.item = item;

    this.map.on('mouseenter', layer.id, () => {
      layer.over = true;
      this.map.getCanvas().style.cursor = this.isCursorOverLayer() ? 'pointer' : '';
    });
    this.map.on('mouseleave', layer.id, () => {
      layer.over = false;
      this.map.getCanvas().style.cursor = this.isCursorOverLayer() ? 'pointer' : '';
    });

    this.map.on('click', layer.id, e => this.navigate(item));

    return layer;
  }

  private addLayer(layer: Layer): Promise<void> {
    const added = this.map.addLayer({
      id: layer.id,
      source: {type: 'geojson', data: layer.sourceData},
      type: 'fill',
      paint: {
        'fill-color': layer.color,
        'fill-opacity': 0,
        'fill-opacity-transition': {duration: 300},
      },
    });
    if (!added) {
      return;
    }
    setTimeout(() => this.map.setPaintProperty(layer.id, 'fill-opacity', 0.6), 0);
    this.layers.push(layer);
  }

  private buildMarker(item: MapInfoOrganizationalUnit): Marker {
    const id = `marker-${item.id}`;

    const marker = new Marker();
    marker.id = id;
    marker.name = item.name;
    marker.position = item.coordinates;

    marker.instance = new mapboxgl.Marker({
      element: this.markerElmt.cloneNode(true),
      anchor: 'bottom',
      offset: [20, 14],
    }).setLngLat(marker.position);

    const mElmt: HTMLDivElement = marker.instance.getElement();
    mElmt.addEventListener('click', () => this.ngZone.run(() => this.navigate(item)));

    if (item.metrics) {
      marker.metrics = item.metrics;
      const energyTag = this.energyTagService.getTag(marker.metrics.energy);
      mElmt.style.backgroundColor = energyTag.color;
      (mElmt.getElementsByClassName('map-marker-letter').item(0) as HTMLDivElement).innerText = energyTag.letter;
      (mElmt.getElementsByClassName('map-marker-arrow').item(0) as HTMLDivElement).style.borderTopColor =
        energyTag.color;
      marker.isPopupInitialized = false;
      mElmt.addEventListener('mouseenter', () => {
        if (!marker.isPopupInitialized) {
          marker.instance.setPopup(this.buildMarkerPopup(marker));
          marker.isPopupInitialized = true;
        }
        if (!marker.instance.getPopup().isOpen()) {
          marker.instance.togglePopup();
        }
      });
      mElmt.addEventListener('mouseleave', () =>
        marker.instance.getPopup().isOpen() ? marker.instance.togglePopup() : null
      );
    }
    return marker;
  }

  private buildMarkerPopup(marker: Marker): mapboxgl.Popup {
    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      offset: {top: [0, 5], bottom: [0, -55]},
    });
    const factory = this.resolver.resolveComponentFactory(EnergyTagsComponent);
    const component = factory.create(this.injector);
    component.instance.shrink = true;
    component.instance.consumption = marker.metrics ? marker.metrics.energy : null;
    component.changeDetectorRef.detectChanges();
    const container = document.createElement('div');
    const name = document.createElement('span');
    name.innerText = marker.name;
    container.appendChild(name);
    container.appendChild(component.location.nativeElement);
    popup.setDOMContent(container);
    return popup;
  }

  private addMarker(marker: Marker): void {
    marker.instance.addTo(this.map);
    setTimeout(() => (marker.instance.getElement().className += ' display'));
    this.markers.push(marker);
  }

  private navigate(item: MapInfoOrganizationalUnit): void {
    if (item.kind === OrganizationalUnitKind.GROUP) {
      this.router.navigate(['/energy-fluid-space/groups', item.id]);
    } else {
      const data = this.sessionService.$headerSelection.value;
      data.perimeter = false;
      data[item.kind] = {id: item.id, name: item.name, pathNames: item.pathNames};
      this.sessionService.setHeaderSelection(data);
    }
  }
}
