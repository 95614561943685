/** @format */

import { HttpClient, HttpProgressEvent } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { ModelMapper } from 'model-mapper';
import { Observable, of } from 'rxjs';
import { last, map, mergeMap, tap } from 'rxjs/operators';
import { AccessControl, AccessControlComment, AccessControlStatus } from '../classes/access-control';
import { IRequestUploadUrlData, File as SesioFile } from '../classes/file';
import { LibConfigService, LibConfig } from '../config.service';
import { v4 } from 'uuid';
import { EmbededUser } from '../classes/user';
import { map as lmap } from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class AccessControlService {
  constructor(@Inject(LibConfigService) protected config: LibConfig, protected http: HttpClient) {}

  public search(search: string): Observable<EmbededUser[]> {
    return this.http
      .get<any>(`${this.config.environment.apiUrl}/access-controls/search-applicants`, { params: { search } })
      .pipe(map(data => lmap(data, d => new ModelMapper(EmbededUser).map(d))));
  }

  public get(id: string): Observable<AccessControl> {
    return this.http
      .get<any>(`${this.config.environment.apiUrl}/access-controls/${id}`)
      .pipe(map(data => new ModelMapper(AccessControl).map(data)));
  }

  public create(data: any): Observable<AccessControl> {
    return this.http
      .post<any>(`${this.config.environment.apiUrl}/access-controls`, data)
      .pipe(map(data => new ModelMapper(AccessControl).map(data)));
  }

  public update(id: string, data: any): Observable<AccessControl> {
    return this.http
      .patch<any>(`${this.config.environment.apiUrl}/access-controls/${id}`, data)
      .pipe(map(data => new ModelMapper(AccessControl).map(data)));
  }

  public updateStatus(id: string, status: AccessControlStatus): Observable<boolean> {
    return this.http.patch<boolean>(`${this.config.environment.apiUrl}/access-controls/${id}/status`, { status });
  }

  public cancel(id: string): Observable<boolean> {
    return this.http.patch<boolean>(`${this.config.environment.apiUrl}/access-controls/${id}/cancel`, null);
  }

  public setNoPurchaseOrder(id: string): Observable<boolean> {
    return this.http.patch<boolean>(`${this.config.environment.apiUrl}/access-controls/${id}/no-purchase-order`, {});
  }

  public triggerPurchaseOrderFileGeneration(id: string): Observable<boolean> {
    return this.http.patch<boolean>(
      `${this.config.environment.apiUrl}/access-controls/${id}/trigger-purchase-order-file-generation`,
      {}
    );
  }

  public requestUploadUrl(id: string, data: IRequestUploadUrlData): Observable<string> {
    return this.http.post(`${this.config.environment.apiUrl}/access-controls/${id}/get-upload-url`, data, {
      responseType: 'text',
    });
  }

  public addComment(
    id: string,
    content: string,
    file: File,
    progress?: (event: HttpProgressEvent) => void
  ): Observable<AccessControlComment> {
    let observable: Observable<any> = of([]);
    if (file) {
      const commentFile = new ModelMapper(SesioFile).map({
        filename: file.name,
        size: file.size,
        mimetypes: [file.type],
        date: file.lastModified,
      });
      const requestUploadUrlData: IRequestUploadUrlData = {
        filename: v4(),
        contentType: file.type,
        prefix: 'comments',
      };
      observable = observable.pipe(
        mergeMap(() => this.requestUploadUrl(id, requestUploadUrlData)),
        tap(url => (commentFile.path = url.split('?')[0])),
        mergeMap(url => {
          return this.http
            .put(url, file, {
              reportProgress: true,
              observe: 'events',
              headers: {
                'Content-Type': file.type,
                'Content-Disposition': `attachment; filename=${encodeURIComponent(commentFile.filename)}`,
              },
            })
            .pipe(tap((event: HttpProgressEvent) => (progress ? progress(event) : null)));
        }),
        last(),
        map(() => commentFile)
      );
    }
    return observable.pipe(
      mergeMap((commentFile: any) => {
        const comment: any = {};
        if (file) {
          comment.file = new ModelMapper(SesioFile).serialize(commentFile);
        }
        if (content) {
          comment.content = content;
        }
        return this.http.post<any>(`${this.config.environment.apiUrl}/access-controls/${id}/comments`, comment);
      }),
      map(data => new ModelMapper(AccessControlComment).map(data))
    );
  }

  public setPurchaseOrderNumber(id: string, number: string): Observable<boolean> {
    return this.http.patch<boolean>(
      `${this.config.environment.apiUrl}/access-controls/${id}/set-purchase-order-number`,
      { number }
    );
  }
}
