/** @format */

import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ModelMapper} from 'model-mapper';
import {LibConfig, LibConfigService} from '../config.service';
import {HttpClient} from '@angular/common/http';
import {TechnicalControl} from '../classes/technical-control';
import {TechnicalControlObservation} from '../classes/technical-control-observation';

@Injectable({
  providedIn: 'root',
})
export class TechnicalControlService {
  constructor(@Inject(LibConfigService) private config: LibConfig, private http: HttpClient) {}

  public list(eqId: string): Observable<TechnicalControl[]> {
    return this.http
      .get<any>(`${this.config.environment.apiUrl}/equipment-technical-controls`, {params: {equipmentId: eqId}})
      .pipe(map(data => data.map(d => new ModelMapper(TechnicalControl).map(d))));
  }

  public last(eqId: string): Observable<TechnicalControl> {
    return this.http
      .get<any>(`${this.config.environment.apiUrl}/equipment-technical-controls/last`, {params: {equipmentId: eqId}})
      .pipe(map(data => new ModelMapper(TechnicalControl).map(data)));
  }

  public observations(eqId: string, robienCompliant?: boolean): Observable<TechnicalControlObservation[]> {
    const params: any = {equipmentId: eqId};
    if (robienCompliant !== undefined) {
      params.robienCompliant = robienCompliant;
    }
    return this.http
      .get<any[]>(`${this.config.environment.apiUrl}/equipment-technical-control-observations`, {params})
      .pipe(map(data => data.map(d => new ModelMapper(TechnicalControlObservation).map(d))));
  }

  public countObservations(
    eqId: string,
    robienCompliant?: boolean
  ): Observable<{total: number; robienCompliant: number}> {
    const params: any = {equipmentId: eqId};
    if (robienCompliant !== undefined) {
      params.robienCompliant = robienCompliant;
    }
    return this.http.get<any>(`${this.config.environment.apiUrl}/equipment-technical-control-observations/count`, {
      params,
    });
  }
}
